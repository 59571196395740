import Form from "app/components/organisms/form";
import React from "react";

export default function TableGroup(props) {
  const { structure, value, onChange, mode, errors } = props;

  const changeValue = (key, val) => {
    onChange && onChange(key, val[key]);
  };

  return (
    <table className="w-full">
      <tbody className="border">
        {!!structure?.length &&
          structure.map((item, key) => (
            <tr key={key} className="border-b last:border-none">
              <td className="bg-gray-200 font-bold text-xs py-3 px-2">{item.title}</td>
              <td className="bg-gray-50 pt-3 px-2">
                <Form value={{ [item.key]: value ? value[item.key] : undefined }} structure={[{ ...item, title: null }]} mode={mode} onChange={(ev) => changeValue(item.key, ev)} />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
