import React from "react";

export default function Indicator(props) {
  const { count = 0, active = false, noShow = false, className = "" } = props;

  if (count > 0 || active) {
    if (noShow)
      return (
        <span className={className}>
          <span className="ml-1 inline-block align-top w-2 h-2 bg-red-700 rounded-full"></span>
        </span>
      );
    else
      return (
        <span className={className}>
          <span className="ml-1 inline-flex items-center justify-center w-6 h-6 text-xs text-white bg-red-700  rounded-full">{!noShow && <>{count < 100 ? count : "99+"}</>}</span>
        </span>
      );
  }

  return null;
}
