import { isArray, isObject } from "app/utils/validators/dataValidator";

const hideField = (element, data) => {
  const input = element.field ? element.field.value || element.field || null : null;
  const when = element.when ? element.when.value || element.when || null : null;
  const value = element.value || "";

  if (input && when) {
    if (when === "equal" && !(data[input] && (data[input].value === value || data[input] === value))) return true;
    else if (when === "notEqual" && (data[input] === value || (data[input] && data[input].value === value))) return true;
    else if (when === "greaterThan" && (!data[input] || parseInt(data[input]) <= parseInt(value))) return true;
    else if (when === "greaterOrEqualThan" && (!data[input] || parseInt(data[input]) < parseInt(value))) return true;
    else if (when === "lowerThan" && parseInt(data[input]) >= parseInt(value)) return true;
    else if (when === "lowerOrEqualThan" && parseInt(data[input] || 0) > parseInt(value)) return true;
    else if (when === "isNull" && !data[input]) return true;
    else if (when === "isNotNull" && !!data[input]) return true;
    else if (when === "isTrue" && !data[input]) return true;
    else if (when === "isFalse" && !!data[input]) return true;
    else if (when === "includes" && !(data[input] && data[input].length && data[input].includes(value))) return true;
  }
  return false;
};

const manageHiddenFields = (structure, data) => {
  const conditionals = [];
  data = data || {};
  if (structure && structure.length) {
    if (structure.find((item) => item.conditional)) {
      for (let i = 0; i < structure.length; i++) {
        const element = structure[i];
        if (element.conditional) {
          if (isObject(element.conditional)) {
            const hide = hideField(element.conditional, data);
            if (hide) conditionals.push(element.key);
          } else if (isArray(element.conditional)) {
            let hide = true;
            for (let i = 0; i < element.conditional.length; i++) {
              const hideOne = hideField(element.conditional[i], data);
              if (!hideOne) hide = false;
            }
            if (hide) conditionals.push(element.key);
          } else {
            console.log("error with conditional field");
          }
        }
      }
    }
  }
  return conditionals;
};

export { manageHiddenFields };
