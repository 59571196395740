import Dropzone from "app/components/atoms/inputs/dropzone";
import { useUploadMediaMutation } from "app/stores/files";
import { showServerError } from "app/utils/server";
import React from "react";
import { useSelector } from "react-redux";

export default function UploadArea(props) {
  const { forceType = undefined, formats, loading = true, reload, select } = props;

  // STORE
  const { project } = useSelector((state) => state.project);

  // REQUEST
  const [uploadMedia, { isLoading: isUploading }] = useUploadMediaMutation();

  // WHEN UPLOADED
  const onChange = (files, valid) => {
    if (files && files.length && valid) {
      uploadMedia({ files, forceType: forceType || undefined, project: project?.id }).then((res) => {
        if (res && res.data) {
          reload();
          select(res.data.map((item) => item.id));
        } else {
          showServerError(res);
        }
      });
    }
  };

  const isLoading = loading || isUploading;

  return (
    <div>
      <Dropzone multi={forceType ? false : true} onChange={onChange} formats={formats} isLoading={isLoading} disabled={isLoading} />
    </div>
  );
}
