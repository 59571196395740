import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Form from "app/components/organisms/form";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function SelectPost(props) {
  const { title, value, description, multiple = false, error, errorMessage, mode, isRequired, onChange } = props;

  const components = [
    {
      type: "Select",
      key: "type",
      title: "Post Type",
      placeholder: "Selecione um Post Type",
      options: [],
    },
    {
      type: "Select",
      key: "post",
      title: "Post",
      multiple: false,
      loadmore: true,
      posttype: undefined,
      placeholder: "Selecione um Post",
      options: [],
    },
  ];
  let styles = {
    title: "block text-sm font-medium text-slate-700",
    box: "bg-gray-50 rounded-lg border",
  };

  // VARIABLES
  const selectedType = value?.type?.value;

  // STATE
  const [structure, setStructure] = useState(components);

  // STORE
  const { language } = useSelector((state) => state.project);
  const { types, posts } = useSelector((state) => state.inputs);

  // SET MULTIPLE
  useEffect(() => {
    const temp = [...structure];
    const index = temp.findIndex((e) => e.key === "post");
    if (index !== -1 && temp[index].multiple !== multiple) {
      temp[index].multiple = multiple;
      setStructure([...temp]);
    }
  }, [selectedType, multiple]);

  // SET TYPES
  useEffect(() => {
    if (types?.length) {
      const temp = [...structure];
      const index = temp.findIndex((e) => e.key === "type");
      if (index !== -1) if (!temp[index]?.options?.length) temp[index].options = types;
      setStructure([...temp]);
    }
  }, [types, multiple]);

  // SET OPTIONS
  useEffect(() => {
    if (selectedType) {
      const temp = [...structure];
      const options = posts.filter((e) => e.posttype == selectedType && e.language == language.slug).map((e) => ({ value: e.id, label: e.title, type: e.posttype }));
      const index = temp.findIndex((e) => e.key === "post");
      if (index !== -1) {
        temp[index].options = options;
        temp[index].posttype = selectedType;
      }
      setStructure([...temp]);
    }
  }, [language, selectedType]);

  if (mode === "input-button") {
    styles.box += " -mt-[1px]";
  } else {
    styles.box += " px-5 pt-5 pb-3";
  }

  if (error) {
    styles.box += " border-red-600";
    styles.title += " text-red-600";
  } else {
    styles.box += " border-transparent";
    styles.title += " text-slate-700";
  }

  return (
    <div className={`block mb-3 ${mode ? mode : ""}`}>
      <div className="flex items-end justify-between">
        <div>
          {title && <span className={styles.title}>{title}</span>}
          {description && <span className="block text-xs font-light text-slate-400 -mt-1.5 mb-1">{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <div className={styles.box}>
        <Form value={value} structure={structure} onChange={onChange} className="flex gap-4" />
      </div>
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </div>
  );
}
