import Icon from "app/components/atoms/icons/icon";
import Indicator from "app/components/atoms/indicator";
import Permission from "app/components/molecules/permission";
import { projectSettingsNavigation } from "app/components/organisms/settings";
import { i18n } from "app/i18n";
import { useSelector } from "react-redux";
import { NavLink, Route, Routes, useParams } from "react-router-dom";

export default function ProjectSettings() {
  // PARAMS
  const { project: idProject, key = "main" } = useParams();

  // STORE
  const { project } = useSelector((store) => store.project);

  const path = idProject ? `/project/${idProject}/settings/` : undefined;

  return (
    <>
      <div className="flex h-full">
        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
          <main className="flex flex-1 overflow-hidden">
            <div className="flex flex-1 flex-col overflow-y-auto xl:overflow-hidden">
              {/* Breadcrumb */}
              <nav aria-label="Breadcrumb" className="border-b border-slate-200 bg-white xl:hidden">
                <div className="mx-auto flex max-w-3xl items-start px-4 py-3 sm:px-6 lg:px-8">
                  <a href="#" className="-ml-1 inline-flex items-center space-x-3 text-sm font-medium text-slate-900">
                    <Icon name="chevronLeft" width="w-5" height="h-5" className="text-slate-400" />
                    <span>{i18n("label.settings")}</span>
                  </a>
                </div>
              </nav>

              <div className="flex flex-1 xl:overflow-hidden">
                {/* Secondary sidebar */}
                <nav aria-label="Sections" className="hidden w-96 flex-shrink-0 border-r border-slate-200 bg-white xl:flex xl:flex-col">
                  <div className="flex h-16 flex-shrink-0 items-center border-b border-slate-200 px-6">
                    <p className="text-lg font-medium text-slate-900">{i18n("label.settings")}</p>
                  </div>
                  <div className="min-h-0 flex-1 overflow-y-auto">
                    {projectSettingsNavigation.map((item) => {
                      const notify = item.label === "pages" && !project?.settings?.frontend?.home;
                      const current = item.label === key;
                      let styles = {
                        button: "border-slate-200 flex border-b p-6 ",
                      };
                      if (current) styles.button += "bg-blue-50 bg-opacity-50";
                      else styles.button += "hover:bg-blue-50 hover:bg-opacity-50";

                      return (
                        <Permission key={item.label} {...item?.permission}>
                          <NavLink to={`${path}${item.label}`} className={styles.button} aria-current={current ? "page" : undefined}>
                            <div className="relative">
                              <Icon name={item.icon} width="w-6" height="h-6" className="-mt-0.5 flex-shrink-0 text-slate-400" />
                              <Indicator active={notify} noShow className="absolute ml-1 top-0 -right-1" />
                            </div>
                            <div className="ml-3 text-sm">
                              <p className="font-medium text-slate-900 relative">{i18n(item.title)}</p>
                              <p className="mt-1 text-slate-500">{i18n(item.description)}</p>
                            </div>
                          </NavLink>
                        </Permission>
                      );
                    })}
                  </div>
                </nav>

                {/* Main content */}
                <div className="flex-1 xl:overflow-y-auto">
                  <div className="mx-auto max-w-4xl px-4 py-10 sm:px-6 lg:px-8 lg:py-12">
                    <Routes>
                      {projectSettingsNavigation.map((item, key) => (
                        <Route key={key} path={item.label} element={item.element} />
                      ))}
                      <Route index element={projectSettingsNavigation[0].element} />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
