import Icon from "app/components/atoms/icons/icon";
import { Content, Header, Struture } from "app/components/atoms/modal";
import ModalCategoryType from "app/components/molecules/modals/modalCategoryType";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import Permission from "app/components/molecules/permission";
import { i18n } from "app/i18n";
import { useCreateCategoryMutation, useDeleteCategoryMutation, useUpdateCategoryMutation } from "app/stores/project";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ItemCategory from "./itemCategory";

export default function ListCategories() {
  // PARAMS
  const { key } = useParams();

  // STORE
  const { project, info } = useSelector((state) => state.project);

  // STATE
  const [isOpen, setIsOpen] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(null);
  const [modalCategory, setModalCategory] = useState(null);

  // REQUESTS
  const [createCategory, { isLoading: isCreateLoading }] = useCreateCategoryMutation();
  const [updateCategory, { isLoading: isUpdateLoading }] = useUpdateCategoryMutation();
  const [deleteCategory, { isLoading: isDeleteLoading }] = useDeleteCategoryMutation();

  // VARS
  const isLoading = isCreateLoading || isUpdateLoading || isDeleteLoading;
  const postType = info?.postTypes?.find((e) => e.key === key) || null;
  const categories = postType?.categories || null;

  // FUNCTIONS
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  // CALL MODAL TO CREATE
  const onCreateCategory = (posttype, type) => {
    setModalCategory({
      isOpen: true,
      onClose: () => {
        setModalCategory(null);
      },
      onSave: ({ key, translations }) => {
        createCategory({ posttype, type, key, project: project.id, translations }).then((res) => {
          if (res.data) showServerSuccess(i18n("toast.create_success"));
          else showServerError(res);
          setModalCategory(null);
        });
      },
    });
  };

  // CALL MODAL TO EDIT OR DELETE
  const onEditCategory = (item) => {
    setModalCategory({
      isOpen: true,
      category: item,
      onClose: () => {
        setModalCategory(null);
      },
      onSave: ({ key, translations }) => {
        updateCategory({ id: item.id, key, translations }).then((res) => {
          if (res.data) showServerSuccess(i18n("toast.update_success"));
          else showServerError(res);
          setModalCategory(null);
        });
      },
      onDelete: () => {
        setModalConfirm({
          isOpen: true,
          type: "DELETE",
          onConfirm: () => {
            deleteCategory({ id: item.id }).then((res) => {
              if (res.data) showServerSuccess(i18n("toast.create_success"));
              else showServerError(res);
              setModalConfirm(null);
              setModalCategory(null);
            });
          },
          onClose: () => {
            setModalConfirm(null);
          },
        });
      },
    });
  };

  if (!categories?.length) return null;

  return (
    <>
      <button type="button" className="btn-primary" onClick={onOpen}>
        {i18n("button.categories")}
      </button>

      <Struture rightSide isOpen={isOpen} onClose={onClose}>
        <Header rightSide title={i18n("label.categories")} subtitle={postType.title} onClose={onClose} />
        <Content rightSide>
          <div>
            <div className="space-y-1 cursor-pointer divide-y">
              {!!categories?.length ? (
                <>
                  <div className="w-full h-full flex-1 space-y-1 cursor-pointer divide-y">
                    {categories.map((type, key) => {
                      return (
                        <div key={key} className="py-6">
                          <div className="flex justify-between items-center mb-2">
                            <div className="flex items-center gap-2 font-medium text-black ">
                              <p>{type.title}</p>
                              {type.multi && <kbd className="text-[9px] bg-gray-100 rounded px-1">{i18n("label.multi")}</kbd>}
                              {type.hierarchy && <kbd className="text-[9px] bg-gray-100 rounded px-1">{i18n("label.hierarchy")}</kbd>}
                            </div>
                            <div>
                              <Permission project dev min="EDITOR">
                                <button type="button" className="btn-icon" onClick={() => onCreateCategory(postType.id, type.id)}>
                                  <Icon name="plus" />
                                </button>
                              </Permission>
                            </div>
                          </div>
                          {!!type.options?.length ? (
                            <div className="flex flex-col gap-1">
                              {type.options.map((item, index) => (
                                <ItemCategory key={index} {...item} slug={item.key} onEdit={onEditCategory} />
                              ))}
                            </div>
                          ) : (
                            <>
                              <p className="text-xs text-gray-400 text-left">{i18n("label.nothing_found")}</p>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  <p className="text-sm text-center">{i18n("label.nothing_found")}</p>
                </>
              )}
            </div>
          </div>
        </Content>
      </Struture>

      <ModalConfirm {...modalConfirm} isLoading={isLoading} />
      <ModalCategoryType {...modalCategory} isLoading={isLoading} />
    </>
  );
}
