import { authApi } from "app/stores/auth";
import { usersApi } from "app/stores/users";
import { useCookies } from "react-cookie";
import { Navigate, Outlet } from "react-router-dom";

export default function ProjectMiddleware(props) {
  const [{ logged_in }, setCookie, removeCookie] = useCookies(["logged_in", "access_token"]);
  const { data: user, isLoading, isFetching, isUninitialized } = usersApi.endpoints.getMe.useQuery(null, { skip: false, refetchOnMountOrArgChange: true });
  const { data: emptyProject, isLoading: isLoadingProject, isFetching: isFetchingProject } = authApi.endpoints.emptyProject.useQuery(null, { skip: false, refetchOnMountOrArgChange: true });
  const loading = isLoading || isFetching || isLoadingProject || isFetchingProject;
  const hasUser = (user && user.user) || (user && !user.error);

  if (isUninitialized || loading) return <div className="loader-full-page"><span className="loader-styled"></span></div>;

  // CLEAN COOKIES WHEN LOGIN RETURN MISTAKES
  if (!hasUser) {
    removeCookie("logged_in");
    removeCookie("access_token");
    return <Navigate to="/login" />;
  }

  return <Outlet />;
}
