import { languagesAll } from "countries-list";
import translations from "../../i18n";

export function i18n(string) {
  if (!string) return "";
  return translations.t(string);
}

export function changeLocale(locale) {
  translations.changeLanguage(locale);
}

export function getLocale() {
  return translations.language || window.localStorage.i18nextLng;
}

export function getLocales() {
  return translations.languages.map((slug) => ({ value: slug, label: languagesAll[slug]?.name || slug }));
}
