import { i18n } from "app/i18n";
import customValidator from "app/utils/validators/customValidator";
import validator from "validator";

const tabs = [
  {
    title: i18n("label.recipients"),
    key: "settings",
  },
];

const components = [
  {
    key: "settings",
    structure: [
      {
        type: "List",
        key: "recipients",
        format: "simple",
        buttonText: i18n("label.add_recipient"),
        noDataText: i18n("label.no_recipients"),
        components: [
          {
            type: "Text",
            title: "Email",
            key: "email",
            rules: [
              { method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") },
              { method: validator.isEmail, validWhen: true, message: i18n("input.required_email") },
            ],
          },
        ],
        rules: [{ method: customValidator.isObjectEmpty, validWhen: false, message: i18n("input.required_field") }],
      },
    ],
  },
];

export { tabs, components };
