import { i18n } from "app/i18n";
import validator from "validator";

export const components = [
  { type: "Text", title: i18n("label.name"), key: "name", placeholder: "John Doe Backoffice", rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }] },
  { type: "Text", title: i18n("label.email"), key: "email", placeholder: "johndoe@svalla.com", rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }] },
  { component: "line" },
  { type: "Text", title: i18n("label.host"), key: "host", placeholder: "mail.host.com", rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }] },
  { type: "Text", title: i18n("label.port"), key: "port", placeholder: "465", rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }] },
  { type: "Toggle", title: i18n("label.secure"), key: "secure" },
  { type: "Toggle", title: i18n("label.reject_unauthorized"), key: "rejectUnauthorized" },
  { type: "Text", title: i18n("label.user"), key: "user", placeholder: "johndoe@svalla.com", rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }] },
  { type: "Password", title: i18n("label.password"), key: "password", rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }] },
];
