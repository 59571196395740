import Loading from "app/components/atoms/loading";
import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import { i18n } from "app/i18n";
import { inputsApi } from "app/stores/inputs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const FULFILLED = "fulfilled";

export default function ModalDynamicPosts(props) {
  const { isOpen = false, filters, isLoading, onClose = () => {} } = props;

  // STORE
  const dispatch = useDispatch();
  const { post } = useSelector((state) => state.post);
  const { language, project } = useSelector((store) => store.project);

  // STATE
  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) getPosts();
    else setPosts(null);
  }, [isOpen]);

  const getPosts = async () => {
    setLoading(true);
    const take = filters?.number ? (filters.number !== "-1" ? parseInt(filters.number) : undefined) : 0;
    const skip = undefined;
    const where = {
      post: {
        project: { id: project.id },
        postType: undefined,
      },
      published: true,
      language: { id: language.id },
    };
    const orderBy = getOrderBy();

    if (post && filters?.related) {
      where.post.postType = { id: post.postType.id };
      where.post.NOT = { id: post.id };

      if (filters?.sameCategory) {
        const content = post.translations.find((e) => e.language.slug === language.slug);
        const categories = content.categories;

        if (categories?.length) {
          where.categories = {
            some: {
              OR: content.categories.map((e) => ({ id: e.id })),
            },
          };
        }
      }
    } else if (filters?.type?.value) {
      where.post.postType = { id: filters.type.value };
    }

    const { data, status } = await dispatch(inputsApi.endpoints.getDynamicSelection.initiate({ take, skip, where, orderBy }, { refetchOnMountOrArgChange: true, forceRefetch: true }));
    if (status === FULFILLED && data && data.length) {
      setPosts(data);
      setLoading(false);
    } else {
      setPosts(null);
      setLoading(false);
    }
  };

  const getOrderBy = () => {
    const string = filters?.order?.value;
    if (!string) return undefined;
    else if (string.indexOf("_") != -1) {
      const parts = string.split("_");
      const key = parts[0];
      const value = parts[1].toLowerCase();
      return { [key]: value };
    } else return undefined;
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={i18n("label.check_results")} onClose={onClose} />
      <Content>
        <Loading active={loading} />
        <div className="mb-6 text-sm text-slate-400">
          <p>Isto é uma representação gráfica da sua seleção.</p>
          <p>Para além dos campos que selecionou, apenas são apresentados páginas publicadas.</p>
        </div>
        {posts?.length ? (
          <div className="overflow-x-auto whitespace-nowrap w-full">
            <div className="flex gap-4 mb-2">
              {posts.map((item, key) => (
                <div key={key} className="border rounded-lg bg-gray-50 w-64">
                  <div className="h-32 w-full relative bg-gray-200 aspect-video">{item?.thumbnail?.href && <img src={item?.thumbnail?.href} className="w-full h-full object-cover" />}</div>
                  <div className="py-3 px-4">
                    <p className="font-semibold text-lg">{item.title}</p>
                    <p className="font-thin text-xs">{item.excerpt}</p>
                    {item?.url && (
                      <a href={item?.url} className="text-xs text-slate-500 mt-2 inline-block">
                        Abrir página
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p>no posts to show</p>
        )}
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} />
    </Struture>
  );
}
