// Molecules
import ListTable from "app/components/molecules/table";
import { headers } from "./constants";

// Inner
import { useGetOccursQuery } from "app/stores/files";
import ItemOccurrence from "./itemOccurrence";

export default function ListOccurs(props) {
  const { id: idFile } = props;

  // REQUESTS
  const { data } = useGetOccursQuery({ id: idFile }, { refetchOnMountOrArgChange: true });

  const occurrences = data?.usedBy?.pages?.length ? data.usedBy.pages : null;

  return (
    <div className="w-full">
      <div>
        {occurrences ? (
          <ListTable headers={headers} total={data?.usedBy?.total} store="post">
            {occurrences.map((ocurrence) => (
              <ItemOccurrence {...ocurrence} key={ocurrence.id} />
            ))}
          </ListTable>
        ) : (
          <p>NOTHING FOUND</p>
        )}
      </div>
    </div>
  );
}
