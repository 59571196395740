import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import Accordion from "app/components/molecules/accordion";
import Permission from "app/components/molecules/permission";
import Form from "app/components/organisms/form";
import { i18n } from "app/i18n";
import { useUpdateBlockPostTypesMutation } from "app/stores/block";
import { setUnsavedChanges } from "app/stores/project";
import { getFlag } from "app/utils/languages";
import { hasPermissionProject } from "app/utils/roles";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

export default function BlockSidebar(props) {
  // PARAMS
  const { loading } = props;
  const dispatch = useDispatch();
  const { key, project: idProject, lang } = useParams();

  // STATE
  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(null);
  const [dataChanged, setDataChanged] = useState(false);

  // STORE
  const { user } = useSelector((store) => store.auth);
  const { block } = useSelector((store) => store.block);
  const { project, languages, info, sidebarChanges } = useSelector((store) => store.project);

  // REQUESTS
  const [updateBlockPostTypes, { isLoading: isUpdating }] = useUpdateBlockPostTypesMutation();

  // VARIABLES
  const posttypes = info && info.postTypes;
  const content = block?.translations?.find((item) => item.language.slug === lang);
  const isLoading = loading || !block || isUpdating;

  useEffect(() => {
    if (block) {
      const vals = getValue();
      setValue({ ...vals });
    }
  }, [block]);

  useEffect(() => {
    if (user && project && posttypes?.length) {
      const input = {
        type: "Checklist",
        key: "posttypes",
        columns: "grid-col-1",
        description: i18n("label.relation.blocks.description"),
        options: posttypes.map((item) => ({ value: item.id, label: item.title })),
      };
      setStructure([input]);
    }
  }, [posttypes, project, user]);

  useEffect(() => {
    setDataChanged(isDataDifferent());
  }, [value]);

  useEffect(() => {
    if (dataChanged !== sidebarChanges) dispatch(setUnsavedChanges(dataChanged));
    return () => dispatch(setUnsavedChanges(false));
  }, [dataChanged]);

  const isDataDifferent = () => {
    if (isLoading) return false;
    return JSON.stringify(getValue()) !== JSON.stringify(value);
  };

  const getValue = () => {
    if (block) return { posttypes: block.postTypes.map((item) => item.id) };
    return {};
  };

  const submitChanges = () => {
    updateBlockPostTypes({ id: block.id, posttypes: value?.posttypes }).then((res) => {
      if (res?.data) showServerSuccess(i18n("toast.success_changes_update"));
      else showServerError(res);
    });
  };

  return (
    <div className="h-full">
      <div className="bg-white min-w-[336px] w-[336px] rounded-t-md border-b h-12" />
      <div className="bg-white flex flex-col justify-between rounded-b-xl xl:rounded-b-md rounded-t-xl xl:rounded-t-none w-full md:min-w-[336px] xl:w-[336px] shadow-lg pb-3 max-h-full overflow-hidden">
        <div className="overflow-auto max-h-full">
          <div className="relative z-10">
            <Accordion title={i18n("label.translations")} disabled defaultOpen={true}>
              {!!languages?.length && (
                <div>
                  <ul className="p-5">
                    {languages.map(({ language }, index) => {
                      const exists = block?.translations?.length ? block.translations.find((e) => e.language.slug === language.slug) : null;
                      return (
                        <li key={index} className="flex items-center justify-between py-3 border-b last:border-b-0">
                          {exists && content ? (
                            <>
                              {content.id === exists.id ? (
                                <>
                                  <span className="text-sm text-slate-900">
                                    <span className="mr-1">{getFlag(language.slug)}</span> {exists.title}
                                  </span>
                                </>
                              ) : (
                                <>
                                  {/* ACCESS TO TRANSLATION */}
                                  <Link to={`/project/${idProject}/posts/${key}/${block.id}/${language.slug}`}>
                                    <span className="text-sm">
                                      <span className="mr-1">{getFlag(language.slug)}</span> {exists.title}
                                    </span>
                                  </Link>
                                  <Link to={`/project/${idProject}/posts/${key}/${block.id}/${language.slug}`}>
                                    <PencilIcon className="w-5" />
                                  </Link>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {/* CREATE TRANSLATION */}
                              <span className="text-sm text-gray-400">
                                <span className="mr-1">{getFlag(language.slug)}</span> {language.name}
                              </span>
                              <Permission project dev min="MANAGER">
                                <button type="button" onClick={() => {}}>
                                  <PlusIcon className="w-5" />
                                </button>
                              </Permission>
                            </>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </Accordion>
            <Accordion title={i18n("label.associated_to")} disabled counter={block?.postTypes?.length || 0} className="relative" defaultOpen={true}>
              {isUpdating ? (
                <div role="status" className="max-w-sm animate-pulse pt-7 pb-5">
                  <div className="h-2.5 bg-slate-300 rounded-full w-48 mb-4"></div>
                  <div className="h-2 bg-slate-300 rounded-full max-w-[360px] mb-2.5"></div>
                  <div className="h-2 bg-slate-300 rounded-full mb-2.5"></div>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <div className="px-5 py-3">
                  <Form disabled={!hasPermissionProject(user, project, "EDITOR", true)} structure={structure} onChange={setValue} value={value} />
                </div>
              )}
            </Accordion>
          </div>
        </div>
        {isDataDifferent() ? (
          <div className="flex justify-end pr-10 pt-4">
            <button
              type="button"
              disabled={isLoading}
              onClick={submitChanges}
              className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none  disabled:bg-gray-300 disabled:pointer-events-none"
            >
              {i18n("button.save")}
            </button>
          </div>
        ) : (
          <div className="pb-7" />
        )}
      </div>
    </div>
  );
}
