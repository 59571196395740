import Dropdown from "app/components/molecules/dropdown";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalInput from "app/components/molecules/modals/modalInput";
import { i18n } from "app/i18n";
import { getRandomString } from "app/utils/content";
import { isArray } from "app/utils/validators/dataValidator";
import update from "immutability-helper";
import { memo, useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import Item from "./item";
import Permission, { HasPermission } from "app/components/molecules/permission";

const FormBuilder = memo(function (props) {
  const { value, onChange, errors } = props;

  const [modal, setModal] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(null);

  // GET INFO & POSITION OF ELEMENT IN ARRAY
  const findItem = useCallback(
    (id) => {
      const card = value.filter((c) => `${c.id}` === id)[0];
      return { card, index: value.indexOf(card) };
    },
    [value]
  );

  // WHEN INPUT IS DRAGGED
  const onMoveItem = useCallback(
    (id, atIndex) => {
      const { card, index } = findItem(id);
      onChange(
        update(value, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        })
      );
    },
    [findItem, value, onChange]
  );

  // FUNCTION FOR DROPAREA
  const [, drop] = useDrop(() => ({ accept: "FORMBUILDER" }));

  // ADD A NEW INPUT TO LIST
  const addInput = (type) => {
    setModal({
      type,
      isOpen: true,
      emailFieldExists: isArray(value, true) ? value.find((e) => e.emailField) : null,
      unavailableSlugs: isArray(value, true) ? value.map((e) => e.slug) : [],
      onSubmit: (input) => {
        let temp = isArray(value, true) ? [...value] : [];
        temp.push({ ...input, id: getRandomString(5) });
        onChange([...temp]);
        setModal(false);
      },
      onClose: () => setModal(false),
    });
  };

  // EDIT INPUT FROM LIST
  const onEditInput = (item) => {
    setModal({
      type: item.type,
      input: item,
      isOpen: true,
      emailFieldExists: value ? value.filter((e) => e.id !== item.id).find((e) => e.emailField) : null,
      unavailableSlugs: value ? value.filter((e) => e.id !== item.id).map((e) => e.slug) : [],
      onSubmit: (data) => {
        const temp = [...value];
        const index = temp.findIndex((e) => e.id === item.id);
        if (index != -1) temp[index] = data;
        onChange([...temp]);
        setModal(false);
      },
      onClose: () => setModal(false),
    });
  };

  // REMOVE INPUT FROM LIST
  const onRemoveInput = (item) => {
    setModalConfirm({
      isOpen: true,
      type: "DELETE",
      text: "Do you want to delete this input from the form?",
      onConfirm: () => {
        const temp = [...value];
        const index = temp.findIndex((e) => e.id === item.id);
        if (index !== -1) temp.splice(index, 1);
        else return null;
        onChange(temp);
        setModalConfirm(null);
      },
      onClose: () => {
        setModalConfirm(null);
      },
    });
  };

  return (
    <>
      <div className="block">
        <div className="mb-8 text-sm text-slate-700 leading-5">
          <p>TEXT ABOUT BUILD FORM (SUPER SHORT)</p>
        </div>
        {!!value?.length && (
          // LIST OF INPUTS
          <div className="h-full w-full overflow-auto">
            <div ref={drop} className="flex flex-col gap-y-2">
              {value.map((item) => (
                <Item
                  key={item.id}
                  {...item}
                  onMoveItem={onMoveItem}
                  findItem={findItem}
                  onEdit={() => onEditInput(item)}
                  onRemove={() => onRemoveInput(item)}
                  error={errors?.find((e) => e.input === item.slug)}
                />
              ))}
            </div>
          </div>
        )}
        <Permission project dev min="EDITOR">
          <div className="flex justify-center mt-6">
            <Dropdown
              text={i18n("label.add_input")}
              icon="plus"
              position={!value?.length ? "bottom-center" : "top-center"}
              options={[
                { title: "Text", onClick: () => addInput("text") },
                { title: "Textarea", onClick: () => addInput("textarea") },
                { title: "Select", onClick: () => addInput("select") },
                { title: "File", onClick: () => addInput("file") },
                { title: "Checkbox", onClick: () => addInput("checkbox") },
                { title: "Checklist", onClick: () => addInput("checklist") },
              ]}
            />
          </div>
        </Permission>
      </div>

      <ModalInput {...modal} />
      <ModalConfirm {...modalConfirm} />
    </>
  );
});
export default FormBuilder;
