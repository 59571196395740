import { gql } from "@apollo/client";
import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { server } from "../../utils/server";

const initialState = {
  user: null,
  token: null,
  isEmptyDashboard: false,
  connectionFailed: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, { payload }) => {
      state.user = payload;
    },
    setLogout: (state) => {
      state.user = null;
    },
    setEmptyDashboard: (state, { payload }) => {
      state.isEmptyDashboard = payload;
    },
    setConnectionFailed: (state, { payload }) => {
      state.connectionFailed = payload;
    },
  },
});

export const authApi = createApi({
  reducerPath: "authAPI",
  baseQuery: graphqlRequestBaseQuery(server),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password, token }) => ({
        document: gql`
          mutation login($email: String!, $password: String!, $token: String) {
            login(email: $email, password: $password, token: $token) {
              error
              message
              user {
                id
              }
              token {
                update_token
                access_token
                refresh_token
                request_token
              }
            }
          }
        `,
        variables: {
          email,
          token,
          password,
        },
      }),
      transformResponse: (response) => response.login,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) dispatch(authSlice.actions.setConnectionFailed(false));
        } catch ({ error }) {
          console.log(error);
          if (error.name === "TypeError" && error.message) dispatch(authSlice.actions.setConnectionFailed(true));
        }
      },
    }),
    logout: builder.mutation({
      query: ({ email, password }) => ({
        document: gql`
          mutation logout($email: String!, $password: String!) {
            logout(email: $email, password: $password) {
              access_token
            }
          }
        `,
        variables: {
          email,
          password,
        },
      }),
      transformResponse: (response) => response.logout,
    }),
    initSvalla: builder.mutation({
      query: ({ user, project, email }) => ({
        document: gql`
          mutation initSvalla($user: UserStartInput!, $project: ProjectStartInput!, $email: EmailSettings!) {
            initSvalla(user: $user, project: $project, email: $email) {
              id
            }
          }
        `,
        variables: {
          user,
          project,
          email,
        },
      }),
      transformResponse: (response) => response.initSvalla,
    }),
    emptyProject: builder.query({
      query: () => ({
        document: gql`
          query noProject {
            noProject
          }
        `,
      }),
      transformResponse: (response) => response.noProject,
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) dispatch(authSlice.actions.setEmptyDashboard(data));
        } catch ({ error }) {
          if (error.name === "TypeError" && error.message) dispatch(authSlice.actions.setConnectionFailed(true));
        }
      },
    }),
    checkEmailSettings: builder.mutation({
      query: ({ email, args }) => ({
        document: gql`
          query testEmail($email: String!, $args: EmailSettings!) {
            testEmail(email: $email, args: $args)
          }
        `,
        variables: {
          email,
          args,
        },
      }),
      transformResponse: (response) => response.testEmail,
    }),
    validateAccount: builder.mutation({
      query: ({ token }) => ({
        document: gql`
          mutation validateAccount($token: String!) {
            validateAccount(token: $token) {
              result
              error
              message
            }
          }
        `,
        variables: {
          token,
        },
      }),
      transformResponse: (response) => response.validateAccount,
    }),
    resendValidationEmail: builder.mutation({
      query: ({ email }) => ({
        document: gql`
          mutation resendValidationEmail($email: String!) {
            resendValidationEmail(email: $email)
          }
        `,
        variables: {
          email,
        },
      }),
      transformResponse: (response) => response.resendValidationEmail,
    }),
    requestNewPassword: builder.mutation({
      query: ({ email }) => ({
        document: gql`
          mutation requestNewPassword($email: String!) {
            requestNewPassword(email: $email) {
              result
              error
              message
            }
          }
        `,
        variables: {
          email,
        },
      }),
      transformResponse: (response) => response.requestNewPassword,
      async onQueryStarted(args, { dispatch }) {
        try {
        } catch ({ error }) {
          if (error.name === "TypeError" && error.message) dispatch(authSlice.actions.setConnectionFailed(true));
        }
      },
    }),
    resetPassword: builder.mutation({
      query: ({ password, token, email }) => ({
        document: gql`
          mutation resetPassword($password: String!, $email: String!, $token: String!) {
            resetPassword(password: $password, email: $email, token: $token) {
              result
              error
              message
            }
          }
        `,
        variables: {
          password,
          token,
          email,
        },
      }),
      transformResponse: (response) => response.resetPassword,
    }),
    createPassword: builder.mutation({
      query: ({ password, token }) => ({
        document: gql`
          mutation createPassword($password: String!, $token: String!) {
            createPassword(password: $password, token: $token) {
              result
              error
              message
            }
          }
        `,
        variables: {
          password,
          token,
        },
      }),
      transformResponse: (response) => response.createPassword,
    }),
  }),
});

export const { setAuthUser, setLogout, setEmptyDashboard } = authSlice.actions;

export const {
  useLoginMutation,
  useLogoutMutation,
  useEmptyProjectQuery,
  useInitSvallaMutation,
  useValidateAccountMutation,
  useCheckEmailSettingsMutation,
  useResendValidationEmailMutation,
  useRequestNewPasswordMutation,
  useResetPasswordMutation,
  useCreatePasswordMutation,
} = authApi;

export default authSlice.reducer;
