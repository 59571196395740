const inputTheme = { type: "Select", title: "Theme", key: "theme", placement: "top", options: [] };

const components = [
  {
    type: "Select",
    title: "Type",
    key: "type",
    placement: "top",
    options: [
      { value: "isSlug", label: "Internal Page" },
      { value: "isLink", label: "External Page" },
      { value: "isDownload", label: "Download" },
      { value: "isAnchor", label: "Anchor" },
    ],
  },
  {
    type: "Text",
    title: "Text",
    key: "text",
  },
  {
    type: "SelectPost",
    title: "Page",
    key: "page",
    conditional: {
      field: "type",
      when: "equal",
      value: "isSlug",
    },
  },
  {
    type: "Text",
    title: "URL",
    key: "url",
    conditional: [
      {
        field: "type",
        when: "equal",
        value: "isLink",
      },
      {
        field: "type",
        when: "equal",
        value: "isDownload",
      },
    ],
  },
  {
    type: "Text",
    title: "Anchor",
    key: "anchor",
    conditional: {
      field: "type",
      when: "equal",
      value: "isAnchor",
    },
  },
];

export { components, inputTheme };
