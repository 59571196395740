import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from "@heroicons/react/24/solid";
import Loading from "app/components/atoms/loading";
import LogoSvalla from "app/components/atoms/logoSvalla";
import { i18n } from "app/i18n";
import { languages } from "countries-list";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import Form from "../../components/organisms/form";
import { setEmptyDashboard, useCheckEmailSettingsMutation, useInitSvallaMutation, useResendValidationEmailMutation } from "../../stores/auth";
import { validation } from "../../utils/validators";
import customValidator from "../../utils/validators/customValidator";

export default function Welcome() {
  const components = {
    0: [
      {
        title: i18n("label.name"),
        key: "username",
        type: "Text",
        placeholder: i18n("label.name"),
        rules: [
          {
            method: validator.isEmpty,
            validWhen: false,
            message: i18n("input.required_field"),
          },
        ],
      },
      {
        title: i18n("label.email"),
        key: "useremail",
        type: "Text",
        placeholder: i18n("label.email"),
        rules: [
          {
            method: validator.isEmpty,
            validWhen: false,
            message: i18n("input.required_field"),
          },
          {
            method: validator.isEmail,
            validWhen: true,
            message: i18n("input.invalid_email"),
          },
          {
            method: customValidator.isEmailAvailable,
            validWhen: true,
            message: i18n("input.email_in_use"),
          },
        ],
      },
      {
        title: i18n("label.password"),
        key: "userpassword",
        type: "Password",
        rules: [
          {
            method: validator.isEmpty,
            validWhen: false,
            message: i18n("input.required_field"),
          },
          {
            method: customValidator.isPasswordStrong,
            validWhen: true,
            message: i18n("input.invalid_password"),
          },
        ],
      },
      {
        title: i18n("label.confirmPassword"),
        key: "confPassword",
        type: "Password",
        rules: [
          {
            method: validator.isEmpty,
            validWhen: false,
            message: i18n("input.required_field"),
          },
          {
            method: customValidator.isEqualTo,
            args: { key: "userpassword" },
            validWhen: true,
            message: i18n("input.dontMatch_password"),
          },
        ],
      },
    ],
    1: [
      {
        title: i18n("label.name"),
        key: "projectname",
        type: "Text",
        placeholder: i18n("label.project_name"),
        onBlur: {
          key: "projectslug",
        },
        rules: [
          {
            method: validator.isEmpty,
            validWhen: false,
            message: i18n("input.required_field"),
          },
        ],
      },
      {
        title: i18n("label.slug"),
        key: "projectslug",
        type: "Text",
        placeholder: "svalla",
        rules: [
          {
            method: validator.isEmpty,
            validWhen: false,
            message: i18n("input.required_field"),
          },
          {
            method: customValidator.isProjectSlugAvailable,
            validWhen: true,
            message: i18n("input.slug_in_use"),
          },
        ],
      },
      {
        tab: 1,
        type: "List",
        key: "languages",
        title: i18n("label.languages"),
        format: "table",
        description: i18n("input.first_language"),
        components: [
          {
            type: "Select",
            key: "language",
            title: i18n("label.language"),
            options: Object.keys(languages)
              .filter((item) => !item.rtl)
              .map((key) => ({ value: key, label: languages[key].name })),
            rules: [
              {
                method: customValidator.isObjectEmpty,
                validWhen: false,
                message: i18n("input.required_field"),
              },
            ],
          },
        ],
        rules: [
          {
            method: customValidator.isObjectEmpty,
            validWhen: false,
            message: i18n("input.required_field"),
          },
        ],
      },
    ],
    2: [
      {
        type: "Text",
        title: i18n("label.host"),
        key: "host",
        placeholder: "mail.host.com",
      },
      {
        type: "Text",
        title: i18n("label.port"),
        key: "port",
        placeholder: "465",
      },
      {
        type: "Toggle",
        title: i18n("label.secure"),
        key: "secure",
      },
      {
        type: "Toggle",
        title: i18n("label.reject_unauthorized"),
        key: "rejectUnauthorized",
      },
      {
        type: "Text",
        title: i18n("label.user"),
        key: "user",
        placeholder: i18n("label.email"),
      },
      {
        type: "Password",
        title: i18n("label.password"),
        key: "password",
        autoComplete: false,
      },
      {
        component: "line",
      },
      {
        type: "Text",
        title: i18n("label.name"),
        key: "name",
        placeholder: i18n("label.name"),
      },
      {
        type: "Text",
        title: i18n("label.email"),
        key: "email",
        placeholder: i18n("label.email"),
      },
    ],
    3: null,
  };
  const activePanel = "relative opacity-100 translate-y-0";
  const inactivePanel = "absolute w-full top-0 pointer-events-none opacity-0 translate-y-10";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [emailSettings, setEmailSettings] = useState(null);
  const [structure, setStructure] = useState(components[0]);

  const [checkEmail, { isLoading: isTesting, data: isValidConfiguration, reset: cleanCheckEmail }] = useCheckEmailSettingsMutation();
  const [initSvalla, { isLoading: isSubmitting, data: firstProject }] = useInitSvallaMutation();
  const [resendEmail, { isLoading: isResending, data: emailSent }] = useResendValidationEmailMutation();

  const getStepStatus = (index) => {
    if (index === activeStep) return "current";
    if (index < activeStep) return "complete";
    if (index > activeStep) return "upcoming";
  };

  const steps = [
    { id: `${i18n("label.step")} 1`, name: i18n("welcomePage.admin_account"), href: "#", status: getStepStatus(0) },
    { id: `${i18n("label.step")} 2`, name: i18n("welcomePage.first_project"), href: "#", status: getStepStatus(1) },
    { id: `${i18n("label.step")} 3`, name: i18n("welcomePage.email_settings"), href: "#", status: getStepStatus(2) },
    { id: `${i18n("label.step")} 4`, name: i18n("welcomePage.preview"), href: "#", status: getStepStatus(3) },
  ];

  useEffect(() => {
    setStructure(components[activeStep]);
  }, [activeStep]);

  useEffect(() => {
    // CHECK EMAIL SETTINGS - IF CHANGES AFTER VALIDATION SUCCESS, ROLLBACK TO DEFAULT "NO VALID"
    if (emailSettings && activeStep === 2) {
      if (
        !(
          value.host === emailSettings.host &&
          value.port === emailSettings.port &&
          value.secure === emailSettings.secure &&
          value.rejectUnauthorized === emailSettings.rejectUnauthorized &&
          value.user === emailSettings.user &&
          value.password === emailSettings.password
        )
      ) {
        setEmailSettings(null);
        cleanCheckEmail();
      }
    }
  }, [value]);

  const ButtonBack = () => (
    <button
      onClick={() => setActiveStep(activeStep - 1)}
      className="mb-4 px-7 py-3 md:mr-3 border-blue-600 bg-blue border text-blue-600 font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:text-white hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg main-transition"
    >
      {i18n("button.previous")}
    </button>
  );

  const ButtonNext = ({ onClick, disabled }) => (
    <button
      onClick={onClick}
      disabled={disabled}
      className="mb-4 px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg main-transition disabled:opacity-75 disabled:cursor-not-allowed disabled:hover:opacity-75 disabled:bg-blue-600 disabled:shadow-md main-transition"
    >
      {i18n("button.next")}
    </button>
  );

  const stepValid = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) {
        const nextStep = activeStep + 1;
        if (nextStep === 3) {
          const args = {
            user: {
              name: value.username,
              email: value.useremail,
              password: value.userpassword,
            },
            project: {
              name: value.projectname,
              slug: value.projectslug,
              languages: value.languages.map(({ language }, index) => ({
                enabled: true,
                published: true,
                default: index === 0,
                name: language.label,
                slug: language.value,
              })),
            },
            email: {
              host: value.host,
              port: value.port,
              secure: value.secure || false,
              rejectUnauthorized: value.rejectUnauthorized || false,
              user: value.user,
              password: value.password,
              name: value.name,
              email: value.email,
            },
          };
          initSvalla(args).then((res) => {
            if (res.data && res.data.id) {
              setEmailSettings(null);
              setValue({ useremail: args.user.email });
            }
          });
        }
        setActiveStep(nextStep);
      }
    });
  };

  const testEmail = () => {
    const tempStructure = structure.map((item) => {
      if (item.type === "Text" || item.type === "Password") return { ...item, rules: [{ method: validator.isEmpty, validWhen: false }] };
      return item;
    });

    validation(tempStructure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) {
        checkEmail({
          email: value.email,
          args: {
            host: value.host,
            port: value.port,
            user: value.user,
            password: value.password,
            secure: !!value.secure,
            rejectUnauthorized: !!value.rejectUnauthorized,
            name: value.name,
            email: value.email,
          },
        }).then((res) => {
          if (res)
            setEmailSettings({
              host: value.host,
              port: value.port,
              user: value.user,
              password: value.password,
              secure: !!value.secure,
              rejectUnauthorized: !!value.rejectUnauthorized,
            });
        });
      }
    });
  };

  const fillSettings = () => {
    setValue({
      ...value,
      host: process.env.REACT_APP_EMAIL_HOST,
      port: process.env.REACT_APP_EMAIL_PORT,
      user: process.env.REACT_APP_EMAIL_USER,
      password: process.env.REACT_APP_EMAIL_PASSWORD,
      secure: process.env.REACT_APP_EMAIL_SECURE === "true",
      rejectUnauthorized: process.env.REACT_APP_EMAIL_REJECTUNAUTHORIZED === "true",
      name: process.env.REACT_APP_EMAIL_NAME,
      email: process.env.REACT_APP_EMAIL_EMAIL,
    });
  };

  const Alert = () => {
    const bgColor = isValidConfiguration ? `bg-green-50` : `bg-red-50`;
    const borderColor = isValidConfiguration ? `border-green-300` : `border-red-300`;
    const textColor = isValidConfiguration ? `text-green-900` : `text-red-900`;

    return (
      <div id="alert-additional-content-2" className={`p-4 my-4 border rounded-lg ${bgColor} ${borderColor}`} role="alert">
        <div className="flex items-center">
          {isValidConfiguration ? <CheckCircleIcon className={`h-6 mr-2 ${textColor}`} /> : <InformationCircleIcon className={`h-6 mr-2 ${textColor}`} />}
          <span className="sr-only">Info</span>
          <h3 className={`text-lg font-medium ${textColor}`}>Email Check-up</h3>
        </div>
        <div className={`mt-2 mb-4 text-sm ${textColor}`}>{isValidConfiguration ? i18n("welcomePage.alert_email_server_settings_success") : i18n("welcomePage.alert_email_server_settings")}</div>
        <div className="flex">
          {!isValidConfiguration && (
            <button
              type="button"
              onClick={testEmail}
              disabled={isTesting}
              className="text-white bg-red-900 hover:bg-red-800 focus:outline-none font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center disabled:pointer-events-none disabled:bg-slate-300 main-transition"
            >
              {i18n("button.test_email")}
            </button>
          )}
          {isTesting && <Loading active={isTesting} />}
        </div>
      </div>
    );
  };

  const sendEmailValidation = () => {
    resendEmail({ email: value.useremail });
  };

  const goToLogin = () => {
    navigate("/");
    dispatch(setEmptyDashboard(false));
  };

  return (
    <div className="h-screen overflow-auto">
      <div className="custom-container mx-auto mb-32 lg:mb-0">
        <div className="pt-16 mb-0 flex flex-col items-center justify-center">
          <LogoSvalla />
          <div className="mt-5 mb-12">
            <p className="text-md text-[#0c1832] text-center">{i18n("welcomePage.description")}</p>
          </div>
        </div>
        {/* NAVIGATION */}
        <nav aria-label="Progress">
          <ol role="list" className="w-full space-y-1 space-x-0 md:space-y-0 md:space-x-8 flex flex-wrap">
            {steps.map((step) => (
              <li key={step.name} className="w-[50%] sm:w-[50%] md:w-fit md:flex-1 p-2">
                {step.status === "complete" ? (
                  <a href={step.href} className="flex flex-col py-2 pl-4 border-l-4 border-indigo-600 group md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0 cursor-default">
                    <span className="text-sm font-medium text-indigo-600">{step.id}</span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </a>
                ) : step.status === "current" ? (
                  <a href={step.href} className="flex flex-col py-2 pl-4 border-l-4 border-indigo-600 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0 cursor-default" aria-current="step">
                    <span className="text-sm font-medium text-indigo-600">{step.id}</span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </a>
                ) : (
                  <a href={step.href} className="flex flex-col py-2 pl-4 border-l-4 border-gray-200 group md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0 cursor-default">
                    <span className="text-sm font-medium text-gray-500">{step.id}</span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </a>
                )}
              </li>
            ))}
          </ol>
        </nav>
        {/* PANELS */}
        <div className="relative pt-16 pb-10 bg-white mt-10 shadow-lg rounded-3xl">
          {/* PANEL 01 */}
          <div className={`px-10 lg:px-20 transition-all duration-700 ease-linear ${activeStep === 0 ? activePanel : inactivePanel}`}>
            <div className="mb-10">
              <p className="uppercase text-center text-md text-gray-500">{i18n("welcomePage.create_first_user")}</p>
            </div>
            <Form value={value} onChange={setValue} structure={structure} />
            <div className="flex flex-col md:flex-row justify-end pt-10 pb-3">
              <ButtonNext onClick={stepValid} />
            </div>
          </div>
          {/* PANEL 02 */}
          <div className={`px-10 lg:px-20 transition-all duration-700 ease-linear ${activeStep === 1 ? activePanel : inactivePanel}`}>
            <div className="mb-10">
              <p className="uppercase text-center text-md text-gray-500">{i18n("welcomePage.create_first_project")}</p>
            </div>
            <Form value={value} onChange={setValue} structure={structure} />
            <div className="flex flex-col md:flex-row justify-end pt-10 pb-3">
              <ButtonBack />
              <ButtonNext onClick={stepValid} />
            </div>
          </div>
          {/* PANEL 03 */}
          <div className={`px-10 lg:px-20 transition-all duration-700 ease-linear ${activeStep === 2 ? activePanel : inactivePanel}`}>
            <div className="mb-10">
              <p className="uppercase text-center text-md text-gray-500">{i18n("welcomePage.set_email_settings")}</p>
            </div>
            <Form value={value} onChange={setValue} structure={structure} />
            <Alert />
            <div className="flex flex-col md:flex-row justify-center md:justify-end pt-10 pb-3">
              <ButtonBack />
              {process.env.REACT_APP_EMAILCONFIG && (
                <button
                  type="button"
                  onClick={fillSettings}
                  className="mb-4 px-7 py-3 md:mr-3 bg-blue-900 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-900 hover:shadow-lg focus:bg-blue-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-900 active:shadow-lg main-transition disabled:opacity-75 disabled:cursor-not-allowed disabled:hover:opacity-75 disabled:bg-blue-700 disabled:shadow-md main-transition"
                >
                  {i18n("button.fill")}
                </button>
              )}
              <ButtonNext onClick={stepValid} disabled={!isValidConfiguration} />
            </div>
          </div>
          {/* PANEL 04 */}
          <div className={`px-10 lg:px-20 transition-all duration-700 ease-linear ${activeStep === 3 ? activePanel : inactivePanel}`}>
            <div className="flex flex-col items-center justify-center">
              {isSubmitting ? (
                <p>
                  <Loading isActive={isSubmitting} />
                </p>
              ) : firstProject ? (
                <>
                  <CheckCircleIcon className="h-24 text-green-500" />
                  <h2 className="mt-5 mb-5 text-center text-md text-gray-500">{i18n("welcomePage.alert_success_creating_project")}</h2>
                </>
              ) : (
                <>
                  <ExclamationTriangleIcon className="h-24 text-red-500" />
                  <h2 className="mt-5 mb-5 text-center text-md text-gray-500">{i18n("welcomePage.alert_error_creating_project")}</h2>
                </>
              )}

              <div className="mt-10">
                <div className="flex flex-col md:flex-row items-center justify-center">
                  <button
                    type="button"
                    onClick={goToLogin}
                    className="mb-4 px-7 py-3 md:mx-2 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg main-transition disabled:opacity-75 disabled:cursor-not-allowed disabled:hover:opacity-75 disabled:bg-blue-600 disabled:shadow-md"
                  >
                    {i18n("button.go_to_login")}
                  </button>
                  <button
                    disabled={isResending}
                    onClick={sendEmailValidation}
                    className="mb-4 px-7 py-3 md:mx-2 border-blue-600 bg-blue border text-blue-600 font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:text-white hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg main-transition"
                  >
                    {i18n("button.resend_email")}
                  </button>
                </div>
                {emailSent && <p className="mt-2 text-center" dangerouslySetInnerHTML={{ __html: `Email sent for <span className="font-bold">${value.useremail}</span>` }} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
