import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import Form from "app/components/organisms/form";
import { cleanStructure } from "app/utils/content";
import { validation } from "app/utils/validators";
import React, { useEffect, useState } from "react";
import { components } from "./constants";
import { i18n } from "app/i18n";

export default function ModalCategory(props) {
  const { isOpen = false, onSave, isLoading, onClose = () => {} } = props;

  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setValue(null);
      setStructure(cleanStructure(components));
    }
  }, [isOpen]);

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) onSave(value);
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={i18n("label.create_category")}onClose={onClose} />
      <Content>
        <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: i18n("button.save"), disabled: isLoading, onClick: onClickSubmit }} />
    </Struture>
  );
}
