import Form from "app/components/organisms/form";
import React from "react";
import Table from "./table";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export default function Group(props) {
  const { title, value, description, format, children, error, disabled, mode, errorMessage, isRequired, onChange } = props;

  const styles = {
    title: "block text-sm font-medium mb-1",
    description: "block text-xs font-light text-slate-400 -mt-1.5 mb-1",
    box: "bg-gray-50 px-5 pt-5 pb-3 rounded-lg border",
  };

  const onChildrenChange = (key, val) => {
    onChange && onChange({ ...value, [key]: val });
  };

  if (error) {
    styles.title += " text-red-600";
    styles.box += " border-red-600";
  } else {
    styles.title += " text-slate-700";
    styles.box += " border-transparent";
  }

  return (
    <div className="block pb-3">
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <div className={styles.box}>
        {format === "table" ? (
          <Table disabled={disabled} structure={children} value={value} mode={mode} onChange={onChildrenChange} />
        ) : (
          <Form disabled={disabled} structure={children} value={value} mode={mode} onChange={onChange} />
        )}
      </div>
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </div>
  );
}
