import { memo } from "react";
import { useDrag, useDrop } from "react-dnd";
import BlockHeader from "../block/header";

export const Card = memo(function Card(props) {
  const { id, accept, moveCard, findCard } = props;

  const originalIndex = findCard(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: accept,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard]
  );
  const [, drop] = useDrop(
    () => ({
      accept: accept,
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );
  const opacity = isDragging ? 0 : 1;
  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{ opacity }}
      className="border py-1 px-3 bg-gray-100 border-t-0 border-gray-300 flex items-center justify-between first:border-t first:rounded-t-lg last:rounded-b-lg"
    >
      <BlockHeader {...props} />
    </div>
  );
});
