import SortableTree, { changeNodeAtPath, insertNode, removeNodeAtPath } from "@nosferatu500/react-sortable-tree";
import "@nosferatu500/react-sortable-tree/style.css";
import ModalComponent from "app/components/molecules/modals/modalComponent";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import { i18n } from "app/i18n";
import { useAddCustomInputMutation } from "app/stores/builder";
import { showServerError } from "app/utils/server";
import { prepareCustomInputs } from "app/utils/visual";
import { useState } from "react";
import { toast } from "react-toastify";
import shortid from "shortid";
import {
  ButonBlock,
  ButtonDelete,
  ButtonDuplicate,
  ButtonEdit,
  ButtonFavorite,
  LabelRequired,
  LabelType,
  Subtitle,
  Title,
  getFieldsOfLevel,
  isCustomInput,
  isKeyAvailableInArray,
  prepareDataForTree,
} from "../helpers";

export default function Dropzone(props) {
  const { data = [], custom, elementInUse = false, basic, blockInput, placeholder = i18n("label.drop_components_here"), onUpdate } = props;

  const maxDepth = undefined;
  const externalNodeType = "builderComponent";

  // STATE
  const [modal, setModal] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);

  // REQUEST
  const [updateCustomInputs, { isLoading }] = useAddCustomInputMutation();

  const onChange = (vals) => {
    onUpdate(vals);
  };

  // NEW OR MOVE COMPONENT
  const onMoveNode = ({ node, treeIndex, prevPath, nextPath, treeData, nextParentNode }) => {
    // NEW COMPONENT
    if (!basic && !prevPath) {
      const d = nextParentNode ? nextParentNode.children : data;
      const p = nextParentNode ? [] : nextPath;
      const custom = !!node?.custom;
      const component = custom ? { key: node.key, title: node.title } : null;

      setModal({
        isOpen: true,
        custom: custom,
        component: component,
        inputType: node.type,
        inputs: getFieldsOfLevel(d, p, node),
        validateKey: (key) => isKeyAvailableInArray(nextParentNode, data, key, node.id),
        onSubmit: (values) => {
          const newNode = { ...node, ...values, id: shortid.generate() };
          if (custom) {
            delete newNode.custom;
            newNode.children = node.children;
            newNode.blocked = true;
            newNode.expanded = false;
          }

          const { treeData } = insertNode({
            newNode,
            treeData: data,
            expandParent: true,
            ignoreCollapsed: false,
            depth: nextPath.length - 1,
            minimumTreeIndex: treeIndex,
            getNodeKey: ({ treeIndex }) => treeIndex,
          });
          onChange(treeData);
          setModal(null);
        },
        onClose: () => setModal(null),
      });
    }
    // MOVE COMPONENT
    else {
      if (basic || isKeyAvailableInArray(nextParentNode, data, node.key, node.id)) onChange(treeData);
      else toast.error(i18n("toast.error_key"));
    }
  };

  // EDIT COMPONENT
  const editNode = ({ node, path, parentNode }) => {
    const d = parentNode ? parentNode.children : data;
    const p = parentNode ? [] : path;

    setModal({
      isOpen: true,
      component: node,
      inputType: node.type,
      buttonText: i18n("label.edit_component"),
      inputs: getFieldsOfLevel(d, p, node),
      validateKey: (key) => isKeyAvailableInArray(parentNode, data, key, node.id),
      onSubmit: (values) => {
        let newNode = { ...node };
        if (typeof newNode.expanded !== "undefined") delete newNode.expanded;
        const items = changeNodeAtPath({
          path,
          treeData: data,
          newNode: { ...newNode, ...values },
          getNodeKey: ({ treeIndex }) => treeIndex,
        });
        onChange(items);
        setModal(null);
      },
      onClose: () => setModal(null),
    });
  };

  // DUPLICATE COMPONENT
  const duplicateNode = ({ node, treeIndex, path, parentNode }) => {
    let i = 1;
    let startKey = `${node.key}Cloned`;
    let key = startKey;

    // AVOID DUPLICATED KEY - BUILD NEW KEY WHILE INVALID
    while (!isKeyAvailableInArray(parentNode, data, key, node.id)) {
      key = `${startKey}${i}`;
      i++;
    }

    const { treeData } = insertNode({
      treeData: data,
      depth: path.length - 1,
      minimumTreeIndex: treeIndex + 1,
      newNode: { ...node, key, id: shortid.generate() },
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: false,
      expandParent: true,
    });
    onChange(treeData);
  };

  // REMOVE COMPONENT
  const removeNode = (path) => {
    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      forceNotice: elementInUse,
      title: i18n("label.delete_field"),
      text: i18n("alert.action_delete"),
      notice: basic ? i18n("alert.section_delete_error") : i18n("alert.section_in_use_delete"),
      onConfirm: () => {
        const items = removeNodeAtPath({ path, treeData: data, ignoreCollapsed: false, getNodeKey: ({ treeIndex }) => treeIndex });
        onChange(items);
        setConfirmModal(null);
      },
      onClose: () => setConfirmModal(null),
    });
  };

  // ADD TO FAVORITES OR REMOVE LINK (UNBLOCK)
  const addToFavorites = (node, unblock, path) => {
    // IF IS CUSTOM INPUT, UNBLOCK TO UNLINK STRUCTURE
    if (unblock) {
      if (node.blocked) delete node.blocked;
      if (node.model) delete node.model;
      const items = changeNodeAtPath({ path, treeData: data, newNode: { ...node, id: shortid.generate() }, getNodeKey: ({ treeIndex }) => treeIndex });
      onChange(items);
    }
    // SIMPLY ADD INPUT TO CUSTOMS
    else {
      updateCustomInputs({ key: "builder", value: prepareCustomInputs(custom, { ...node, model: node.id }, false) }).then((res) => {
        if (!res?.data) showServerError(res);
      });
    }
  };

  // TOGGLE BLOCKAGE
  const toggleBlock = (node, status, path) => {
    console.log(node, status, path);
    const items = changeNodeAtPath({
      path,
      treeData: data,
      newNode: { ...node, blocked: !status },
      getNodeKey: ({ treeIndex }) => treeIndex,
    });
    onChange(items);
  };

  return (
    <>
      <div className={`${basic ? "basic-model " : ""} block relative h-full overflow-visible`}>
        <SortableTree
          maxDepth={maxDepth}
          isVirtualized={true}
          onMoveNode={onMoveNode}
          treeData={prepareDataForTree(data, custom)}
          placeholderRenderer={() => (
            <div className="border border-dashed rounded-sm h-full w-full relative flex items-center justify-center">
              <span className="text-xl xl:text-3xl text-slate-300 italic">{placeholder}</span>
            </div>
          )}
          dndType={externalNodeType}
          onChange={(items) => [...items]}
          canNodeHaveChildren={(node) => {
            return node.isList && !node.blocked;
          }}
          generateNodeProps={(element) => ({
            title: <Title {...element} />,
            subtitle: <Subtitle basic={basic} {...element} />,
            buttons: [
              <LabelRequired basic={basic} {...element} />,
              <LabelType basic={basic} isCustom={isCustomInput(element.node, custom)} {...element} />,
              <ButonBlock disabled={!blockInput} {...element} onClick={toggleBlock} />,
              <ButtonFavorite basic={basic} isCustom={isCustomInput(element.node, custom)} disabled={isLoading} {...element} onClick={addToFavorites} />,
              <ButtonEdit basic={basic} {...element} onClick={editNode} />,
              <ButtonDuplicate basic={basic} {...element} onClick={duplicateNode} />,
              <ButtonDelete basic={basic} {...element} onClick={removeNode} />,
            ],
          })}
        />
      </div>

      <ModalComponent {...modal} />
      <ModalConfirm {...confirmModal} />
    </>
  );
}
