import React from "react";
import { DragSource } from "react-dnd";

const externalNodeType = "builderComponent";
const externalNodeSpec = {
  beginDrag: (componentProps) => ({
    node: {
      id: componentProps.node.id,
      type: componentProps.node.type,
      title: componentProps.node.title,
      key: componentProps.node.key,
      isList: !!componentProps.node.isList,
    },
  }),
  endDrag: (item) => item.node,
};
const externalNodeCollect = (connect, monitor) => ({ connectDragSource: connect.dragSource() });
function externalNodeBaseComponent({ connectDragSource, node, onClick }) {
  return connectDragSource(
    <button
      type="button"
      onClick={onClick}
      className="flex w-full p-3 bg-gray-200 justify-between items-center rounded-md overflow-hidden mb-2 cursor-pointer transition-all duration-300 hover:bg-gray-300"
    >
      <span>{node.title}</span>
      {node.icon}
    </button>,
    { dropEffect: "copy" }
  );
}
const DragElement = DragSource(externalNodeType, externalNodeSpec, externalNodeCollect)(externalNodeBaseComponent);
export default DragElement;
