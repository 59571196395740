export default function InputCheckbox(props) {
  const { name, type, description, text, required } = props;

  const styles = {
    title: "block text-sm font-medium text-slate-700",
    description: "block text-xs font-light text-slate-400 mt-0.5 ml-2",
    text: "block text-xs font-normal text-slate-700 ml-2",
    required: "text-xs font-bold text-red-600",
    input: "ml-2 bg-gray-50 border border-gray-300 rounded transition-all duration-300 ease-in-out outline-none hover:border-gray-400 read-only:pointer-events-none",
  };

  return (
    <div>
      <div className="flex items-center justify-between mx-2">
        <div>{name && <label className={styles.title}>{name}</label>}</div>
        <div className="flex items-center justify-end">{required && <span className={styles.required}>*</span>}</div>
      </div>
      <div className="flex items-center">
        <input type={type} readOnly={true} className={styles.input} />
        {text && <div className={styles.text}>{text}</div>}
      </div>
      {description && <span className={styles.description}>{description}</span>}
    </div>
  );
}
