import { i18n } from "app/i18n";
import { useUpdateFormMutation } from "app/stores/forms";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import QuickEdition from "app/components/organisms/quickEdition";

export default function FormHeader() {
  // PARAMS
  const { lang } = useParams();

  // STORE
  const { form } = useSelector((state) => state.form);

  // STATES
  const [editing, setEditing] = useState(false);

  // ACTIONS
  const [updateForm, { isLoading }] = useUpdateFormMutation();

  // VARIABLES
  const content = form?.translations?.find((item) => item.language.slug === lang);
  const loading = isLoading || !editing || !content;

  const onSaveChanges = (data) => {
    updateForm(data).then((res) => {
      if (res && res.data) {
        setEditing(false);
        showServerSuccess(i18n("toast.success_changes_update"));
      } else {
        showServerError(res);
      }
    });
  };

  return (
    <div className="bg-blue-900 text-white">
      <div className="custom-container" id="header-edition">
        <div className="inputs-box pt-8 pb-8 md:flex md:items-center lg:justify-between">
          <div className="min-w-0">
            <QuickEdition form={form} content={content} active={editing} loading={loading} onToggle={setEditing} onSave={onSaveChanges} />
          </div>
        </div>
      </div>
    </div>
  );
}
