import Icon from "app/components/atoms/icons/icon";
import { i18n } from "app/i18n";
import { getSource } from "app/utils/content";
import { useState } from "react";
import Draggable from "react-draggable";
import Form from "../../form";

export default function EmailLayout(props) {
  const { value, structure, onChange, loading, defaultValue, changed } = props;

  const [open, setOpen] = useState(false);

  const cleanAll = () => {
    const keys = structure.map((e) => e.key);
    const temp = value || {};
    for (let i = 0; i < keys.length; i++) {
      temp[keys[i]] = undefined;
    }
    onChange({ ...temp });
  };

  return (
    <div className="relative py-10 px-4" style={{ backgroundColor: value?.backgroundColor || "#F1F5F9" }}>
      <div>
        {/* IMAGE */}
        {value?.image?.path && (
          <div className="mx-auto max-w-xl">
            <div className="w-full">
              <img src={getSource(value.image.path)} />
            </div>
          </div>
        )}
        {/* CONTENT */}
        <div className="mx-auto max-w-xl pt-8 pb-4 px-4" style={{ backgroundColor: value?.boxColor || "#FFFFFF" }}>
          <div className="p-10">
            <div className="text-center pb-4 px-6">
              <h1 className="font-semibold text-3xl" style={{ color: value?.titleColor || "#222222" }}>
                Email Title
              </h1>
            </div>
            <div className="pt-4 pb-10">
              <p className="text-sm" style={{ color: value?.textColor || "#666666" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="text-center">
              <button className="px-8 py-4" style={{ backgroundColor: value?.buttonColor || "#000", color: value?.buttonTextColor || "#FFF" }}>
                Button Text
              </button>
            </div>
          </div>
        </div>
        {/* COPY */}
        <div className="mx-auto max-w-xl pt-4">
          <div className="text-center">
            <p className="text-xs" style={{ color: value?.copyrightColor || "#CCC" }}>
              © Svalla 2023 - All rights reserved
            </p>
          </div>
        </div>
      </div>

      <div className="absolute top-0 right-0 z-10">
        <Draggable handle=".handle">
          <div className="flex">
            <div className="flex flex-col">
              <button className="handle cursor-move bg-slate-200 border-b border-b-slate-300 p-3">
                <Icon name="menu" width="w-8" height="h-8" />
              </button>
              <button className="bg-slate-200 p-3" onClick={() => setOpen(!open)}>
                <Icon name="pencil" width="w-8" height="h-8" />
              </button>
            </div>
            <div className={`h-full max-h-[480px] overflow-auto pt-4 pb-20 bg-white main-transition ${open ? "w-80 px-6 border shadow-xl" : "w-0 px-0"}`}>
              <Form defaultValue={defaultValue} changed={changed} value={value} structure={structure} onChange={onChange} errors={null} disabled={loading} />

              <button
                type="button"
                className="w-full mt-8 px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={cleanAll}
              >
                {i18n("label.clean_all_inputs")}
              </button>
            </div>
          </div>
        </Draggable>
      </div>
    </div>
  );
}
