import { LinkIcon, PencilIcon } from "@heroicons/react/24/outline";
import Icon from "app/components/atoms/icons/icon";
import Date from "app/components/atoms/inputs/date";
import Slug from "app/components/atoms/inputs/slug";
import Text from "app/components/atoms/inputs/text";
import Toggle from "app/components/atoms/inputs/toggle";
import LabelPostStatus from "app/components/molecules/labels/labelPostStatus";
import Permission from "app/components/molecules/permission";
import { i18n } from "app/i18n";
import { setHeaderChanges } from "app/stores/project";
import { showServerError } from "app/utils/server";
import { validation } from "app/utils/validators";
import { objectsAreEqual } from "app/utils/validators/dataValidator";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { blockStructure, formStructure, postStructure } from "./constants";

export default function QuickEdition(props) {
  const { content, post, form, block, active, loading, onSave, onToggle } = props;

  // PARAMS
  const dispatch = useDispatch();

  // STATE
  const [value, setValue] = useState(null);
  const [errors, setErrors] = useState(null);
  const [structure, setStructure] = useState(null);
  const [dataChanged, setDataChanged] = useState(false);

  // STORE
  const { headerChanges } = useSelector((store) => store.project);

  // VARS
  const disabled = !active || loading;

  useEffect(() => {
    if (content) buildForm();
    if (!active) fillForm();
  }, [active, content]);

  useEffect(() => {
    if (active) setDataChanged(isDataDifferent());
  }, [content, value]);

  useEffect(() => {
    if (dataChanged !== headerChanges) dispatch(setHeaderChanges(dataChanged));
    return () => dispatch(setHeaderChanges(false));
  }, [dataChanged]);

  // SET STRUCTURE
  const buildForm = () => {
    let temp = undefined;

    if (post) temp = [...postStructure];
    if (block) temp = [...blockStructure];
    if (form) temp = [...formStructure];

    if (!temp?.length) return null;

    // APPLY CHANGES IN SLUG INPUT
    const index = temp.findIndex((e) => e.key === "slug");
    if (index !== -1) {
      const indexMethod = temp[index].rules.findIndex((e) => e.args);
      if (indexMethod !== -1) {
        // ID
        temp[index].rules[indexMethod].args.id = content.id;
        // TYPE
        if (post) temp[index].rules[indexMethod].args.type = post.postType.key;
        // PROJECT
        if (post) temp[index].rules[indexMethod].args.project = post.project.id;
        if (block) temp[index].rules[indexMethod].args.project = block.project.id;
        // LANGUAGE
        temp[index].rules[indexMethod].args.language = content.language.slug;
      }
    }
    setStructure([...temp]);

    if (content) fillForm();
  };

  // SET VALUE
  const fillForm = () => {
    if (content) {
      let temp = { title: content.title };
      if (post) temp.publishedDate = dayjs(content.publishedDate).toISOString();
      if (post || block) temp.slug = content.slug;
      if (post || block) temp.published = content.published;
      if (form) temp.published = content.enable;

      setValue({ ...temp });
      setErrors(null);
    }
  };

  // CHECK FOR DIFFERENT DATA
  const isDataDifferent = () => {
    if (!content) return false;
    let temp = { title: content.title };
    if (post) temp.publishedDate = dayjs(content.publishedDate).toISOString();
    if (post || block) temp.slug = content.slug;
    if (post || block) temp.published = content.published;
    if (form) temp.published = content.enable;

    return !objectsAreEqual(temp, value);
  };

  // ON SUBMIT
  const onSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setErrors(null);
      if (validation.isValid) {
        onSave({ id: content.id, title: value.title, slug: value.slug, published: value.published, publishedDate: value.publishedDate });
      } else {
        setErrors(validation);
        showServerError({ error: { message: i18n("toast.post.edit.error") } });
      }
    });
  };

  return (
    <>
      {/* TITLE */}
      <div className="flex gap-2">
        <div className="flex-1 input-post-title">
          <Text
            disabled={disabled}
            readOnly={disabled}
            value={value?.title}
            onChange={(ev) => setValue({ ...value, title: ev })}
            error={active && errors && Object.keys(errors).length && errors?.title?.isInvalid}
            errorMessage={active && errors && Object.keys(errors).length && errors?.title?.message}
          />
        </div>
        <div className="flex items-center mb-3 gap-1">
          {active ? (
            <>
              {/* SUBMIT */}
              <button
                type="button"
                disabled={!dataChanged}
                onClick={() => onSubmit(false)}
                className="flex items-center justify-center w-9 h-9 p-2.5 aspect-square rounded-full transition-colors duration-200 ease-in text-white outline-none bg-green-600 hover:bg-green-400 disabled:bg-green-200"
              >
                <Icon name="check" />
              </button>
              {/* CANCEL */}
              <button
                type="button"
                onClick={() => onToggle(false)}
                className="flex items-center justify-center w-9 h-9 p-2.5 aspect-square rounded-full transition-colors duration-200 ease-in text-white outline-none bg-red-600 hover:bg-red-400"
              >
                <Icon name="close" />
              </button>
            </>
          ) : (
            // OPEN EDITION
            <Permission project dev min="MANAGER">
              <button
                type="button"
                onClick={() => onToggle(true)}
                className="flex items-center justify-center w-9 h-9 p-2.5 aspect-square rounded-full transition-colors duration-200 ease-in text-white outline-none bg-black hover:bg-slate-400"
              >
                <PencilIcon className="h-8 w-8 text-white" />
              </button>
            </Permission>
          )}
        </div>
      </div>

      {/* ELEMENTS */}
      <div className="flex flex-col items-start min-h-12 gap-4 h-10 lg:flex-row lg:flex-wrap">
        {/* STATUS */}
        <div className="input-post-status flex items-center text-sm text-gray-300 h-10">
          {active ? (
            <label className="flex w-full rounded-lg border-gray-200 hover:border-gray-400 focus:border-blue-700">
              <span className="inline-flex items-center text-sm px-3 rounded-l-lg text-gray-400 bg-gray-200 border">
                <Toggle disabled={disabled} value={value?.published} onChange={(ev) => setValue({ ...value, published: ev })} texts={[i18n("label.published"), i18n("label.draft")]} />
              </span>
              <div className="inline-flex items-center px-2.5 h-10 text-sm rounded-r-lg min-w-[90px] bg-gray-50 text-gray-400 border !outline-none w-full trasition-all duration-300 ease-in-out !ring-0 !focus-visible:border-none">
                {value?.published ? i18n("label.published") : i18n("label.draft")}
              </div>
            </label>
          ) : (
            <LabelPostStatus published={form ? content?.enabled : content?.published} />
          )}
        </div>

        {/* PATH */}
        {(post || block) && (
          <div className="input-post-slug flex flex-col text-sm text-gray-300 w-xl h-fit">
            <label className="flex w-full h-10">
              {active ? (
                <>
                  <span
                    aria-errormessage={(active && errors && Object.keys(errors).length && errors?.slug?.isInvalid) || undefined}
                    className="inline-flex items-center h-10 text-sm px-3 pr-2 rounded-l-lg text-gray-400 bg-gray-200 border border-gray-200"
                  >
                    <LinkIcon className="h-5 w-5" />
                    {post && <span className="ml-2">{`${content?.path.split("/").slice(0, -1).join("/")}`}/</span>}
                  </span>
                  <Slug
                    disabled={disabled}
                    value={value?.slug}
                    options={{ trim: false, fullRemove: true }}
                    onChange={(ev) => setValue({ ...value, slug: ev })}
                    error={active && errors && Object.keys(errors).length && errors?.slug?.isInvalid}
                    errorMessage={active && errors && Object.keys(errors).length && errors?.slug?.message}
                  />
                </>
              ) : (
                <>
                  <span className="inline-flex items-center h-10 px-3 text-sm text-gray-400">
                    <LinkIcon className="h-5 w-5" />
                  </span>
                  <div className="inline-flex items-center px-0 h-10 text-sm text-gray-400 w-full">
                    {post && <span>{content?.path}</span>}
                    {block && <span>{content?.slug}</span>}
                  </div>
                </>
              )}
            </label>
          </div>
        )}

        {/* PUBLISHED DATE */}
        {post && (
          <div className="input-post-date">
            <Date
              clearable={false}
              disabled={disabled}
              readOnly={disabled}
              value={value?.publishedDate}
              onChange={(ev) => setValue({ ...value, publishedDate: ev })}
              error={active && errors && Object.keys(errors).length && errors.publishedDate && errors.publishedDate.isInvalid}
              errorMessage={active && errors && Object.keys(errors).length && errors.publishedDate && errors.publishedDate.message}
            />
          </div>
        )}
      </div>
    </>
  );
}
