export const hasLength = (val) => {
  return val && val.length >= 8;
};

export const hasNumber = (val) => {
  return val && val.match(/(?=.*[0-9])/);
};

export const hasLowercase = (val) => {
  return val && val.match(/(?=.*[a-z])/);
};

export const hasUppercase = (val) => {
  return val && val.match(/(?=.*[A-Z])/);
};

export const hasSpecialcase = (val) => {
  return val && val.match(/(?=.*[!,%,&,@,#,$,^,*,?,_,~,<,>,])/);
};

export const getCurrentValue = (val) => {
  let strength = 0;
  if (val) {
    // IF LENGTH >= 8
    if (hasLength(val)) strength += 1;
    // IF HAS A NUMBER
    if (hasNumber(val)) strength += 1;
    // IF HAS A SPECIAL CHARACTER
    if (hasSpecialcase(val)) strength += 1;
    // IF HAS A LOWERCASE CHARACTER
    if (hasLowercase(val)) strength += 1;
    // IF HAS A UPPERCASE CHARACTER
    if (hasUppercase(val)) strength += 1;
  }
  return strength;
};
