import { gql } from "@apollo/client";
import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { serverAuth } from "app/utils/server";
import { IDLE, LOADING, NOTFOUND, SUCCESS } from "app/utils/status";
import { addRevisionToPost, updateActivePost, updateMenuPostTitle, updateSectionStatusInPost } from "app/utils/visual";
import { projectSlice } from "../project";

const initialState = {
  post: null,
  loading: false,
  postStatus: IDLE,

  revisions: null,
  take: 5,
  page: 1,
  where: undefined,
  skip: undefined,
  orderBy: { createdAt: "desc" },
};

export const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    setPost: (state, { payload }) => {
      state.post = payload;
    },
    setPostStatus: (state, { payload }) => {
      state.postStatus = payload;
    },
    setWhere: (state, { payload }) => {
      state.where = payload;
    },
    setOrderBy: (state, { payload }) => {
      state.orderBy = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
      state.skip = (state.page - 1) * state.take;
    },
  },
});

export const postApi = createApi({
  reducerPath: "postAPI",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    getPost: builder.query({
      query: ({ id, type, project }) => ({
        document: gql`
          query ($id: String!, $type: String!, $project: String!) {
            post(id: $id, type: $type, project: $project) {
              id
              project {
                id
                name
              }
              postType {
                id
                key
                title
                structure
                webservice
                categories {
                  id
                  title
                  multi
                  hierarchy
                  options {
                    id
                    categoryType {
                      key
                    }
                    translations {
                      value
                      language {
                        slug
                      }
                    }
                  }
                }
              }
              template {
                id
                sections {
                  id
                  order
                  section {
                    id
                    title
                    structure
                    thumbnail {
                      path
                    }
                  }
                }
              }
              translations {
                id
                url
                slug
                path
                title
                excerpt
                metatags
                published
                publishedDate
                createdAt
                updatedAt
                categories {
                  id
                  translations {
                    value
                    language {
                      slug
                    }
                  }
                  categoryType {
                    id
                    multi
                  }
                }
                properties {
                  key
                  value
                }
                author {
                  id
                  name
                }
                thumbnail {
                  id
                  path
                }
                language {
                  slug
                }
                sections {
                  id
                  enabled
                  data {
                    data
                    status
                    createdAt
                    author {
                      name
                    }
                  }
                  section {
                    id
                    order
                    sectionId
                  }
                }
              }
            }
          }
        `,
        variables: {
          id,
          type,
          project,
        },
      }),
      transformResponse: (response) => response.post,
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(postSlice.actions.setPost(data));
          let status = LOADING;
          if (data.id && data.translations.find((e) => e.language.slug === params.language)) status = SUCCESS;
          else status = NOTFOUND;
          dispatch(postSlice.actions.setPostStatus(status));
        } catch (err) {
          dispatch(postSlice.actions.setPostStatus(NOTFOUND));
          console.error("err", err);
        }
      },
    }),
    createRevision: builder.mutation({
      query: ({ data, content, section, status }) => ({
        document: gql`
          mutation createRevision($data: Json!, $content: GenericConnect!, $section: GenericConnect!, $status: RevisionStatus!) {
            createRevision(data: $data, content: $content, section: $section, status: $status) {
              id
              sections {
                id
                enabled
                data {
                  data
                  status
                  createdAt
                  author {
                    name
                  }
                }
                section {
                  id
                  order
                  sectionId
                }
              }
            }
          }
        `,
        variables: {
          data,
          content: { connect: { id: content } },
          section: { connect: { id: section } },
          status: status ? "PUBLIC" : "DRAFT",
        },
      }),
      transformResponse: (response) => response.createRevision,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          let { post } = getState();

          // ADD REVISION TO POST
          addRevisionToPost(post.post, data, postSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    getRevisions: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query revisions($take: Int, $skip: Int, $where: RevisionWhere, $orderBy: RevisionOrderBy) {
            revisions(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              data
              status
              createdAt
              author {
                name
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.revisions,
    }),
    getRevisionsTotal: builder.query({
      query: ({ where }) => ({
        document: gql`
          query revisionsTotal($where: RevisionWhere) {
            revisionsTotal(where: $where)
          }
        `,
        variables: {
          where,
        },
      }),
      transformResponse: (response) => response.revisionsTotal,
    }),
    updateSectionStatus: builder.mutation({
      query: ({ id, enabled }) => ({
        document: gql`
          mutation updateSectionStatus($id: String!, $enabled: Boolean!) {
            updateSectionStatus(id: $id, enabled: $enabled) {
              id
              enabled
              content {
                id
              }
            }
          }
        `,
        variables: {
          id,
          enabled,
        },
      }),
      transformResponse: (response) => response.updateSectionStatus,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          let { post } = getState();

          updateSectionStatusInPost(post.post, data, postSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    updatePost: builder.mutation({
      query: ({ id, title, slug, published, publishedDate }) => ({
        document: gql`
          mutation updatePostTranslation($id: String!, $title: String, $slug: String, $published: Boolean, $publishedDate: DateTime) {
            updatePostTranslation(id: $id, title: $title, slug: $slug, published: $published, publishedDate: $publishedDate) {
              id
              slug
              type
              path
              title
              published
              publishedDate
              language {
                slug
              }
              post {
                id
              }
            }
          }
        `,
        variables: {
          id,
          title,
          slug,
          published,
          publishedDate,
        },
      }),
      transformResponse: (response) => response.updatePostTranslation,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { post, project } = getState();

          updateActivePost(post.post, data, postSlice, dispatch);
          updateMenuPostTitle(project.info, data, projectSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    updatePostInfo: builder.mutation({
      query: ({ id, author, thumbnail, excerpt, metatags, categories, properties }) => ({
        document: gql`
          mutation updatePostTranslation($id: String!, $author: GenericConnect, $thumbnail: GenericConnectOrDisconnect, $excerpt: String, $metatags: Json, $categories: Json, $properties: Json) {
            updatePostTranslation(id: $id, author: $author, thumbnail: $thumbnail, excerpt: $excerpt, metatags: $metatags, categories: $categories, properties: $properties) {
              id
              excerpt
              metatags
              published
              categories {
                id
                translations {
                  value
                  language {
                    slug
                  }
                }
                categoryType {
                  id
                  multi
                }
              }
              properties {
                key
                value
              }
              author {
                id
                name
              }
              thumbnail {
                id
                path
              }
              post {
                postType {
                  id
                  key
                  categories {
                    id
                    title
                    multi
                    hierarchy
                    options {
                      id
                      categoryType {
                        key
                      }
                      translations {
                        value
                        language {
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          id,
          excerpt,
          metatags,
          thumbnail,
          categories,
          properties,
          author: author ? { connect: { id: author } } : undefined,
        },
      }),
      transformResponse: (response) => response.updatePostTranslation,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { post } = getState();

          updateActivePost(post.post, data, postSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    generateToken: builder.mutation({
      query: ({ content, project, type }) => ({
        document: gql`
          mutation generateToken($content: String!, $project: String!, $type: String!) {
            generateToken(content: $content, project: $project, type: $type) {
              data
              error
              message
            }
          }
        `,
        variables: {
          content,
          project,
          type,
        },
      }),
      transformResponse: (response) => response.generateToken,
    }),
  }),
});

export const { setPostStatus, setWhere, setOrderBy, setPage } = postSlice.actions;

export const {
  useGetPostQuery,
  useCreateRevisionMutation,
  useGetRevisionsQuery,
  useGetRevisionsTotalQuery,
  useUpdatePostMutation,
  useUpdatePostInfoMutation,
  useUpdateSectionStatusMutation,
  useGenerateTokenMutation,
} = postApi;

export default postSlice.reducer;
