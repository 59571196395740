import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { i18n } from "app/i18n";
import { Link, useParams } from "react-router-dom";
import { useValidateAccountMutation } from "../../stores/auth";

export default function ValidateAccount() {
  let { token } = useParams();

  const errors = {
    "missing.token": "Missing Token",
    "invalid.token": "Invalid Token",
    "invalid.user": "Invalid User",
    "already.validated": "The user was already validated",
    "expired.jwt": "Expired Token",
    "malformed.jwt": "Malformed Token",
    "user.validated": "User successfully validated",
  };
  const [request, setRequest] = useState(false);
  const [validateAccount, { isLoading, data, isUninitialized }] = useValidateAccountMutation();

  // STYLES
  const styles = {
    alert: "relative w-full p-2 rounded-sm mt-3 text-center",
  };

  useEffect(() => {
    if (token && isUninitialized) setRequest(true);
  }, [token, isUninitialized]);

  useEffect(() => {
    if (request) validateAccount({ token });
  }, [request]);

  if (data?.error) styles.alert += " text-red-700 border-b-2 border-red-700";
  else styles.alert += " text-green-700 border-b-2 border-green-700";

  return (
    <div className="flex flex-col items-center justify-center">
      {isLoading ? (
        <div className="relative w-full p-2 rounded-sm text-left flex items-center">
          <span className="text-sm font-normal text-[#0c1832]">Loading...</span>
        </div>
      ) : (
        <>
          {data ? (
            <>
              <div className="mb-8 flex items-center flex-col">
                {data.error ? <ExclamationCircleIcon className="h-20 text-red-700" /> : <CheckCircleIcon className="h-20 text-green-700" />}
                <div className={styles.alert} role="alert">
                  <span className="text-md font-normal">
                    {data.error && i18n("validateAccount.error")}
                    {!data.error && i18n("validateAccount.success")}
                  </span>
                </div>
              </div>

              <Link to="/login" className="text-gray-800 text-sm transition-all ease-in-out duration-500 hover:text-black mt-2 mb-2">
                {i18n("button.backToLogin")}
              </Link>
            </>
          ) : (
            <p>no data</p>
          )}
        </>
      )}
    </div>
  );
}
