import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function Struture(props) {
  const { isOpen, size, media, rightSide, onClose, children } = props;

  let styles = {
    zIndex: "z-50",
    width: "max-w-2xl",
    back: "flex items-center justify-center min-h-full p-4 text-center",
    rounded: "rounded-xl",
  };
  let animation = {
    enter: "ease-out duration-300",
    enterFrom: "opacity-0 scale-95",
    enterTo: "opacity-100 scale-100",
    leave: "ease-in duration-200",
    leaveFrom: "opacity-100 scale-100",
    leaveTo: "opacity-0 scale-95",
  };

  // BIG SIZE MODAL
  if (size && size === "md") styles = { ...styles, zIndex: "z-40", width: "max-w-6xl" };
  // MODAL FOR MEDIA INPUT
  if (media) styles.zIndex += " media-modal-index";
  // MODAL FOR SETTINGS
  if (rightSide) {
    styles = { ...styles, width: "w-[420px]", rounded: "rounded-none", back: "flex min-h-full justify-end" };
    animation = { ...animation, enterFrom: "opacity-0 w-1/2 translate-x-full", enterTo: "opacity-100 translate-x-0", leaveFrom: "opacity-100 translate-x-0", leaveTo: "opacity-0 translate-x-full" };
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className={`relative ${styles.zIndex}`} onClose={onClose}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-scroll">
          <div className={styles.back}>
            <Transition.Child as={Fragment} {...animation}>
              <Dialog.Panel className={`w-full ${styles.width} text-left transition-all transform bg-white shadow-xl ${styles.rounded}`}>{children}</Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
