import { useEffect, useState } from "react";

// Atoms
import { Struture, Header, Content, Footer, Tabs } from "../../../atoms/modal";

// Organisms
import Form from "app/components/organisms/form";

// Helpers
import { validation } from "app/utils/validators";
import { components, tabs } from "./constants";
import { cleanStructure } from "app/utils/content";
import { i18n } from "app/i18n";


export default function ModalSection(props) {
  const { isOpen = false, section, buttonText = i18n("button.create_section"), onSubmit, isLoading, onClose = () => {} } = props;

  const [value, setValue] = useState(null);
  const [tabActive, setTabActive] = useState(0);
  const [structure, setStructure] = useState(components);

  useEffect(() => {
    if (!isOpen) {
      setValue(null);
      setTabActive(0);
      setStructure(cleanStructure(components));
    }
  }, [isOpen]);

  useEffect(() => {
    if (section) {
      setValue({ ...section });
      const tempStructure = [...structure];
      if (section.id) {
        const index = tempStructure.findIndex((item) => item.key === "key");
        if (index !== -1) tempStructure[index].rules[1].args.id = section.id;
      }
      setStructure([...tempStructure]);
    }
  }, [section]);

  const onClickEnter = (code) => {
    if (code === 13) onClickSubmit();
  };

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) {
        onSubmit({ ...value, thumbnail: value.thumbnail ? { connect: { id: value.thumbnail.id } } : undefined });
      }
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={section ? i18n("label.edit_section") : i18n("label.create_new_section")} onClose={onClose} />
      <Tabs tabs={tabs} active={tabActive} onChange={setTabActive} />
      <Content tabs tab={tabActive}>
        {tabs.map((tab, index) => (
          <div key={index}>
            <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} tabMode tab={tabActive} onKeyDown={onClickEnter} />

            {tab.name === "Templates" && (
              <div>
                <h6 className="text-text">{i18n("label.text_associated_to_section")}</h6>
                {section && section.templates && !!section.templates.length ? (
                  <ul className="flex flex-col space-y-1 mt-2">
                    {section.templates.map((item, key) => (
                      <li key={key}>
                        <div className="transition-all duration-300 ease-linear w-full group flex items-center px-4 py-3 text-sm font-medium rounded-md text-gray-600 bg-slate-50">
                          {item.template.title}
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-sm text-gray-300">{i18n("label.no_templates")}</p>
                )}
              </div>
            )}
          </div>
        ))}
      </Content>
      <Footer
        loading={isLoading}
        cancel={{
          text: i18n("button.close"),
          onClick: onClose,
        }}
        submit={{
          text: buttonText,
          disabled: isLoading,
          onClick: onClickSubmit,
        }}
      />
    </Struture>
  );
}
