import validator from "validator";
import { i18n } from "app/i18n";

export const tabs = [{ name: i18n("label.input") }, { name: i18n("label.customize") }, { name: i18n("label.advanced") }];

export const components = [
  {
    tab: 0,
    type: "Text",
    key: "title",
    title: i18n("label.title"),
    onBlur: { key: "key", lower: true },
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    tab: 0,
    type: "Slug",
    key: "key",
    title: i18n("label.key"),
    options: { lower: false },
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    tab: 0,
    type: "Text",
    key: "description",
    title: i18n("label.description"),
  },
  { tab: 2, type: "Checkbox", key: "isRequired", title: i18n("label.required"), text: i18n("label.required_description") },
  {
    tab: 2,
    type: "Group",
    key: "conditional",
    title: i18n("label.conditional_field"),
    description: i18n("label.conditional_field_description"),
    children: [
      {
        type: "Select",
        title: i18n("label.field"),
        key: "field",
        options: [],
      },
      {
        type: "Select",
        title: i18n("label.when"),
        key: "when",
        options: [
          { value: "equal", label: i18n("label.conditional_equal") },
          { value: "notEqual", label: i18n("label.conditional_not_equal") },
          { value: "greaterThan", label: i18n("label.conditional_greater") },
          { value: "greaterOrEqualThan", label: i18n("label.conditional_greater_or_equal") },
          { value: "lowerThan", label: i18n("label.conditional_lower") },
          { value: "lowerOrEqualThan", label: i18n("label.conditional_lower_or_equal") },
          { value: "isNull", label: i18n("label.conditional_null") },
          { value: "isNotNull", label: i18n("label.conditional_not_null") },
          { value: "isFalse", label: i18n("label.conditional_false") },
          { value: "isTrue", label: i18n("label.conditional_true") },
          { value: "includes", label: i18n("label.conditional_includes") },
        ],
      },
      {
        type: "Text",
        key: "value",
        title: i18n("label.value"),
      },
    ],
  },
];
