import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "../../select";

export default function SelectForm(props) {
  // STORE
  const { language, project } = useSelector((state) => state.project);
  const { forms } = useSelector((state) => state.form);

  // STATE
  const [options, setOptions] = useState(null);

  // SET OPTIONS
  useEffect(() => {
    if (forms?.length && language) {
      let temp = [];
      temp = forms.map((form) => ({
        value: form.id,
        label: form.translations?.find((e) => e.language.slug === language.slug)?.title || form.translations[0].title,
      }));
      setOptions(temp);
    }
  }, [forms, language, project]);

  return (
    <div>
      <Select {...props} options={options} />
    </div>
  );
}
