import { i18n } from "app/i18n";
import validator from "validator";

const components = [
  {
    type: "Text",
    key: "title",
    title: i18n("label.title"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
];

const componentsDuplication = [
  {
    type: "Toggle",
    key: "copy",
    title: "Copy Content",
    description: "Active to duplicate the content",
  },
  {
    type: "Select",
    key: "language",
    options: [],
    title: "Language",
    description: "Select the language for duplication",
    conditional: {
      field: "copy",
      when: "isTrue",
    },
  },
  {
    type: "Toggle",
    key: "translate",
    title: "Translate Content",
    description: "Automatic translate content using Google Translate",
    conditional: {
      field: "copy",
      when: "isTrue",
    },
  },
];

export { components, componentsDuplication };
