import Icon from "app/components/atoms/icons/icon";
import Dropdown from "app/components/molecules/dropdown";
import ModalExternalMedia from "app/components/molecules/modals/modalExternalMedia";
import { i18n } from "app/i18n";
import { setView, setWhere, useCreateExternalMediaMutation } from "app/stores/files";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function MediaTabs(props) {
  const { showFilters = false, onClickFilter } = props;

  // STORE
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { views, mediaView, where } = useSelector((state) => state.file);
  const { project } = useSelector((state) => state.project);

  // CONSTANTS
  const tabs = [
    { key: "all", name: i18n("button.uploads") },
    { key: "myfiles", name: i18n("button.my_uploads") },
  ];

  // STATE
  const [modal, setModal] = useState(null);
  const [tabActive, setTabActive] = useState(tabs[0].key);

  // REQUEST
  const [uploadMedia, { isLoading }] = useCreateExternalMediaMutation();

  useEffect(() => {
    addAuthorFilter();
  }, [tabActive]);

  // WHEN USER CHANGE TAB
  const onChangeTab = (key) => {
    setTabActive(key);
  };
  // IF USER CLICK ON MY UPLOAD, APPLY FILTER ON MEDIA QUERY
  const addAuthorFilter = () => {
    let temp = { ...where };
    if (tabActive === "myfiles") temp.uploadBy = { id: user.id };
    else temp.uploadBy = undefined;
    dispatch(setWhere({ ...temp }));
  };
  // WHEN USER WANT TO UPLOAD EXTERNAL LINK
  const onUploadExternal = () => {
    setModal({
      isOpen: true,
      onSubmit: ({ title, url }) => {
        uploadMedia({ title, url, project: project?.id }).then((res) => {
          if (res?.data) {
            showServerSuccess(i18n("toast.success_upload"));
            setModal(null);
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setModal(null),
    });
  };

  return (
    <>
      <div className="mt-3 sm:mt-2">
        {/* Tabs Mobile */}
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            defaultValue={tabActive}
            onChange={(ev) => onChangeTab(ev.target.value)}
            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:border-blue-500"
          >
            {tabs.map((tab, key) => (
              <option key={key} value={tab.key}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        {/* Tabs Desktop */}
        <div className="hidden sm:block">
          <div className="flex flex-col-reverse lg:flex-row lg:items-center border-b border-gray-200">
            {/* FILTER BY USER */}
            <nav className="flex flex-1 -mb-px space-x-6 xl:space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <button
                  type="button"
                  key={tab.name}
                  aria-current={tabActive === tab.key ? "page" : undefined}
                  className={` whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium transition-all ease-in-out duration-300 ${
                    tabActive === tab.key ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  }`}
                  onClick={() => onChangeTab(tab.key)}
                >
                  {tab.name}
                </button>
              ))}
            </nav>

            <div className="flex items-center justify-between w-full lg:w-auto">
              <div>
                {/* CHANGE LAYOUT */}
                <div className="hidden items-center rounded-lg bg-gray-100 p-0.5 sm:flex">
                  {views.map(({ label, icon }, key) => {
                    let className = "rounded-md p-1.5 text-gray-400 hover:shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 ";
                    if (key) className += "ml-0.5 ";
                    if (mediaView === label) className += "bg-white shadow-sm ";
                    return (
                      <button type="button" key={key} className={className} onClick={() => dispatch(setView(label))}>
                        <Icon name={icon} width="w-5" height="h-5" />
                      </button>
                    );
                  })}
                </div>
              </div>

              <div className="flex items-center">
                {/* TOGGLE FOR FILTERS */}
                <div className="ml-6 items-center">
                  <button
                    type="button"
                    className={`ml-0.5 rounded-md p-1.5 text-gray-400 ${
                      showFilters ? "shadow-sm bg-white" : "hover:shadow-sm hover:bg-white"
                    } focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500`}
                    onClick={onClickFilter}
                  >
                    <Icon name="settings" height="h-5" width="h-5" />
                    <span className="sr-only">Show filters</span>
                  </button>
                </div>

                {/* BUTTON FOR UPLOAD */}
                <div className="ml-6 relative z-20 items-center upload-zone-buttons flex">
                  <div className="flex items-center bg-white rounded-md shadow-sm">
                    <label
                      htmlFor="input-upload-media"
                      className="py-1.5 px-0.5 border-r rounded-l-md border-gray-100 text-gray-400 cursor-pointer hover:bg-gray-50 focus:outline-none transition-all ease-in-out duration-300"
                    >
                      <span className="text-xs px-4">{i18n("button.upload")}</span>
                    </label>
                    <Dropdown icon="chevronDown" options={[{ title: i18n("label.external_file"), onClick: () => onUploadExternal() }]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalExternalMedia {...modal} isLoading={isLoading} />
    </>
  );
}
