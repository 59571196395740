import Avatar from "app/components/atoms/avatar";
import Icon from "app/components/atoms/icons/icon";
import Loading from "app/components/atoms/loading";
import { Content, Header, Struture } from "app/components/atoms/modal";
import Tooltip from "app/components/atoms/tooltip";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import Permission from "app/components/molecules/permission";
import { i18n } from "app/i18n";
import { useGetTotalQuery, useRestoreUserMutation, usersApi } from "app/stores/users";
import { showServerError, showServerSuccess } from "app/utils/server";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
const FULFILLED = "fulfilled";

export default function ListUsersArchived(props) {
  const { reload, onRestoreOne } = props;

  // PARAMS
  const dispatch = useDispatch();

  // STATE
  const [users, setUsers] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [where, setWhere] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);

  // FUNCTIONS
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  // QUERY
  const { data: total, refetch: updateDeleteUserTotal } = useGetTotalQuery({ where });

  // MUTATION
  const [restoreAccess, { isLoading: isRestoring }] = useRestoreUserMutation();

  // VARS
  const isLoading = isRestoring || loading;

  useEffect(() => {
    setWhere({ deletedAt: { lt: dayjs().toISOString() } });
    return () => setWhere(null);
  }, []);

  useEffect(() => {
    if (reload) setWhere({ deletedAt: { lt: dayjs().toISOString() } });
    return () => setWhere(null);
  }, [reload]);

  useEffect(() => {
    if (isOpen) getUsers();
    else setUsers(null);
  }, [isOpen]);

  const getUsers = async () => {
    setLoading(true);
    setUsers(null);

    const take = 20;
    const skip = 0;
    const orderBy = { deletedAt: "asc" };

    try {
      const { data, status } = await dispatch(usersApi.endpoints.getUsers.initiate({ take, skip, where, orderBy, noAttach: true }, { forceRefetch: true }));
      if (status === FULFILLED && data?.length) {
        setUsers(data);
        setLoading(false);
      } else {
        setUsers(null);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const restoreAccount = (user) => {
    setConfirmModal({
      isOpen: true,
      title: i18n("label.restore_access.title"),
      text: i18n("label.restore_access.text"),
      mode: "INPUTS",
      inputs: [
        {
          type: "Toggle",
          key: "alert",
          title: i18n("label.send_alert_to_user"),
          texts: [i18n("label.send"), i18n("label.no_send")],
        },
      ],
      onClose: () => setConfirmModal(null),
      onConfirm: (data) => {
        restoreAccess({ id: user.id, alert: !!data?.alert }).then((res) => {
          if (res?.data) {
            updateDeleteUserTotal();
            setIsOpen(false);
            onRestoreOne();
            showServerSuccess(i18n("toast.success_delete"));
          } else {
            showServerError(res);
          }
          setConfirmModal(null);
        });
      },
    });
  };

  return (
    <Permission min="SUPER" dev>
      <>
        {!!total && (
          <button type="button" className="btn-primary relative" onClick={onOpen}>
            <Icon name="user" strokeWidth={2} />
            <Icon name="forbidden" className="absolute left-1/2 bottom-1 h-4 text-red-600" strokeWidth={3} />
          </button>
        )}

        <Struture rightSide isOpen={isOpen} onClose={onClose}>
          <Header rightSide title={i18n("label.deleted_users")} onClose={onClose} />
          <Content rightSide>
            <div>
              <div className="space-y-1 cursor-pointer divide-y mb-10">
                {!!users?.length ? (
                  <>
                    {users.map((user, key) => (
                      <div key={key} className="flex items-center justify-between py-2">
                        <div className="flex items-center gap-4">
                          <Avatar photo={user.photo} name={user.name} />
                          <div className="flex flex-col items-start gap-0">
                            <span className="leading-none">{user.name}</span>
                            <span className="text-xs text-gray-400">{user.email}</span>
                          </div>
                        </div>
                        <div>
                          <Tooltip message={i18n("label.restore_access")}>
                            <button type="button" className="btn-icon" onClick={() => restoreAccount(user)}>
                              <Icon name="undo" />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-sm text-center">{i18n("label.nothing_found")}</p>
                )}
              </div>
              <div className="w-full mt-4 relative flex justify-center">
                <Loading active={isLoading} />
              </div>
            </div>
          </Content>
        </Struture>

        <ModalConfirm {...confirmModal} isLoading={isLoading} />
      </>
    </Permission>
  );
}
