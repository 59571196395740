import { i18n } from "app/i18n";
import validator from "validator";

const components = [
  {
    type: "Text",
    key: "title",
    title: i18n("label.title"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    type: "Text",
    key: "url",
    title: i18n("label.url"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: validator.isURL,
        validWhen: true,
        message: i18n("input.invalid_url"),
      },
    ],
  },
];

export { components };
