import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import Form from "app/components/organisms/form";
import { i18n } from "app/i18n";
import React, { useEffect, useState } from "react";
import { components } from "./constants";
import { cleanStructure } from "app/utils/content";
import validator from "validator";
import { getFlag } from "app/utils/languages";
import { validation } from "app/utils/validators";

export default function ModalTranslation(props) {
  const { isOpen = false, translation, project, languages, isLoading, buttonText = i18n("button.create_new"), onSubmit, onClose = () => {} } = props;

  // STATE
  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState();

  useEffect(() => {
    if (isOpen) {
      _prepareStructure();
    } else {
      setStructure(null);
      setValue(null);
    }
  }, [isOpen]);

  // FUNCTIONS
  const _prepareStructure = () => {
    const tempStructure = cleanStructure(components);

    // LANGUAGES
    if (languages?.length) {
      const index = tempStructure.findIndex((item) => item.key === "values");
      if (index !== -1) {
        let children = [];
        for (let i = 0; i < languages.length; i++) {
          children.push({
            type: "Text",
            key: languages[i].language.slug,
            title: `${getFlag(languages[i].language.slug)} ${languages[i].language.name}`,
            rules: [
              {
                method: validator.isEmpty,
                validWhen: false,
                message: i18n("input.required_field"),
              },
            ],
          });
        }
        tempStructure[index].children = children;
      }
    }
    // PROJECT
    if (project?.id) {
      const index = tempStructure.findIndex((item) => item.key === "key");
      if (index !== -1) tempStructure[index].rules[1].args.project = project.id;
    }
    // TRANSLATION
    if (translation?.id) {
      const index = tempStructure.findIndex((item) => item.key === "key");
      if (index !== -1) tempStructure[index].rules[1].args.id = translation.id;
    }
    // SET VALUE
    if (translation?.id) {
      const values = {};
      if (languages?.length) {
        languages.forEach(({ language }) => {
          values[language.slug] = translation?.values?.find((e) => e.language.slug === language.slug)?.value || "";
        });
      }

      setValue({ values, key: translation.key });
    }

    setStructure([...tempStructure]);
  };
  const onClickSubmit = () => {
    const key = value.key;
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) {
        let values = languages.map((e) => ({ value: value?.values[e.language.slug] || "", language: e.language.slug }));
        onSubmit({ key, values });
      }
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={translation ? i18n("label.edit_translation") : i18n("label.create_translation")} onClose={onClose} />
      <Content>
        <div>
          <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
        </div>
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: buttonText, disabled: isLoading, onClick: onClickSubmit }} />
    </Struture>
  );
}
