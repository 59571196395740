const defaultModules = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  ["link", "image", "video", "formula"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ["clean"],
];

export function buildModules(modules, formats, txtColors, bgColors) {
  if (!modules.length) return defaultModules;

  function isModuleActive(key) {
    return modules.includes(key);
  }

  function isFormatActive(key) {
    return !!formats?.find((e) => e.includes(key));
  }

  let toolbar = [];

  ////////////////////////////////////////////////////////////////
  // HEADERS
  const hasHeaders = isModuleActive("blocks");

  if (hasHeaders) {
    let headers = [];
    if (formats?.length) {
      if (isFormatActive("=h1")) headers.push(1);
      if (isFormatActive("=h2")) headers.push(2);
      if (isFormatActive("=h3")) headers.push(3);
      if (isFormatActive("=h4")) headers.push(4);
      if (isFormatActive("=h5")) headers.push(5);
      if (isFormatActive("=h6")) headers.push(6);
      if (isFormatActive("=p")) headers.push(false);
    }

    if (headers.length) toolbar.push([{ header: headers }]);
  }
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // STYLE SPAN
  const hasBold = isModuleActive("bold");
  const hasItalic = isModuleActive("italic");
  const hasUnderline = isModuleActive("underline");
  const hasStrike = isModuleActive("strikethrough");

  if (hasBold || hasItalic || hasUnderline || hasStrike) {
    let span = [];
    if (hasBold) span.push("bold");
    if (hasItalic) span.push("italic");
    if (hasUnderline) span.push("underline");
    if (hasStrike) span.push("strike");
    if (span.length) toolbar.push(span);
  }
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // ALIGNMENT
  const hasAlignLeft = isModuleActive("alignleft");
  const hasAlignCenter = isModuleActive("aligncenter");
  const hasAlignRight = isModuleActive("alignright");
  const hasAlignJustify = isModuleActive("alignjustify");

  if (hasAlignLeft || hasAlignCenter || hasAlignRight || hasAlignJustify) {
    let align = [];
    if (hasAlignLeft) align.push({ align: "" });
    if (hasAlignCenter) align.push({ align: "center" });
    if (hasAlignRight) align.push({ align: "right" });
    if (hasAlignJustify) align.push({ align: "justify" });

    if (align.length) toolbar.push(align);
  }
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // TEXT COLORS
  const hasTextColors = isModuleActive("forecolor");
  const hasBgColors = isModuleActive("backcolor");

  if (hasTextColors || hasBgColors) {
    let colors = [];
    if (hasTextColors && txtColors?.length) colors.push({ color: txtColors });
    if (hasBgColors && bgColors?.length) colors.push({ background: bgColors });
    if (colors.length) toolbar.push(colors);
  }
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // LIST ORDENATION
  const hasListOrder = isModuleActive("numlist");
  const hasListBullet = isModuleActive("bullist");
  const hasListCheck = isModuleActive("checklist");

  if (hasListOrder || hasListBullet || hasListCheck) {
    let lists = [];
    if (hasListOrder) lists.push({ list: "ordered" });
    if (hasListBullet) lists.push({ list: "bullet" });
    if (hasListCheck) lists.push({ list: "check" });
    if (lists.length) toolbar.push(lists);
  }
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // IN/OUT DENT
  const hasIndent = isModuleActive("indent");
  const hasOutdent = isModuleActive("outdent");

  if (hasIndent || hasOutdent) {
    let inoutdent = [];
    if (hasIndent) inoutdent.push({ indent: "+1" });
    if (hasOutdent) inoutdent.push({ indent: "-1" });
    if (inoutdent.length) toolbar.push(inoutdent);
  }
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // TEXT POSITION
  const hasSuper = isModuleActive("superscript");
  const hasSub = isModuleActive("subscript");

  if (hasSuper || hasSub) {
    let texts = [];
    if (hasSuper) texts.push({ script: "super" });
    if (hasSub) texts.push({ script: "sub" });
    if (texts.length) toolbar.push(texts);
  }
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // BLOCKS
  const hasBlockquote = isModuleActive("blockquote");
  const hasCode = isModuleActive("code");

  if (hasBlockquote || hasCode) {
    let blocks = [];
    if (hasBlockquote) blocks.push("blockquote");
    if (hasCode) blocks.push("code-block");
    if (blocks.length) toolbar.push(blocks);
  }
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // SPECIALS
  const hasImage = isModuleActive("image");
  const hasLink = isModuleActive("link");

  if (hasImage || hasLink) {
    let specials = [];
    if (hasImage) specials.push("image");
    if (hasLink) specials.push("link");
    if (specials.length) toolbar.push(specials);
  }
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // CLEAN
  toolbar.push(["clean"]);
  ////////////////////////////////////////////////////////////////

  return toolbar;
}
