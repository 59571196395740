import { i18n } from "app/i18n";

let tabs = [
  {
    title: i18n("label.global"),
    key: "global",
  },
  {
    title: i18n("label.metatags"),
    key: "metatags",
  },
  {
    title: i18n("label.categories"),
    key: "categories",
  },
  {
    title: i18n("label.attributes"),
    key: "attributes",
  },
  {
    title: i18n("label.properties"),
    key: "properties",
  },
];

const components = [
  {
    key: "global",
    structure: [
      { type: "Select", title: i18n("label.author"), key: "author", options: [] },
      { type: "Multimedia", title: i18n("label.thumbnail"), key: "thumbnail", noAlt: true, clearMode: true },
      { type: "Textarea", title: i18n("label.excerpt"), key: "excerpt" },
    ],
  },
  {
    key: "metatags",
    structure: [
      {
        type: "Group",
        key: "metatags",
        children: [
          { type: "Multimedia", title: i18n("label.image"), key: "image", format: ["IMAGE"], noAlt: true },
          { type: "Text", title: i18n("label.title"), key: "title" },
          { type: "Text", title: i18n("label.description"), key: "description", multiline: true },
          { type: "Text", title: i18n("label.keywords"), key: "keywords" },
        ],
      },
    ],
  },
  {
    key: "categories",
    structure: [],
  },
  {
    key: "attributes",
    structure: [],
  },
  {
    key: "properties",
    structure: [],
  },
];

export { tabs, components };
