import React, { useEffect, useState } from "react";
import Form from "../../form";
import { componentLocale, components } from "./constants";
import { i18n } from "app/i18n";
import { useDispatch, useSelector } from "react-redux";
import { validation } from "app/utils/validators";
import { showServerError, showServerSuccess } from "app/utils/server";
import { setUnsavedChanges, useUpdateProjectMutation } from "app/stores/project";
import { cleanStructure } from "app/utils/content";

export default function TabLanguages() {
  // PARAMS
  const dispatch = useDispatch();

  // STORE
  const { project, unsavedChanges } = useSelector((store) => store.project);

  // STATE
  const [value, setValue] = useState(null);
  const [changed, setChanged] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [structure, setStructure] = useState(null);

  // REQUEST
  const [updateProject, { isLoading }] = useUpdateProjectMutation();

  // VARS
  const showLocaleInput = project?.languages?.length <= 1;

  useEffect(() => {
    if (project) fillForm();
  }, [project]);

  useEffect(() => {
    if (changed !== unsavedChanges) dispatch(setUnsavedChanges(changed));
    return () => dispatch(setUnsavedChanges(false));
  }, [changed]);

  const buildStructure = () => {
    const tempStructure = cleanStructure(components);
    if (showLocaleInput) tempStructure.push(componentLocale);
    setStructure(tempStructure);
  };

  const fillForm = () => {
    const temp = {
      languages: project.languages.map((item) => ({
        default: item.default,
        enabled: item.enabled,
        language: { value: item.language.slug, label: item.language.name },
      })),
    };
    if (showLocaleInput) temp.showLocale = !!project?.settings?.frontend?.showLocale;
    setValue({ ...temp });
    setDefaultValue({ ...temp });

    buildStructure();
  };

  const submitForm = () => {
    validation(structure, value, (structure, validation) => {
      const index = structure.findIndex((item) => item.key === "languages");
      if (value && index !== -1) {
        if (!value.languages || (value.languages && !value.languages.length)) {
          structure[index].error = structure[index].error || true;
          structure[index].errorList = i18n("input.languages_required");
          validation.isValid = false;
        } else if (value.languages.filter((item) => item.default).length === 0) {
          structure[index].error = structure[index].error || true;
          structure[index].errorList = i18n("input.language_default_required");
          validation.isValid = false;
        } else if (value.languages.filter((item) => item.default).length > 1) {
          structure[index].error = structure[index].error || true;
          structure[index].errorList = i18n("input.language_default_unique");
          validation.isValid = false;
        } else {
          structure[index].error = null;
          structure[index].errorList = null;
        }
      }
      setStructure([...structure]);

      if (validation.isValid) {
        const frontend = { ...project?.settings?.frontend };
        if (value.languages.length <= 1) frontend.showLocale = value.showLocale;
        else if (frontend.showLocale) delete frontend.showLocale;

        // SETTINGS PREPARE
        const data = {
          id: project.id,
          languages: value.languages.map((e) => ({
            name: e.language.label,
            slug: e.language.value,
            enabled: !!e.enabled,
            published: !!e.published,
            default: !!e.default,
          })),
          settings: { frontend },
        };

        updateProject(data).then((res) => {
          if (res?.data) showServerSuccess(i18n("toast.success_update"));
          else showServerError(res);
        });
      }
    });
  };

  const loading = isLoading;

  return (
    <div>
      <h1 className="text-3xl font-bold tracking-tight text-slate-900">Languages</h1>
      <div className="divide-y-slate-200 mt-6 space-y-8 divide-y">
        {/* LANGUAGES OF PROJECT */}
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
          <div className="sm:col-span-6">
            <p className="mt-1 text-sm text-slate-500">Information to identify the project</p>
            <div className="mt-6">
              <Form defaultValue={defaultValue} changed={setChanged} structure={structure} value={value} onChange={setValue} disabled={loading} />
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-x-3 pt-8">
          <button
            type="button"
            disabled={loading}
            onClick={fillForm}
            className="flex items-center relative justify-center rounded-md border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-slate-900 shadow-sm hover:bg-slate-50 focus:outline-none"
          >
            {i18n("label.cancel")}
          </button>
          <button
            type="submit"
            onClick={submitForm}
            disabled={!changed || loading}
            className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none  disabled:bg-blue-200 disabled:cursor-not-allowed"
          >
            {i18n("label.save")}
          </button>
        </div>
      </div>
    </div>
  );
}
