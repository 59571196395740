import React from "react";
import Component from "app/components/atoms/inputs";
import IconX from "app/components/atoms/icons/iconX";
import slugify from "slugify";

export default function Table(props) {
  const { value, structure, errors, disabled, onChange, remove } = props;

  const headers = structure.map((item) => item.title);

  const changeValue = (index, item, key, val) => {
    const tempValue = item ? { ...item, [key]: val } : { [key]: val };
    onChange && onChange(index, { ...tempValue });
  };

  return (
    <div className="mt-1 mb-3">
      <table className="w-full -mb-[1px]">
        <thead className="bg-gray-200 font-normal text-xs ">
          <tr>
            {headers.map((item, index) => (
              <th key={String(index)} className="p-3">
                {item}
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody className="bg-gray-50">
          {value.map((item, index) => (
            <tr key={String(index)}>
              {structure.map((input, key) => {
                const Input = Component[input.type];
                const getValue = (key) => (item ? item[key] : null);
                const getError = (input) => {
                  if (errors) if (Object.keys(errors).length) if (errors[index]) if (errors[index][input.key]) return errors[index][input.key].error;
                  return input.error;
                };
                const getErrorMessage = (input) => {
                  if (errors) if (Object.keys(errors).length) if (errors[index]) if (errors[index][input.key]) return errors[index][input.key].errorMessage;
                  return input.errorMessage;
                };
                const onBlur = () => {
                  if (!input.onBlur) return null;
                  if (getValue(input.onBlur.key)) return null;
                  const replacement = typeof input.onBlur.replacement != "undefined" ? input.onBlur.replacement : "";
                  const lower = typeof input.onBlur.lower != "undefined" ? input.onBlur.lower : true;
                  changeValue(index, item, input.onBlur.key, slugify(getValue(input.key), { replacement, lower }));
                };
                return (
                  <td key={String(key)} className="p-3 pb-0 border-b border-gray-200">
                    <Input
                      {...input}
                      text={null}
                      title={null}
                      onBlur={onBlur}
                      target={input.key}
                      error={getError(input)}
                      errorMessage={getErrorMessage(input)}
                      value={getValue(input.key)}
                      disabled={disabled || input.disabled}
                      onChange={(val) => changeValue(index, item, input.key, val)}
                    />
                  </td>
                );
              })}
              <td className="pr-3 border-b text-right">
                <button className="btn-icon inline-flex" tabIndex={-1} onClick={() => remove(index)}>
                  <IconX className="w-4" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
