import { useEffect, useState } from "react";

// Components
import Form from "app/components/organisms/form";
import { Content, Footer, Header, Struture } from "../../../atoms/modal";

// Helpers
import { i18n } from "app/i18n";
import { cleanStructure } from "app/utils/content";
import { validation } from "app/utils/validators";
import { components } from "./constants";

export default function ModalExternalMedia(props) {
  const { isOpen = false, template, buttonText = i18n("button.upload_link"), onSubmit, isLoading, onClose = () => {} } = props;

  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(components);

  useEffect(() => {
    if (!isOpen) {
      setValue(null);
      setStructure(cleanStructure(components));
    }
  }, [isOpen]);

  useEffect(() => {
    if (template) setValue(template);
  }, [template]);

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) {
        onSubmit(value);
      }
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={i18n("label.upload_external")} onClose={onClose} />
      <Content>
        <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: buttonText, onClick: onClickSubmit, disabled: isLoading }} />
    </Struture>
  );
}
