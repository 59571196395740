import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { i18n } from "app/i18n";
import { isArray } from "app/utils/validators/dataValidator";
import { useState } from "react";
import Icon from "../../icons/icon";
import Block from "./block";
import ListSimple from "./simple";
import Table from "./table";

export default function List(props) {
  const { name, title, value, components, children, format, error, errorList, noDataText = undefined, buttonText = undefined, errorMessage, disabled, description, isRequired, onChange } = props;

  const styles = {
    title: "block text-sm font-medium mb-1",
    description: "block text-xs font-light text-slate-400 -mt-1.5 mb-1",
  };

  const [dragActive, setDragActive] = useState(false);

  // WHEN THE CONTENT CHANGES IN DATA FORM INSIDE ITEM
  const onChildrenChange = (index, values) => {
    let result = [...value];
    result[index] = { ...result[index], ...values };
    onChange && onChange([...result]);
  };

  // WHEN USER DUPLICATE THE ITEM
  const duplicateLine = (index) => {
    let result = isArray(value, true) ? [...value] : [];
    result.splice(index, 0, result[index]);
    onChange && onChange([...result]);
  };

  // WHEN THE USER CHANGES THE LABEL NAME FOR ITEM
  const onLabelChange = (index, labelName) => {
    let result = [...value];
    result[index] = { ...result[index], labelName };
    onChange && onChange([...result]);
  };

  // WHEN THE USER CLICKS ON DELETE BUTTON
  const removeLine = (index) => {
    const result = isArray(value, true) ? [...value] : [];
    result.splice(index, 1);
    onChange && onChange([...result]);
  };

  // WHEN CLICK IN ADD NEW LINE BUTTON
  const addLine = () => {
    let result = isArray(value, true) ? [...value] : [];
    result.push({});
    onChange && onChange([...result]);
  };

  if (error) styles.title += " text-red-600";
  else styles.title += " text-slate-700";

  let listError = null;
  if (errorList && typeof errorList === "string") listError = errorList;

  return (
    <div className="block mb-3">
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        <div className="flex items-center gap-4">
          {format !== "table" && format !== "simple" && !!value?.length && value.length > 1 && (
            <div className="flex items-center gap-1 mb-1">
              <span className="text-xs">{dragActive ? "Edit" : "Reorder"}</span>
              <button type="button" className="btn-icon" onClick={() => setDragActive(!dragActive)}>
                <Icon name={dragActive ? "pencil" : "order"} width="w-4" height="h-4" className="text-slate-700" />
              </button>
            </div>
          )}
          {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
        </div>
      </div>
      {value && !!value.length ? (
        <>
          {format === "table" ? (
            <Table disabled={disabled} value={value} onChange={onChildrenChange} remove={removeLine} structure={components || children} errors={errorList} />
          ) : format === "simple" ? (
            <ListSimple disabled={disabled} value={value} onChange={onChildrenChange} remove={removeLine} structure={components || children} errors={errorList} />
          ) : (
            <Block
              name={name}
              value={value}
              disabled={disabled}
              onChange={onChildrenChange}
              remove={removeLine}
              duplicate={duplicateLine}
              onLabelChange={onLabelChange}
              structure={components || children}
              errors={errorList}
              dragActive={dragActive}
              onChangeOrder={onChange}
            />
          )}
        </>
      ) : (
        noDataText && <span className="block text-center text-xs text-slate-300">{noDataText}</span>
      )}
      <div className={`flex  justify-between ${format === "simple" ? "flex-col items-center" : "flex-row items-start"}`}>
        <div>{error && <p className="mt-3 text-xs text-red-600">{listError || errorMessage}</p>}</div>
        <div className="flex items-start justify-end">
          {!disabled && (
            <button type="button" onClick={addLine} className="btn-sm-primary mt-3">
              <Icon name="plus" />
              {buttonText || i18n("button.new_line")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
