import validator from "validator";
import customValidator from "app/utils/validators/customValidator";
import { i18n } from "app/i18n";

const tabs = [{ name: i18n("label.contentType") }, { name: i18n("label.projects") }];

const components = [
  {
    tab: 0,
    type: "Text",
    key: "title",
    title: i18n("label.title"),
    onBlur: { key: "key", lower: true },
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    tab: 0,
    type: "Text",
    key: "key",
    title: i18n("label.key"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: customValidator.isContentTypeKeyAvailable,
        validWhen: true,
        args: { id: undefined },
        message: i18n("input.key_already_in_use"),
      },
    ],
  },
  {
    tab: 1,
    type: "Select",
    key: "projects",
    title: i18n("label.projects"),
    multiple: true,
    options: [],
  },
];

export { components, tabs };
