import ModalMedia from "app/components/molecules/modals/modalMedia";
import { useState } from "react";
import IconPlus from "../../icons/iconPlus";
import { getSource } from "app/utils/content";

export default function Avatar(props) {
  const { value, multi = false, title, error, onChange } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const onSubmit = (val) => {
    onChange(val);
    closeModal();
  };

  return (
    <label className="inline-block mb-3">
      {title && <span className="block text-sm font-medium text-slate-700 mb-1">{title}</span>}
      <div className="h-32 relative aspect-square rounded-full bg-gray-50 border border-gray-300 overflow-hidden">
        {value ? (
          <button type="button" onClick={openModal} className="flex items-center justify-center group">
            <img src={getSource(value.path)} className="absolute inset-0 object-cover w-full h-full scale-100 transition-all duration-300 ease-linear hover:scale-105" />
            <span className="text-white text-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 scale-0 transition-all duration-300 ease-linear group-hover:scale-100">EDIT</span>
          </button>
        ) : (
          <button type="button" onClick={openModal} className="h-full w-full flex items-center justify-center text-gray-300 font-bold hover:bg-gray-100 hover:text-gray-400">
            <IconPlus className="h-8 w-8" />
          </button>
        )}
      </div>
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}

      <ModalMedia formats="AVATAR" value={value} isOpen={modalOpen} onClose={closeModal} onSubmit={onSubmit} multi={multi} />
    </label>
  );
}
