import Loading from "app/components/atoms/loading";
import { i18n } from "app/i18n";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import validator from "validator";
import Form from "../../components/organisms/form";
import { useLoginMutation } from "../../stores/auth";
import { useResendTokenCodeMutation, usersApi } from "../../stores/users";
import { secureHost, setHeader } from "../../utils/server";
import { validation } from "../../utils/validators";
import dayjs from "dayjs";

export default function Login() {
  const { buttonColor } = useOutletContext();
  const [{ refresh_token }, setCookie, removeCookie] = useCookies(["logged_in", "access_token", "refresh_token"]);

  const components = [
    {
      tab: 0,
      type: "Text",
      key: "email",
      placeholder: "Email",
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.empty_email"),
        },
        {
          method: validator.isEmail,
          validWhen: true,
          message: i18n("input.invalid_email"),
        },
      ],
    },
    {
      tab: 0,
      type: "Password",
      key: "password",
      placeholder: "Password",
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.empty_password"),
        },
      ],
    },
    {
      tab: 1,
      type: "Text",
      key: "token",
      placeholder: i18n("login.code_placeholder"),
      description: i18n("login.code_description"),
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("login.code_message"),
        },
      ],
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const queryPassword = searchParams.get("password");
  const queryUser = searchParams.get("newuser");

  // STATES
  const [value, setValue] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [formMessage, setFormMessage] = useState(null);
  const [structure, setStructure] = useState(components);

  // REQUEST
  const [login, { isLoading: isLoadingLogin }] = useLoginMutation();
  const [resendTokenCode, { isLoading: isLoadingToken }] = useResendTokenCodeMutation();

  // STORE
  const { user, connectionFailed } = useSelector((state) => state.auth);

  const isLoading = isLoadingLogin || isLoadingToken;

  useEffect(() => {
    if (user) navigate("/dashboard", { replace: true });
  }, [user]);

  useEffect(() => {
    const onChange = (status, message) => {
      navigate("/login", { replace: true });
      setFormMessage({ status, message });
      setTimeout(() => {
        setFormMessage(null);
      }, 5000);
    };

    if (queryPassword) {
      if (queryPassword === "ok") onChange(true, "password.message_updated"); // Success! You can now login with your new password.
      if (queryPassword === "nok") onChange(false, "password.error_updating"); // An error has ocorred. Please try again later.
    }
    if (queryUser) {
      if (queryUser === "ok") onChange(true, "user.create_success"); // Your account is successfully created
      if (queryUser === "nok") onChange(false, "user.error_creating"); // An error has ocorred. Please try again later.
    }
  }, [queryUser, queryPassword]);

  useEffect(() => {
    if (refresh_token) setValue({ token: refresh_token });
  }, [refresh_token]);

  const onClickEnter = (code) => {
    if (code === 13) onLogin();
  };

  const onLogin = () => {
    const fields = structure.filter((item) => item.tab === tabIndex);
    validation(fields, value, (st, validation) => {
      setStructure(structure.map((item) => ({ ...item, error: st?.find((e) => e.key === item.key)?.error, errorMessage: st?.find((e) => e.key === item.key)?.errorMessage })));
      if (validation.isValid) {
        login(value)
          // VERIFY
          .then((res) => {
            if (res.data) {
              if (res.data.error) {
                setFormMessage({ status: false, message: i18n(res.data.message) });
                return false;
              } else if (!res.data.user && res.data.token && res.data.token.request_token) {
                setValue({ ...value, token: undefined });
                setTabIndex(1);
              } else if (!res.data.user && res.data.token && res.data.token.update_token) {
                setValue({ ...value, token: undefined });
                setTabIndex(1);
                return { tokenExists: true };
              } else {
                return res.data;
              }
            }
          })
          // DEFINE TOKEN
          .then((res) => {
            if (res && res.user && res.token) {
              const path = "/";
              const secure = secureHost();
              const expiresLoggedIn = dayjs().add(1, "day").toDate();
              const expiresToken = dayjs().add(5, "day").toDate();

              setCookie("logged_in", true, { secure, path, expires: expiresLoggedIn });
              setCookie("access_token", res.token.access_token, { secure, path, expires: expiresToken });
              setCookie("refresh_token", res.token.refresh_token, { secure, path, expires: expiresToken });
              return { result: !!res.token.access_token, token: res.token.access_token };
            } else if (res && res.tokenExists) {
              return { result: false, token: true };
            }
            return { result: false };
          })
          // SET HEADER
          .then((response) => {
            if (response.result) {
              setHeader(response.token);
            } else if (!response.result && !response.token) {
              removeCookie("token");
              removeCookie("logged_in");
              removeCookie("access_token");
            }
            return response;
          })
          // DEFINE USER
          .then((response) => {
            if (response?.result) {
              dispatch(usersApi.endpoints.getMe.initiate(null));
              navigate("/dashboard", { replace: true });
            }
          });
      }
    });
  };

  const onResendToken = () => {
    setFormMessage(null);
    resendTokenCode({ email: value.email }).then((res) => {
      if (res && res.data && res.data.result) setFormMessage({ status: true, message: i18n("login.code_sent") });
      else setFormMessage({ status: false, message: i18n("login.code_unsent") });
    });
  };

  return (
    <div>
      <div className="mb-8">
        <h1 className="text-xl text-[#0c1832]">{i18n("login.title")}</h1>
        <p className="text-md text-[#627385]">{i18n("login.description")}</p>
      </div>

      <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} mode="login" tabMode tab={tabIndex} onKeyDown={onClickEnter} />

      {tabIndex === 1 && (
        <div className="relative w-full flex justify-end">
          <button
            className="relative inline-block py-2 px-4 rounded-md hover:bg-gray-200 text-xs font-normal transition-all ease-in-out duration-300"
            disabled={isLoading}
            type="button"
            onClick={onResendToken}
          >
            {i18n("login.resend_code")}
          </button>
        </div>
      )}

      {formMessage && (
        <div className={`relative w-full p-2 rounded-sm bg-white/40 shadow-md border-b-2 ${formMessage.status ? "text-green-700  border-green-700" : "text-red-700  border-red-700"}`} role="alert">
          <span className="text-xs font-normal">{formMessage.message}</span>
        </div>
      )}

      <div className="flex justify-between items-center flex-wrap mt-6">
        <div className="flex items-center mt-2 mb-2 mr-6">
          <button
            type="button"
            onClick={onLogin}
            disabled={isLoading}
            style={{ backgroundColor: buttonColor || undefined }}
            className="inline-block px-7 py-3 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition-all duration-500 ease-in-out disabled:pointer-events-none disabled:!cursor-not-allowed disabled:!shadow-md bg-[#182e5f] disabled:!opacity-30 hover:!bg-[#0c1832] focus:bg-[#0c1832] active:bg-[#0c1832]"
          >
            {i18n("button.login")}
          </button>

          <Loading active={isLoading} className="ml-2" />
        </div>

        <Link to={"/request-password"} className="text-gray-800 text-sm transition-all ease-in-out duration-500 hover:!text-[#0c1832] mt-2 mb-2" style={{ color: buttonColor || undefined }}>
          {i18n("button.forgot_password")}
        </Link>
      </div>

      {connectionFailed && (
        <div className="relative w-full p-2 rounded-sm bg-red-200 shadow-md text-red-700 my-2" role="alert">
          <span className="text-xs font-normal">{i18n("server.connection_missing")}</span>
        </div>
      )}
    </div>
  );
}
