import { i18n } from "app/i18n";
import customValidator from "app/utils/validators/customValidator";

const components = [
  {
    tab: "pages",
    type: "Select",
    title: "Homepage",
    key: "home",
    options: undefined,
    posttype: undefined,
    loadmore: true,
    rules: [
      {
        method: customValidator.isObjectEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    tab: "pages",
    type: "Select",
    title: "Privacy Policy",
    key: "privacy",
    options: undefined,
    posttype: undefined,
    loadmore: true,
  },
  {
    tab: "pages",
    type: "Select",
    title: "Newsletter Page",
    key: "newsletter",
    options: undefined,
    posttype: undefined,
    loadmore: true,
  },
  {
    tab: "seo",
    type: "Multimedia",
    title: "Image",
    formats: ["IMAGE"],
    noAlt: true,
    key: "image",
  },
  {
    tab: "seo",
    type: "Text",
    title: "Title",
    key: "title",
  },
  {
    tab: "seo",
    type: "Textarea",
    title: "Description",
    key: "description",
  },
  // CONFIGS
  {
    tab: "path",
    type: "Text",
    title: "Tab Title",
    description: "Use [page-title] to place the title of page",
    key: "tabTitle",
  },
];

export { components };
