import { PencilIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import { TwitterPicker, ChromePicker, CirclePicker, SketchPicker, PhotoshopPicker, BlockPicker } from "react-color";
import IconX from "../../icons/iconX";

export default function Color(props) {
  const { value = undefined, title, format = "simple", collect, defaults = null, error, errorMessage, onChange } = props;

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [timerID, setTimerID] = useState(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) handleClose();
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref]);

  const timer = (hex) => {
    setTimeout(() => {
      collectColor(hex);
    }, 1600);
  };
  const handleSelect = (ev) => {
    if (collect) {
      if (timerID) clearTimeout(timerID);
      setTimerID(timer(ev.hex));
    } else {
      onChange(ev.hex);
    }
  };
  const collectColor = (hex) => {
    let temp = value?.length ? [...value] : [];
    if (collect) temp.push(hex);
    else temp = hex;
    onChange(temp);
  };
  const removeItem = (val) => {
    let temp = value?.length ? [...value] : [];
    const index = temp.findIndex((item) => item === val);
    if (index !== -1) temp.splice(index, 1);
    onChange(temp);
  };
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="block mb-3">
      {title && <span className="block text-sm font-medium text-slate-700 mb-1">{title}</span>}
      <div>
        {format === "simple" && (
          <div ref={ref} className="relative inline-block">
            <div className="group relative inline-block bg-white " onClick={handleClick}>
              <div
                className="w-12 h-12 bg-slate-300 rounded-md overflow-hidden cursor-pointer transition-all duration-300 ease-in-out shadow-lg hover:shadow-xl"
                style={{ backgroundColor: value || undefined }}
              />
              <div className="absolute left-14 top-0 bottom-0 m-auto opacity-0 flex items-center justify-center group-hover:opacity-100 transition-all duration-300 ease-in-out">
                <span>
                  <PencilIcon className="w-5 h-5" />
                </span>
              </div>
            </div>
            {isOpen ? (
              <div className="absolute z-10 pb-10">
                <div className="fixed index-0" onClick={handleClose} />
                <SketchPicker color={value || undefined} onChange={handleSelect} />
              </div>
            ) : null}
          </div>
        )}
        {format === "short" && <TwitterPicker color={value || undefined} onChangeComplete={handleSelect} colors={collect ? [] : undefined} triangle="hide" value={collect ? "" : value} />}
        {format === "chrome" && <ChromePicker onChange={handleSelect} color={value || undefined} />}
        {format === "circle" && <CirclePicker color={value || undefined} onChangeComplete={handleSelect} />}
        {format === "sketch" && <SketchPicker color={value || undefined} onChangeComplete={handleSelect} colors={defaults} />}
        {format === "photoshop" && <PhotoshopPicker color={value || undefined} onChangeComplete={handleSelect} colors={defaults} />}
        {format === "block" && <BlockPicker color={value || undefined} triangle="hide" colors={defaults} onChangeComplete={handleSelect} />}
      </div>
      {collect && (
        <div className="mt-3">
          {value && !!value.length ? (
            <>
              {value.map((backgroundColor, key) => (
                <div key={String(key)} className="w-14 mr-3 mb-3 inline-block border rounded relative">
                  <div className="h-7 w-full m-auto rounded-t" style={{ backgroundColor }} />
                  <button
                    type="button"
                    onClick={() => removeItem(backgroundColor)}
                    className="h-4 w-4 bg-gray-100 hover:bg-primary-500 hover:text-white absolute top-0 right-0 flex items-center justify-center rounded-full border translate-x-1/2 -translate-y-1/2 z-10"
                  >
                    <IconX className="h-3 w-3" />
                  </button>
                  <span className="block my-1 uppercase text-center text-[10px] tracking-wide">{backgroundColor}</span>
                </div>
              ))}
            </>
          ) : (
            <p className="text-xs text-gray-400">Nothing selected!</p>
          )}
        </div>
      )}
    </div>
  );
}
