import TabEmail from "./email";
import TabEmailDesign from "./emailDesign";
import TabEmailGlobal from "./emailGlobal";
import TabEmailTexts from "./emailTexts";
import TabLanguages from "./languages";
import TabLogin from "./login";
import TabModules from "./modules";
import TabMultimedia from "./multimedia";
import TabNotifications from "./notifications";
import TabPages from "./pages";
import TabProject from "./project";
import TabSitemap from "./sitemap";
import TabUsers from "./users";

export const projectSettingsNavigation = [
  {
    label: "main",
    title: "label.project",
    description: "label.project.tab.description",
    icon: "cog",
    element: <TabProject />,
    permission: { min: "ADMINISTRATOR", project: true, dev: true },
  },
  {
    label: "users",
    title: "label.users",
    description: "label.users.tab.description",
    icon: "users",
    element: <TabUsers />,
    permission: { min: "CONTRIBUTOR", project: true, dev: true },
  },
  {
    label: "pages",
    title: "label.pages",
    description: "label.pages.tab.description",
    icon: "document",
    element: <TabPages />,
    permission: { min: "MANAGER", project: true, dev: true },
  },
  {
    label: "modules",
    title: "label.modules",
    description: "label.modules.tab.description",
    icon: "module",
    element: <TabModules />,
    permission: { min: "ADMINISTRATOR", project: true, dev: true },
  },
  {
    label: "languages",
    title: "label.languages",
    description: "label.languages.tab.description",
    icon: "comment",
    element: <TabLanguages />,
    permission: { min: "MANAGER", project: true, dev: true },
  },
  {
    label: "multimedia",
    title: "label.multimedia",
    description: "label.multimedia.tab.description",
    icon: "media",
    element: <TabMultimedia />,
    permission: { min: "ADMINISTRATOR", project: true, dev: true },
  },
  {
    label: "sitemap",
    title: "label.sitemap",
    description: "label.sitemap.tab.description",
    icon: "sitemap",
    element: <TabSitemap />,
    permission: { min: "EDITOR", project: true, dev: true },
  },
  {
    label: "notifications",
    title: "label.notifications",
    description: "label.notifications.tab.description",
    icon: "bell",
    element: <TabNotifications />,
    permission: { min: "ADMINISTRATOR", project: true, dev: true },
  },
  {
    label: "email",
    title: "label.email",
    description: "label.email.tab.description",
    icon: "email",
    element: <TabEmail />,
    permission: { min: "EDITOR", project: true, dev: true },
  },
];

export const setttingsNavigation = [
  {
    title: "settings.dashboard_title",
    icon: "home",
    element: TabLogin,
    permission: { min: "ADMINISTRATOR", dev: true },
  },
  {
    title: "settings.email_server",
    icon: "email",
    element: TabEmailGlobal,
    permission: { min: "ADMINISTRATOR", dev: true },
  },
  {
    title: "settings.email_layout",
    description: "settings.email_layout_description",
    icon: "design",
    element: TabEmailDesign,
    permission: { min: "ADMINISTRATOR", dev: true },
  },
  {
    title: "settings.email_texts",
    description: "settings.email_texts_description",
    icon: "document",
    element: TabEmailTexts,
    permission: { min: "ADMINISTRATOR", dev: true },
  },
];
