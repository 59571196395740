import React from "react";
import { i18n } from "app/i18n";
import { getCurrentValue, hasLength, hasLowercase, hasNumber, hasSpecialcase, hasUppercase } from "./functions";
import IconCheck from "./icon";

export default function PasswordStrength(props) {
  const { hidden, disabled, value, withInfo } = props;

  const styles = {
    line: "h-[4px] absolute bottom-0 left-0 transition-all duration-300 ease-linear",
    text: "font-bold pl-2",
  };

  const levels = [
    { strength: 0, percent: "w-0", color: "", borderColor: "", text: "-" },
    { strength: 1, percent: "w-[5%]", color: "text-[#AD4631]", borderColor: "bg-[#AD4631]", text: i18n("passStrength.weakText")},
    { strength: 2, percent: "w-1/4", color: "text-[#FA6F52]", borderColor: "bg-[#FA6F52]", text: i18n("passStrength.mediumText")},
    { strength: 3, percent: "w-1/2", color: "text-[#FADD5F]", borderColor: "bg-[#FADD5F]", text: i18n("passStrength.goodText")},
    { strength: 4, percent: "w-3/4", color: "text-[#44AD52]", borderColor: "bg-[#44AD52]", text: i18n("passStrength.strongText")},
    { strength: 5, percent: "w-full", color: "text-[#6BFA7E]", borderColor: "bg-[#6BFA7E]", text: i18n("passStrength.excellentText")},
  ];

  const currentValue = levels.find((i) => i.strength === getCurrentValue(value));
  if (hidden) return null;

  return (
    <div className={`relative w-full p-3 mb-2 bg-white/40 rounded-sm shadow-md ${disabled ? "opacity-50 pointer-events-none" : ""}`}>
      {withInfo && (
        <>
          <p className="relative text-xs font-normal text-[#0c1832]">{i18n("passStrength.title")}</p>
          <ul className="text-xs ml-1 mb-6 leading-4">
            <li>
              <IconCheck active={hasLength(value)} /> <span>{i18n("passStrength.lenght8")}</span>
            </li>
            <li>
              <IconCheck active={hasNumber(value)} /> <span> {i18n("passStrength.includeNumber")}</span>
            </li>
            <li>
              <IconCheck active={hasLowercase(value)} /> <span>{i18n("passStrength.includeLowercase")}</span>
            </li>
            <li>
              <IconCheck active={hasUppercase(value)} /> <span>{i18n("passStrength.includeUppercase")}</span>
            </li>
            <li>
              <IconCheck active={hasSpecialcase(value)} /> <span> {i18n("passStrength.includeCharacter")}</span>
            </li>
          </ul>
        </>
      )}
      <div className={`${styles.line} ${currentValue.percent} ${currentValue.borderColor}`} />
      <p className="relative text-xs font-normal text-[#0c1832]">
        {i18n("passStrength.strength")} <span className={`${styles.text} ${currentValue.color}`}>{currentValue.text}</span>
      </p>
    </div>
  );
}
