import { i18n } from "app/i18n";
import customValidator from "app/utils/validators/customValidator";
import validator from "validator";

const components = {
  text: [
    {
      type: "Text",
      key: "name",
      title: i18n("label.name"),
      onBlur: { key: "slug", lower: true, replacement: "", removeExtra: true },
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.required_field"),
        },
      ],
    },
    {
      type: "Text",
      key: "slug",
      title: i18n("label.slug"),
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.required_field"),
        },
      ],
    },
    {
      type: "Text",
      key: "description",
      title: i18n("label.description"),
    },
    {
      type: "Text",
      key: "placeholder",
      title: i18n("label.placeholder"),
    },
    {
      type: "Toggle",
      key: "required",
      title: i18n("label.required"),
    },
    {
      type: "Toggle",
      key: "emailField",
      title: i18n("label.is_email_field"),
    },
  ],
  textarea: [
    {
      type: "Text",
      key: "name",
      title: i18n("label.name"),
      onBlur: { key: "slug", lower: true, replacement: "", removeExtra: true },
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.required_field"),
        },
      ],
    },
    {
      type: "Text",
      key: "slug",
      title: i18n("label.slug"),
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.required_field"),
        },
      ],
    },
    {
      type: "Text",
      key: "description",
      title: i18n("label.description"),
    },
    {
      type: "Text",
      key: "placeholder",
      title: i18n("label.placeholder"),
    },
    {
      type: "Toggle",
      key: "required",
      title: i18n("label.required"),
    },
  ],
  select: [
    {
      type: "Text",
      key: "name",
      title: i18n("label.name"),
      onBlur: { key: "slug", lower: true, replacement: "", removeExtra: true },
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.required_field"),
        },
      ],
    },
    {
      type: "Text",
      key: "slug",
      title: i18n("label.slug"),
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.required_field"),
        },
      ],
    },
    {
      type: "Text",
      key: "description",
      title: i18n("label.description"),
    },
    {
      type: "Text",
      key: "placeholder",
      title: i18n("label.placeholder"),
    },
    {
      type: "Toggle",
      key: "required",
      title: i18n("label.required"),
    },
    {
      type: "Toggle",
      key: "multi",
      title: i18n("label.multi"),
    },
    {
      type: "List",
      key: "options",
      title: i18n("label.options"),
      format: "table",
      components: [
        { key: "label", title: i18n("label.label"), placeholder: i18n("label.label"), type: "Text", onBlur: { key: "value" } },
        { key: "value", title: i18n("label.value"), placeholder: i18n("label.value"), type: "Text" },
      ],
      rules: [
        {
          method: customValidator.isObjectEmpty,
          validWhen: false,
          message: i18n("input.options_needed"),
        },
      ],
    },
  ],
  file: [
    {
      type: "Text",
      key: "name",
      title: i18n("label.name"),
      onBlur: { key: "slug", lower: true, replacement: "", removeExtra: true },
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.required_field"),
        },
      ],
    },
    {
      type: "Text",
      key: "slug",
      title: i18n("label.slug"),
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.required_field"),
        },
      ],
    },
    {
      type: "Text",
      key: "description",
      title: i18n("label.description"),
    },
    {
      type: "Text",
      key: "placeholder",
      title: i18n("label.placeholder"),
    },
    {
      type: "Toggle",
      key: "required",
      title: i18n("label.required"),
    },
    {
      type: "Checklist",
      title: i18n("label.formats"),
      key: "formats",
      options: [
        { value: "IMAGE", label: "Image" },
        { value: "VIDEO", label: "Video" },
        { value: "FILE", label: "File" },
      ],
    },
  ],
  checkbox: [
    {
      type: "Text",
      key: "name",
      title: i18n("label.name"),
      onBlur: { key: "slug", lower: true, replacement: "", removeExtra: true },
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.required_field"),
        },
      ],
    },
    {
      type: "Text",
      key: "slug",
      title: i18n("label.slug"),
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.required_field"),
        },
      ],
    },
    {
      type: "Text",
      key: "description",
      title: i18n("label.description"),
    },
    {
      type: "Editor",
      key: "text",
      title: i18n("label.text"),
      modules: ["link", "bold", "italic", "underline"],
    },
    {
      type: "Toggle",
      key: "required",
      title: i18n("label.required"),
    },
  ],
  checklist: [
    {
      type: "Text",
      key: "name",
      title: i18n("label.name"),
      onBlur: { key: "slug", lower: true, replacement: "", removeExtra: true },
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.required_field"),
        },
      ],
    },
    {
      type: "Text",
      key: "slug",
      title: i18n("label.slug"),
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.required_field"),
        },
      ],
    },
    {
      type: "Text",
      key: "description",
      title: i18n("label.description"),
    },
    {
      type: "Toggle",
      key: "multi",
      title: i18n("label.multi"),
    },
    {
      type: "Toggle",
      key: "required",
      title: i18n("label.required"),
    },
    {
      type: "List",
      key: "options",
      title: i18n("label.options"),
      format: "table",
      components: [
        { key: "label", title: i18n("label.label"), placeholder: i18n("label.label"), type: "Text", onBlur: { key: "value" } },
        { key: "value", title: i18n("label.value"), placeholder: i18n("label.value"), type: "Text" },
      ],
      rules: [
        {
          method: customValidator.isObjectEmpty,
          validWhen: false,
          message: i18n("input.options_needed"),
        },
      ],
    },
  ],
};

export { components };
