import validator from "validator";
import customValidator from "app/utils/validators/customValidator";
import { i18n } from "app/i18n";

const components = [
  {
    type: "Text",
    key: "key",
    title: i18n("label.key"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: customValidator.isTranslationKeyAvailable,
        validWhen: true,
        args: { id: undefined, project: undefined },
        message: i18n("input.slug_in_use"),
      },
    ],
  },
  {
    type: "Group",
    key: "values",
    title: "Languages",
    children: [],
  },
];

export { components };
