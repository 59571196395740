import { useEffect, useState } from "react";

// Atoms
import { Struture, Header, Content, Footer } from "../../../atoms/modal";
import Form from "app/components/organisms/form";

// Helpers
import { components } from "./constants";
import { validation } from "app/utils/validators";
import { cleanStructure } from "app/utils/content";
import { i18n } from "app/i18n";

export default function ModalTemplate(props) {
  const { isOpen = false, template, buttonText = i18n("button.create_template"), onSubmit, isLoading, onClose = () => {} } = props;

  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(components);

  useEffect(() => {
    if (!isOpen) {
      setValue(null);
      setStructure(cleanStructure(components));
    }
  }, [isOpen]);

  useEffect(() => {
    if (template) setValue(template);
  }, [template]);

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) {
        onSubmit(value);
      }
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={template ? i18n("label.edit_template") : i18n("label.create_new_template")} onClose={onClose} />
      <Content>
        <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
      </Content>
      <Footer cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: buttonText, onClick: onClickSubmit, disabled: isLoading }} />
    </Struture>
  );
}
