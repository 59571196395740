import { PhotoIcon } from "@heroicons/react/24/outline";
import IconForbidden from "app/components/atoms/icons/IconForbidden";
import { Content } from "app/components/atoms/modal";
import Tooltip from "app/components/atoms/tooltip";
import Dropdown from "app/components/molecules/dropdown";
import HorizontalScroll from "app/components/molecules/horizontalScroll";
import LabelRevisionStatus from "app/components/molecules/labels/labelRevisionStatus";
import LabelSectionStatus from "app/components/molecules/labels/labelSectionStatus";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalPreview from "app/components/molecules/modals/modalPreview";
import ModalRevision from "app/components/molecules/modals/modalRevision";
import Permission from "app/components/molecules/permission";
import PopupBuilder from "app/components/molecules/popupBuilder";
import Form from "app/components/organisms/form";
import { i18n } from "app/i18n";
import { useCreateRevisionMutation } from "app/stores/post";
import { setUnsavedChanges } from "app/stores/project";
import { getSource } from "app/utils/content";
import { showServerError, showServerSuccess } from "app/utils/server";
import { NOTFOUND } from "app/utils/status";
import { validation } from "app/utils/validators";
import { objectsAreEqual } from "app/utils/validators/dataValidator";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

export default function PostEdition(props) {
  const { loading } = props;

  // PARAMS
  const dispatch = useDispatch();
  const { key, project, lang } = useParams();

  // STORE
  const { post, postStatus } = useSelector((state) => state.post);
  const { unsavedChanges } = useSelector((state) => state.project);

  // STATE
  const [tab, setTab] = useState(0);
  const [value, setValue] = useState(null);
  const [errors, setErrors] = useState(null);
  const [dataChanged, setDataChanged] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(null);
  const [modalPreview, setModalPreview] = useState(null);
  const [modalRevision, setModalRevision] = useState(null);

  // REQUESTS
  const [createRevision, { isLoading: isCreating }] = useCreateRevisionMutation();

  // VARS
  const isLoading = loading || isCreating;
  const content = post?.translations?.find((item) => item.language.slug === lang);

  useEffect(() => {
    return () => setErrors(null);
  }, []);

  useEffect(() => {
    if (post && content) {
      const idSection = post?.template?.sections?.find((e) => e.order === tab)?.id;
      const lastRevision = content?.sections?.find((e) => e.section.id === idSection)?.data?.data;
      setValue({ ...lastRevision });
    }
  }, [tab, post, content]);

  useEffect(() => {
    setDataChanged(isDataDifferent());
  }, [tab, post, content, value]);

  useEffect(() => {
    if (dataChanged !== unsavedChanges) dispatch(setUnsavedChanges(dataChanged));
    return () => dispatch(setUnsavedChanges(false));
  }, [dataChanged]);

  // CHECK FOR DIFFERENT DATA
  const isDataDifferent = () => {
    const idSection = post?.template?.sections?.find((e) => e.order === tab)?.id;
    const lastRevision = content?.sections?.find((e) => e.section.id === idSection)?.data?.data;
    return !objectsAreEqual(lastRevision, value);
  };

  // SHOW REVISIONS (TO DO)
  const onShowRevisions = () => {
    setModalRevision({
      isOpen: true,
      current: tab,
      content: content,
      sections: post?.template?.sections,
      onClose: () => setModalRevision(null),
    });
  };

  // CLEAN CHANGES
  const cleanChanges = () => {
    const idSection = post?.template?.sections?.find((e) => e.order === tab)?.id;
    if (!idSection) return null;
    const lastRevision = content?.sections?.find((e) => e.section.id === idSection)?.data?.data || null;
    setValue({ ...lastRevision });
  };

  // ON SUBMIT
  const onSubmitNewContent = (status = false) => {
    if (!dataChanged) return false;

    const structure = post?.template?.sections?.find((e) => e.order === tab).section.structure;
    validation(structure, value, (structure, validation) => {
      setErrors(structure);

      if (validation.isValid) {
        createRevision({ data: value, content: content?.id, section: post?.template?.sections?.find((e) => e.order === tab)?.id, status }).then((res) => {
          if (res && res.data) showServerSuccess(i18n("toast.success_update"));
          else showServerError(res);
        });
      }
    });
  };

  // GET STRUCTURE FOR CURRENT SECTION
  const buildStructure = (tab) => {
    const structure = post?.template?.sections?.find((e) => e.order === tab)?.section?.structure;
    if (!errors?.length) return structure;
    if (!structure?.length) return [];

    return structure.map((item, index) => {
      const error = errors[index]?.error || false;
      const errorMessage = errors[index]?.errorMessage || null;
      const errorList = errors[index]?.errorList || null;
      const children = item.children && errors[index]?.children ? errors[index]?.children : item.children;
      const components = item.components && errors[index]?.components ? errors[index]?.components : item.components;
      return { ...item, children, components, error, errorMessage, errorList };
    });
  };

  // OPEN PREVIEW OF SECTION
  const showThumbnail = (path) => {
    setModalPreview({
      isOpen: true,
      href: getSource(path),
      onClose: () => setModalPreview(null),
    });
  };

  // ON CHANGE TAB
  const changeTab = (index) => {
    if (dataChanged) {
      setModalConfirm({
        isOpen: true,
        type: "SAVE",
        mode: "BASIC",
        title: i18n("label.ignore_changes.title"),
        text: i18n("label.ignore_changes.text"),
        buttonText: i18n("label.ignore_changes.button"),
        onConfirm: () => {
          setTab(index);
          setModalConfirm(null);
        },
        onClose: () => {
          setModalConfirm(null);
        },
      });
    } else {
      setTab(index);
    }
  };

  return (
    <>
      <div className="flex flex-col flex-1 h-full overflow-hidden rounded-t-md">
        <div className="bg-white w-full border-b min-h-12">
          {postStatus !== NOTFOUND && (
            <HorizontalScroll>
              {post?.template?.sections?.map((item, key) => {
                let styles = {
                  button: "h-12 px-6 py-1 main-transition text-sm whitespace-nowrap border-b-2 bg-white flex items-center",
                  ball: "h-2 w-2 inline-block rounded-full mr-2",
                };

                if (key === tab) styles.button += " border-blue-900 text-slate-800";
                else styles.button += " border-white text-slate-400 hover:text-slate-700";

                const enabled = content?.sections?.find((e) => e.section.id === item.id)?.enabled;
                if (enabled) styles.ball += " bg-green-600";
                else styles.ball += " bg-red-600";

                return (
                  <button key={key} className={styles.button} onClick={() => changeTab(key)}>
                    <div className={styles.ball} />
                    {item.section.title}
                  </button>
                );
              })}
            </HorizontalScroll>
          )}
        </div>
        <div className="bg-white flex-1 flex flex-col w-full rounded-b-md overflow-hidden">
          {/* EDITOR */}
          <div className="flex-1 overflow-auto pb-10">
            {postStatus !== NOTFOUND ? (
              <Content tabs tab={tab}>
                {post?.template?.sections?.map(({ section, order }, key) => {
                  const last = content?.sections?.find((e) => e.section.order === order);
                  return (
                    <div key={key}>
                      {section?.structure?.length ? (
                        <div>
                          <div className="flex justify-between items-center mb-8">
                            <LabelSectionStatus id={last?.id} content={!last ? content?.id : undefined} section={!last ? section?.id : undefined} enabled={last?.enabled} />
                            <div className="flex">
                              <LabelRevisionStatus message={`${last?.data?.author?.name} - ${dayjs(last?.data?.createdAt).format("DD.MM.YY HH:mm")}`} status={last?.data?.status} />
                              {section?.thumbnail?.path && (
                                <Tooltip message={i18n("label.preview")} placement="top">
                                  <kbd
                                    className="px-3 py-1.5 whitespace-nowrap cursor-pointer text-xs font-semibold text-slate-800 bg-slate-200 rounded-xl inline-flex items-center ml-2"
                                    onClick={() => showThumbnail(section.thumbnail.path)}
                                  >
                                    <PhotoIcon className="h-4" />
                                  </kbd>
                                </Tooltip>
                              )}
                              <PopupBuilder id={section.id} />
                            </div>
                          </div>
                          {key === tab && <Form structure={buildStructure(tab)} onChange={setValue} value={value} disabled={isLoading} />}
                        </div>
                      ) : (
                        <p>{i18n("alert.no_input_build")}</p>
                      )}
                    </div>
                  );
                })}
              </Content>
            ) : (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <IconForbidden width="w-32" height="h-32" className="mb-10 text-red-700" />
                <h3 className="mb-2 text-4xl font-semibold text-black uppercase">{i18n("label.page_not_found")}</h3>
                <p className="mb-10 text-md font-normal max-w-sm text-center text-slate-600">{i18n("label.page_not_found_description")}</p>
                <Link to={`/project/${project}/posts/${key}`}>
                  <button
                    type="button"
                    className="flex mr-1.5 items-center relative justify-center rounded-md border border-blue-800 bg-transparent px-4 py-2 text-sm font-medium text-blue-800 shadow-sm hover:bg-blue-700 hover:border-blue-700 hover:text-white focus:outline-none "
                  >
                    {i18n("label.return_dashboard")}
                  </button>
                </Link>
              </div>
            )}
          </div>

          {/* BUTTONS */}
          {postStatus !== NOTFOUND && (
            <div className="border-t py-4 px-6 flex justify-between">
              <Dropdown
                position="top-left"
                options={[
                  { title: i18n("label.revisions"), onClick: onShowRevisions, icon: "revision" },
                  { title: i18n("button.clean_changes"), onClick: cleanChanges, icon: "rollback", delete: true },
                ]}
              />
              <div className="flex">
                <button
                  type="button"
                  disabled={!dataChanged || isLoading}
                  onClick={() => onSubmitNewContent(false)}
                  className="flex mr-1.5 items-center relative justify-center rounded-md border border-blue-600 bg-transparent px-4 py-2 text-sm font-medium text-blue-600 shadow-sm hover:border-blue-200 hover:text-blue-200 focus:outline-none  disabled:text-blue-200 disabled:border-blue-200 disabled:hover:bg-white disabled:hover:border-blue-200 disabled:cursor-not-allowed"
                >
                  {i18n("button.save_as_draft")}
                </button>
                <Permission project dev min="EDITOR">
                  <button
                    type="button"
                    disabled={!dataChanged || isLoading}
                    onClick={() => onSubmitNewContent(true)}
                    className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none  disabled:bg-blue-200 disabled:cursor-not-allowed"
                  >
                    {i18n("button.publish")}
                  </button>
                </Permission>
              </div>
            </div>
          )}
        </div>
      </div>

      <ModalConfirm {...modalConfirm} />
      <ModalPreview {...modalPreview} />
      <ModalRevision {...modalRevision} />
    </>
  );
}
