import { Menu, Transition } from "@headlessui/react";
import Icon from "app/components/atoms/icons/icon";
import { Fragment } from "react";

export default function Dropdown(props) {
  const { text, options, disabled, icon = "ellipsis", position = "bottom-right" } = props;

  let styles = {
    main: "absolute z-20 w-48 min-w-48 bg-white divide-y rounded-md shadow-xl divide-primary-50 ring-1 ring-text ring-opacity-5 focus:outline-none",
    wrapper: "flex flex-col gap-1 px-1 py-1",
  };

  if (position === "bottom-right") {
    styles.main += " origin-top-right mt-2 right-0";
  } else if (position === "bottom-left") {
    styles.main += " origin-top-left mt-2 left-0";
  } else if (position === "bottom-center") {
    styles.main += " origin-center mt-2 right-1/2 translate-x-1/2 top-full";
  } else if (position === "top-left") {
    styles.main += " origin-bottom-left mb-2 left-0 bottom-full";
  } else if (position === "top-right") {
    styles.main += " origin-bottom-right mb-2 right-0 bottom-full";
  } else if (position === "top-center") {
    styles.main += " origin-center mb-2 right-1/2 translate-x-1/2 bottom-full";
  }

  return (
    <Menu as="div" className="relative inline-block text-left outer-dropdown">
      <div>
        {text ? (
          <Menu.Button
            disabled={disabled}
            className="flex items-center relative justify-center rounded-md border border-transparent btn-sm-primary px-4 py-2 gap-4 text-sm font-medium text-white shadow-sm focus:outline-none  disabled:bg-blue-200 disabled:opacity-40 disabled:cursor-not-allowed"
          >
            {icon && <Icon name={icon} />}
            <span>{text}</span>
          </Menu.Button>
        ) : (
          <Menu.Button disabled={disabled} className="btn-icon disabled:opacity-20 disabled:cursor-not-allowed">
            <Icon name={icon} />
          </Menu.Button>
        )}
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={styles.main}>
          <div className={styles.wrapper}>
            {options
              .filter((e) => !e.disabled)
              .map((item, index) => (
                <Menu.Item key={String(index)}>
                  {({ active }) => (
                    <button type="button" className={`menu-item ${active ? `${item.delete ? "bg-red-500 " : "bg-primary-500 "} text-white` : "text-text"}`} onClick={() => item.onClick()}>
                      {item.icon && <Icon name={item.icon} />}
                      {item.title}
                    </button>
                  )}
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
