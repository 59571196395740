import { i18n } from "app/i18n";
import { inputsApi } from "app/stores/inputs";
import { setUnsavedChanges, useUpdateProjectMutation } from "app/stores/project";
import { cleanStructure } from "app/utils/content";
import { showServerError, showServerSuccess } from "app/utils/server";
import { FULFILLED } from "app/utils/status";
import { validation } from "app/utils/validators";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "../../form";
import { components } from "./constants";

export default function TabPages() {
  // PARAMS
  const dispatch = useDispatch();

  // STORE
  const { posts } = useSelector((store) => store.inputs);
  const { info, language, project, unsavedChanges } = useSelector((store) => store.project);

  // STATE
  const [value, setValue] = useState(null);
  const [changed, setChanged] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [structure, setStructure] = useState(components);

  // REQUEST
  const [updateProject, { isLoading }] = useUpdateProjectMutation();

  // VARIABLES
  const isNewsletterActive = project?.modules?.newsletter || false;

  useEffect(() => {
    if (project) fillForm();
  }, [project]);

  useEffect(() => {
    if (changed !== unsavedChanges) dispatch(setUnsavedChanges(changed));
    return () => dispatch(setUnsavedChanges(false));
  }, [changed]);

  const buildStructure = () => {
    if (info?.postTypes?.length) {
      let id = null;
      // GET MAIN PAGES ONLY
      const index = info.postTypes.findIndex((e) => e.key === "pages");
      if (index !== -1) id = info.postTypes[index].id;

      if (id) {
        const temp = cleanStructure(components);

        // ADD TO HOMEPAGE, PRIVACY AND NEWSLETER
        for (let i = 0; i < temp.length; i++) {
          if (temp[index].type === "Select" && temp[index].loadmore) temp[index].posttype = id;
        }

        // ALERT FOR NO HOMEPAGE
        if (!project?.settings?.frontend?.home) {
          const indexHome = temp.findIndex((e) => e.key === "home");
          if (indexHome !== -1) {
            temp[indexHome].error = true;
            temp[indexHome].errorMessage = i18n("field.request_for_project");
          }
        }

        // REMOVE NEWSLETTER FIELD IF MODULE IS NOT ACITVE
        if (!isNewsletterActive) {
          const indexNewsletter = temp.findIndex((e) => e.key === "newsletter");
          if (indexNewsletter !== -1) temp.splice(indexNewsletter, 1);
        }

        setStructure([...temp]);
      }
    }
  };

  const fillForm = async () => {
    let listOfPosts = posts || [];

    const getPage = (id) => {
      if (!id) return undefined;
      return listOfPosts.find((e) => e.value === id);
    };

    const tempValue = {
      ...project?.settings?.frontend,
      ...project?.settings?.metatags,
    };

    const idHome = tempValue?.home;
    const idPrivacy = tempValue?.privacy;
    const idNewsletter = tempValue?.newsletter;

    if (idHome || idPrivacy || idNewsletter) {
      const home = getPage(idHome);
      const privacy = getPage(idPrivacy);
      const newsletter = getPage(idNewsletter);

      const where = { language: { id: language.id }, OR: [] };
      if (!home && idHome) where.OR.push({ post: { id: idHome } });
      if (!privacy && idPrivacy) where.OR.push({ post: { id: idPrivacy } });
      if (!newsletter && idNewsletter) where.OR.push({ post: { id: idNewsletter } });

      if (!home || !privacy || !newsletter) {
        if (where.OR.length) {
          const { data, status } = await dispatch(inputsApi.endpoints.getInputPosts.initiate({ where }));
          if (status === FULFILLED && data?.length) {
            listOfPosts = [...listOfPosts, ...data.map((e) => ({ value: e.id, label: e.title, type: e.posttype }))];

            // SET VALUE
            tempValue.home = getPage(idHome);
            tempValue.privacy = getPage(idPrivacy);
            tempValue.newsletter = getPage(idNewsletter);
          }
        }
      } else {
        tempValue = { ...tempValue, home, privacy, newsletter };
      }
    }

    setValue({ ...tempValue });
    setDefaultValue({ ...tempValue });
    buildStructure();
  };

  const submitForm = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);

      if (validation.isValid) {
        let frontend = {};
        let metatags = {};

        // HOME
        if (value?.home?.value) frontend.home = value?.home?.value;
        // PRIVACY
        if (value?.privacy?.value) frontend.privacy = value?.privacy?.value;
        // NEWSLETTER
        if (value?.newsletter?.value) frontend.newsletter = value?.newsletter?.value;
        // TAB TITLE
        if (value?.tabTitle) frontend.tabTitle = value?.tabTitle;
        // METATAGS TITLE
        if (value?.title) metatags.title = value?.title;
        // METATAGS DESCRIPTION
        if (value?.description) metatags.description = value?.description;
        // METATAGS IMAGE
        if (value?.image) metatags.image = value?.image;

        updateProject({ id: project.id, settings: { frontend, metatags } }).then((res) => {
          if (res?.data) showServerSuccess(i18n("toast.success_update"));
          else showServerError(res);
        });
      }
    });
  };

  const loading = isLoading;

  return (
    <>
      <div className="divide-y-slate-200 space-y-8 divide-y">
        <div>
          <h1 className="text-3xl font-bold tracking-tight text-slate-900">Main Pages</h1>
          {/* HOMEPAGE, PRIVACY & NEWSLETTER LINKS */}
          <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
            <div className="sm:col-span-6">
              <h2 className="text-xl font-medium text-slate-900">Global Pages</h2>
              <p className="mt-1 text-sm text-slate-500">Information to identify the project</p>
              <div className="mt-6">
                <Form defaultValue={defaultValue} changed={setChanged} structure={structure.filter((e) => e.tab === "pages")} value={value} onChange={setValue} disabled={loading} />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-8">
          {/* METATAGS */}
          <h1 className="text-3xl font-bold tracking-tight text-slate-900">SEO</h1>
          <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
            <div className="sm:col-span-6">
              <h2 className="text-xl font-medium text-slate-900">Search Engine Optimization</h2>
              <p className="mt-1 text-sm text-slate-500">Customize your page metatags for default</p>
              <div className="mt-6">
                <Form defaultValue={defaultValue} changed={setChanged} structure={structure.filter((e) => e.tab === "seo")} value={value} onChange={setValue} disabled={loading} />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-8">
          {/* TAB TITLE */}
          <h1 className="text-3xl font-bold tracking-tight text-slate-900">Page Configs</h1>
          <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
            <div className="sm:col-span-6">
              <h2 className="text-xl font-medium text-slate-900">Tab title</h2>
              <p className="mt-1 text-sm text-slate-500">Customize your tab title in browser</p>
              <div className="mt-6">
                <Form defaultValue={defaultValue} changed={setChanged} structure={structure.filter((e) => e.tab === "path")} value={value} onChange={setValue} disabled={loading} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-end gap-x-3 pt-8">
            <button
              type="button"
              disabled={loading}
              onClick={fillForm}
              className="flex items-center relative justify-center rounded-md border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-slate-900 shadow-sm hover:bg-slate-50 focus:outline-none"
            >
              {i18n("label.cancel")}
            </button>
            <button
              type="submit"
              onClick={submitForm}
              disabled={!changed || loading}
              className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none  disabled:bg-blue-200 disabled:cursor-not-allowed"
            >
              {i18n("label.save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
