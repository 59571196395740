import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Icon from "app/components/atoms/icons/icon";
import { i18n } from "app/i18n";
import { isObject } from "app/utils/validators/dataValidator";
import { Fragment, useState } from "react";

export default function SelectSearch(props) {
  const { value, label, options, onChange, createButton, disabled, clearable, multiple, error } = props;

  const styles = {
    button:
      "relative w-full cursor-default flex items-center overflow-hidden text-sm rounded-lg bg-gray-50 text-left focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300",
    input: "w-full border-none py-2.5 pl-2.5 pr-10 leading-5 bg-gray-50 focus:ring-0 ",
  };
  const [query, setQuery] = useState("");

  const getSearchText = (ev) => {
    let search = "";
    const text = getLabel(value) || null;
    if (text) search = ev.replace(text, "");
    else search = ev;
    setQuery(search);
  };

  const getLabel = (_val) => {
    if (!_val) return "";
    if (multiple) return `${_val.map((item) => item.label).join(", ")} `;
    else if (!_val.label) return "";
    return `${_val.label} `;
  };

  const clearButton = () => {
    const isClearable = clearable;
    if (!isClearable) return null;
    if (!value || !isObject(value, true)) return null;

    return (
      <span
        type="button"
        disabled={disabled}
        className="cursor-pointer absolute inset-y-0 right-7 flex items-center pr-2"
        onClick={(ev) => {
          ev.stopPropagation();
          onChange(undefined);
        }}
      >
        <Icon name="close" className="text-gray-400" />
      </span>
    );
  };

  let filteredOptions = options;
  if (query !== "") filteredOptions = options.filter((option) => option.label.toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, "")));

  if (error) styles.button += " border border-red-600 focus:border-red-600 focus-visible:border-red-600";
  else styles.button += " border border-gray-300 focus:border-blue-500 focus-visible:border-blue-500";

  if (!getLabel(value)) styles.input += " text-xs text-gray-400";
  else styles.input += " text-sm text-gray-900";

  return (
    <Combobox value={value} onChange={onChange} multiple={multiple} nullable={clearable} disabled={disabled}>
      <div className="relative">
        <div className={styles.button}>
          <Combobox.Input placeholder={label} displayValue={(value) => getLabel(value)} onChange={(event) => getSearchText(event.target.value)} className={styles.input} />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            {clearButton()}
            <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
          </Combobox.Button>
        </div>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery("")}>
          <Combobox.Options className="absolute mt-1 pb-6 w-full z-10 text-base ring-0 focus:outline-none sm:text-sm bg-transparent">
            <div className="bg-white rounded-md py-1 shadow-lg">
              <div className="max-h-40 overflow-auto">
                {!filteredOptions?.length && query !== "" ? (
                  <div className="relative cursor-pointer select-none py-2 px-4 text-gray-700">{i18n("label.nothing_found")}</div>
                ) : (
                  filteredOptions.map((option) => (
                    <Combobox.Option
                      key={option.id}
                      value={option}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-2 pl-10 pr-4 transition-all ease-in-out duration-300 ${active ? "bg-blue-100 text-blue-900" : "text-gray-900"}`
                      }
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{option.label}</span>
                          {selected ? (
                            <span className={`absolute inset-y-0 left-0 flex items-center pl-3 transition-all ease-in-out duration-300  ${active ? "text-white" : "text-blue-600"}`}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </div>
              {createButton}
            </div>
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
