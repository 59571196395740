import { i18n } from "app/i18n";
import { isArray } from "../validators/dataValidator";
import dayjs from "dayjs";

const orientationLabels = [
  { value: 0, label: i18n("label.landscape") },
  { value: 1, label: i18n("label.portrait") },
  { value: 2, label: i18n("label.square") },
];

export const cleanStructure = (structure) => {
  if (!structure) return null;
  if (!isArray(structure)) return null;
  if (!structure.length) return null;

  let temp = [...structure];
  for (let i = 0; i < temp.length; i++) {
    if (temp[i].error) temp[i].error = null;
    if (temp[i].notify) temp[i].notify = false;
    if (temp[i].errorList) temp[i].errorList = null;
    if (temp[i].args?.id) temp[i].args.id = undefined;
    if (temp[i].disabled) temp[i].disabled = undefined;
    if (temp[i].children) temp[i].children = cleanStructure(temp[i].children);
    if (temp[i].components) temp[i].components = cleanStructure(temp[i].components);
    if (temp[i].rules && temp[i].rules.length && temp[i].rules.filter((el) => el.temporary).length) temp[i].rules = temp[i].rules.filter((el) => !el.temporary);
  }
  return temp.filter((el) => !el.temporary);
};

export function formatSize(size) {
  if (size < 1024) {
    return size + " bits";
  } else if (size < 1024 ** 2) {
    let fileSizeKB = size / 1024;
    return fileSizeKB.toFixed(2) + " KB";
  } else if (size < 1024 ** 3) {
    let fileSizeMB = size / 1024 ** 2;
    return fileSizeMB.toFixed(2) + " MB";
  } else {
    let fileSizeGB = size / 1024 ** 3;
    return fileSizeGB.toFixed(2) + " GB";
  }
}

export function cleanHTML(htmlString) {
  if (!htmlString) return null;

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc.documentElement.textContent;
}

export function formatDifference(date) {
  const now = dayjs();
  const diff = now.diff(date); // Difference in milliseconds

  const days = Math.floor(diff / (24 * 60 * 60 * 1000));
  const hours = Math.floor((diff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  const minutes = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000));
  const months = Math.floor(diff / (30 * 24 * 60 * 60 * 1000));

  let result = "";

  if (months > 3) result = "+3 months";
  else if (months > 0) result = `${months} month${months === 1 ? "" : "s"}`;
  else if (days > 0) result += `${days} day${days === 1 ? "" : "s"} `;
  else if (hours > 0) result += `${hours} hour${hours === 1 ? "" : "s"} `;
  else if (minutes > 0) result += `${minutes} minute${minutes === 1 ? "" : "s"} `;

  if (result === "") result = "just now";
  else result += " ago";

  return result;
}

export function sumSizes(data) {
  let total = 0;
  if (data && data.length) {
    data.forEach((item) => {
      total = total + item.size;
    });
  }
  return total;
}

export const fileTypes = [
  { value: "IMAGE", label: i18n("label.image") },
  { value: "VIDEO", label: i18n("label.video") },
  { value: "FILE", label: i18n("label.file") },
  { value: "AVATAR", label: i18n("label.avatar") },
  { value: "EXTERNAL_VIDEO", label: i18n("label.external_video") },
  { value: "EXTERNAL_FILE", label: i18n("label.external_file") },
  { value: "EXTERNAL_IMAGE", label: i18n("label.external_image") },
];

export function showOrientation(value) {
  const orientation = orientationLabels.find((e) => e.value === value);
  if (orientation) return orientation.label;
  else return "";
}

export function showFileType(value) {
  const fileType = fileTypes.find((e) => e.value === value);
  if (fileType) return fileType.label;
  else return "";
}

export function removeBreaklines(string) {
  return string.replace(/(\r\n|\n|\r|<br>)/gm, "");
}

function isVideoLink(url) {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=([a-zA-Z0-9_-]{11})|embed\/([a-zA-Z0-9_-]{11}))|youtu\.be\/([a-zA-Z0-9_-]{11}))/i;
  const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/(channels\/\w+\/)?(\d+))/i;
  const dailymotionRegex = /^(https?:\/\/)?(www\.)?(dailymotion\.com\/video\/(\w+))/i;
  const wistiaRegex = /^(https?:\/\/)?(www\.)?(wistia\.com\/(medias\/)?(\w+))/i;
  const twitchRegex = /^(https?:\/\/)?(www\.)?(twitch\.tv\/videos\/(\d+))/i;
  const matchYouTube = url.match(youtubeRegex);
  if (matchYouTube) return "youtube";
  const matchVimeo = url.match(vimeoRegex);
  if (matchVimeo) return "vimeo";
  const matchDailymotion = url.match(dailymotionRegex);
  if (matchDailymotion) return "dailymotion";
  const matchWistia = url.match(wistiaRegex);
  if (matchWistia) return "wistia";
  const matchTwitch = url.match(twitchRegex);
  if (matchTwitch) return "twitch";
  return false;
}

export function getFileExtension(url) {
  // CLEAN URL
  let path = undefined;
  if (isValidURL(url)) {
    path = new URL(url);
    path.search = "";
    path = path.toString();
  } else {
    path = url;
  }

  // GET THE EXTENSION
  const extension = path.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();

  // COMPARE EXTENSIONS
  const imageExtensions = ["png", "jpg", "jpeg", "gif", "webp", "svg"];
  const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv", "mkv"];
  const documentExtensions = ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "txt", "csv"];

  // GET TYPE FROM EXTENSION
  if (!imageExtensions.includes(extension) && !videoExtensions.includes(extension) && !documentExtensions.includes(extension)) {
    return isVideoLink(path) || "html";
  }

  // RETURN
  return extension;
}

export function getFilename(url) {
  if (!url) return null;
  // Remove query parameters and fragments from the URL
  const cleanUrl = url.split(/[?#]/)[0];
  // Extract the filename from the cleaned URL
  const fullFilename = cleanUrl.substring(cleanUrl.lastIndexOf("/") + 1);
  // Remove svalla code
  const originalName = fullFilename.substring(fullFilename.indexOf("-") + 1);
  // Remove extension
  const filename = originalName.substring(originalName.lastIndexOf("."), -1);
  return filename;
}

export function isImageExtension(string) {
  const imageExtensions = ["jpg", "jpeg", "svg", "png", "gif", "bmp", "webp"];
  return imageExtensions.includes(string);
}

export function isVideoExtension(string) {
  const videoExtensions = ["mp4", "avi", "mkv", "mov", "wmv", "flv", "webm"];
  return videoExtensions.includes(string);
}

export function isValidURL(url) {
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return urlPattern.test(url);
}

export const getRandomString = (length = 12, specials = true) => {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  if (specials) characters += "#!@$%&()?+*-.€[]";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getSource = (path) => {
  if (!path) return null;
  if (isValidURL(path)) return path;
  return `${process.env.REACT_APP_MULTIMEDIA_HOST}/${path}`;
};

export const getTranslation = (translations, language) => {
  if (!translations?.length) return null;
  if (!language) return translations[0].value;

  const translation = translations.find((e) => e.language.slug === language);
  if (translation) return translation.value;
  else return translations[0].value;
};

export function canParseJSON(jsonString) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
}
