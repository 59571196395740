import Text from "app/components/atoms/inputs/text";
import { i18n } from "app/i18n";
import { objectsAreEqual } from "app/utils/validators/dataValidator";
import { adjustTextareaHeight } from "app/utils/visual";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function EmailTextLayout(props) {
  const { value, config, fields, hiddenFields, placeholders, onChange, defaultValue, changed, disabled } = props;

  const { project } = useSelector((state) => state.project);
  const hide = hiddenFields;

  const onChangeInput = (name, val) => {
    let temp = value ? { ...value } : {};
    temp[name] = val;
    onChange({ ...temp });
  };

  useEffect(() => {
    if (value && defaultValue && changed) {
      if (!objectsAreEqual(defaultValue, value)) changed(true);
      else changed(false);
    }
  }, [value, defaultValue, changed]);

  return (
    <>
      <div className="pb-8">
        <Text title="Subject" value={value?.subject} placeholder={placeholders?.subject || "Enter email subject"} onChange={(val) => onChangeInput("subject", val)} />
      </div>
      <div className="relative py-10 px-4" style={{ backgroundColor: config?.backgroundColor || "#F1F5F9" }}>
        <div>
          {/* IMAGE */}
          {config?.image?.href && (
            <div className="mx-auto max-w-xl">
              <div className="w-full">
                <img src={config.image.href} />
              </div>
            </div>
          )}
          {/* CONTENT */}
          <div className="mx-auto max-w-xl pt-4 pb-4 px-4" style={{ backgroundColor: config?.boxColor || "#FFFFFF" }}>
            <div className="p-10">
              {/* TITLE */}
              {!hide?.title && (
                <div className="text-left pb-4">
                  <input
                    type="text"
                    disabled={disabled}
                    value={value?.title}
                    placeholder={placeholders?.title || "Email Title"}
                    onChange={(ev) => onChangeInput("title", ev.target.value)}
                    className="w-full text-left font-semibold text-3xl border-none p-0 text-[#222] placeholder:text-[#CCC] hover:bg-gray-50"
                  />
                </div>
              )}
              {/* TEXT */}
              {!hide?.text && (
                <div className="pt-4 pb-2">
                  <textarea
                    value={value?.text}
                    disabled={disabled}
                    placeholder={
                      placeholders?.text ||
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                    }
                    onChange={(ev) => {
                      adjustTextareaHeight(ev.target);
                      onChangeInput("text", ev.target.value);
                    }}
                    className="textarea-auto w-full border-none p-0 text-sm text-[#444] placeholder:text-[#CCC] hover:bg-gray-50"
                  />
                </div>
              )}
              {/* TABLE */}
              {!hide?.table && (
                <div className="pt-4 pb-6 px-12">
                  {!!fields?.length ? (
                    <>
                      <table className="w-full border">
                        <tbody>
                          {fields.map((e) => (
                            <tr>
                              <td className="border w-1/2 text-sm py-0.5 px-2 font-poppins">{e}</td>
                              <td className="border w-1/2 text-sm py-0.5 px-2"></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <>
                      <p className="text-sm text-center">{i18n("label.first_set_fields")}</p>
                    </>
                  )}
                </div>
              )}
              {/* END TEXT */}
              {!hide?.endtext && (
                <div className="pt-2 pb-5">
                  <textarea
                    value={value?.endtext}
                    disabled={disabled}
                    placeholder={placeholders?.endtext || "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}
                    onChange={(ev) => {
                      adjustTextareaHeight(ev.target);
                      onChangeInput("endtext", ev.target.value);
                    }}
                    className="textarea-auto  w-full border-none p-0 text-sm text-[#444] placeholder:text-[#CCC] hover:bg-gray-50"
                  />
                </div>
              )}
              {/* TEXT FOR BUTTON */}
              {!hide?.button && (
                <div className="text-center pt-5">
                  <button className="px-8 py-4" style={{ backgroundColor: config?.buttonColor || "#000", color: config?.buttonTextColor || "#FFF" }}>
                    <input
                      type="text"
                      disabled={disabled}
                      value={value?.buttonTxt}
                      placeholder={placeholders?.buttonTxt || "Visit Website"}
                      onChange={(ev) => onChangeInput("buttonTxt", ev.target.value)}
                      className="text-center border-none p-0 bg-transparent text-[#FFF] focus:bg-transparent placeholder:text-[#CCC] hover:bg-transparent"
                    />
                  </button>
                </div>
              )}
            </div>
            {/* LABEL */}
            {!hide?.label && (
              <div className="text-center">
                <input
                  type="text"
                  disabled={disabled}
                  value={value?.label}
                  placeholder={placeholders?.label || project?.name || "Svalla CMS"}
                  onChange={(ev) => onChangeInput("label", ev.target.value)}
                  className="text-center text-xs w-full border-none p-0 bg-transparent focus:bg-transparent text-[#444] placeholder:text-[#CCC] hover:bg-transparent"
                />
              </div>
            )}
          </div>
          {/* COPY */}
          {!hide?.copyright && (
            <div className="mx-auto max-w-lg pt-4 pb-6">
              <div className="text-center">
                <textarea
                  disabled={disabled}
                  value={value?.copyright}
                  placeholder={placeholders?.copyright || "© Svalla 2024 - All rights reserved"}
                  onChange={(ev) => {
                    adjustTextareaHeight(ev.target);
                    onChangeInput("copyright", ev.target.value);
                  }}
                  style={{ color: config?.copyrightColor || "#CCC" }}
                  className="textarea-auto w-full text-center border-none p-0 text-xs bg-transparent placeholder:text-[#CCC] hover:bg-transparent"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
