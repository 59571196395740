const IDLE = "idle";
const LOADING = "loading";
const SUCCESS = "success";
const ERROR = "error";
const NOTHING = "nothing";
const NOTFOUND = "notfound";
const DONE = "done";
const FAILED = "failed";
const NOT_APPROVED = "notapproved";
const FULFILLED = "fulfilled";

export { IDLE, LOADING, SUCCESS, ERROR, NOTHING, NOTFOUND, DONE, FAILED, NOT_APPROVED, FULFILLED };
