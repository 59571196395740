import ModalMedia from "app/components/molecules/modals/modalMedia";
import { i18n } from "app/i18n";
import update from "immutability-helper";
import { memo, useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import Icon from "../../icons/icon";
import Text from "../text";
import Item from "./item";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { getSource } from "app/utils/content";

const Container = memo(function (props) {
  const { value, title, description, disabled, error, errorMessage, isRequired, onChange } = props;

  const styles = {
    title: "block text-sm font-medium mb-1",
    description: "block text-xs font-light text-slate-400 -mt-1.5 mb-1",
    box: "h-[380px] min-h-[380px] w-full relative bg-gray-50 border rounded-lg overflow-hidden resize-y flex",
  };

  const [modal, setModal] = useState(null);
  const [selected, setSelected] = useState(null);

  // GET INFO & POSITION OF ELEMENT IN ARRAY
  const findItem = useCallback(
    (id) => {
      const card = value.filter((c) => `${c?.id}` === id)[0];
      return { card, index: value.indexOf(card) };
    },
    [value]
  );

  // WHEN IMAGE IS DRAGGED
  const onMoveItem = useCallback(
    (id, atIndex) => {
      const { card, index } = findItem(id);

      const newPost = update(value, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
      });

      onChange(
        update(value, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        })
      );
    },
    [findItem, value, onChange]
  );

  // FUNCTION FOR DROPAREA
  const [, drop] = useDrop(() => ({ accept: "Item" }));

  // ADD A NEW FILE TO LIST
  const onAddFile = () => {
    setModal({
      isOpen: true,
      multi: true,
      onSubmit: (files) => {
        let temp = value ? value : [];
        temp = [...temp, ...files];
        onChange(temp);
        setModal(false);
      },
      onClose: () => setModal(false),
    });
  };

  // CLEAN THE LIST
  const onRemoveAll = () => {
    onChange(null);
    onSelectMedia(null);
  };

  // WHEN IMAGE CLICKED FOR DETAILS
  const onSelectMedia = (item) => {
    setSelected(item);
  };

  // WHEN DETAILS WAS CHANGED
  const onChangeItemInfo = (alt, caption) => {
    if (!selected) return null;
    setSelected({
      ...selected,
      alt: typeof alt !== "undefined" ? alt : selected.alt,
      caption: typeof caption !== "undefined" ? caption : selected.caption,
    });
  };

  // SAVE THE CHANGES IN FILE DETAILS
  const onSaveMedia = () => {
    if (!selected) return null;
    let temp = [...value];
    const index = temp.findIndex((item) => item.id === selected.id);
    if (index !== -1) temp[index] = { ...temp[index], ...selected };
    else return null;
    onChange([...temp]);
    onSelectMedia(null);
  };

  // REMOVE IMAGE FROM LIST
  const onRemoveMedia = (item) => {
    const temp = [...value];
    const index = temp.findIndex((e, i) => e.id === item.id && i === item.tag);
    if (index !== -1) temp.splice(index, 1);
    else return null;
    onChange(temp);
    onSelectMedia(null);
  };

  if (error) {
    styles.title += " text-red-600";
    styles.box += " border-red-600";
  } else {
    styles.title += " text-slate-700";
    styles.box += " border-gray-300";
  }

  return (
    <div className="block mb-3">
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <div className={styles.box}>
        {!!value?.length && (
          // LIST OF IMAGES
          <div className="p-4 h-full w-full overflow-auto">
            <div ref={drop} className={`grid grid-cols-3 gap-x-3 gap-y-4 sm:gap-x-4 md:grid-cols-3 xl:grid-cols-5`}>
              {value.map((item, key) => (
                <Item
                  key={`${item?.id}-${key}`}
                  tag={key}
                  {...item}
                  active={selected && key === selected.tag}
                  onMoveItem={onMoveItem}
                  findItem={findItem}
                  onSelect={onSelectMedia}
                  onRemove={onRemoveMedia}
                />
              ))}
            </div>
          </div>
        )}
        <div
          className={`absolute inset-0 bg-gray-900/20 overflow-auto py-4 px-2 transition-opacity duration-500 ease-in-out flex items-center justify-center ${
            selected ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0"
          }`}
        >
          <div className="bg-white rounded-lg max-w-lg w-full mx-auto">
            {selected && (
              // FILE DETAIL
              <div className="p-4">
                <div className="flex justify-between items-center border-b pb-1 mb-2">
                  <h3 className="font-bold text-lg uppercase text-center">Edit File</h3>
                  <button type="button" className="btn-icon" onClick={() => onSelectMedia(null)}>
                    <Icon name="close" />
                  </button>
                </div>
                <div className="flex flex-row gap-4">
                  <div className="block w-40 m-auto relative overflow-hidden rounded-lg aspect-h-7 aspect-w-10 group">
                    <img className="object-cover" src={getSource(selected.path)} alt={selected.id} />
                  </div>
                  <div className="flex-1">
                    <Text title="Alternative Text" value={selected.alt} onChange={(val) => onChangeItemInfo(val)} />
                    <Text title="Caption" value={selected.caption} onChange={(val) => onChangeItemInfo(undefined, val)} />
                  </div>
                </div>
                <div className="flex flex-col gap-2 mt-2">
                  <button
                    type="button"
                    className="flex items-center relative justify-center rounded-md border border-transparent bg-primary-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none  disabled:bg-blue-200 disabled:cursor-not-allowed"
                    onClick={onSaveMedia}
                  >
                    {i18n("button.save")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-start justify-between">
        <div>{error && <p className="mt-3 text-xs text-red-600">{errorMessage}</p>}</div>
        <div className="flex items-start justify-between gap-2">
          {!disabled && (
            <>
              <button type="button" onClick={onRemoveAll} className="btn-sm-danger-outline mt-3">
                <Icon name="clean" />
                {i18n("button.clear")}
              </button>
              <button type="button" onClick={onAddFile} className="btn-sm-primary mt-3">
                <Icon name="plus" />
                {i18n("button.add")}
              </button>
            </>
          )}
        </div>
      </div>

      <ModalMedia {...modal} multi={true} />
    </div>
  );
});
export default Container;
