import Dropdown from "app/components/molecules/dropdown";
import LabelRevisionStatus from "app/components/molecules/labels/labelRevisionStatus";
import Permission from "app/components/molecules/permission";
import { i18n } from "app/i18n";
import dayjs from "dayjs";

export default function ItemRevision(props) {
  const { id, createdAt, author, status, onEdit, onDelete } = props;
  return (
    <tr key={id}>
      <td className="whitespace-nowrap">{dayjs(createdAt).format("DD.MM.YYYY HH:mm")}</td>
      <td className="whitespace-nowrap">{author?.name}</td>
      <td>
        <LabelRevisionStatus status={status} />
      </td>
      <td>
        <Permission min="ADMINISTRATOR">
          <Dropdown
            icon="ellipsis"
            options={[
              { title: i18n("button.edit"), onClick: onEdit, icon: "edit" },
              { title: i18n("button.delete"), onClick: onDelete, icon: "delete", delete: true },
            ]}
          />
        </Permission>
      </td>
    </tr>
  );
}
