import { useEffect, useState } from "react";
import validator from "validator";
import { useSelector } from "react-redux";

// Atoms
import { Struture, Header, Content, Footer } from "../../../atoms/modal";

// Organisms
import Form from "app/components/organisms/form";

// Helpers
import { validation } from "app/utils/validators";
import { components, componentsDuplication, componentsPostDuplication } from "./constants";
import { cleanStructure } from "app/utils/content";
import { i18n } from "app/i18n";
import { getFlag } from "app/utils/languages";
import customValidator from "app/utils/validators/customValidator";

export default function ModalPost(props) {
  const {
    isOpen = false,
    post,
    type,
    language,
    templates,
    duplicate,
    duplicatePost,
    availableTranslations,
    modalTitle,
    buttonText = i18n("button.create_new"),
    isLoading,
    onSubmit,
    onClose = () => {},
  } = props;

  // STORE
  const { project, languages } = useSelector((store) => store.project);

  // STATE
  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(components);
  const [structureDuplicate, setStructureDuplicate] = useState(componentsDuplication);

  useEffect(() => {
    if (!isOpen) {
      setValue(null);
      setStructure(components);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && type && project && language) {
      let tempStructure = cleanStructure(components);

      // CONFIGURE INPUT SLUG WITH ARGUMENTS
      const index = tempStructure.findIndex((item) => item.key === "slug");
      if (index !== -1) {
        tempStructure[index].rules[1].args.type = type;
        tempStructure[index].rules[1].args.project = project.id;
        tempStructure[index].rules[1].args.language = language.slug;
        if (post) tempStructure[index].rules[1].args.id = post.id;
      }

      // IF TEMPLATES, CONFIGURE INPUT TEMPLATE WITH OPTIONS
      if (templates?.length) {
        const index = tempStructure.findIndex((item) => item.key === "template");
        // IN CASE IT HAS JUST ONE TEMPLATE, PRESELECT AND HIDE INPUT
        if (templates.length === 1) {
          setValue({ ...value, template: { value: templates[0].id, label: templates[0].title } });
          if (index !== -1) tempStructure.splice(index, 1);
        } else {
          if (index !== -1) tempStructure[index].options = templates.map((item) => ({ value: item.id, label: item.title }));
        }
      } else if (templates === null) {
        const index = tempStructure.findIndex((item) => item.key === "template");
        if (index != -1) tempStructure.splice(index, 1);
      }

      // ADD NEW INPUTS IF MODAL IS FOR DUPLICATION
      if (duplicatePost) {
        tempStructure = cleanStructure(componentsPostDuplication);
        const index = tempStructure.findIndex((item) => item.key === "translations");
        if (index !== -1) {
          const children = [];
          if (languages?.length) {
            const langs = languages.filter((e) => post.translations.find((p) => p.language.slug === e.language.slug));
            for (let i = 0; i < langs.length; i++) {
              children.push({
                type: "Group",
                key: langs[i].language.slug,
                format: "table",
                title: `${getFlag(langs[i].language.slug)} ${langs[i].language.name}`,
                children: [
                  {
                    type: "Text",
                    key: "title",
                    title: "Title",
                    onBlur: { key: "slug", lower: true, replacement: "-", removeExtra: true },
                    rules: [
                      {
                        method: validator.isEmpty,
                        validWhen: false,
                        message: i18n("input.required_field"),
                      },
                    ],
                  },
                  {
                    type: "Slug",
                    key: "slug",
                    title: "Slug",
                    options: { fullRemove: true },
                    rules: [
                      {
                        method: validator.isEmpty,
                        validWhen: false,
                        message: i18n("input.required_field"),
                      },
                      {
                        method: customValidator.isPageSlugAvailable,
                        validWhen: true,
                        args: {
                          type: type,
                          project: project.id,
                          language: langs[i].language.slug,
                        },
                        message: i18n("input.slug_in_use"),
                      },
                    ],
                  },
                ],
              });
            }
          }
          tempStructure[index].children = children;

          if (post) {
            if (post?.translations?.length) {
              const translations = {};
              for (let i = 0; i < post.translations.length; i++) {
                translations[post.translations[i].language.slug] = {
                  title: post.translations[i].title,
                  slug: post.translations[i].slug,
                };
              }
              setValue({ translations });
            }
          }
        }
      }
      setStructure([...tempStructure]);
    }
  }, [post, duplicatePost, isOpen, type, project, language]);

  useEffect(() => {
    if (duplicate && availableTranslations?.length) {
      const temp = cleanStructure(componentsDuplication);
      const index = temp.findIndex((e) => e.key === "language");
      if (index !== -1) temp[index].options = availableTranslations;
      setStructureDuplicate([...temp]);
      if (language) {
        const active = availableTranslations.find((e) => e.value == language.id);
        if (active) setValue({ ...value, language: active });
      }
    }
  }, [duplicate, duplicatePost, availableTranslations]);

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) onSubmit({ ...value, template: value?.template?.value });
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={modalTitle ? modalTitle : post ? i18n("label.edit_post") : i18n("label.create_post")} onClose={onClose} />
      <Content>
        <div>
          <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
          {duplicate && <Form value={value} onChange={setValue} structure={structureDuplicate} disabled={isLoading} />}
        </div>
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: buttonText, disabled: isLoading, onClick: onClickSubmit }} />
    </Struture>
  );
}
