import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import Icon from "app/components/atoms/icons/icon";
import { i18n } from "app/i18n";
import { getFlag } from "app/utils/languages";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Resume() {
  const { info, project } = useSelector((store) => store.project);

  const languages = project?.languages;

  return (
    <>
      <div className="bg-[#0056FF] px-10 pt-16 pb-20">
        <div className="text-white">
          <h1 className="text-3xl font-bold">{project?.name}</h1>
          <div className="text-slate-300 flex gap-2">
            <Link to="/dashboard" className="text-slate-400">
              <span>Dashboard</span>
            </Link>
            <span>&#8250;</span>
            <span>{i18n("label.project")}</span>
          </div>
        </div>
      </div>
      <div className="custom-container">
        <div className="-translate-y-10">
          <div className="flex flex-wrap gap-4 ">
            {/* PUBLISHED PAGES */}
            <div className="flex-1 bg-white rounded-2xl p-10 flex">
              {!!info?.pages && (
                <div className="divide-y flex-1 h-full flex flex-col justify-between">
                  <div className="pb-2 flex items-center">
                    <Icon name="globe" height="h-10" width="w-10" className="mr-2" />
                    <div className="flex items-end">
                      <h2 className="text-[6rem] leading-none font-bold">{info?.pages?.active?.total}</h2>
                      <p className="text-lg font-semibold leading-none ml-2 mb-3 w-28">{i18n("label.published_pages")}</p>
                    </div>
                  </div>
                  <div className="pt-2 flex gap-2">
                    {languages?.length > 1 &&
                      languages.map((e, key) => (
                        <div key={key} className="flex-1 bg-slate-100 text-slate-500 py-2 rounded-lg flex flex-row justify-center items-center gap-2 text-center">
                          <span className="text-sm font-semibold">{info?.pages?.active[e.language.slug]}</span>
                          <span className="text-xs">
                            {getFlag(e.language.slug)} {e.language.name}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
            {/* FORMS */}
            {project?.modules?.forms && (
              <div className="flex-1 bg-white rounded-2xl p-10 flex">
                {!!info?.forms && (
                  <div className="divide-y flex-1 h-full flex flex-col justify-between">
                    <div className="pb-2 flex items-center">
                      <ClipboardDocumentListIcon className="h-10 w-10 mr-2" />
                      <div className="flex items-end">
                        <h2 className="text-[6rem] leading-none font-bold">{info?.forms}</h2>
                        <p className="text-lg font-semibold leading-none ml-2 mb-3 w-28">{i18n("label.new_form_submissions")}</p>
                      </div>
                    </div>
                    <div className="pt-2 flex gap-2">
                      <Link to={"forms"} className="flex-1 bg-slate-100 text-slate-500 py-2 rounded-lg flex flex-col text-center">
                        <span className="text-sm">{i18n("button.go_to_forms")}</span>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* LANGUAGES */}
            <div className="flex-1 bg-white rounded-2xl p-10 flex flex-col gap-1">
              <h4 className="text-lg font-semibold">{i18n("label.languages")}</h4>
              {!!languages?.length &&
                languages.map((e, key) => (
                  <div key={key} className="bg-slate-100 text-slate-400 py-2 rounded-lg flex items-center justify-between px-6">
                    <span>{e.language.name}</span>
                    <span className={`rounded-full aspect-square border ${e.enabled ? "border-green-300 bg-green-300 text-white" : "border-slate-200 text-slate-300"}`}>
                      <Icon name="check" height="h-4" width="w-4" />
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="mt-4">
            {/* REVISIONS */}
            {!!info?.revisions?.length && (
              <div className="bg-white rounded-2xl p-10 flex flex-col gap-1">
                <h4 className="text-lg font-semibold">{i18n("label.latest_updates")}</h4>

                <table>
                  <tbody className="divide-y">
                    {info.revisions.map((e, key) => (
                      <tr className="text-sm" key={key}>
                        <td>
                          <Link to={e.link} className="text-slate-700 hover:text-blue-700">
                            {languages?.length > 1 && <span className="pr-2">{getFlag(e.language)}</span>}
                            <span>{e.title}</span>
                          </Link>
                        </td>
                        <td className="text-center">{e.type}</td>
                        <td className="text-center">{e.status === "PUBLIC" ? i18n("label.published") : i18n("label.draft")}</td>
                        <td className="text-center">{e.author}</td>
                        <td className="text-center">{dayjs(e.date).format("DD/MM/YYYY HH:mm")}</td>
                        <td className="py-1 text-right">
                          <Link to={e.link}>
                            <button className="btn-icon ml-auto">
                              <Icon name="chevronRight" />
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
