import { Disclosure, Transition } from "@headlessui/react";
import Icon from "app/components/atoms/icons/icon";
import { i18n } from "app/i18n";
import { useCreateEnvironmentMutation, useUpdateEnvironmentMutation } from "app/stores/environment";
import { setUnsavedChanges } from "app/stores/project";
import { showServerError, showServerSuccess } from "app/utils/server";
import { validation } from "app/utils/validators";
import { objectsAreEqual } from "app/utils/validators/dataValidator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmailTextLayout from "../../builder/EmailTextLayout";
import { hiddenFields, modules } from "./constants";

export default function TabEmailTexts(props) {
  // PARAMS
  const { active } = props;
  const dispatch = useDispatch();

  // STATE
  const key = "emailTexts";
  const [value, setValue] = useState(null);
  const [changed, setChanged] = useState(false);
  const [structure, setStructure] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);

  // STORE
  const { environments, staticEnvironments } = useSelector((store) => store.environments);
  const { unsavedChanges } = useSelector((store) => store.project);

  // REQUEST
  const [createEnvironment, { isLoading: isCreating }] = useCreateEnvironmentMutation();
  const [updateEnvironment, { isLoading: isUpdating }] = useUpdateEnvironmentMutation();

  // VARS
  const defaultKey = "defaultTexts";
  const isLoading = isCreating || isUpdating;
  const defaults = staticEnvironments?.length ? staticEnvironments?.find((e) => e.key === defaultKey)?.value : undefined;

  useEffect(() => {
    if (!active) {
      setValue(null);
      setDefaultValue(null);
    } else if (environments?.length) {
      const item = environments.find((e) => e.key === key);
      if (item) {
        setValue({ ...item.value });
        setDefaultValue({ ...item.value });
      }
    }
  }, [active, environments]);

  useEffect(() => {
    if (value) {
      if (!objectsAreEqual(defaultValue, value)) setChanged(true);
      else setChanged(false);
    }
  }, [value, defaultValue]);

  useEffect(() => {
    if (changed !== unsavedChanges) dispatch(setUnsavedChanges(changed));
    return () => dispatch(setUnsavedChanges(false));
  }, [changed]);

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);

      if (validation.isValid) {
        const index = environments.findIndex((item) => item.key === key);
        // CREATE
        if (index === -1)
          createEnvironment({ key, value }).then((res) => {
            if (res?.data) showServerSuccess(i18n("toast.success_update"));
            else showServerError(res);
          });
        // UPDATE
        else
          updateEnvironment({ id: environments[index].id, value }).then((res) => {
            if (res?.data) showServerSuccess(i18n("toast.success_update"));
            else showServerError(res);
          });
      }
    });
  };

  const styles = {
    button: {
      open: "bg-gray-300 transition duration-600 ease-linear flex w-full justify-between rounded-t-lg text-slate-800 px-5 py-3 text-left text-base font-medium hover:bg-gray-300",
      close: "bg-gray-200  transition duration-600 ease-linear flex w-full justify-between rounded-b-lg rounded-t-lg text-slate-800 px-5 py-3 text-left text-base font-medium hover:bg-gray-300",
    },
    panel: {
      open: "bg-gray-100 px-5 pt-6 pb-8 text-sm text-gray-500 rounded-b-lg",
      close: "bg-gray-0 px-5 pt-6 pb-8 text-sm text-gray-500 rounded-b-lg rounded-t-lg",
    },
    transition: {
      enter: "transition duration-100 ease-out",
      enterFrom: "transform -translate-y-8 opacity-0 h-0",
      enterTo: "transform translate-y-0 opacity-100 h-full",
      leave: "transition duration-75 ease-out",
      leaveFrom: "transform translate-y-0 opacity-100 h-full",
      leaveTo: "transform -translate-y-8 opacity-0 h-0",
    },
  };

  return (
    <div>
      <div>
        <h3 className="text-slate-800 font-bold text-xl">{i18n("settings.email_server")}</h3>
        <p className="text-slate-500 font-normal text-sm">{i18n("settings.email_server_settings")}</p>
      </div>
      <div className="my-8">
        <div className="space-y-2">
          {modules.map((module, key) => {
            const val = (value && value[module.label]) || undefined;
            const placeholders = (defaults && defaults[module.label]) || undefined;
            const fields = hiddenFields[module.label] || undefined;
            const onChange = (ev) => setValue({ ...value, [module.label]: ev });

            return (
              <div key={key}>
                <Disclosure as="div">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className={open ? styles.button.open : styles.button.close}>
                        <div>
                          <span>{module.title}</span>
                          {val && <span className="text-xs text-slate-400 ml-4">{i18n("label.edited")}</span>}
                        </div>
                        <Icon name="chevronDown" className={`${open ? "rotate-180 transform" : ""} text-slate-800`} />
                      </Disclosure.Button>
                      <Transition show={open} {...styles.transition}>
                        <Disclosure.Panel className={open ? styles.panel.open : styles.panel.close}>
                          <div>
                            <p className="mb-12">{module.description}</p>
                            <EmailTextLayout value={val} hiddenFields={fields} placeholders={placeholders} onChange={onChange} disabled={isLoading} />
                          </div>
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          onClick={onClickSubmit}
          disabled={!changed || isLoading}
          className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none  disabled:bg-blue-200 disabled:cursor-not-allowed"
        >
          {i18n("button.submit")}
        </button>
      </div>
    </div>
  );
}
