import IconPlus from "app/components/atoms/icons/iconPlus";
import IconX from "app/components/atoms/icons/iconX";
import Select from "app/components/atoms/inputs/select";
import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import Form from "app/components/organisms/form";
import { useGetPostTypesQuery } from "app/stores/builder";
import { useGetProjectsQuery } from "app/stores/projects";
import { cleanStructure } from "app/utils/content";
import { postTypeRolesList, projectRolesList, rolesList } from "app/utils/roles";
import { validation } from "app/utils/validators";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tabs } from "../../../atoms/modal";
import { components, inputForceUser, inputProjectRole } from "./constants";
import { i18n } from "app/i18n";

export default function ModalUser(props) {
  const { isOpen = false, user, isLoading, buttonText = i18n("button.create_user"), insideProject = false, idProject, onSubmit, onClose = () => {} } = props;

  // VARS
  const allTabs = [{ name: i18n("label.user_information") }, { name: i18n("label.permissions"), noInside: true }, { name: i18n("label.postTypes") }];

  // STATE
  const [value, setValue] = useState(null);
  const [tabs, setTabs] = useState(allTabs);
  const [tabActive, setTabActive] = useState(0);
  const [structure, setStructure] = useState(components);

  // REQUEST
  const { data: projects } = useGetProjectsQuery({});
  const { user: userAuth } = useSelector((data) => data.auth);
  const { data: posttypes, refetch } = useGetPostTypesQuery({ orderBy: { title: "desc" } });

  useEffect(() => {
    if (isOpen) {
      refetch();
      buildStructure();
      if (user) fillForm();
    } else {
      setDefault();
    }
  }, [isOpen, user]);

  const setDefault = () => {
    setValue(null);
    setTabActive(0);
    setStructure(null);
    setTabs(allTabs);
  };

  const fillForm = () => {
    if (!user) return null;
    const temp = { ...user };
    temp.role = rolesList.find((item) => item.value === user.role);
    if (insideProject) {
      const projectRole = user?.projects?.find((e) => e.project.id === idProject)?.role;
      temp.role = projectRolesList.find((item) => item.value === projectRole);
    }
    temp.projects = user.projects.map(({ project, role }) => ({ project: { value: project.id, label: project.name }, role: projectRolesList.find((item) => item.value === role) }));
    temp.posttypes = user.posttypes.map(({ typeKey, role }) => ({ key: typeKey, role: postTypeRolesList.find((item) => item.value === role) || undefined }));
    setValue({ ...temp });
  };

  const buildStructure = () => {
    if (!userAuth) return null;

    const hasAuth = userAuth;
    const tempStructure = cleanStructure(components);

    // VALIDATION EMAIL
    if (user) {
      const index = tempStructure.findIndex((item) => item.key === "email");
      if (index != -1) tempStructure[index].rules[2].args.id = user.id;
    }

    // REMOVE TECH ROLES FOR NOT SUPER
    if (hasAuth && hasAuth.role !== "SUPER") {
      const index = tempStructure.findIndex((e) => e.key === "role");
      if (index != -1) tempStructure[index].options = tempStructure[index].options.filter((e) => e.value !== "SUPER" && e.value !== "DEVELOPER");
    }

    // REMOVE AUTO FIELDS
    if (insideProject) {
      const index = tempStructure.findIndex((e) => e.key === "role");
      if (index != -1) tempStructure.splice(index, 1, inputProjectRole);

      // TABS
      let temp = allTabs.filter((e) => !e.noInside);
      setTabs(temp);
    }

    setStructure([...tempStructure]);
  };

  function addMore() {
    if (value && projects && value.projects && value.projects.length >= projects.length) return;
    let temp = value || {};
    temp.projects = temp.projects || [];
    temp.projects.push({});
    setValue({ ...temp });
  }
  function removeLine(index) {
    let temp = value || {};
    temp.projects.splice(index, 1);
    setValue({ ...temp });
  }
  function filteredOptions(index) {
    if (!projects) return [];
    if (!value || !value.projects) return projects;

    const filtered = projects.filter(
      (project) =>
        !value.projects.find((item) => item.project && item.project.value === project.id) ||
        (value.projects[index] && value.projects[index].project && project.id === value.projects[index].project.value)
    );
    return filtered.map(({ id, name }) => ({ value: id, label: name }));
  }
  function onChangeInputProject(val, key, index) {
    let temp = value || {};
    temp.projects[index][key] = val;
    setValue({ ...temp });
  }
  function onChangeInputPostType(val, key) {
    let temp = value || {};
    temp.posttypes = temp.posttypes || [];

    const index = temp.posttypes.findIndex((item) => item.key === key);
    if (index != -1) temp.posttypes[index] = { key, role: val };
    else temp.posttypes.push({ key, role: val });

    setValue({ ...temp });
  }
  function onClickSubmit() {
    validation(structure, value, (structure, validation) => {
      let tempStructure = [...structure];
      let tempValidation = { ...validation };

      if (insideProject) {
        // CHECK EMAIL VALIDATION
        if (validation.email.isInvalid) {
          const index = tempStructure.findIndex((e) => e.key === "email");
          const addForceInput = tempStructure.findIndex((e) => e.key === "force") === -1;
          if (validation.email.code === "already.in.use") {
            // IF FORCE ACTIVE, IGNORE VALIDATION
            if (value.force) {
              validation.email.isInvalid = false;
              if (index !== -1) tempStructure[index].error = false;
            }
            // ADD FORCE INPUT TO IGNORE VALIDATION
            else {
              if (addForceInput && index !== -1) tempStructure.splice(index + 1, 0, inputForceUser);
            }
          }
        }
        tempValidation.isValid = !Object.keys(tempValidation).filter((key) => tempValidation[key].isInvalid).length;
      }

      setStructure([...tempStructure]);

      if (tempValidation.isValid) {
        // GLOBAL ROLE
        let role = value?.role?.value;
        // PROJECT SETTINGS
        let projects = undefined;
        if (value?.projects?.length) projects = value.projects.filter((item) => item?.role?.value && item?.project?.value).map((item) => ({ role: item.role.value, project: item.project.value }));

        if (insideProject) {
          // AUTOMATICALLY SET USER
          role = "USER";
          // APPLY GLOBAL ROLE TO CURRENT PROJECT
          if (idProject) {
            if (!projects?.length) {
              projects = [{ role: value?.role?.value, project: idProject }];
            } else {
              const index = projects.findIndex((e) => e.project === idProject);
              if (index !== -1) projects[index].role = value?.role?.value;
              else projects = [...projects, { role: value?.role?.value, project: idProject }];
            }
          }
        }

        onSubmit({
          role,
          projects,
          name: value.name,
          email: value.email,
          reuse: insideProject ? !!value.force : false,
          posttypes: !!value?.posttypes?.length ? posttypes.map((item) => ({ role: value?.posttypes?.find((el) => el.key == item.key)?.role?.value || undefined, postType: item.key })) : undefined,
        });
      } else setTabActive(0);
    });
  }

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={user ? i18n("label.edit_user") : i18n("label.create_new_user")} onClose={onClose} />
      <Tabs tabs={tabs} active={tabActive} onChange={setTabActive} />
      <Content tabs tab={tabActive}>
        <div>
          <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
        </div>
        {/* PROJECT ROLES */}
        {!insideProject && (
          <div>
            <h4 className="text_lg">{i18n("label.project_roles")}</h4>
            <p className="mt-2 mb-6 font-normal text_sm text-label">{i18n("label.project_roles_description")}</p>
            <div>
              {!!value?.projects?.length &&
                value.projects.map((item, index) => (
                  <div key={String(index)} className="flex flex-nowrap gap-3 items-center w-full">
                    <Select disabled={isLoading} value={item.project} options={filteredOptions(index)} onChange={(ev) => onChangeInputProject(ev, "project", index)} />
                    <Select disabled={isLoading} value={item.role} options={projectRolesList} onChange={(ev) => onChangeInputProject(ev, "role", index)} />
                    <button disabled={isLoading} onClick={() => removeLine(index)} className="btn-icon min-w-[2.5rem] mb-3">
                      <IconX />
                    </button>
                  </div>
                ))}
              <div className="text-center mt-3">
                <button onClick={addMore} className="btn-primary-outline" disabled={value && projects && value.projects && value.projects.length === projects.length}>
                  <IconPlus />
                  {i18n("button.add_project")}
                </button>
              </div>
              {isLoading && "loading..."}
            </div>
          </div>
        )}
        {/* POST TYPES */}
        <div>
          <h4 className="text_lg">{i18n("label.postType_roles")}</h4>
          <p className="mt-2 mb-6 font-normal text_sm text-label">{i18n("label.project_roles_description")}</p>
          {posttypes && posttypes.length ? (
            <table className="w-full ">
              <tbody>
                {posttypes.map((item, index) => {
                  const userRole = value?.posttypes?.length ? value.posttypes.find((el) => el.key === item.key) : undefined;
                  const val = userRole ? userRole.role : undefined;

                  return (
                    <tr key={String(index)} className="align-middle ">
                      <td className="pr-3">{item.title}</td>
                      <td className="pl-3">
                        <div className="mt-1">
                          <Select
                            disabled={isLoading}
                            placeholder={i18n("label.select_option")}
                            value={val}
                            options={postTypeRolesList}
                            clearable
                            onChange={(ev) => onChangeInputPostType(ev, item.key)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : undefined}
        </div>
      </Content>
      <Footer
        loading={isLoading}
        dynamic={!user && tabActive < tabs?.length - 1}
        cancel={{ text: i18n("button.close"), disabled: isLoading, onClick: onClose }}
        submit={{ text: buttonText, disabled: isLoading, onClick: onClickSubmit }}
        next={{ text: i18n("button.next"), disabled: isLoading, onClick: () => setTabActive(tabActive + 1) }}
      />
    </Struture>
  );
}
