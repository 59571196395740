import {
  Bars3BottomLeftIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
  CursorArrowRaysIcon,
  ListBulletIcon,
  PhotoIcon,
  PowerIcon,
  QueueListIcon,
  RectangleStackIcon,
  Square2StackIcon,
  Squares2X2Icon,
  NewspaperIcon,
  SwatchIcon,
  HashtagIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";

import { i18n } from "app/i18n";

const elements = [
  // TEXT
  {
    id: "1",
    title: "Text",
    key: "Text",
    type: "Text",
    icon: <Bars3BottomLeftIcon className="h-6 text-gray-500" />,
    fields: [
      { type: "Toggle", title: i18n("label.multi_line"), key: "multiline" },
      { type: "Toggle", title: i18n("label.editor"), key: "editor" },
      {
        type: "Checklist",
        title: i18n("label.modules"),
        key: "modules",
        conditional: {
          field: { value: "editor" },
          when: { value: "isTrue" },
        },
        options: [
          { value: "blocks", label: "Text formats" },
          { value: "fontfamily", label: "Font Family" },
          { value: "bold", label: "Bold" },
          { value: "italic", label: "Italic" },
          { value: "underline", label: "Underline" },
          { value: "strikethrough", label: "Strikethrough" },
          { value: "forecolor", label: "Text Color" },
          { value: "backcolor", label: "Back Color" },
          { value: "image", label: "Image" },
          { value: "link", label: "Link" },
          { value: "alignleft", label: "Align Left" },
          { value: "aligncenter", label: "Align Center" },
          { value: "alignright", label: "Align Right" },
          { value: "alignjustify", label: "Justify Text" },
          { value: "bullist", label: "Bull List" },
          { value: "numlist", label: "Numeric List" },
          { value: "checklist", label: "Check List" },
          { value: "indent", label: "Indent Text" },
          { value: "outdent", label: "Outdent Text" },
          { value: "superscript", label: "Superscript" },
          { value: "subscript", label: "Subscript" },
          { value: "blockquote", label: "Blockquote" },
          { value: "code", label: "HTML" },
        ],
      },
      {
        type: "Color",
        title: "Text Colors",
        key: "forecolors",
        conditional: {
          field: { value: "modules" },
          when: { value: "includes" },
          value: "forecolor",
        },
        collect: true,
        format: "short",
      },
      {
        type: "Color",
        title: "Background Colors",
        key: "backcolors",
        conditional: {
          field: { value: "modules" },
          when: { value: "includes" },
          value: "backcolor",
        },
        collect: true,
        format: "short",
      },
      {
        type: "Checklist",
        title: "Text Formats",
        key: "formats",
        conditional: {
          field: { value: "modules" },
          when: { value: "includes" },
          value: "blocks",
        },
        options: [
          { value: "Paragraph=p", label: "Paragraph" },
          { value: "Heading 1=h1", label: "H1" },
          { value: "Heading 2=h2", label: "H2" },
          { value: "Heading 3=h3", label: "H3" },
          { value: "Heading 4=h4", label: "H4" },
          { value: "Heading 5=h5", label: "H5" },
          { value: "Heading 6=h6", label: "H6" },
        ],
      },
    ],
  },
  // NUMERIC
  {
    id: "14",
    title: "Numeric",
    key: "Numeric",
    type: "Numeric",
    icon: <HashtagIcon className="h-6 text-gray-500" />,
    fields: [
      { type: "Numeric", title: i18n("label.minimum"), key: "min", clearable: true },
      { type: "Numeric", title: i18n("label.maximum"), key: "max", clearable: true },
    ],
  },
  // SELECT
  {
    id: "2",
    title: "Select",
    key: "Select",
    type: "Select",
    icon: <QueueListIcon className="h-6 text-gray-500" />,
    fields: [
      {
        key: "options",
        title: i18n("label.options"),
        type: "List",
        format: "table",
        components: [
          { key: "label", title: i18n("label.label"), placeholder: i18n("label.label"), type: "Text", onBlur: { key: "value" } },
          { key: "value", title: i18n("label.value"), placeholder: i18n("label.value"), type: "Text" },
        ],
      },
    ],
  },
  // MULTIMEDIA
  {
    id: "3",
    title: "Multimedia",
    key: "Multimedia",
    type: "Multimedia",
    icon: <PhotoIcon className="h-6 text-gray-500" />,
    fields: [
      {
        type: "Checklist",
        title: "Formats",
        key: "formats",
        description: "Select the formats you want",
        options: [
          { value: "IMAGE", label: "Image" },
          { value: "VIDEO", label: "Video" },
          { value: "FILE", label: "File" },
        ],
      },
      {
        type: "Checkbox",
        title: "Externals",
        key: "externals",
        text: "Include external media",
        conditional: [
          {
            field: { value: "formats" },
            when: { value: "includes" },
            value: "IMAGE",
          },
          {
            field: { value: "formats" },
            when: { value: "includes" },
            value: "FILE",
          },
          {
            field: { value: "formats" },
            when: { value: "includes" },
            value: "VIDEO",
          },
        ],
      },
      {
        type: "Radio",
        title: "Responsive Layout",
        key: "responsive",
        description: "Select the layout you want",
        conditional: {
          field: { value: "formats" },
          when: { value: "includes" },
          value: "IMAGE",
        },
        options: [
          { value: "BASIC", label: "Basic" },
          { value: "MOBILE", label: "Mobile" },
          { value: "TABLET", label: "Tablet" },
          { value: "DESKTOP", label: "Desktop" },
        ],
      },
      {
        type: "Checklist",
        title: "Fields",
        key: "fields",
        description: "Select the fields you want send for frontend. (Default: HREF, PLACEHOLDER, ALT, EXTENSION)",
        options: [
          { value: "href", label: "Link" },
          { value: "placeholder", label: "Placeholder" },
          { value: "alt", label: "Alternative Text" },
          { value: "extension", label: "Extension" },
          { value: "size", label: "Size" },
          { value: "height", label: "Height" },
          { value: "width", label: "Width" },
        ],
      },
    ],
  },
  // DATE
  {
    id: "8",
    title: "Date",
    key: "Date",
    type: "Date",
    icon: <CalendarDaysIcon className="h-6 text-gray-500" />,
    fields: [
      {
        type: "Toggle",
        key: "showTime",
        title: "Show Time",
      },
      {
        type: "Select",
        key: "dateFormat",
        title: "Date Format",
        options: [
          { value: "dd/MM/yyyy", label: "Day/Month/Year" },
          { value: "d MMM yy", label: "Day Month Year" },
        ],
      },
      {
        type: "Toggle",
        key: "yearSelector",
        title: "Year Selector",
      },
      {
        type: "Toggle",
        key: "monthSelector",
        title: "Month Selector",
      },
    ],
  },
  // GALLERY
  {
    id: "4",
    title: "Gallery",
    key: "Gallery",
    type: "Gallery",
    icon: <Square2StackIcon className="h-6 text-gray-500" />,
    fields: [
      {
        type: "Checklist",
        title: "Formats",
        key: "formats",
        description: "Select the formats you want",
        options: [
          { value: "IMAGE", label: "Image" },
          { value: "VIDEO", label: "Video" },
          { value: "FILE", label: "File" },
        ],
      },
      {
        type: "Text",
        title: "Max items",
        key: "max",
        description: "Select the maximum of items you allow",
      },
      {
        type: "Text",
        title: "Min items",
        key: "min",
        description: "Select the minimum of items you require",
      },
      {
        type: "Checklist",
        title: "Fields",
        key: "fields",
        description: "Select the fields you want send for frontend. (Default: HREF, PLACEHOLDER, ALT, EXTENSION)",
        options: [
          { value: "href", label: "Link" },
          { value: "placeholder", label: "Placeholder" },
          { value: "alt", label: "Alternative Text" },
          { value: "extension", label: "Extension" },
          { value: "size", label: "Size" },
          { value: "height", label: "Height" },
          { value: "width", label: "Width" },
        ],
      },
    ],
  },
  // GROUP
  {
    id: "5",
    title: "Group",
    key: "Group",
    type: "Group",
    isList: true,
    icon: <RectangleStackIcon className="h-6 text-gray-500" />,
  },
  // LIST
  {
    id: "6",
    title: "List",
    key: "List",
    type: "List",
    isList: true,
    icon: <ListBulletIcon className="h-6 text-gray-500" />,
    fields: [
      {
        type: "Text",
        title: "Max records",
        key: "max",
        description: "Select the maximum of records you allow",
      },
      {
        type: "Text",
        title: "Min records",
        key: "min",
        description: "Select the minimum of records you require",
      },
    ],
  },
  // BUTTON
  {
    id: "7",
    title: "Button",
    key: "Button",
    type: "Button",
    icon: <CursorArrowRaysIcon className="h-6 text-gray-500" />,
    fields: [
      {
        type: "Checklist",
        title: i18n("label.options"),
        key: "options",
        description: "Select the options you want",
        options: [
          { value: "isSlug", label: "Internal Links" },
          { value: "isLink", label: "External Links" },
          { value: "isDownload", label: "Download" },
          { value: "isAnchor", label: "Anchor" },
        ],
      },
      {
        key: "themes",
        title: i18n("label.themes"),
        type: "List",
        format: "table",
        components: [
          { key: "label", title: i18n("label.label"), placeholder: i18n("label.label"), type: "Text", onBlur: { key: "value" } },
          { key: "value", title: i18n("label.value"), placeholder: i18n("label.value"), type: "Text" },
        ],
      },
    ],
  },
  // CHECKBOX
  {
    id: "10",
    title: "Checkbox",
    key: "Checkbox",
    type: "Checkbox",
    icon: <CheckCircleIcon className="h-6 text-gray-500" />,
    mainFields: [
      {
        type: "Text",
        key: "text",
        title: i18n("label.text"),
        description: i18n("label.checkbox_text"),
      },
    ],
  },
  // CHECKLIST
  {
    id: "9",
    title: "Checklist",
    key: "Checklist",
    type: "Checklist",
    icon: <Squares2X2Icon className="h-6 text-gray-500" />,
    fields: [
      {
        type: "Toggle",
        key: "single",
        title: "Unique Selection",
      },
      {
        key: "options",
        title: i18n("label.options"),
        type: "List",
        format: "table",
        components: [
          { key: "label", title: i18n("label.label"), placeholder: i18n("label.label"), type: "Text", onBlur: { key: "value" } },
          { key: "value", title: i18n("label.value"), placeholder: i18n("label.value"), type: "Text" },
        ],
      },
    ],
  },
  // TOGGLE
  {
    id: "11",
    title: "Toggle",
    key: "Toggle",
    type: "Toggle",
    icon: <PowerIcon className="h-6 text-gray-500" />,
  },
  // SELECT POST
  {
    id: "12",
    title: "Select Post",
    key: "SelectPost",
    type: "SelectPost",
    icon: <SwatchIcon className="h-6 text-gray-500" />,
    fields: [
      {
        type: "Checklist",
        title: "Fields",
        key: "fields",
        description: "Select the fields you want send for frontend. (Default: TITLE, PATH, EXCERPT, THUMBNAIL, PUBLISHED DATE)",
        options: [
          { value: "title", label: "Title" },
          { value: "path", label: "Path" },
          { value: "excerpt", label: "Excerpt" },
          { value: "thumbnail", label: "Thumbnail" },
          { value: "publishedDate", label: "Published Date" },
          { value: "categories", label: "Categories" },
          { value: "properties", label: "Properties" },
          { value: "author", label: "Author" },
        ],
      },
    ],
  },
  // SELECT FORM
  {
    id: "14",
    title: "Select Form",
    key: "SelectForm",
    type: "SelectForm",
    icon: <SwatchIcon className="h-6 text-gray-500" />,
  },
  // SELECT CATEGORY
  {
    id: "14",
    title: "Select Category",
    key: "SelectCategory",
    type: "SelectCategory",
    icon: <SwatchIcon className="h-6 text-gray-500" />,
  },
  // DYNAMIC CONTENT
  {
    id: "13",
    title: "Dynamic",
    key: "DynamicContent",
    type: "DynamicContent",
    icon: <NewspaperIcon className="h-6 text-gray-500" />,
    fields: [
      {
        type: "Toggle",
        title: "Related",
        key: "related",
        text: "Active related posts",
        description: "Check this option to allow the user to select only related pages",
      },
      {
        type: "Checklist",
        title: "Fields",
        key: "fields",
        description: "Select the fields you want send for frontend. (Default: TITLE, PATH, EXCERPT, THUMBNAIL, PUBLISHED DATE)",
        options: [
          { value: "title", label: "Title" },
          { value: "path", label: "Path" },
          { value: "excerpt", label: "Excerpt" },
          { value: "thumbnail", label: "Thumbnail" },
          { value: "publishedDate", label: "Published Date" },
          { value: "categories", label: "Categories" },
          { value: "properties", label: "Properties" },
          { value: "author", label: "Author" },
        ],
      },
    ],
  },
  // DYNAMIC CATEGORIES
  {
    id: "13",
    title: "Categories",
    key: "DynamicCategory",
    type: "DynamicCategory",
    icon: <RectangleGroupIcon className="h-6 text-gray-500" />,
    fields: [
      {
        type: "Toggle",
        title: "Include Total",
        key: "showTotal",
        description: "Show the number of linked posts",
      },
    ],
  },
];

export default elements;
