export default function LogoSvalla(props) {
  const { short } = props;

  let fill = "#0056EE";

  return (
    <div className="flex items-center gap-2">
      {/* ICON */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.68 36" height={36} width={36.68} className="w-auto">
        <path fill={fill} fillRule="evenodd" style={{ opacity: 0.9 }} d="M25.74,10.57,1.1,24.8a2.19,2.19,0,0,0-.81,3A16.42,16.42,0,0,0,28.73,11.37a2.18,2.18,0,0,0-3-.8" />
        <path fill={fill} fillRule="evenodd" style={{ opacity: 0.9 }} d="M14,2.2A16.43,16.43,0,0,0,8,24.63a2.18,2.18,0,0,0,3,.8L35.59,11.2a2.19,2.19,0,0,0,.8-3A16.43,16.43,0,0,0,14,2.2" />
      </svg>

      {/* NAME */}
      {!short && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.63 36" height={36} width={78.63} className="w-auto">
          <path
            fill="#000f38"
            fillRule="evenodd"
            d="M70.36,25.82A3.46,3.46,0,0,0,74,22.14a3.47,3.47,0,0,0-3.62-3.69,3.69,3.69,0,0,0,0,7.37Zm4.43-11.17h3.84v15H75l-.17-1.84c-1.1,1.37-2.73,1.92-5,1.92a7.29,7.29,0,0,1-7.19-7.57,7.21,7.21,0,0,1,7.29-7.57,6.68,6.68,0,0,1,4.66,1.51Zm-68,3.12c-1.08,0-1.76.36-1.76,1.11s.78,1,3,1.53c2.44.59,5,1.5,5,4.66,0,2.86-2.31,4.85-6.67,4.85A11.39,11.39,0,0,1,0,28l1.76-3.16A8.87,8.87,0,0,0,6.84,26.5c1.24,0,1.89-.39,1.89-1.17S8,24.19,6,23.73C3.49,23.11.65,22,.65,19.08s2.48-4.73,6.25-4.73a11.37,11.37,0,0,1,6.19,1.7L11.4,19.21A8.68,8.68,0,0,0,6.81,17.77Zm19.6-3.12H30.9l-6.72,15H21l-6.74-15h4.52l3.9,9.51Zm23-4a4.27,4.27,0,0,1,4.27-4.27V29.63H49.45Zm7,0a4.27,4.27,0,0,1,4.26-4.27V29.63H56.41ZM38.31,25.82a3.47,3.47,0,0,0,3.63-3.68,3.47,3.47,0,0,0-3.63-3.69,3.57,3.57,0,0,0-3.62,3.69A3.58,3.58,0,0,0,38.31,25.82Zm4.24-9.74.2-1.43h3.83v15H42.91l-.16-1.84c-1.11,1.37-2.74,1.92-5,1.92a7.29,7.29,0,0,1-7.2-7.57,7.22,7.22,0,0,1,7.29-7.57A6.68,6.68,0,0,1,42.55,16.08Z"
          />
        </svg>
      )}
    </div>
  );
}
