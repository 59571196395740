import React, { useEffect, useState } from "react";
import { components, componentsDuplication } from "./constants";
import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import Form from "app/components/organisms/form";
import { i18n } from "app/i18n";
import { validation } from "app/utils/validators";
import { cleanStructure } from "app/utils/content";
import { useSelector } from "react-redux";

export default function ModalForm(props) {
  const { isOpen = false, form, duplicate, availableTranslations, buttonText = i18n("button.create_new"), isLoading, onSubmit, onClose = () => {} } = props;

  // STORE
  const { project, language } = useSelector((store) => store.project);

  // STATE
  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(components);
  const [structureDuplicate, setStructureDuplicate] = useState(componentsDuplication);

  useEffect(() => {
    if (!isOpen) {
      setValue(null);
      setStructure(components);
    }
  }, [isOpen]);

  useEffect(() => {
    if (availableTranslations?.length) {
      const temp = cleanStructure(componentsDuplication);
      const index = temp.findIndex((e) => e.key === "language");
      if (index !== -1) temp[index].options = availableTranslations;
      setStructureDuplicate([...temp]);
      if (language) {
        const active = availableTranslations.find((e) => e.value == language.id);
        if (active) setValue({ ...value, language: active });
      }
    }
  }, [availableTranslations]);

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) onSubmit({ ...value });
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={form ? i18n("label.edit_form") : i18n("label.create_form")} onClose={onClose} />
      <Content>
        <div>
          <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
          {duplicate && <Form value={value} onChange={setValue} structure={structureDuplicate} disabled={isLoading} />}
        </div>
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: buttonText, disabled: isLoading, onClick: onClickSubmit }} />
    </Struture>
  );
}
