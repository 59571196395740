import { gql } from "@apollo/client";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { serverAuth } from "app/utils/server";

export const languagesApi = createApi({
  reducerPath: "language",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    getLanguages: builder.query({
      query: ({ first, after, where, orderBy }) => ({
        document: gql`
          query languages($first: Int, $after: String, $where: LanguageWhere, $orderBy: LanguageOrderBy) {
            languages(first: $first, after: $after, where: $where, orderBy: $orderBy) {
              id
              name
              slug
            }
          }
        `,
        variables: {
          first,
          after,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.languages,
    }),
  }),
});

export const { useGetLanguagesQuery } = languagesApi;
