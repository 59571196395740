import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React from "react";

export default function Range(props) {
  const { title, value = "", error, min = "0", step = "10", max = "100", onChange, errorMessage, description, isRequired } = props;

  let styles = {
    div: "block relative mb-3",
    input: "!focus-visible:border-none !outline-none !ring-0 disabled:text-gray-400 disabled:pointer-events-none",
    description: "block text-xs font-light text-slate-400 -mt-0.5 mb-1",
    title: "block text-sm font-medium text-slate-700",
  };

  if (error) {
    styles.title += " text-red-600";
  }

  return (
    <div className={styles.div}>
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        <div>
          {value}
          {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
        </div>
      </div>
      <div className="relative">
        <input
          min={min}
          max={max}
          step={step}
          type="range"
          value={value || 0}
          onChange={(ev) => onChange && onChange(ev.target.value)}
          className="w-full h-2 range-xl bg-gray-300 rounded-lg appearance-none range-orange-900 cursor-pointer"
        />
      </div>
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </div>
  );
}
