import Icon from "app/components/atoms/icons/icon";
import Loading from "app/components/atoms/loading";
import { Content } from "app/components/atoms/modal";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import { setttingsNavigation } from "app/components/organisms/settings";
import { i18n } from "app/i18n";
import { useGetEnviromentsQuery } from "app/stores/environment";
import { setUnsavedChanges } from "app/stores/project";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Settings() {
  // PARAMS
  const dispatch = useDispatch();

  // STATE
  const [tab, setTab] = useState(0);
  const [modalConfirm, setModalConfirm] = useState(null);

  // REQUEST
  const { isLoading } = useGetEnviromentsQuery({});

  // STORE
  const { unsavedChanges } = useSelector((state) => state.project);

  // FUNCTION
  const changeTab = (index) => {
    if (unsavedChanges) {
      setModalConfirm({
        isOpen: true,
        type: "SAVE",
        mode: "BASIC",
        title: i18n("label.ignore_changes.title"),
        text: i18n("label.ignore_changes.text"),
        buttonText: i18n("label.ignore_changes.button"),
        onConfirm: () => {
          setTab(index);
          setModalConfirm(null);
          dispatch(setUnsavedChanges(false));
        },
        onClose: () => {
          setModalConfirm(null);
        },
      });
    } else {
      setTab(index);
    }
  };

  return (
    <>
      <div className="flex custom-container min-h-full">
        <div className="flex flex-col lg:flex-row flex-1 pt-32 lg:pt-16 pb-10 min-h-full">
          <div className="max-w-full lg:max-w-[240px] xl:max-w-xs w-full lg:pr-4 mb-6 lg:mb-0">
            <nav className="space-y-1 static" aria-label="Sidebar">
              {setttingsNavigation.map((item, index) => {
                let styles = {
                  button: "transition-all duration-300 ease-linear w-full group flex items-center px-3 py-2 text-sm font-medium rounded-md",
                  icon: "transition-all duration-300 ease-linear flex-shrink-0 -ml-1 mr-3 h-6 w-6",
                };
                if (index === tab) {
                  styles.icon += " text-gray-500";
                  styles.button += " bg-gray-200 text-gray-900";
                } else {
                  styles.icon += " text-gray-400";
                  styles.button += " text-gray-600 hover:bg-white hover:text-gray-900";
                }

                return (
                  <button key={index} className={styles.button} onClick={() => changeTab(index)} aria-current={index === tab ? "page" : undefined}>
                    <Icon name={item.icon} width="w-6" height="h-6" className={styles.icon} />
                    <span className="truncate">{i18n(item.title)}</span>
                  </button>
                );
              })}
            </nav>
          </div>
          <div className="flex-1 bg-white rounded-md h-fit">
            <Loading active={isLoading} />
            <Content tabs tab={tab}>
              {setttingsNavigation.map((item, index) => (
                <div key={index}>
                  <item.element active={index === tab} />
                </div>
              ))}
            </Content>
          </div>
        </div>
      </div>

      <ModalConfirm {...modalConfirm} />
    </>
  );
}
