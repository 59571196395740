import customValidator from "app/utils/validators/customValidator";
import validator from "validator";
import { i18n } from "app/i18n";

export const structure = [
  {
    type: "Text",
    key: "name",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
];
export const structurePassword = [
  {
    key: "password",
    type: "Password",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: customValidator.isPasswordCurrentAndValid,
        validWhen: true,
        args: { id: null },
        message: i18n("input.password_incorrect"),
      },
    ],
  },
  {
    key: "newPassword",
    type: "Password",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: customValidator.isPasswordStrong,
        validWhen: true,
        message: i18n("input.invalid_password"),
      },
    ],
  },
  {
    key: "confirm",
    type: "Password",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: customValidator.isEqualTo,
        args: { key: "newPassword" },
        validWhen: true,
        message: i18n("input.dontMatch_password"),
      },
    ],
  },
];
