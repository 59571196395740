import { getSource } from "app/utils/content";
import IconUser from "../icons/iconUser";

export default function Avatar(props) {
  const { size = "w-10 h-10 min-w-[2.5rem]", photo, name, className } = props;

  return (
    <div className={`overflow-hidden relative bg-gray-300 rounded-full flex items-center justify-center ${size} ${className}`}>
      {photo?.path ? <img alt={name || ""} src={getSource(photo.path)} className={`object-cover ${size} ${className} rounded-full`} /> : <IconUser className={`text-gray-400 ${className}`} />}
    </div>
  );
}
