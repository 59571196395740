import Icon from "app/components/atoms/icons/icon";
import { useDispatch, useSelector } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "./functions";

export default function SelectLoadMore(props) {
  const { value, posttype, multiple, placeholder, disabled, options, error, onChange } = props;

  const dispatch = useDispatch();
  const { types } = useSelector((state) => state.inputs);
  const { project, language } = useSelector((state) => state.project);
  const total = posttype && types.find((e) => e.value === posttype) ? types.find((e) => e.value === posttype).total : 0;

  return (
    <div className={`input-loadmore__outer${multiple ? " input-loadmore__outer--is-multi" : ""}${error ? " input-loadmore__outer--error" : ""}`}>
      <AsyncPaginate
        value={value}
        key={posttype}
        options={options}
        isMulti={multiple}
        onChange={onChange}
        isDisabled={disabled}
        placeholder={placeholder}
        closeMenuOnSelect={!multiple}
        classNamePrefix="input-loadmore"
        loadOptions={(search, options) => loadOptions(search, options, { id: posttype, total }, language, project, dispatch)}
      />
      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <Icon name="chevronUpDown" className="text-gray-400" />
      </span>
    </div>
  );
}
