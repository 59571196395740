import { EyeIcon } from "@heroicons/react/24/outline";
import { i18n } from "app/i18n";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// MOLECULES
import Preview from "app/components/organisms/modules/preview";
import Rebuild from "app/components/organisms/modules/rebuild";

// ORGANISMS
import QuickEdition from "app/components/organisms/quickEdition";

// STORE
import { useUpdatePostMutation } from "app/stores/post";

// UTILS
import { showServerError, showServerSuccess } from "app/utils/server";

export default function PostHeader() {
  // PARAMS
  const { lang } = useParams();

  // STORE
  const { post } = useSelector((state) => state.post);
  const { project } = useSelector((state) => state.project);

  // STATES
  const [editing, setEditing] = useState(false);

  // ACTIONS
  const [updatePost, { isLoading }] = useUpdatePostMutation();

  // VARIABLES
  const content = post?.translations?.find((item) => item.language.slug === lang);
  const loading = isLoading || !editing || !content;

  const onSaveChanges = (data) => {
    updatePost(data).then((res) => {
      if (res && res.data) {
        setEditing(false);
        showServerSuccess(i18n("toast.success_changes_update"));
      } else {
        showServerError(res);
      }
    });
  };

  return (
    <div className="bg-blue-900 text-white">
      <div className="custom-container" id="header-edition">
        <div className="inputs-box pt-8 pb-8 md:flex md:items-center lg:justify-between">
          <div className="min-w-0">
            <QuickEdition post={post} content={content} active={editing} loading={loading} onToggle={setEditing} onSave={onSaveChanges} />
          </div>
          <div className="mt-5 flex lg:mt-0 lg:ml-4">
            <div className="flex gap-3">
              {/* OPEN PAGE IN NEW TAB */}
              {!!project?.url && content?.published && (
                <a href={content.url} target="_black" rel="noopener noreferrer">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md border border-transparent bg-gray-900 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 !outline-none transition-all duration-500 ease-in-out !ring-0"
                  >
                    <EyeIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
                    {i18n("button.see_page")}
                  </button>
                </a>
              )}
              {/* PREVIEW */}
              <Preview />

              {/* REBUILD */}
              <Rebuild />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
