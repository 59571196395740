import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import Accordion from "app/components/molecules/accordion";
import ModalPost from "app/components/molecules/modals/modalPost";
import Form from "app/components/organisms/form";
import { i18n } from "app/i18n";
import { useUpdatePostInfoMutation } from "app/stores/post";
import { setUnsavedChanges, useCreatePostTranslationMutation } from "app/stores/project";
import { canParseJSON, cleanStructure, getTranslation } from "app/utils/content";
import { getFlag } from "app/utils/languages";
import { hasPermission, hasPermissionProject } from "app/utils/roles";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { components, tabs } from "./constants";
import Permission from "app/components/molecules/permission";
import { isArray, isObject } from "app/utils/validators/dataValidator";

export default function PostSidebar(props) {
  // PARAMS
  const { loading } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { key, project: idProject, lang } = useParams();

  // STORE
  const { user } = useSelector((state) => state.auth);
  const { post } = useSelector((state) => state.post);
  const { project, users, language, languages, sidebarChanges } = useSelector((state) => state.project);

  // STATES
  const [value, setValue] = useState(null);
  const [modal, setModal] = useState(null);
  const [panels, setPanels] = useState(tabs);
  const [dataChanged, setDataChanged] = useState(false);
  const [structure, setStructure] = useState(components);

  // REQUESTS
  const [updatePost, { isLoading: isUpdating }] = useUpdatePostInfoMutation();
  const [createPostTranslation, { isLoading: isCreating }] = useCreatePostTranslationMutation();

  // VARIABLES
  const content = post?.translations?.find((item) => item.language.slug === lang);
  const isLoading = loading || !post || isUpdating || isCreating;

  useEffect(() => {
    if (post && user && project) {
      if (components) {
        let tempStructure = cleanStructure(components);

        // CLEAN PROPERTIES FIELDS
        const indexA = tempStructure.findIndex((item) => item.key === "attributes");
        if (indexA !== -1) tempStructure[indexA].structure = [];
        const indexP = tempStructure.findIndex((item) => item.key === "properties");
        if (indexP !== -1) tempStructure[indexP].structure = [];

        // DISABLE INPUT IF DONT HAVE PERMISSION
        if (!hasPermissionProject(user, project, "EDITOR", true)) {
          tempStructure = tempStructure.map((e) => ({ ...e, disabled: true }));
        }

        // CATEGORIES
        if (post?.postType?.categories?.length) {
          const index = tempStructure.findIndex((item) => item.key === "categories");
          if (index !== -1) {
            const children = [];
            for (let i = 0; i < post.postType.categories.length; i++) {
              children.push({
                type: "Select",
                creatable: true,
                searchable: true,
                clearable: true,
                key: post.postType.categories[i].id,
                title: post.postType.categories[i].title,
                multiple: post.postType.categories[i].multi,
                options: post.postType.categories[i].options.map((item) => ({ value: item.id, label: getTranslation(item.translations, language?.slug) })),
              });
            }
            tempStructure[index].structure = [{ type: "Group", key: "categories", children }];
          }
        } else {
          const index = tempStructure.findIndex((item) => item.key === "categories");
          if (index !== -1) tempStructure[index].structure = [];
        }

        // USERS
        if (users?.length) {
          const index = tempStructure.findIndex((item) => item.key === "global");
          if (index !== -1) {
            const index1 = tempStructure[index].structure.findIndex((item) => item.key === "author");
            if (index1 !== -1) {
              tempStructure[index].structure[index1].options = users
                .filter((e) => (hasPermission(user, ["DEVELOPER"]) ? true : e.projects.find((p) => p.project.id === project.id)))
                .map((user) => ({ value: user.id, label: user.name }));
              if (content?.author) {
                const indexAuthor = tempStructure[index].structure[index1].options.findIndex((e) => e.value === content.author.id);
                if (indexAuthor === -1) tempStructure[index].structure[index1].options.push({ value: content.author.id, label: content.author.name });
              }
            }
          }
        }

        // PROPERTIES
        const isWebserviceType = !!post?.postType?.webservice;
        if (post?.postType?.structure?.length) {
          if (isWebserviceType) {
            const attrs = post.postType.structure.filter((e) => e.blocked);
            const props = post.postType.structure.filter((e) => !e.blocked);
            if (attrs?.length) {
              const index = tempStructure.findIndex((item) => item.key === "attributes");
              if (index !== -1) tempStructure[index].structure = [{ type: "Group", key: "attributes", disabled: true, children: attrs }];
            }
            if (props?.length) {
              const index = tempStructure.findIndex((item) => item.key === "properties");
              if (index !== -1) tempStructure[index].structure = [{ type: "Group", key: "properties", children: props }];
            }
          } else {
            const index = tempStructure.findIndex((item) => item.key === "properties");
            if (index !== -1) tempStructure[index].structure = [{ type: "Group", key: "properties", children: post?.postType?.structure }];
          }
        }

        setStructure([...tempStructure]);
      }
      const vals = getValue();
      setValue({ ...vals });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, project, user]);

  useEffect(() => {
    setDataChanged(isDataDifferent());
  }, [value]);

  useEffect(() => {
    if (dataChanged !== sidebarChanges) dispatch(setUnsavedChanges(dataChanged));
    return () => dispatch(setUnsavedChanges(false));
  }, [dataChanged]);

  const getValue = () => {
    const temp = {};
    if (content) {
      temp.author = undefined;
      temp.categories = undefined;
      temp.properties = undefined;
      temp.thumbnail = content?.thumbnail || undefined;
      temp.excerpt = content?.excerpt || undefined;
      temp.metatags = content?.metatags || undefined;

      // AUTHOR
      if (content.author) temp.author = { value: content.author.id, label: content.author.name };
      // PROPERTIES
      if (content.properties && content.properties.length) {
        temp.properties = {};
        for (let i = 0; i < content.properties.length; i++) {
          if (canParseJSON(content.properties[i].value)) {
            temp.properties[content.properties[i].key] = JSON.parse(content.properties[i].value);
          } else {
            temp.properties[content.properties[i].key] = content.properties[i].value;
          }
          // if (isObject(content.properties[i].value) || isArray(content.properties[i].value)) {
          //   temp.properties[content.properties[i].key] = JSON.parse(content.properties[i].value);
          // } else {
          //   temp.properties[content.properties[i].key] = content.properties[i].value;
          // }
        }
      }
      // CATEGORIES
      if (content.categories && content.categories.length) {
        temp.categories = {};
        let types = [];
        content.categories.forEach((item) => {
          const index = types.findIndex((e) => e.type === item.categoryType.id);
          if (index !== -1) {
            if (item.categoryType.multi) types[index].value = [...types[index].value, { value: item.id, label: getTranslation(item.translations, language?.slug) }];
            else types[index].value = { value: item.id, label: getTranslation(item.translations, language?.slug) };
          } else {
            types.push({
              type: item.categoryType.id,
              value: item.categoryType.multi
                ? [{ value: item.id, label: getTranslation(item.translations, language?.slug) }]
                : { value: item.id, label: getTranslation(item.translations, language?.slug) },
            });
          }
        });
        for (let i = 0; i < types.length; i++) {
          temp.categories[types[i].type] = types[i].value;
        }
      }
      // ATTRIBUTES (READ-ONLY)
      const index = components.findIndex((item) => item.key === "attributes");
      if (index !== -1 && components[index]?.structure?.length) {
        temp.attributes = {};
        for (let i = 0; i < content.properties.length; i++) {
          if (canParseJSON(content.properties[i].value)) {
            temp.attributes[content.properties[i].key] = JSON.parse(content.properties[i].value);
          } else {
            temp.attributes[content.properties[i].key] = content.properties[i].value;
          }
        }
      }
    }
    return temp;
  };

  const isDataDifferent = () => {
    if (isLoading) return false;
    return JSON.stringify(getValue()) !== JSON.stringify(value);
  };

  const onCreateTranslation = (language) => {
    const availableTranslations = languages
      .filter((e) => post.translations.find((content) => content.language.slug === e.language.slug))
      .map((e) => {
        const title = post.translations.find((content) => content.language.slug === e.language.slug).title;
        return { value: e.language.id, label: `<span className="mr-1">${getFlag(e.language.slug)}</span> ${title}` };
      });

    setModal({
      post: null,
      isOpen: true,
      templates: null,
      language: language,
      duplicate: true,
      availableTranslations,
      type: post.postType.key,
      onSubmit: ({ slug, title, copy, language: origin, translate }) => {
        createPostTranslation({ post: post.id, language: language.id, title, slug, copy, origin: origin.value, translate }).then((res) => {
          if (!res.error && res.data) {
            navigate(`/project/${idProject}/posts/${key}/${res.data.post.id}/${res.data.language.slug}`);
            showServerSuccess(i18n("toast.success_create"));
          } else {
            showServerError(res);
          }
          setModal(null);
        });
      },
      onClose: () => setModal(null),
    });
  };

  const submitChanges = () => {
    if (!dataChanged) return false;

    let data = {
      id: content.id,
      author: value?.author?.value,
      excerpt: value.excerpt,
      metatags: value.metatags || undefined,
      categories: value.categories,
      properties: value.properties,
      language: lang,
      thumbnail: undefined,
    };

    if (value?.thumbnail) {
      if (value?.thumbnail?.id) data.thumbnail = { connect: { id: value?.thumbnail?.id } };
      else if (content?.thumbnail) data.thumbnail = { disconnect: true };
    }

    updatePost(data).then((res) => {
      if (res && res.data) showServerSuccess(i18n("toast.success_changes_update"));
      else showServerError(res);
    });
  };

  return (
    <>
      <div className="h-full">
        <div className="bg-white w-full md:min-w-[336px] xl:w-[336px] rounded-t-md border-b h-12" />
        <div className="bg-white flex flex-col justify-between rounded-b-md shadow-lg pb-3 max-h-full overflow-hidden">
          <div className="relative z-10">
            {post && content && (
              <>
                <div className="flex-1 overflow-auto max-h-full">
                  <div className="relative">
                    <Accordion title={i18n("label.translations")} disabled defaultOpen={true}>
                      {!!languages?.length && (
                        <div>
                          <ul className="px-5 py-3">
                            {languages.map(({ language }, index) => {
                              const exists = post?.translations?.length ? post.translations.find((e) => e.language.slug === language.slug) : null;
                              return (
                                <li key={index} className="flex items-center justify-between py-3 border-b last:border-b-0">
                                  {exists ? (
                                    <>
                                      {content.id === exists.id ? (
                                        <>
                                          {/* CURRENT LANGUAGE */}
                                          <span className="text-sm text-slate-900">
                                            <span className="mr-1">{getFlag(language.slug)}</span> {exists.title}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <Link to={`/project/${idProject}/posts/${key}/${post.id}/${language.slug}`}>
                                            <span className="text-sm">
                                              <span className="mr-1">{getFlag(language.slug)}</span> {exists.title}
                                            </span>
                                          </Link>
                                          <Link to={`/project/${idProject}/posts/${key}/${post.id}/${language.slug}`}>
                                            <PencilIcon className="w-5" />
                                          </Link>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <span className="text-sm text-gray-400">
                                        <span className="mr-1">{getFlag(language.slug)}</span> {language.name}
                                      </span>
                                      <Permission project dev min="MANAGER">
                                        <button type="button" onClick={() => onCreateTranslation(language)}>
                                          <PlusIcon className="w-5" />
                                        </button>
                                      </Permission>
                                    </>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </Accordion>
                    {panels.map((item, index) => {
                      const inputs = structure.find((e) => e.key === item.key);
                      if (!inputs || !inputs.structure || !inputs.structure.length) {
                        return null;
                      }
                      return (
                        <Accordion key={item.key} title={item.title} className={`relative z-${(panels.length - index) * 10}`}>
                          {isUpdating ? (
                            <div role="status" className="max-w-sm animate-pulse pt-7 pb-5">
                              <div className="h-2.5 bg-slate-300 rounded-full w-48 mb-4"></div>
                              <div className="h-2 bg-slate-300 rounded-full max-w-[360px] mb-2.5"></div>
                              <div className="h-2 bg-slate-300 rounded-full mb-2.5"></div>
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <div className={!index ? "px-5 py-3" : ""}>
                              <Form disabled={inputs.disabled} structure={inputs.structure} onChange={setValue} mode="sidebar" value={value} />
                            </div>
                          )}
                        </Accordion>
                      );
                    })}
                  </div>
                </div>
                {dataChanged ? (
                  <div className="flex justify-end pr-10 pb-1 border-t">
                    <button
                      type="button"
                      onClick={submitChanges}
                      className="flex mt-4 items-center relative justify-center rounded-md border border-transparent bg-blue-900 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none  disabled:bg-gray-300 disabled:pointer-events-none transition-all ease-in-out duration-300"
                    >
                      {i18n("button.save")}
                    </button>
                  </div>
                ) : (
                  <div className="py-7 border-t" />
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <ModalPost {...modal} isLoading={isLoading} />
    </>
  );
}
