import Icon from "app/components/atoms/icons/icon";
import React from "react";
import { DragSource } from "react-dnd";

const externalNodeType = "builderComponent";
const externalNodeSpec = {
  beginDrag: (componentProps) => ({
    node: {
      id: componentProps.node.id,
      type: componentProps.node.type,
      title: componentProps.node.title,
      key: componentProps.node.key,
      isList: !!componentProps.node.isList,
      custom: !!componentProps.node.custom,
      model: componentProps.node.model,
      children: componentProps.node.children,
    },
  }),
  endDrag: (item) => item.node,
};
const externalNodeCollect = (connect, monitor) => ({ connectDragSource: connect.dragSource() });
function externalNodeBaseComponent({ connectDragSource, node, onClick, onEdit }) {
  return connectDragSource(
    <div
      type="button"
      onClick={onClick}
      className="flex w-full px-3 py-2 bg-gray-200 justify-between items-center rounded-md overflow-hidden mb-2 cursor-pointer transition-all duration-300 hover:bg-gray-300"
    >
      <span>{node.title}</span>
      <button
        type="button"
        onClick={(ev) => {
          ev.stopPropagation();
          onEdit(node);
        }}
        className="btn-icon"
      >
        <Icon name="edit" height="h-5" width="w-5" />
      </button>
    </div>,
    { dropEffect: "copy" }
  );
}
const DragElementCustom = DragSource(externalNodeType, externalNodeSpec, externalNodeCollect)(externalNodeBaseComponent);
export default DragElementCustom;
