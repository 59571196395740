import React from "react";

export default function Tooltip(props) {
  const { children, message, placement = "top", disabled } = props;

  let place = "bottom-full my-2 left-1/2 -translate-x-1/2";
  if (placement === "bottom") place = "top-full my-2 left-1/2 -translate-x-1/2";
  if (placement === "left") place = "right-full mx-2 top-1/2 -translate-y-1/2";
  if (placement === "right") place = "left-full mx-2 top-1/2 -translate-y-1/2";

  if (disabled) return children;
  return (
    <div className="group relative inline-flex align-middle">
      {children}
      <span className={`absolute scale-0 whitespace-nowrap transition-all rounded bg-gray-800 p-2 text-xs text-white ${place} group-hover:scale-100`}>{message}</span>
    </div>
  );
}
