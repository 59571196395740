import React, { useRef } from "react";
import MediaGridItem from "./item/grid";
import MediaListItem from "./item/list";

export default function MediaList(props) {
  const { files, view, current, onSelectAll, onSelect } = props;

  const checkbox = useRef();
  const allChecked = current && files.length === current.length;

  const toggleAll = () => {
    onSelectAll(!allChecked);
  };

  return (
    <>
      {/* GRID VIEW */}
      {!!files?.length && view === "grid" && (
        <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {files.map((file, key) => (
            <MediaGridItem key={key} {...file} active={current && current.includes(file.id)} onClick={onSelect} />
          ))}
        </ul>
      )}

      {/* LIST VIEW */}
      {!!files?.length && view === "list" && (
        <table className="min-w-full table-fixed divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                <input
                  type="checkbox"
                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  ref={checkbox}
                  checked={allChecked}
                  onChange={toggleAll}
                />
              </th>
              <th scope="col" className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                Name
              </th>
              {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Title
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Email
                    </th> */}
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3"></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {files.map((file, key) => (
              <MediaListItem key={key} {...file} active={current && current.includes(file.id)} onClick={onSelect} />
            ))}
          </tbody>
        </table>
      )}

      {/* IF NO FILES */}
      {!files || (!files.length && <p className="text-center text-xl my-4">No files</p>)}
    </>
  );
}
