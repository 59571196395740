import Icon from "../../icons/icon";

export default function Tabs(props) {
  const { tabs, active, toggleFilters, onChange } = props;

  if (tabs?.length > 0) {
    return (
      <div className="px-6 py-4 border-b flex items-center justify-between border-primary-50">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab, index) => (
            <button
              key={String(index)}
              onClick={() => onChange(index)}
              className={`
                ${active === index ? "bg-primary-100 text-primary-700" : "text-text hover:text-primary-500"}
                  px-6 py-3 font-medium text-sm rounded-md main-transition
                `}
            >
              {tab.name}
            </button>
          ))}
        </nav>
        {toggleFilters && (
          <div>
            <button type="button" className="p-2 rounded-md main-transition border hover:text-primary-700 hover:border-primary-700" onClick={toggleFilters}>
              <Icon name="settings" />
            </button>
          </div>
        )}
      </div>
    );
  }
  return null;
}
