import { useEffect, useState } from "react";
import { languages } from "countries-list";

// Atoms
import Form from "app/components/organisms/form";
import { Struture, Header, Content, Footer, Tabs } from "../../../atoms/modal";

// Helpers
import { validation } from "app/utils/validators";
import { components, tabs } from "./constants";
import { cleanStructure } from "app/utils/content";
import { i18n } from "app/i18n";

export default function ModalProject(props) {
  const { isOpen = false, project, buttonText = "Create Project", onSubmit, isLoading, onClose = () => {} } = props;

  const [value, setValue] = useState(null);
  const [errors, setErrors] = useState(null);
  const [tabActive, setTabActive] = useState(0);
  const [structure, setStructure] = useState(components);

  useEffect(() => {
    if (!isOpen) {
      setValue(null);
      setTabActive(0);
      setStructure(cleanStructure(components));
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const tempStructure = [...components];
      if (languages) {
        const index = structure.findIndex((item) => item.key === "languages");
        if (index !== -1)
          tempStructure[index].components[0].options = Object.keys(languages)
            .filter((item) => !item.rtl)
            .map((key) => ({ value: key, label: languages[key].name }));
      }
      if (project) {
        const index = tempStructure.findIndex((item) => item.key === "slug");
        if (index !== -1) tempStructure[index].rules[1].args.id = project.id;
      }
      setStructure([...tempStructure]);
    }
    // eslint-disable-next-line
  }, [isOpen, project, languages]);

  useEffect(() => {
    if (value && value.languages && value.languages.length) {
      if (value.languages.filter((item) => item.default).length > 1) setErrors("Please select only one language to default!");
      else if (errors) setErrors(null);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (project) {
      setValue({
        name: project.name,
        slug: project.slug,
        enabled: project.enabled,
        thumbnail: project.thumbnail,
        languages: project.languages.map((item) => ({ ...item, language: { value: item.language.slug, label: item.language.name } })),
      });
    }
  }, [project]);

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      const index = structure.findIndex((item) => item.key === "languages");
      if (value && index !== -1) {
        if (!value.languages || (value.languages && !value.languages.length)) {
          structure[index].error = structure[index].error || true;
          structure[index].errorList = "Please define the languages of your project";
          validation.isValid = false;
        } else if (value.languages.filter((item) => item.default).length === 0) {
          structure[index].error = structure[index].error || true;
          structure[index].errorList = "Please select one default language";
          validation.isValid = false;
        } else if (value.languages.filter((item) => item.default).length > 1) {
          structure[index].error = structure[index].error || true;
          structure[index].errorList = "Please select only one language as default";
          validation.isValid = false;
        } else {
          structure[index].error = null;
          structure[index].errorList = null;
        }
      }
      setStructure([...structure]);
      if (structure.find((item) => item.error)) setTabActive(structure.find((item) => item.error).tab);

      if (validation.isValid) {
        onSubmit({
          name: value.name,
          slug: value.slug,
          enabled: !!value.enabled,
          thumbnail: value.thumbnail ? { connect: { id: value.thumbnail.id } } : undefined,
          languages: value.languages
            ? value.languages.map((item) => ({
                name: item.language.label,
                slug: item.language.value,
                enabled: !!item.enabled,
                published: true,
                default: !!item.default,
              }))
            : [],
        });
      }
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={project ? i18n("label.edit_project") : i18n("label.create_project")} onClose={onClose} />
      <Tabs tabs={tabs} active={tabActive} onChange={setTabActive} />
      <Content tabs tab={tabActive}>
        {tabs.map((tab, index) => (
          <div key={index} aria-label={tab.name}>
            <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} tabMode tab={tabActive} />
          </div>
        ))}
      </Content>
      <Footer
        loading={isLoading}
        dynamic={!project && tabActive < tabs?.length - 1}
        cancel={{ text: i18n("button.close"), onClick: onClose }}
        submit={{ text: buttonText, disabled: errors || isLoading, onClick: onClickSubmit }}
        next={{ text: i18n("button.next"), disabled: isLoading, onClick: () => setTabActive(tabActive + 1) }}
      />
    </Struture>
  );
}
