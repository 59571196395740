import FormEdition from "app/components/organisms/editor/form/edition";
import FormHeader from "app/components/organisms/editor/form/header";
import FormSidebar from "app/components/organisms/editor/form/sidebar";
import { useGetFormQuery } from "app/stores/forms";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function FormsEdit() {
  // PARAMS
  const { lang: language, id, project } = useParams();

  // REQUESTS
  const { isLoading, refetch } = useGetFormQuery({ id, project, language });

  useEffect(() => {
    if (id && refetch) refetch();
  }, [id, language, project, refetch]);

  return (
    <div className="h-full flex flex-col relative">
      {/* HEADER */}
      <FormHeader loading={isLoading} />

      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="h-12 bg-blue-900" />
        <div className="-mt-12 custom-container flex-1 h-full items-start overflow-hidden flex flex-row gap-6">
          {/* EDITION */}
          <FormEdition loading={isLoading} />

          {/* SIDEBAR */}
          <FormSidebar loading={isLoading} />
        </div>
        <div className="h-6" />
      </div>
    </div>
  );
}
