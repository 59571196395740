import { i18n } from "app/i18n";
import { fileTypes, formatSize, getSource, isImageExtension } from "app/utils/content";

export default function MediaItem(props) {
  const { id, title, extension, size, type, path, active, onClick } = props;

  let styles = {
    outer: "aspect-video group block w-full overflow-hidden rounded-lg bg-gray-300 ",
    image: "pointer-events-none object-scale-down w-full h-full ",
    file: "w-full h-full flex items-center justify-center bg-gray-300 text-3xl font-bold uppercase group-hover:opacity-75",
  };

  if (active) {
    styles.outer += "ring-4 ring-blue-600 ring-offset-2";
    styles.image += "";
  } else {
    styles.outer += "focus-within:ring-4 focus-within:ring-blue-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-100";
    styles.image += "group-hover:opacity-75";
  }

  return (
    <li className="relative">
      <div className={styles.outer} onClick={() => onClick(id)}>
        {type === "IMAGE" || isImageExtension(extension) ? (
          <img src={getSource(path)} alt="" className={styles.image} />
        ) : (
          <div className={styles.file}>
            <span>{extension}</span>
          </div>
        )}
        <button type="button" className="absolute inset-0 focus:outline-none">
          <span className="sr-only">View details for {title}</span>
        </button>
      </div>
      <p className="block mt-2 text-sm font-medium text-gray-900 truncate pointer-events-none">{title}</p>
      <p className="block text-sm font-medium text-gray-500 pointer-events-none uppercase">
        {type ? (type.includes("EXTERNAL") ? fileTypes.find((e) => e.value === type).label : formatSize(size)) : i18n("label.file")}
      </p>
    </li>
  );
}
