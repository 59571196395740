import BlockEdition from "app/components/organisms/editor/block/edition";
import BlockHeader from "app/components/organisms/editor/block/header";
import BlockSidebar from "app/components/organisms/editor/block/sidebar";
import { useGetBlockQuery } from "app/stores/block";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function BlocksEdit() {
  const { lang: language, block: id, project } = useParams();

  const { isLoading, refetch } = useGetBlockQuery({ id, language, project });

  useEffect(() => {
    if (id && refetch) refetch();
  }, [id, language, project, refetch]);

  return (
    <div className="h-full flex flex-col relative">
      {/* HEADER */}
      <BlockHeader />

      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="h-12 bg-blue-900" />
        <div className="-mt-12 custom-container flex-1 h-full items-start overflow-hidden flex flex-row gap-6">
          {/* EDITION */}
          <BlockEdition loading={isLoading} />

          {/* SIDEBAR */}
          <BlockSidebar loading={isLoading} />
        </div>
        <div className="h-6" />
      </div>
    </div>
  );
}
