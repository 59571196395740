import { i18n } from "app/i18n";
import customValidator from "app/utils/validators/customValidator";

export const components = [
  {
    type: "Multimedia",
    key: "image",
    noAlt: true,
    title: i18n("settings.dashboard_image"),
    description: i18n("settings.dashboard_image_description"),
    rules: [
      {
        method: customValidator.isObjectEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  { type: "Color", key: "color", title: i18n("settings.dashboard_entry_color"), format: "chrome" },
];
