import React, { useEffect, useState } from "react";
import { i18n } from "app/i18n";

// Atoms
import { Struture, Header, Content, Footer, Tabs } from "../../../atoms/modal";

// Organisms
import Form from "app/components/organisms/form";

// Helpers
import { validation } from "app/utils/validators";
import { components, tabs } from "./constants";
import { cleanStructure } from "app/utils/content";

// Store
import { useGetProjectsQuery } from "app/stores/projects";

export default function ModalContentType(props) {
  const { isOpen = false, contentType, buttonText = i18n("button.create_contentType"), onSubmit, isLoading, onClose = () => {} } = props;

  const [value, setValue] = useState(null);
  const [tabActive, setTabActive] = useState(0);
  const [structure, setStructure] = useState(components);

  const { data: projects } = useGetProjectsQuery({ orderBy: { name: "desc" } });

  useEffect(() => {
    if (!isOpen) {
      setValue(null);
      setTabActive(0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const tempStructure = cleanStructure(components);
      if (contentType) {
        // KEY
        const index = tempStructure.findIndex((item) => item.key === "key");
        if (index != -1) tempStructure[index].rules[1].args.id = contentType.id;
      }

      // PROJECTS
      if (projects?.length) {
        const index = tempStructure.findIndex((item) => item.key === "projects");
        if (index !== -1) tempStructure[index].options = projects.map((item) => ({ value: item.id, label: item.name }));
      }
      setStructure([...tempStructure]);
    }
  }, [isOpen, contentType, projects]);

  useEffect(() => {
    if (contentType) {
      const tempValue = { ...contentType };
      // PROJECTS
      if (tempValue?.projects?.length) {
        const temps = [];
        for (let i = 0; i < tempValue?.projects.length; i++) {
          const template = projects.find((item) => item.id === tempValue.projects[i].id);
          if (template) temps.push({ value: template.id, label: template.name });
        }
        tempValue.projects = temps;
      }
      setValue({ ...tempValue });
    }
  }, [contentType]);

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) {
        onSubmit({
          key: value.key,
          title: value.title,
          projects: value && value.projects && value.projects.length ? value.projects.map((item) => item.value) : undefined,
        });
      }
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={contentType ? i18n("label.edit_contentType") : i18n("label.create_contentType")} onClose={onClose} />
      <Tabs tabs={tabs} active={tabActive} onChange={setTabActive} />
      <Content tabs tab={tabActive}>
        {tabs.map((tab, index) => (
          <div key={index}>
            <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} tabMode tab={tabActive} />
          </div>
        ))}
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: buttonText, disabled: isLoading, onClick: onClickSubmit }} />
    </Struture>
  );
}
