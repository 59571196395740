import { i18n } from "app/i18n";
import ItemFile from "./itemFile";

export default function ListFiles(props) {
  const { files, selected = [], onSelect } = props;

  return (
    <div>
      {!!files?.length ? (
        <ul className="grid grid-cols-2 gap-x-2 gap-y-4 sm:gap-x-4 sm:grid-cols-3 md:grid-cols-4">
          {files.map((item, key) => (
            <ItemFile key={key} {...item} active={selected && selected.includes(item.id)} onClick={onSelect} />
          ))}
        </ul>
      ) : (
        <p className="text-sm">{i18n("label.no_results_to_display")}</p>
      )}
    </div>
  );
}
