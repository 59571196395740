import { i18n } from "app/i18n";
import customValidator from "app/utils/validators/customValidator";
import { languages } from "countries-list";

const components = [
  {
    type: "List",
    key: "languages",
    format: "table",
    components: [
      {
        type: "Select",
        key: "language",
        title: i18n("label.language"),
        disabled: true,
        options: Object.keys(languages)
          .filter((item) => !item.rtl)
          .map((key) => ({ value: key, label: languages[key].name })),
        rules: [
          {
            method: customValidator.isObjectEmpty,
            validWhen: false,
            message: i18n("input.required_field"),
          },
        ],
      },
      {
        type: "Checkbox",
        key: "default",
        title: i18n("label.default"),
        text: "Set default language of project",
      },
      {
        type: "Checkbox",
        key: "enabled",
        title: i18n("label.enabled"),
        text: "Define if it appears in frontend",
      },
    ],
  },
];

const componentLocale = {
  type: "Toggle",
  key: "showLocale",
  title: "Include locale in url",
};

export { components, componentLocale };
