import IconForbidden from "app/components/atoms/icons/IconForbidden";
import { Content } from "app/components/atoms/modal";
import Dropdown from "app/components/molecules/dropdown";
import LabelRevisionStatus from "app/components/molecules/labels/labelRevisionStatus";
import ModalRevision from "app/components/molecules/modals/modalRevision";
import Permission from "app/components/molecules/permission";
import PopupBuilder from "app/components/molecules/popupBuilder";
import Form from "app/components/organisms/form";
import { i18n } from "app/i18n";
import { useCreateRevisionMutation } from "app/stores/block";
import { setUnsavedChanges } from "app/stores/project";
import { showServerError, showServerSuccess } from "app/utils/server";
import { NOTFOUND } from "app/utils/status";
import { validation } from "app/utils/validators";
import { objectsAreEqual } from "app/utils/validators/dataValidator";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

export default function BlockEdition(props) {
  // PARAMS
  const { loading } = props;
  const dispatch = useDispatch();
  const { project, lang } = useParams();

  // STORE
  const { block, blockStatus } = useSelector((state) => state.block);
  const { unsavedChanges } = useSelector((state) => state.project);

  // STATE
  const [value, setValue] = useState(null);
  const [errors, setErrors] = useState(null);
  const [dataChanged, setDataChanged] = useState(false);
  const [modalRevision, setModalRevision] = useState(null);

  // REQUESTS
  const [createRevision, { isLoading: isCreating }] = useCreateRevisionMutation();

  // VARS
  const content = block?.translations?.find((item) => item.language.slug === lang);
  const last = content?.revisions?.length ? content.revisions[0] : {};
  const isLoading = loading || !content || isCreating;

  useEffect(() => {
    if (block && content) {
      setValue({ ...last?.data });
    }
  }, [block, content]);

  useEffect(() => {
    setDataChanged(isDataDifferent());
  }, [content, value]);

  useEffect(() => {
    if (dataChanged !== unsavedChanges) dispatch(setUnsavedChanges(dataChanged));
    return () => dispatch(setUnsavedChanges(false));
  }, [dataChanged]);

  // WHEN CLICK TO SEE REVISIONS
  const onShowRevisions = () => {
    setModalRevision({
      block: true,
      isOpen: true,
      content: content,
      onClose: () => setModalRevision(null),
    });
  };

  // CHECK FOR DIFFERENT DATA
  const isDataDifferent = () => {
    return !objectsAreEqual(last?.data, value);
  };

  // WHEN CLICK TO DELETE CHANGES
  const cleanChanges = () => {
    setValue({ ...last?.data });
  };

  // WHEN SUBMIT
  const onSubmitNewContent = (status = false) => {
    if (!dataChanged) return false;
    const structure = block?.contentType?.structure;
    validation(structure, value, (structure, validation) => {
      setErrors(structure);
      if (validation.isValid) {
        createRevision({ data: value, content: content?.id, status }).then((res) => {
          if (res?.data) showServerSuccess(i18n("toast.success_update"));
          else showServerError(res);
        });
      }
    });
  };

  // GET STRUCTURE
  const buildStructure = () => {
    const structure = block?.contentType?.structure;
    if (!errors || !errors.length) return structure;

    return structure.map((item, index) => {
      const error = errors[index]?.error || false;
      const errorMessage = errors[index]?.errorMessage || null;
      const errorList = errors[index]?.errorList || null;
      const children = item.children && errors[index]?.children ? errors[index]?.children : item.children;
      const components = item.components && errors[index]?.components ? errors[index]?.components : item.components;
      return { ...item, children, components, error, errorMessage, errorList };
    });
  };

  return (
    <>
      <div className="flex flex-col flex-1 h-full overflow-hidden rounded-t-md">
        <div className="bg-white w-full overflow-hidden border-b h-12" />

        <div className="bg-white w-full flex-1 rounded-b-md overflow-hidden flex flex-col post-editor shadow-lg relative">
          <div className="flex-1 overflow-auto">
            {blockStatus !== NOTFOUND ? (
              <Content>
                {block?.contentType?.structure?.length ? (
                  <div>
                    <div className="flex justify-end items-center mb-8">
                      <LabelRevisionStatus message={`${last?.author?.name} - ${dayjs(last?.createdAt).format("DD.MM.YY HH:mm")}`} status={last?.status} />
                      <PopupBuilder block />
                    </div>
                    <Form structure={buildStructure()} onChange={setValue} value={value} disabled={isLoading} />
                  </div>
                ) : (
                  <p>{i18n("label.nothing_found")}</p>
                )}
              </Content>
            ) : (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <IconForbidden width="w-32" height="h-32" className="mb-10 text-red-700" />
                <h3 className="mb-2 text-4xl font-semibold text-black uppercase">{i18n("label.page_not_found")}</h3>
                <p className="mb-10 text-md font-normal max-w-sm text-center text-slate-600">{i18n("label.page_not_found_description")}</p>
                <Link to={`/project/${project}`}>
                  <button
                    type="button"
                    className="flex mr-1.5 items-center relative justify-center rounded-md border border-blue-800 bg-transparent px-4 py-2 text-sm font-medium text-blue-800 shadow-sm hover:bg-blue-700 hover:border-blue-700 hover:text-white focus:outline-none "
                  >
                    {i18n("label.return_dashboard")}
                  </button>
                </Link>
              </div>
            )}
          </div>
          {blockStatus !== NOTFOUND && (
            <div className="border-t py-4 px-6 flex justify-between">
              <Dropdown
                position="top-left"
                options={[
                  { title: i18n("label.revisions"), onClick: onShowRevisions, icon: "revision" },
                  { title: i18n("button.clean_changes"), onClick: cleanChanges, icon: "rollback", delete: true },
                ]}
              />
              <div className="flex">
                <button
                  type="button"
                  disabled={!dataChanged || isLoading}
                  onClick={() => onSubmitNewContent(false)}
                  className="flex mr-1.5 items-center relative justify-center rounded-md border border-blue-600 bg-transparent px-4 py-2 text-sm font-medium text-blue-600 shadow-sm hover:bg-blue-500 hover:border-blue-500 hover:text-white focus:outline-none  disabled:text-blue-200 disabled:border-blue-200 disabled:hover:bg-white disabled:hover:border-blue-200 disabled:cursor-not-allowed"
                >
                  {i18n("button.save_as_draft")}
                </button>
                <Permission project dev min="EDITOR">
                  <button
                    type="button"
                    disabled={!dataChanged || isLoading}
                    className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none  disabled:bg-blue-200 disabled:cursor-not-allowed"
                    onClick={() => onSubmitNewContent(true)}
                  >
                    {i18n("button.publish")}
                  </button>
                </Permission>
              </div>
            </div>
          )}
        </div>
      </div>

      <ModalRevision {...modalRevision} />
    </>
  );
}
