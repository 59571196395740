import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import Indicator from "app/components/atoms/indicator";
import Dropdown from "app/components/molecules/dropdown";
import LabelPostStatus from "app/components/molecules/labels/labelPostStatus";
import Permission, { HasPermission } from "app/components/molecules/permission";
import { i18n } from "app/i18n";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function ItemForm(props) {
  const { id, translations, onCreateTranslation, onSeeSubmissions, onDuplicate, onDelete } = props;

  // PARAMS
  const navigate = useNavigate();

  // STORE
  const { language, languages } = useSelector((state) => state.project);

  // VARS
  const info = language && translations?.length ? translations.find((item) => item.language.slug === language.slug) : undefined;

  // FUNCTIONS
  const onEdit = () => {
    navigate(`${id}/${language.slug}`);
  };

  return (
    <tr key={id}>
      <td>
        {info ? (
          <>
            <Link to={`${id}/${language.slug}`} className="text-sm text-slate-700 whitespace-nowrap">
              {info.title}
            </Link>
            <Indicator noShow count={info.unReadCount} />
          </>
        ) : (
          <p>CREATE</p>
        )}
      </td>
      <td>
        {languages?.length && (
          <>
            {languages.map((lang, key) => {
              const exists = translations?.length ? translations.findIndex((item) => item.language.slug === lang.language.slug) : undefined;
              return (
                <span key={key} className="h-4 w-6 mx-1 inline-flex items-center justify-center transition-all ease-in-out duration-500 text-gray-500 hover:text-gray-900">
                  {exists !== -1 ? (
                    <Link to={`${id}/${lang.language.slug}`}>
                      <PencilIcon className="w-5" />
                    </Link>
                  ) : (
                    <>
                      <Permission project dev min="MANAGER">
                        <button type="button" onClick={() => onCreateTranslation(lang)}>
                          <PlusIcon className="w-5" />
                        </button>
                      </Permission>
                    </>
                  )}
                </span>
              );
            })}
          </>
        )}
      </td>
      <td>
        <button type="button" disabled={!info} className="cursor-pointer transition-all duration-500 ease-linear hover:text-slate-700" onClick={() => onSeeSubmissions(info)}>
          {info?.count || 0} {i18n("label.records")}
        </button>
      </td>
      <td className="w-10">
        <LabelPostStatus published={info?.enabled} />
      </td>
      <td>
        <Permission min="EDITOR" project dev>
          <Dropdown
            icon="ellipsis"
            disabled={!info}
            options={[
              { title: i18n("button.edit"), icon: "edit", onClick: onEdit, disabled: !HasPermission({ min: "EDITOR", project: true, dev: true }) },
              { title: i18n("button.duplicate"), icon: "copy", onClick: onDuplicate, disabled: !HasPermission({ min: "MANAGER", project: true, dev: true }) },
              { title: i18n("label.submissions"), icon: "form", onClick: () => onSeeSubmissions(info), disabled: !HasPermission({ min: "EDITOR", project: true, dev: true }) },
              { title: i18n("button.delete"), icon: "delete", onClick: onDelete, delete: true, disabled: !HasPermission({ min: "MANAGER", project: true, dev: true }) },
            ]}
          />
        </Permission>
      </td>
    </tr>
  );
}
