import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Icon from "app/components/atoms/icons/icon";
import Tooltip from "app/components/atoms/tooltip";
import ModalDynamicPosts from "app/components/molecules/modals/modalDynamicPosts";
import Form from "app/components/organisms/form";
import { cleanStructure } from "app/utils/content";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function DynamicContent(props) {
  const { title, description, value, related, error, errorMessage, isRequired, onChange } = props;

  const components = [
    {
      type: "Numeric",
      key: "number",
      title: "Number",
      max: 40,
      min: -1,
    },
    {
      type: "Select",
      key: "order",
      title: "Order",
      placeholder: "Selecione",
      options: [
        { value: "publishedDate_DESC", label: "Most Recents" },
        { value: "publishedDate_ASC", label: "Most Oldest" },
        { value: "title_ASC", label: "Alphabetically" },
      ],
    },
    {
      type: "Toggle",
      title: "Related",
      key: "related",
    },
    {
      type: "Toggle",
      title: "Same Category",
      key: "sameCategory",
      text: "Filter by category",
      conditional: {
        field: "related",
        when: "isTrue",
      },
    },
    {
      type: "Select",
      key: "type",
      title: "Post Type",
      placeholder: "Selecione um Post Type",
      options: [],
      conditional: {
        field: "related",
        when: "isFalse",
      },
    },
  ];

  let styles = {
    title: "block text-sm font-medium mb-1",
    box: "px-5 pt-5 pb-3 bg-gray-50 rounded-lg border dynamic-content",
  };

  // STATE
  const [modal, setModal] = useState(null);
  const [structure, setStructure] = useState(components);

  // STORE
  const { types } = useSelector((state) => state.inputs);

  // VAR
  const isRelated = value?.related;

  useEffect(() => {
    if (types) {
      const temp = cleanStructure(components);

      // FILL TYPES SELECT
      if (types?.length) {
        const index = temp.findIndex((e) => e.key === "type");
        if (index !== -1) temp[index].options = types;
      }

      // RELATED FILL
      if (!related) {
        const index = temp.findIndex((e) => e.key === "related");
        if (index !== -1) temp.splice(index, 1);
        if (value?.related) {
          const temp = value;
          delete temp.related;
          onChange({ ...temp });
        }
      }

      setStructure([...temp]);
    }
  }, [types, related]);

  useEffect(() => {
    if (!isRelated && value?.sameCategory) {
      const temp = { ...value };
      delete temp.sameCategory;
      onChange({ ...temp });
    }
  }, [isRelated]);

  const onOpenModal = (filters) => {
    setModal({
      filters,
      isOpen: true,
      onClose: () => setModal(false),
    });
  };

  if (error) {
    styles.box += " border-red-600";
    styles.title += " text-red-600";
  } else {
    styles.box += " border-transparent";
    styles.title += " text-slate-700";
  }

  return (
    <div className="block mb-3">
      <div className="flex items-end justify-between">
        <div>
          {title && <span className={styles.title}>{title}</span>}
          {description && <span className="block text-xs font-light text-slate-400 -mt-1.5 mb-1">{description}</span>}
        </div>
        <div className="text-gray-400 cursor-pointer flex items-center transition-all duration-500 ease-in-out hover:text-primary-700/80">
          <Tooltip message="See results" placement="left">
            <button type="button" className="btn-icon" onClick={() => onOpenModal(value)}>
              <Icon name="info" />
            </button>
          </Tooltip>
          {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 ${error ? "text-red-600" : "text-slate-700"}`} />}
        </div>
      </div>
      <div className={styles.box}>
        <Form value={value} structure={structure} onChange={onChange} className="flex gap-4" />
      </div>
      {error && <p className="mt-1 text-xs text-left text-red-600">{errorMessage}</p>}

      <ModalDynamicPosts {...modal} />
    </div>
  );
}
