import { useState, useRef, useCallback, useContext, useEffect } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

const useDrag = () => {
  const [clicked, setClicked] = useState(false);
  const [dragging, setDragging] = useState(false);
  const position = useRef(0);

  const dragStart = useCallback((ev) => {
    position.current = ev.clientX;
    setClicked(true);
  }, []);

  const dragStop = useCallback(
    () =>
      window.requestAnimationFrame(() => {
        setDragging(false);
        setClicked(false);
      }),
    []
  );

  const dragMove = (ev, cb) => {
    const newDiff = position.current - ev.clientX;
    const movedEnough = Math.abs(newDiff) > 5;
    if (clicked && movedEnough) setDragging(true);
    if (dragging && movedEnough) {
      position.current = ev.clientX;
      cb(newDiff);
    }
  };

  return { dragStart, dragStop, dragMove, dragging, position, setDragging };
};

const onWheel = (apiObj, ev) => {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }
  if (ev.deltaY < 0) apiObj.scrollNext();
  else if (ev.deltaY > 0) apiObj.scrollPrev();
};

const Arrow = ({ right, disabled }) => {
  return (
    <div
      disabled={disabled}
      className={`w-16 from-gray-300 absolute inset-y-0 z-10 to-transparent pointer-events-none ${right ? "right-0 bg-gradient-to-l" : "left-0 bg-gradient-to-r"} ${
        disabled ? "opacity-0" : "opacity-100"
      }`}
    />
  );
};

const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } = useContext(VisibilityContext);
  const [disabled, setDisabled] = useState(!initComplete || (initComplete && isFirstItemVisible));

  useEffect(() => {
    if (visibleElements.length) setDisabled(isFirstItemVisible);
  }, [isFirstItemVisible, visibleElements]);

  return <Arrow disabled={disabled} onClick={() => scrollPrev()} />;
};
const RightArrow = () => {
  const { isLastItemVisible, scrollNext, visibleElements } = useContext(VisibilityContext);
  const [disabled, setDisabled] = useState(!visibleElements.length && isLastItemVisible);
  useEffect(() => {
    if (visibleElements.length) setDisabled(isLastItemVisible);
  }, [isLastItemVisible, visibleElements]);

  return <Arrow right disabled={disabled} onClick={() => scrollNext()} />;
};

export { useDrag, onWheel, LeftArrow, RightArrow };
