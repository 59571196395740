const { getEmojiFlag } = require("countries-list");

const getFlag = (lang) => {
  let ref = undefined;
  switch (lang) {
    case "en":
      ref = "US";
      break;
    default:
      ref = lang.toUpperCase();
      break;
  }
  return getEmojiFlag(ref);
};

export { getFlag };
