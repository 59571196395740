import React from "react";

// Organisms
import ListProjects from "../../components/organisms/lists/listProjects";

export default function Projects() {
  return (
    <div>
      <ListProjects />
    </div>
  );
}
