import { createBrowserRouter } from "react-router-dom";

import Api from "app/containers/Api";
import Blocks from "app/containers/Blocks";
import BlocksEdit from "app/containers/BlocksEdit";
import Builders from "app/containers/Builders";
import ContentTypes from "app/containers/Builders/ContentTypes";
import PostTypes from "app/containers/Builders/PostTypes";
import Sections from "app/containers/Builders/Sections";
import Templates from "app/containers/Builders/Templates";
import Dashboard from "app/containers/Dashboard";
import Forms from "app/containers/Forms";
import FormsEdit from "app/containers/FormsEdit";
import Intro from "app/containers/Intro";
import Login from "app/containers/Login";
import Multimedia from "app/containers/Multimedia";
import NewAccount from "app/containers/NewAccount";
import PostEdit from "app/containers/PostEdit";
import Posts from "app/containers/Posts";
import Project from "app/containers/Project";
import ProjectSettings from "app/containers/ProjectSettings";
import RequestPassword from "app/containers/RequestPassword";
import ResetPassword from "app/containers/ResetPassword";
import Settings from "app/containers/Settings";
import Translations from "app/containers/Translations";
import Users from "app/containers/Users";
import ValidateAccount from "app/containers/ValidateAccount";
import Welcome from "app/containers/Welcome";

// MIDDLEWARE
import AuthMiddleware from "app/components/middleware/auth";
import ProjectMiddleware from "app/components/middleware/project";
import WelcomeMiddleware from "app/components/middleware/welcome";

const router = createBrowserRouter([
  // INTRO
  {
    path: "/",
    element: <Intro />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "request-password",
        element: <RequestPassword />,
      },
      {
        path: "validate-account",
        element: <ValidateAccount />,
        children: [
          {
            path: ":token",
            element: <ValidateAccount />,
          },
        ],
      },
      {
        path: "new-password",
        element: <ResetPassword />,
        children: [
          {
            path: ":token",
            element: <ResetPassword />,
          },
        ],
      },
      {
        path: "new-account",
        element: <NewAccount />,
        children: [
          {
            path: ":token",
            element: <NewAccount />,
          },
        ],
      },
    ],
  },
  // PROJECT
  {
    path: "project",
    element: <ProjectMiddleware />,
    children: [
      {
        path: ":project",
        element: <Project />,
        children: [
          {
            path: "media",
            element: <Multimedia />,
          },
          {
            path: "translations",
            element: <Translations />,
          },
          {
            path: "posts/:key",
            element: <Posts />,
            children: [
              {
                path: ":post/:lang",
                element: <PostEdit />,
              },
            ],
          },
          {
            path: "blocks",
            element: <Blocks />,
            children: [
              {
                path: ":block/:lang",
                element: <BlocksEdit />,
              },
            ],
          },
          {
            path: "forms",
            element: <Forms />,
            children: [
              {
                path: ":id/:lang",
                element: <FormsEdit />,
              },
            ],
          },
          {
            path: "settings",
            element: <ProjectSettings />,
            children: [
              {
                path: "",
                element: <ProjectSettings />,
              },
              {
                path: ":key",
                element: <ProjectSettings />,
              },
            ],
          },
        ],
      },
    ],
  },
  // DASHBOARD
  {
    path: "/dashboard",
    element: <AuthMiddleware />,
    children: [
      {
        path: "",
        element: <Dashboard />,
        children: [
          {
            path: "api",
            element: <Api />,
          },
          {
            path: "users",
            element: <Users />,
          },
          {
            path: "settings",
            element: <Settings />,
          },
          {
            path: "builders",
            element: <Builders />,
            children: [
              {
                path: "sections",
                element: <Sections />,
                children: [
                  {
                    path: ":id",
                    element: <Sections />,
                  },
                ],
              },
              {
                path: "templates",
                element: <Templates />,
                children: [
                  {
                    path: ":id",
                    element: <Templates />,
                  },
                ],
              },
              {
                path: "post-types",
                element: <PostTypes />,
                children: [
                  {
                    path: ":id",
                    element: <PostTypes />,
                  },
                ],
              },
              {
                path: "content-types",
                element: <ContentTypes />,
                children: [
                  {
                    path: ":id",
                    element: <ContentTypes />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  // WELCOME
  {
    path: "welcome",
    element: <WelcomeMiddleware />,
    children: [
      {
        path: "",
        element: <Welcome />,
      },
    ],
  },
]);

export default router;
