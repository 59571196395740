import IconForbidden from "app/components/atoms/icons/IconForbidden";
import { Content } from "app/components/atoms/modal";
import Dropdown from "app/components/molecules/dropdown";
import EmailTextLayout from "app/components/organisms/builder/EmailTextLayout";
import { i18n } from "app/i18n";
import { useGetEnviromentQuery } from "app/stores/environment";
import { useUpdateFormMutation } from "app/stores/forms";
import { showServerError, showServerSuccess } from "app/utils/server";
import { NOTFOUND } from "app/utils/status";
import { isArray, objectsAreEqual } from "app/utils/validators/dataValidator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { tabs } from "./constants";
import Permission from "app/components/molecules/permission";
import { setUnsavedChanges } from "app/stores/project";

export default function EditionEmails(props) {
  const { loading } = props;

  // PARAMS
  const dispatch = useDispatch();
  const { project, lang: language } = useParams();

  // STORE
  const { form, formStatus } = useSelector((state) => state.form);
  const { unsavedChanges } = useSelector((state) => state.project);

  // STATE
  const [tab, setTab] = useState(0);
  const [value, setValue] = useState(null);
  const [emailConfig, setEmailConfig] = useState(null);
  const [dataChanged, setDataChanged] = useState(false);

  // REQUEST
  const { data: environment, isLoading: isLoadingEnv } = useGetEnviromentQuery({ key: "emailConfig" });

  // MUTATION
  const [updateForm, { isLoading: isUpdating }] = useUpdateFormMutation();

  // VARIABLES
  const content = form?.translations?.find((item) => item.language.slug === language);
  const fields = isArray(content?.structure, true) ? content?.structure?.map((e) => e.name) : [];
  const isLoading = loading || isUpdating || isLoadingEnv;

  useEffect(() => {
    if (content?.email) setValue({ admin: content.email.admin, client: content.email.client });
  }, [content]);

  useEffect(() => {
    if (project && environment) {
      let temp = {};
      if (project.emailConfig) temp = project.emailConfig;
      else temp = environment;
      setEmailConfig(temp);
    }
  }, [project, environment]);

  useEffect(() => {
    setDataChanged(isDataDifferent());
  }, [value]);

  useEffect(() => {
    if (dataChanged !== unsavedChanges) dispatch(setUnsavedChanges(dataChanged));
    return () => dispatch(setUnsavedChanges(false));
  }, [dataChanged]);

  // CHECK FOR DIFFERENT DATA
  const isDataDifferent = () => {
    return !objectsAreEqual({ admin: content.email.admin, client: content.email.client }, value);
  };

  // ON SUBMIT
  const onSave = () => {
    updateForm({ id: content.id, emails: value }).then((res) => {
      if (res.data) showServerSuccess(i18n("toast.email_config_updated"));
      else showServerError(res);
    });
  };

  const cleanChanges = () => {
    setValue({ client: content?.email?.client, admin: content?.email?.admin });
  };

  const onChangeInputs = (key, val) => {
    let temp = value ? value : {};
    temp[key] = val;
    setValue({ ...temp });
  };

  return (
    <>
      <div className="flex border-b">
        {tabs.map((item, key) => {
          let styles = {
            button: "h-12 px-6 py-1 main-transition text-sm whitespace-nowrap border-b-2 bg-white flex items-center",
          };
          if (key === tab) styles.button += " border-blue-900 text-slate-800";
          else styles.button += " border-white text-slate-400 hover:text-slate-700";
          return (
            <button key={key} className={styles.button} onClick={() => setTab(key)}>
              {item.title}
            </button>
          );
        })}
      </div>
      <div className="flex-1 overflow-auto">
        {formStatus !== NOTFOUND ? (
          <Content>
            {content ? (
              <div>
                {tab === 0 && <EmailTextLayout config={emailConfig} value={value?.client} fields={fields} onChange={(e) => onChangeInputs("client", e)} errors={null} disabled={loading} />}
                {tab === 1 && <EmailTextLayout config={emailConfig} value={value?.admin} fields={fields} onChange={(e) => onChangeInputs("admin", e)} errors={null} disabled={loading} />}
              </div>
            ) : (
              <p>{i18n("label.nothing_found")}</p>
            )}
          </Content>
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <IconForbidden width="w-32" height="h-32" className="mb-10 text-red-700" />
            <h3 className="mb-2 text-4xl font-semibold text-black uppercase">{i18n("label.page_not_found")}</h3>
            <p className="mb-10 text-md font-normal max-w-sm text-center text-slate-600">{i18n("label.page_not_found_description")}</p>
            <Link to={`/project/${project}`}>
              <button
                type="button"
                className="flex mr-1.5 items-center relative justify-center rounded-md border border-blue-800 bg-transparent px-4 py-2 text-sm font-medium text-blue-800 shadow-sm hover:bg-blue-700 hover:border-blue-700 hover:text-white focus:outline-none "
              >
                {i18n("label.return_dashboard")}
              </button>
            </Link>
          </div>
        )}
      </div>
      {formStatus !== NOTFOUND && (
        <Permission project dev min="EDITOR">
          <div className="border-t py-4 px-6 flex justify-between">
            <div>
              <Dropdown position="top-left" options={[{ title: i18n("button.clean_changes"), onClick: cleanChanges, icon: "rollback", delete: true }]} />
            </div>
            <div className="flex">
              <button
                type="button"
                onClick={onSave}
                disabled={!dataChanged || isLoading}
                className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none  disabled:bg-blue-200 disabled:cursor-not-allowed"
              >
                {i18n("button.save")}
              </button>
            </div>
          </div>
        </Permission>
      )}
    </>
  );
}
