import { gql } from "@apollo/client";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { createSlice } from "@reduxjs/toolkit";
import { serverAuth } from "app/utils/server";

const initialState = {
  projects: null,
  take: 10,
  page: 0,
  skip: 0,
  where: undefined,
  orderBy: { createdAt: "desc" },
  loading: false,
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setTake: (state, { payload }) => {
      state.take = payload;
    },
    setProjects: (state, { payload }) => {
      state.projects = payload;
    },
    setSkip: (state, { payload }) => {
      state.skip = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
      state.skip = (payload ? payload - 1 : 0) * state.take;
    },
    setOrder: (state, { payload }) => {
      state.orderBy = payload;
    },
  },
});

export const projectsApi = createApi({
  reducerPath: "projectsAPI",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    isProjectSlugAvailable: builder.query({
      query: ({ id, slug }) => ({
        document: gql`
          query projectSlugAvailable($id: String, $slug: String!) {
            projectSlugAvailable(id: $id, slug: $slug)
          }
        `,
        variables: {
          id,
          slug,
        },
      }),
      transformResponse: (response) => response.projectSlugAvailable,
    }),
    getProjects: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query projects($take: Int, $skip: Int, $where: ProjectWhere, $orderBy: ProjectOrderBy) {
            projects(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              name
              slug
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.projects,
    }),
    getMyProjects: builder.query({
      query: () => ({
        document: gql`
          query myProjects {
            myProjects {
              id
              name
              slug
              enabled
              thumbnail {
                id
                href
              }
              languages {
                enabled
                default
                published
                language {
                  name
                  slug
                }
              }
            }
          }
        `,
      }),
      transformResponse: (response) => response.myProjects,
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(projectsSlice.actions.setProjects(data));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    createProject: builder.mutation({
      query: ({ name, slug, enabled, thumbnail, languages }) => ({
        document: gql`
          mutation createProject($name: String!, $slug: String!, $enabled: Boolean!, $thumbnail: GenericConnect, $languages: [LanguageInput!]!) {
            createProject(name: $name, slug: $slug, enabled: $enabled, thumbnail: $thumbnail, languages: $languages) {
              id
            }
          }
        `,
        variables: {
          name,
          slug,
          enabled,
          thumbnail,
          languages,
        },
      }),
      transformResponse: (response) => response.createProject,
    }),
    updateProject: builder.mutation({
      query: ({ id, name, slug, enabled, thumbnail, languages }) => ({
        document: gql`
          mutation updateProject($id: String!, $name: String!, $slug: String!, $enabled: Boolean!, $thumbnail: GenericConnect, $languages: [LanguageInput!]!) {
            updateProject(id: $id, name: $name, slug: $slug, enabled: $enabled, thumbnail: $thumbnail, languages: $languages) {
              id
            }
          }
        `,
        variables: {
          id,
          name,
          slug,
          enabled,
          thumbnail,
          languages,
        },
      }),
      transformResponse: (response) => response.updateProject,
    }),
    deleteProject: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation deleteProject($id: String!) {
            deleteProject(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteProject,
    }),
  }),
});

export const { setTake, setProjects, setSkip, setOrder, setPage } = projectsSlice.actions;

export const { useGetProjectsQuery, useUpdateProjectMutation, useDeleteProjectMutation, useIsProjectSlugAvailableQuery, useCreateProjectMutation, useGetMyProjectsQuery } = projectsApi;

export default projectsSlice.reducer;
