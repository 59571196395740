import { i18n } from "app/i18n";
import { Content, Footer, Header, Struture } from "../../../atoms/modal";
import ListOccurs from "app/components/organisms/lists/listOccurs";

export default function ModalOccurs(props) {
  const { id, isOpen = false, onClose = () => {} } = props;

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={i18n("label.where_appears")} onClose={onClose} />
      <Content>
        <div>{id && <ListOccurs id={id} />}</div>
      </Content>
      <Footer cancel={{ text: i18n("button.close"), onClick: onClose }} />
    </Struture>
  );
}
