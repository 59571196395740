export default function InputChecklist(props) {
  const { name, description, multi, options, required } = props;

  const styles = {
    title: "block text-sm font-medium text-slate-700",
    description: "block text-xs font-light text-slate-400 mt-0.5 ml-2",
    required: "text-xs font-bold text-red-600",
    input: "bg-gray-50 border border-gray-300 text-gray-900 rounded transition-all duration-300 ease-in-out outline-none hover:border-gray-400 read-only:pointer-events-none",
    box: "flex items-center pl-3 rounded-lg bg-gray-50 cursor-pointer border border-gray-200",
  };

  const type = multi ? "checkbox" : "radio";

  return (
    <div>
      <div className="flex items-center justify-between mx-2">
        <div>{name && <label className={styles.title}>{name}</label>}</div>
        <div className="flex items-center justify-end">{required && <span className={styles.required}>*</span>}</div>
      </div>
      <div className="grid gap-1 grid-cols-3 md:grid-cols-4 mt-1">
        {options?.length &&
          options.map((option, key) => (
            <div className={styles.box} key={key}>
              <input type={type} readOnly={true} className={styles.input} />
              <span className="py-2 ml-2 w-full text-xs font-light text-gray-900">{option.label}</span>
            </div>
          ))}
      </div>
      {description && <span className={styles.description}>{description}</span>}
    </div>
  );
}
