// Molecules
import Select from "app/components/atoms/inputs/select";
import ListTable from "app/components/molecules/table";
import { setOrderBy, setPage, setWhere, useGetRevisionsQuery, useGetRevisionsTotalQuery } from "app/stores/post";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { headers } from "./constants";

// Inner
import ItemRevision from "./itemRevision";

export default function ListRevisions(props) {
  const { section: sectionId, content, block, sections } = props;

  const dispatch = useDispatch();
  const [section, setSection] = useState(null);
  const { take, skip, where, orderBy } = useSelector((state) => state.post);

  const { data: revisions } = useGetRevisionsQuery({ take, skip, where, orderBy });
  const { data: total } = useGetRevisionsTotalQuery({ where });

  useEffect(() => {
    if (sectionId) {
      const el = sections.find((e) => e.value === sectionId);
      if (el) setSection(el);
    }
  }, [sectionId]);

  useEffect(() => {
    if (section) {
      let temp = { content: undefined };
      const sectionContent = content.sections.find((e) => e.section.id === section.value);
      if (sectionContent) temp.content = { id: sectionContent.id };
      else temp.content = { section: { id: section.value } };
      dispatch(setWhere({ ...temp }));
    } else if (!sectionId) {
      let temp = { block: { id: content.id } };
      dispatch(setWhere({ ...temp }));
    }
  }, [section, content]);

  return (
    <div className="w-full">
      {!block && (
        <div className="flex items-center justify-end pb-2">
          <div className="w-1/3">
            <Select
              value={section}
              options={sections || []}
              onChange={(ev) => {
                setSection(ev);
                dispatch(setPage(1));
              }}
            />
          </div>
        </div>
      )}
      <div>
        <ListTable headers={headers} setOrder={setOrderBy} setPage={setPage} store="post" total={total}>
          {revisions && !!revisions.length && revisions.map((revision) => <ItemRevision {...revision} key={revision.id} />)}
        </ListTable>
      </div>

      {/* <ModalConfirm {...confirmModal} isLoading={isLoading} /> */}
    </div>
  );
}
