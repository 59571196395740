import { i18n } from "app/i18n";
import customValidator from "app/utils/validators/customValidator";
import validator from "validator";

const components = [
  {
    placeholder: i18n("label.password"),
    key: "password",
    type: "Password",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.empty_password"),
      },
      {
        method: customValidator.isPasswordStrong,
        validWhen: true,
        message: i18n("input.invalid_password"),
      },
    ],
  },
  {
    placeholder: i18n("label.confirmPassword"),
    key: "confPassword",
    type: "Password",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.empty_password"),
      },
      {
        method: customValidator.isEqualTo,
        args: { key: "password" },
        validWhen: true,
        message: i18n("input.dontMatch_password"),
      },
    ],
  },
];

export { components };
