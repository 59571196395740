import React from "react";
import { i18n } from "app/i18n";

export default function Content(props) {
  const { children, tabs, tab, noPad, rightSide } = props;

  const styles = {
    padding: "px-12 py-10",
  };

  if (noPad) styles.padding = "";
  if (rightSide) styles.padding = "px-8 py-10";

  if (tabs)
    return (
      <div className={styles.padding}>
        {children ? (
          children
            .filter((e) => !!e)
            .map((child, index) => (
              <div key={String(index)} className={`${tab === index ? "block" : "hidden"}`}>
                {child}
              </div>
            ))
        ) : (
          <span className="block text-sm text-gray-800">{i18n("label.no_content_to_display")}</span>
        )}
      </div>
    );
  if (noPad) return <div>{children ? children : i18n("label.no_content_to_display")}</div>;
  return <div className={styles.padding}>{children ? children : i18n("label.no_content_to_display")}</div>;
}
