import { i18n } from "app/i18n";

export const components = [
  { type: "Multimedia", noAlt: true, title: i18n("setting.email_image"), key: "image" },
  { type: "Color", key: "backgroundColor", title: i18n("setting.email_background_color"), format: "simple" },
  { type: "Color", key: "titleColor", title: i18n("setting.email_title_color"), format: "simple" },
  { type: "Color", key: "textColor", title: i18n("setting.email_text_color"), format: "simple" },
  { type: "Color", key: "boxColor", title: i18n("setting.email_box_color"), format: "simple" },
  { type: "Color", key: "buttonColor", title: i18n("setting.email_button_color"), format: "simple" },
  { type: "Color", key: "buttonTextColor", title: i18n("setting.email_button_text_color"), format: "simple" },
  { type: "Color", key: "copyrightColor", title: i18n("setting.email_copyright_color"), format: "simple" },
];
