import { Listbox, Transition } from "@headlessui/react";
import Icon from "app/components/atoms/icons/icon";
import { i18n } from "app/i18n";
import { Fragment } from "react";

export default function SelectBasic(props) {
  const { value, label, multiple, clearable, disabled, options, placement = "bottom", error, onChange, createButton } = props;

  const clearButton = () => {
    if (!value || !clearable) return null;

    return (
      <span
        type="button"
        disabled={disabled}
        className="cursor-pointer absolute inset-y-0 right-7 flex items-center pr-2"
        onClick={(ev) => {
          ev.stopPropagation();
          onChange(undefined);
        }}
      >
        <Icon name="close" className="text-gray-400" />
      </span>
    );
  };

  let styles = {
    button:
      "relative w-full cursor-pointer text-sm rounded-lg bg-gray-50 border py-2.5 pl-2.5 pr-10 text-left ring-0 !outline-none transition-all ease-in-out duration-300 disabled:pointer-events-none",
    options: "absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
  };

  if (placement === "top") styles.options += " mb-1 bottom-full";
  else styles.options += " mt-1 top-full";

  if (error) styles.button += " border-red-600 hover:border-red-600 focus:border-red-600 focus-visible:border-0 aria-expanded:border-red-600";
  else styles.button += " border-gray-300 hover:border-gray-400 focus:border-blue-700 focus-visible:border-0 aria-expanded:border-blue-700";

  return (
    <Listbox value={value} onChange={onChange} multiple={multiple} disabled={disabled}>
      <div className="relative">
        <Listbox.Button className={styles.button}>
          <span className="block truncate" dangerouslySetInnerHTML={{ __html: label }} />
          {clearButton()}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <Icon name="chevronUpDown" className="text-gray-400" />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className={styles.options}>
            {!!options?.length ? (
              options.map((option) => (
                <Listbox.Option
                  value={option}
                  key={option.value}
                  className={({ active }) => `relative cursor-pointer select-none py-2 pl-10 pr-4 transition-all ease-in-out duration-300 ${active ? "bg-blue-100 text-blue-900" : "text-gray-900"}`}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`} dangerouslySetInnerHTML={{ __html: option.label || "" }} />
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                          <Icon name="check" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))
            ) : (
              <div className="relative cursor-default select-none py-2 pl-10 pr-4 text-gray-700">{i18n("label.nothing_found")}</div>
            )}
            {createButton}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
