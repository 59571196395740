export default function InputFile(props) {
  const { name, options, description, placeholder, required } = props;

  const styles = {
    title: "block text-sm font-medium text-slate-700",
    description: "block text-xs font-light text-slate-400 mt-0.5 ml-2",
    required: "text-xs font-bold text-red-600",
    input: "block w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg transition-all duration-300 ease-in-out outline-none hover:border-gray-400",
  };

  return (
    <div>
      <div className="flex items-center justify-between mx-2">
        <div>{name && <label className={styles.title}>{name}</label>}</div>
        <div className="flex items-center justify-end">{required && <span className={styles.required}>*</span>}</div>
      </div>
      <select placeholder={placeholder} className={styles.input} defaultValue="">
        <option value="">{placeholder}</option>
        {!!options?.length &&
          options.map((option, key) => (
            <option key={key} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
      {description && <span className={styles.description}>{description}</span>}
    </div>
  );
}
