import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import App from "./app/containers/App";
import { store } from "./app/store";
import i18n from "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import router from "app/utils/router";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <DndProvider backend={HTML5Backend}>
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <RouterProvider router={router}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </RouterProvider>
        </Provider>
      </I18nextProvider>
    </React.StrictMode>
  </DndProvider>
);

reportWebVitals();
