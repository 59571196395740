import React from "react";

import { Dialog } from "@headlessui/react";

import Icon from "../../icons/icon";

export default function Header(props) {
  const { title, subtitle, rightSide, onClose } = props;

  let styles = {
    block: "flex items-center justify-between",
    title: "",
  };

  if (rightSide) {
    styles.title += "text_xl";
    styles.block += " px-8 pt-8 pb-6";
  } else {
    styles.title += "text_2xl";
    styles.block += " px-6 h-20 border-b border-primary-50";
  }

  return (
    <div className={styles.block}>
      <div className="flex flex-col">
        <Dialog.Title as="h3" className={styles.title}>
          {title}
        </Dialog.Title>
        {subtitle && <p className="text-xs text-gray-400">{subtitle}</p>}
      </div>

      {onClose && (
        <button className="btn-icon" onClick={onClose}>
          <Icon name="close" height="h-6" width="w-6" />
        </button>
      )}
    </div>
  );
}
