export default function InputTextarea(props) {
  const { name, type, description, placeholder, required } = props;

  const styles = {
    title: "block text-sm font-medium text-slate-700",
    description: "block text-xs font-light text-slate-400 mt-0.5 ml-2",
    input:
      "block w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg transition-all duration-300 ease-in-out outline-none hover:border-gray-400 read-only:pointer-events-none resize-none",
  };

  return (
    <div>
      <div className="flex items-center justify-between mx-2">
        <div>{name && <label className={styles.title}>{name}</label>}</div>
        <div>{required && <span className="text-red-600">*</span>}</div>
      </div>
      <textarea type={type} readOnly={true} placeholder={placeholder} className={styles.input}></textarea>
      {description && <span className={styles.description}>{description}</span>}
    </div>
  );
}
