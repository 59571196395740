import Icon from "app/components/atoms/icons/icon";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalUser from "app/components/molecules/modals/modalUser";
import Permission from "app/components/molecules/permission";
import ListTable from "app/components/molecules/table";
import { i18n } from "app/i18n";
import { setOrderBy, setPage, setWhere, useCreateUserMutation, useDeleteUserMutation, useGetTotalQuery, useGetUsersQuery, useUpdateUserMutation } from "app/stores/users";
import { showServerError, showServerSuccess } from "app/utils/server";
import customValidator from "app/utils/validators/customValidator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ItemUser from "../../lists/listUsers/itemUser";
import { headers } from "./constants";

export default function TabUsers() {
  // STORE
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.project);
  const { take, skip, where, orderBy } = useSelector((state) => state.user);

  // STATE
  const [modal, setModal] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);

  // REQUESTS
  const { data: total, refetch: updateTotal } = useGetTotalQuery({ where });
  const { data: users, refetch: updateList } = useGetUsersQuery({ take, skip, where, orderBy, noAttach: true });

  // MUTATION
  const [createUser, { isLoading: isCreating }] = useCreateUserMutation();
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
  const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation();

  // VARS
  const isLoading = isCreating || isUpdating || isDeleting;

  useEffect(() => {
    if (project) dispatch(setWhere({ projects: { some: { project: { id: project.id } } } }));
    return () => dispatch(setWhere(undefined));
  }, [project]);

  // WHEN CREATE A NEW PROJECT USER
  const onCreate = () => {
    // VALIDATE IF EMAIL ALREADY EXISTS
    setModal({
      isOpen: true,
      idProject: project.id,
      insideProject: true,
      onClose: () => setModal(null),
      onSubmit: (data) => {
        createUser(data).then((res) => {
          if (res?.data) {
            updateList();
            updateTotal();
            setModal(null);
            showServerSuccess(i18n("toast.success_add"));
          } else {
            showServerError(res);
          }
        });
      },
    });
  };
  // WHEN EDIT DATA OF USER
  const onEdit = (user) => {
    setModal({
      user,
      isOpen: true,
      idProject: project.id,
      insideProject: true,
      buttonText: i18n("button.update"),
      onClose: () => setModal(null),
      onSubmit: (data) => {
        updateUser({ ...data, id: user.id, inside: project.id }).then((res) => {
          if (res.data) {
            updateList();
            setModal(null);
            showServerSuccess(i18n("toast.success_update"));
          }
          showServerError(res);
        });
      },
    });
  };
  // REMOVE USER FROM PROJECT
  const onDelete = (user) => {
    let title = i18n("alert.delete_user");
    let text = i18n("alert.delete_account");
    let inputs = undefined;
    let mode = "REQUIRED";

    if (user?.hasData) {
      title = i18n("alert.delete_user_with_transfer");
      text = i18n("alert.delete_account_with_transfer");
      mode = "INPUTS";
      inputs = [
        {
          key: "user",
          type: "Select",
          title: "Select User",
          placement: "top",
          options: users.filter((e) => e.id !== user.id).map((user) => ({ value: user.id, label: user.name })),
          rules: [
            {
              method: customValidator.isObjectEmpty,
              validWhen: false,
              message: i18n("input.required_field"),
            },
          ],
        },
      ];
    }

    setConfirmModal({
      type: "DELETE",
      mode,
      isOpen: true,
      inputs,
      title,
      text,
      buttonText: i18n("button.delete"),
      onClose: () => {
        setConfirmModal(null);
      },
      onConfirm: (data) => {
        deleteUser({ id: user.id, user: data?.value, permanently: false, inside: project.id }).then((res) => {
          if (res?.data) {
            updateList();
            updateTotal();
            showServerSuccess(i18n("toast.success_delete"));
          } else {
            showServerError(res);
          }
          setConfirmModal(null);
        });
        // setConfirmModal(null);
      },
    });
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-3xl font-bold tracking-tight text-slate-900">{i18n("label.users")}</h1>
          <p className="mt-1 text-sm text-slate-500">{i18n("label.users.tab.description")}</p>
        </div>
        <div>
          <Permission project dev equal="ADMINISTRATOR">
            <button type="button" className="btn-primary-outline" onClick={onCreate}>
              <Icon name="plus" />
              {i18n("button.new_user")}
            </button>
          </Permission>
        </div>
      </div>
      <div className="divide-y-slate-200 mt-6 space-y-8 divide-y">
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
          <div className="sm:col-span-6">
            <div className="mt-6">
              <ListTable headers={headers} setOrder={setOrderBy} setPage={setPage} store="user" total={total}>
                {!!users?.length && users.map((user) => <ItemUser {...user} project={project?.id} key={user.id} onEdit={() => onEdit(user)} onDelete={() => onDelete(user)} />)}
              </ListTable>
            </div>
          </div>
        </div>
      </div>

      <ModalUser {...modal} isLoading={isLoading} />
      <ModalConfirm {...confirmModal} isLoading={isLoading} />
    </>
  );
}
