import Loading from "app/components/atoms/loading";
import Dropdown from "app/components/molecules/dropdown";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalPreview from "app/components/molecules/modals/modalPreview";
import ModalSection from "app/components/molecules/modals/modalSection";
import BuilderConstructor from "app/components/organisms/builder";
import HistoryBuilder from "app/components/organisms/builder/History";
import { i18n } from "app/i18n";
import { setActiveHistory, useDeleteSectionMutation, useGetSectionQuery, useUpdateSectionMutation } from "app/stores/builder";
import { getSource } from "app/utils/content";
import { showServerError } from "app/utils/server";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export default function SectionEdit(props) {
  const { id, reloadList } = props;

  // PARAMS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // STATE
  const [timer, setTimer] = useState(null);
  const [modal, setModal] = useState(null);
  const [structure, setStructure] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);
  const [previewModal, setPreviewModal] = useState(null);

  // STORE
  const { active, history } = useSelector((store) => store.builder);

  // REQUESTS
  const { data: section, isFetching, isLoading: isGetting, refetch } = useGetSectionQuery({ id });
  const [updateSection, { isLoading: isUpdating, isError }] = useUpdateSectionMutation();
  const [deleteSection, { isLoading: isDeleting }] = useDeleteSectionMutation();

  // VARS
  const isLoading = isDeleting || isUpdating || isGetting;

  useEffect(() => {
    refetch();
    return () => {
      if (structure) setStructure(null);
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (section) setStructure(section?.structure || []);
  }, [section]);

  // UPDATE FROM HISTORY
  const onUseHistory = (isNext) => {
    if (!history) return null;
    if (history?.length < 2) return null;

    const direction = isNext ? -1 : 1;
    const newActive = active + direction;

    if (newActive >= history.length) return null;
    if (newActive < 0) return null;

    if (history.at(newActive)) {
      setStructure(history.at(newActive));
      dispatch(setActiveHistory(newActive));

      // STOP TIMER IF EXISTS
      if (timer) clearTimeout(timer);

      // UPDATE IN DATABASE
      const newTimer = setTimeout(() => {
        onUpdate(history.at(newActive), true);
      }, 1400);

      // SET NEW TIMER
      setTimer(newTimer);
    }
  };

  // UPDATE FROM BUILDER
  const onUpdate = (structure, history) => {
    updateSection({ id: section.id, structure, history }).then((res) => {
      if (res?.data) {
        if (!history) setStructure(res?.data?.structure);
      } else {
        showServerError(res);
      }
    });
  };

  // EDIT SECTION SETTINGS
  const onEdit = () => {
    setModal({
      section,
      isOpen: true,
      buttonText: i18n("button.update_section"),
      onSubmit: (data) => {
        updateSection({ id: section.id, title: data.title, key: data.key, thumbnail: data.thumbnail }).then((res) => {
          if (res && res.data) {
            reloadList();
          } else {
            showServerError(res);
          }

          setModal(null);
        });
      },
      onClose: () => setModal(null),
    });
  };

  // DROP ALL INPUTS IN STRUCTURE
  const onEmptize = () => {
    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      title: i18n("label.clean_all_inputs"),
      forceNotice: section.inUse,
      notice: i18n("alert.action_clean_page"),
      onConfirm: () => {
        updateSection({ id: section.id, structure: [] }).then((res) => {
          if (res && res.data) {
            //  refetch();
          } else {
            showServerError(res);
          }
          setConfirmModal(null);
        });
      },
      onClose: () => setConfirmModal(null),
    });
  };

  // DELETE SECTION
  const onDelete = () => {
    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      buttonText: i18n("button.delete"),
      title: i18n("label.delete_section"),
      text: i18n("alert.section_delete"),
      forceNotice: section.inUse,
      notice: i18n("alert.section_in_use"),
      onConfirm: () => {
        deleteSection({ id: section.id }).then((res) => {
          if (res && res.data) {
            reloadList();
            navigate("/dashboard/builders/sections");
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setConfirmModal(null),
    });
  };

  // OPEN THUMBNAIL
  const onOpenPreview = () => {
    setPreviewModal({
      isOpen: true,
      href: getSource(section?.thumbnail?.href),
      onClose: () => setPreviewModal(null),
    });
  };

  if (!id)
    return (
      <div>
        <p>{i18n("sections.noItems")}</p>
      </div>
    );

  if (isFetching) return <Loading active className="h-full flex items-center justify-center" />;

  return (
    <>
      <BuilderConstructor showFavorites refetch={refetch} isError={isError} onUpdate={onUpdate} isLoading={isLoading} data={{ ...section, structure }} placeholder={i18n("label.your_inputs_here")}>
        <HistoryBuilder active={active} length={history?.length || 0} onNext={() => onUseHistory(true)} onPrev={() => onUseHistory(false)} />
        {section?.thumbnail?.href && (
          <button className="btn-primary-outline mx-2" onClick={onOpenPreview}>
            {i18n("label.preview")}
          </button>
        )}
        <Dropdown
          options={[
            { title: i18n("button.edit"), onClick: onEdit, icon: "edit" },
            { title: i18n("button.cleanStructure"), onClick: onEmptize, icon: "clean" },
            { title: i18n("button.delete"), onClick: onDelete, icon: "delete", delete: true },
          ]}
        />
      </BuilderConstructor>

      <ModalSection {...modal} />
      <ModalPreview {...previewModal} />
      <ModalConfirm {...confirmModal} />
    </>
  );
}
