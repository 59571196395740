import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { projectRoles, roles } from "app/utils/roles";

export function HasPermission(props) {
  const { min, max, equal, project, dev } = props;
  const { user } = useSelector((state) => state.auth);
  const { project: proj } = useSelector((state) => state.project);

  // IF DON'T AUTH
  if (!user) return false;

  // ALL AVAILABLE FOR SUPER
  if (user.role === "SUPER") return true;

  // PERMISSION FOR DEVELOPER
  if (dev && user.role === "DEVELOPER") return true;

  // IF PROJECT RESTRICTED
  if (project && !user.projects.find((item) => item.project.id === proj?.id)) return false;

  // IF DON'T HAVE PROJECT PERMISSION
  if (project) {
    const userProjectRole = user.projects.find((item) => item.project.id === proj?.id).role;

    // MIN
    if (min && projectRoles[userProjectRole] < projectRoles[min]) return false;

    // MAX
    if (max && projectRoles[userProjectRole] > projectRoles[max]) return false;

    // EQUAL
    if (equal && projectRoles[userProjectRole] !== projectRoles[equal]) return false;
  } else {
    // MIN
    if (min && roles[user.role] < roles[min]) return false;

    // MAX
    if (max && roles[user.role] > roles[max]) return false;

    // EQUAL
    if (equal && roles[user.role] !== roles[equal]) return false;
  }

  return true;
}

export default function Permission(props) {
  const { min, max, equal, project, dev, children } = props;
  const { user } = useSelector((state) => state.auth);
  const { project: proj } = useSelector((state) => state.project);

  // IF DON'T AUTH
  if (!user) return null;

  // ALL AVAILABLE FOR SUPER
  if (user.role === "SUPER") return children;

  // PERMISSION FOR DEVELOPER
  if (dev && user.role === "DEVELOPER") return children;

  // IF PROJECT RESTRICTED
  if (project && !user.projects.find((item) => item.project.id === proj?.id)) return null;

  // IF DON'T HAVE PROJECT PERMISSION
  if (project) {
    const userProjectRole = user.projects.find((item) => item.project.id === proj?.id).role;

    // MIN
    if (min && projectRoles[userProjectRole] < projectRoles[min]) return null;

    // MAX
    if (max && projectRoles[userProjectRole] > projectRoles[max]) return null;

    // EQUAL
    if (equal && projectRoles[userProjectRole] !== projectRoles[equal]) return null;
  } else {
    // MIN
    if (min && roles[user.role] < roles[min]) return null;

    // MAX
    if (max && roles[user.role] > roles[max]) return null;

    // EQUAL
    if (equal && roles[user.role] !== roles[equal]) return null;
  }

  return <>{children}</>;
}

Permission.propTypes = {
  min: PropTypes.oneOf(["ADMINISTRATOR", "USER", "MANAGER", "EDITOR", "CONTRIBUTOR", "DEVELOPER", "SUPER"]),
  max: PropTypes.oneOf(["ADMINISTRATOR", "USER", "MANAGER", "EDITOR", "CONTRIBUTOR", "DEVELOPER", "SUPER"]),
  equal: PropTypes.oneOf(["ADMINISTRATOR", "USER", "MANAGER", "EDITOR", "CONTRIBUTOR", "DEVELOPER", "SUPER"]),
  project: PropTypes.bool,
  dev: PropTypes.bool,
  children: PropTypes.element.isRequired,
};
