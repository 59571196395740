import React from "react";
import slugify from "slugify";
import Text from "../text";

const symbols = ["\\$", "€", "%", "&", "ª", "º", "\\|", "±", "§", "\\<", "\\>"];

export default function Slug(props) {
  const { options, onChange, mode } = props;

  const replaceString = (replacement, text) => {
    if (!replacement || !replacement.length) return text;
    let regex,
      replaceString = text;
    for (var i = 0; i < replacement.length; i++) {
      regex = new RegExp(replacement[i], "g");
      replaceString = replaceString.replace(regex, "");
    }
    return replaceString;
  };

  const handleChange = (val) => {
    let settings = { lower: true, replacement: "-", remove: /[*+~.()'"/!:@]/g, trim: true };
    if (options) {
      if (typeof options.lower !== "undefined") settings.lower = options.lower;
      if (typeof options.replacement !== "undefined") settings.replacement = options.replacement;
      if (typeof options.remove !== "undefined") settings.remove = options.remove;
      if (typeof options.trim !== "undefined") settings.trim = options.trim;
      if (typeof options.fullRemove !== "undefined" && options.fullRemove) settings.remove = /[$*+~^_.,ªº#()'"!?:;@\\/=]/g;
      if (typeof options.fullRemove !== "undefined" && options.fullRemove) val = replaceString(symbols, val);
    }
    onChange(slugify(val, settings));
  };

  return <Text {...props} type="text" onChange={handleChange} mode={mode} />;
}
