import Loading from "app/components/atoms/loading";
import { i18n } from "app/i18n";
import PasswordStrength from "app/components/molecules/passwordStrength";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import validator from "validator";
import Form from "../../components/organisms/form";
import { useResetPasswordMutation } from "../../stores/auth";
import { validation } from "../../utils/validators";
import customValidator from "../../utils/validators/customValidator";
import { secureHost } from "app/utils/server";

export default function ResetPassword() {
  let { token } = useParams();
  const { buttonColor } = useOutletContext();

  const components = [
    {
      type: "Text",
      key: "email",
      placeholder: "Email",
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.empty_email"),
        },
        {
          method: validator.isEmail,
          validWhen: true,
          message: i18n("input.invalid_email"),
        },
      ],
    },
    {
      key: "password",
      type: "Password",
      placeholder: "Password",
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.empty_password"),
        },
        {
          method: customValidator.isPasswordStrong,
          validWhen: true,
          message: i18n("input.invalid_password"),
        },
      ],
    },
    {
      placeholder: "Confirm Password",
      key: "confPassword",
      type: "Password",
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.empty_checkPassword"),
        },
        {
          method: customValidator.isEqualTo,
          args: { key: "password" },
          validWhen: true,
          message: i18n("input.dontMatch_password"),
        },
      ],
    },
  ];

  const navigate = useNavigate();

  // COOKIES
  const [{ passwordtoken }, setCookie, removeCookie] = useCookies(["passwordtoken"]);

  // STATE
  const [value, setValue] = useState(null);
  const [formMessage, setFormMessage] = useState(null);
  const [structure, setStructure] = useState(components);

  // REQUEST
  const [resetPassword, { data, isLoading: isResetting }] = useResetPasswordMutation();

  const loading = isResetting;

  const onSubmit = () => {
    if (!passwordtoken || passwordtoken === "") {
      setValue(null);
      setFormMessage({ status: false, message: "no.token" });
    } else {
      validation(structure, value, (structure, validation) => {
        setStructure([...structure]);
        if (validation.isValid || !token)
          resetPassword({ password: value.password, email: value.email, token: passwordtoken }).then((res) => {
            if (res && res.data && !res.data.error) {
              setValue(null);
              removeCookie("passwordtoken");
              navigate("/login?password=ok", { replace: true });
            } else {
              setFormMessage({ status: !data.error, message: i18n("user.request_error") });
            }
          });
      });
    }
  };

  useEffect(() => {
    if (token) {
      const secure = secureHost();
      setCookie("passwordtoken", token, { secure });
      navigate("/new-password");
    }
  }, [token]);

  return (
    <div>
      <div className="mb-8">
        <h1 className="text-xl text-[#0c1832]">{i18n("resetPassword.title")}</h1>
        <p className="text-md text-[#627385]">{i18n("resetPassword.description")}</p>
      </div>

      <Form value={value} onChange={setValue} structure={structure} disabled={loading} mode={"login"} />

      <PasswordStrength withInfo hidden={data} disabled={isResetting} value={value?.password} />

      {formMessage && (
        <div className={`relative w-full p-2 rounded-sm bg-white/40 shadow-md border-b-2 ${formMessage.status ? "text-green-700  border-green-700" : "text-red-700  border-red-700"}`} role="alert">
          <span className="text-xs font-normal">{formMessage.message}</span>
        </div>
      )}

      <div className="flex justify-between items-center flex-wrap mt-6">
        <div className="flex items-center mt-2 mb-2 mr-6">
          <button
            type="button"
            onClick={onSubmit}
            style={{ backgroundColor: buttonColor || undefined }}
            disabled={isResetting || !passwordtoken || (data && !data.error)}
            className="inline-block px-7 py-3 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition-all duration-500 ease-in-out disabled:pointer-events-none disabled:!cursor-not-allowed disabled:!shadow-md bg-[#182e5f] disabled:!opacity-30 hover:!bg-[#0c1832] focus:bg-[#0c1832] active:bg-[#0c1832]"
          >
            {i18n("button.resetPassword")}
          </button>
          <Loading active={loading} className="ml-2" />
        </div>

        <Link to={"/login"} className="text-gray-800 text-sm transition-all ease-in-out duration-500 hover:!text-[#0c1832] mt-2 mb-2" style={{ color: buttonColor || undefined }}>
          {i18n("button.backToLogin")}
        </Link>
      </div>
    </div>
  );
}
