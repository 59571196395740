import { memo } from "react";
import { useDrag, useDrop } from "react-dnd";
import Icon from "../../icons/icon";
import Tooltip from "../../tooltip";
import { getSource } from "app/utils/content";

const Item = memo(function (props) {
  const { id, tag, alt, path, caption, active, onMoveItem, findItem, onSelect, onRemove } = props;

  const originalIndex = findItem(id).index;

  // FUNCTION FOR WHEN IT IS PICKED
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "Item",
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) onMoveItem(droppedId, originalIndex);
      },
    }),
    [id, originalIndex, onMoveItem]
  );

  // FUNCTION FOR WHEN IT IS DROPPED
  const [, drop] = useDrop(
    () => ({
      accept: "Item",
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findItem(id);
          onMoveItem(draggedId, overIndex);
        }
      },
    }),
    [findItem, onMoveItem]
  );

  // CHANGE OPACITY OF ELEMENT WHEN DRAGGED
  const opacity = isDragging ? 0 : 1;

  return (
    <div ref={(node) => drag(drop(node))} style={{ opacity }} className="relative">
      <div className={`group relative rounded-md overflow-hidden ${active ? "ring-4 ring-blue-600 ring-offset-2" : ""}`}>
        <img className="h-28 aspect-video object-cover transition-all duration-200 ease-linear group-hover:scale-110" src={getSource(path)} alt={alt} />
        <div className="scale-0 absolute inset-0 flex gap-2 items-center justify-center transition-all duration-200 ease-linear group-hover:scale-100">
          <button className="btn-icon shadow-sm bg-white hover:text-white hover:bg-primary-400 h-8 w-8" onClick={() => onSelect({ id, tag, path, alt, caption })}>
            <Icon name="pencil" height="h-4" width="w-4" />
          </button>
          <button className="btn-icon shadow-sm bg-white hover:text-white hover:bg-red-700 h-8 w-8" onClick={() => onRemove({ id, tag })}>
            <Icon name="delete" height="h-4" width="w-4" />
          </button>
        </div>
      </div>
      {!isDragging && (
        <div className="absolute bottom-1 left-1 flex gap-1">
          {alt && (
            <Tooltip message={alt}>
              <span className="text-[10px] relative bg-white/60 py-0.5 px-1 rounded-sm transition-all duration-300 ease-linear hover:bg-white">ALT</span>
            </Tooltip>
          )}
          {caption && (
            <Tooltip message={caption}>
              <span className="text-[10px] relative bg-white/60 py-0.5 px-1 rounded-sm transition-all duration-300 ease-linear hover:bg-white">CAPTION</span>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
});

export default Item;
