import { i18n } from "app/i18n";
import validator from "validator";

const components = [
  {
    tab: "config",
    type: "Toggle",
    title: i18n("input.use_specific_email_config"),
    description: i18n("input.use_specific_email_config_text"),
    key: "active",
  },
  {
    tab: "config",
    type: "Text",
    title: i18n("label.host"),
    key: "host",
    rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }],
    conditional: {
      field: "active",
      when: "isTrue",
    },
  },
  {
    tab: "config",
    type: "Text",
    title: i18n("label.port"),
    key: "port",
    rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }],
    conditional: {
      field: "active",
      when: "isTrue",
    },
  },
  {
    tab: "config",
    type: "Toggle",
    title: i18n("label.secure"),
    key: "secure",
    conditional: {
      field: "active",
      when: "isTrue",
    },
  },
  {
    tab: "config",
    type: "Toggle",
    title: i18n("label.reject_unauthorized"),
    key: "rejectUnauthorized",
    conditional: {
      field: "active",
      when: "isTrue",
    },
  },
  {
    tab: "config",
    type: "Text",
    title: i18n("label.user"),
    key: "user",
    rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }],
    conditional: {
      field: "active",
      when: "isTrue",
    },
  },
  {
    tab: "config",
    type: "Password",
    title: i18n("label.password"),
    key: "password",
    rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }],
    conditional: {
      field: "active",
      when: "isTrue",
    },
  },
  {
    tab: "config",
    component: "line",
    conditional: {
      field: "active",
      when: "isTrue",
    },
  },
  {
    tab: "config",
    type: "Text",
    title: i18n("label.name"),
    key: "name",
    rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }],
    conditional: {
      field: "active",
      when: "isTrue",
    },
  },
  {
    tab: "config",
    type: "Text",
    title: i18n("label.email"),
    key: "email",
    rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }],
    conditional: {
      field: "active",
      when: "isTrue",
    },
  },
  {
    tab: "visual",
    type: "Toggle",
    title: i18n("input.use_specific_email_visual"),
    description: i18n("input.use_specific_email_visual_text"),
    key: "visualActive",
  },
  { tab: "visual-open", type: "Multimedia", title: i18n("setting.email_image"), key: "image", noAlt: true, description: "Recommended: 600x338 pixeis" },
  { tab: "visual-open", type: "Color", key: "backgroundColor", title: i18n("setting.email_background_color"), format: "simple" },
  { tab: "visual-open", type: "Color", key: "titleColor", title: i18n("setting.email_title_color"), format: "simple" },
  { tab: "visual-open", type: "Color", key: "textColor", title: i18n("setting.email_text_color"), format: "simple" },
  { tab: "visual-open", type: "Color", key: "boxColor", title: i18n("setting.email_box_color"), format: "simple" },
  { tab: "visual-open", type: "Color", key: "buttonColor", title: i18n("setting.email_button_color"), format: "simple" },
  { tab: "visual-open", type: "Color", key: "buttonTextColor", title: i18n("setting.email_button_text_color"), format: "simple" },
  { tab: "visual-open", type: "Color", key: "copyrightColor", title: i18n("setting.email_copyright_color"), format: "simple" },
];

export { components };
