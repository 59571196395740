import { Menu, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/outline";
import Loading from "app/components/atoms/loading";
import { setProject } from "app/stores/project";
import { useGetMyProjectsQuery } from "app/stores/projects";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Account from "../../account";
import { i18n } from "app/i18n";
import Icon from "app/components/atoms/icons/icon";

export default function ProjectHeader(props) {
  const { toggleLanguage } = props;

  const dispatch = useDispatch();

  const { project, unsavedChanges, sidebarChanges, headerChanges } = useSelector((state) => state.project);
  const { data: projects, isLoading } = useGetMyProjectsQuery();

  const runPrompt = unsavedChanges || sidebarChanges || headerChanges;

  return (
    <header className="w-full">
      <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
        {/* <button
      type="button"
      className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none md:hidden"
      onClick={() => setMobileMenuOpen(true)}
    >
      <span className="sr-only">Open sidebar</span>
      <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
    </button> */}
        <div className="flex flex-1 justify-between items-center px-4 sm:px-6">
          <Menu as="div" className="relative inline-block">
            <div>
              <Menu.Button className="rounded-md pr-4 py-2 font-medium transition-all duration-500 flex items-center gap-4 justify-center hover:text-indigo-700">
                <Icon name="chevronLeft" />
                <div>{project && <span>{project.name}</span>}</div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute -left-1 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1">
                  {isLoading && "loading..."}
                  {projects?.length &&
                    projects.map((item, key) => (
                      <Menu.Item key={key}>
                        {({ active }) => (
                          <NavLink
                            to={`/project/${item.id}`}
                            className={`${active ? "bg-[#0056FF] text-white" : "text-gray-900"} ${
                              project && item.id === project.id ? "bg-[#0056FF] text-white pointer-events-none" : ""
                            } group flex w-full items-center rounded-md px-2 py-2 mb-1 text-sm transition-all duration-300 ease-in-out`}
                          >
                            {item.name}
                          </NavLink>
                        )}
                      </Menu.Item>
                    ))}
                </div>
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <NavLink
                        to={"/dashboard"}
                        onClick={() => dispatch(setProject(null))}
                        className={`${active ? "bg-[#0056FF] text-white" : "text-gray-900"} group flex w-full items-center rounded-md px-2 py-2 text-sm transition-all duration-300 ease-in-out`}
                      >
                        {i18n("button.back_projects")}
                      </NavLink>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <div className="flex flex-1 group relative">
            {runPrompt && <div className="h-2 w-2 rounded-full bg-blue-700" />}
            {/* <form className="flex w-full md:ml-0" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                {i18n("label.search_all")}
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                  <MagnifyingGlassIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                </div>
                <input
                  type="search"
                  id="search-field"
                  name="search-field"
                  placeholder={i18n("label.search")}
                  className="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0"
                />
              </div>
            </form>
            <div className="hidden absolute top-full mt-4 p-4 w-full bg-white shadow-lg overflow-scroll max-h-60 group-focus-within:block">
              <p>{i18n("label.type_for_search")}</p>
              <div className="w-full">
                <Loading active className="w-full text-center" />
              </div>
            </div> */}
          </div>
          <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
            {/* <button type="button" className="flex items-center justify-center rounded-full bg-[#0056FF] p-1 text-white hover:bg-indigo-700 focus:outline-none transition-all duration-500">
              <PlusIcon className="h-6 w-6" aria-hidden="true" />
              <span className="sr-only">{i18n("label.add_file")}</span>
            </button> */}

            {/* Profile dropdown */}
            <Account toggleLanguage={toggleLanguage} />
          </div>
        </div>
      </div>
    </header>
  );
}
