import React, { useState } from "react";
import Editor from "../editor";
import Textarea from "../textarea";
import IconEye from "../../icons/iconEye";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export default function Text(props) {
  const {
    title,
    value = "",
    type = "text",
    readOnly = false,
    editor,
    multiline,
    onBlur,
    onFocus,
    onChange,
    placeholder,
    autoComplete,
    disabled,
    error,
    errorMessage,
    onKeyUp,
    onKeyDown,
    mode,
    description,
    isRequired,
  } = props;

  //showPassword variable
  const [inputType, setInputType] = useState(type);
  const [isPasswordShow, setShowPassword] = useState(false);

  if (editor) return <Editor {...props} />;
  if (multiline) return <Textarea {...props} />;

  let styles = {
    div: "block relative",
    input: "!focus-visible:border-none !outline-none !ring-0 disabled:text-gray-400 disabled:pointer-events-none",
    passwordIcon: "absolute text-sm disabled:pointer-events-none",
    description: "block",
    title: "block text-sm font-medium text-slate-700 ",
  };

  if (mode === "login") {
    styles.input += ` peer block min-h-[auto] w-full bg-transparent ring-0 border-0 border-b outline-none border-b-[#b8c9ca] text-gray-900 text-sm px-3 transition-all duration-200 ease-linear focus:ring-0 focus:border-b-[#8f9eb1] focus:outline-none focus:placeholder:opacity-100 disabled:opacity-25  peer-focus:text-primary  data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0`;
    styles.div += " mb-8";
    styles.passwordIcon += " top-1.5 right-2";
    styles.description += " text-[11px] font-light text-slate-400 pt-1 ml-3 mb-2";
  } else if (mode === "hidden") {
    styles.input += " text-sm px-0 border-0 bg-transparent !ring-0 outline-none shadow-none trasition-all duration-300 ease-in-out";
  } else {
    styles.input +=
      " bg-gray-50 border border-gray-300 focus:border-blue-700 text-gray-900 text-sm rounded-lg block w-full p-2.5 trasition-all duration-300 ease-in-out !ring-0 !outline-none hover:border-gray-400 read-only:text-gray-400 read-only:bg-gray-100 disabled:bg-gray-100";
    styles.div += " mb-3 ";
    styles.passwordIcon += " bottom-[10px] right-3";
    styles.description += " text-xs font-light text-slate-400 -mt-0.5 mb-1";
  }

  if (mode === "sidebar") {
    styles.input += " disabled:bg-gray-50";
  }

  if (error) {
    styles.input += " border-red-600 hover:border-red-600";
    styles.title += " text-red-600";
  }

  const showHidePassword = () => {
    setShowPassword(!isPasswordShow);
    if (isPasswordShow) setInputType("password");
    else setInputType("text");
  };

  return (
    <div className={styles.div}>
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <div className="relative">
        <input
          required
          type={inputType}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value || ""}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
          readOnly={readOnly || disabled}
          aria-errormessage={!!error && !!errorMessage}
          onKeyDown={(ev) => onKeyDown && onKeyDown(ev.keyCode)}
          onKeyUp={(ev) => onKeyUp && onKeyUp(ev.keyCode)}
          onChange={(ev) => onChange && onChange(ev.target.value)}
          className={`${type === "password" && "pr-[30px]"} ${styles.input}`}
        />
        {type === "password" && (
          <button className={styles.passwordIcon} onClick={showHidePassword} disabled={disabled} tabIndex={-1}>
            <IconEye closed={isPasswordShow} width="w-5" className="transition-all ease-in-out duration-500 fill-gray-500 hover:fill-gray-600" />
          </button>
        )}
      </div>
      {placeholder && mode === "login" && (
        <label className="pointer-events-none absolute left-3 top-1 -translate-y-[1.3rem] scale-[0.9] text-primary mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] text-xs leading-[1.5] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[1.3rem] peer-focus:scale-[0.9] peer-focus:text-primary peer-valid:-translate-y-[1.3rem] peer-valid:scale-[0.9] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-autofill:scale-[0.9] peer-autofill:-translate-y-[1.3rem] motion-reduce:transition-none">
          {placeholder}
        </label>
      )}
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </div>
  );
}

// EDITOR
// --- https://tailwindcomponents.com/component/wysiwyg
// --- https://editor-tw.fouita.com/?ref=madewithsvelte.com
// https://www.npmjs.com/package/react-draft-wysiwyg
