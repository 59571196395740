import { i18n } from "app/i18n";
import { setUnsavedChanges, useUpdateProjectMutation } from "app/stores/project";
import { showServerError, showServerSuccess } from "app/utils/server";
import { validation } from "app/utils/validators";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "../../form";
import { components } from "./constants";
import EmailLayout from "../../builder/EmailLayout";
import { cleanStructure } from "app/utils/content";

export default function TabEmail() {
  // PARAMS
  const dispatch = useDispatch();

  // STORE
  const { project, unsavedChanges } = useSelector((store) => store.project);

  // STATE
  const [value, setValue] = useState(null);
  const [changed, setChanged] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [structure, setStructure] = useState(components);

  // REQUEST
  const [updateProject, { isLoading }] = useUpdateProjectMutation();

  useEffect(() => {
    if (project) fillForm();
  }, [project]);

  useEffect(() => {
    if (changed !== unsavedChanges) dispatch(setUnsavedChanges(changed));
    return () => dispatch(setUnsavedChanges(false));
  }, [changed]);

  const fillForm = () => {
    const content = { ...project.emailConfig };
    setValue(content);
    setDefaultValue(content);
    setStructure(cleanStructure(components));
  };

  const submitForm = () => {
    validation(structure, value, (structure, validation) => {
      let temp = { ...validation };
      setStructure([...structure]);

      // REMOVE REQUIREMENT WHEN ACTIVE IS NOT ENABLED
      if (!value.active) {
        const configKeys = structure.filter((e) => e.tab === "config").map((e) => e.key);
        const keys = Object.keys(temp).filter((e) => configKeys.includes(e));
        if (keys?.length) {
          for (let i = 0; i < keys.length; i++) {
            temp[keys[i]] = { error: false, message: null };
          }
        }
      }

      // REMOVE REQUIREMENT WHEN VISUAL ACTIVE IS NOT ENABLED
      if (!value.visualActive) {
        const configKeys = structure.filter((e) => e.tab === "visual").map((e) => e.key);
        const keys = Object.keys(temp).filter((e) => configKeys.includes(e));
        if (keys?.length) {
          for (let i = 0; i < keys.length; i++) {
            temp[keys[i]] = { isInvalid: false, message: null };
          }
        }
      }

      // CHECK IF FORM IS VALID AFTER VALIDATE ALL PARTS
      const keys = Object.keys(temp);
      temp.isValid = keys?.length && !keys.filter((e) => temp[e].isInvalid).length;

      if (temp.isValid) {
        setStructure([...structure.map((e) => ({ ...e, error: false }))]);

        // PREPARE VALUE
        const data = {
          id: project.id,
          emailConfig: value,
        };

        updateProject(data).then((res) => {
          if (res?.data) showServerSuccess(i18n("toast.success_update"));
          else showServerError(res);
        });
      } else {
        setStructure([...structure]);
      }
    });
  };

  const loading = isLoading;

  return (
    <div>
      <h1 className="text-3xl font-bold tracking-tight text-slate-900">{i18n("label.email")}</h1>
      <div className="divide-y-slate-200 mt-6 space-y-8 divide-y">
        {/* SPECIFY THE MODULES ACTIVED FOR PROJECT */}
        <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
          <div className="sm:col-span-6">
            <h2 className="text-xl font-medium text-slate-900">Email Sender Config</h2>
            <p className="mt-1 text-sm text-slate-500">Define the configuration for email sender</p>
            <div className="mt-6">
              <Form defaultValue={defaultValue} changed={setChanged} structure={structure.filter((e) => e.tab === "config")} value={value} onChange={setValue} disabled={loading} />
            </div>
          </div>
        </div>

        {/* SPECIFY THE MODULES ACTIVED FOR PROJECT */}
        <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
          <div className="sm:col-span-6">
            <h2 className="text-xl font-medium text-slate-900">Email Design</h2>
            <p className="mt-1 text-sm text-slate-500">Customize your email layout</p>
            <div className="mt-6">
              <Form defaultValue={defaultValue} changed={setChanged} structure={structure.filter((e) => e.tab === "visual")} value={value} onChange={setValue} disabled={loading} />
              {value?.visualActive && (
                <div className="border">
                  <EmailLayout
                    draggable
                    value={value}
                    loading={loading}
                    onChange={setValue}
                    changed={setChanged}
                    defaultValue={defaultValue}
                    structure={structure.filter((e) => e.tab === "visual-open")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-x-3 pt-8">
          <button
            type="button"
            onClick={fillForm}
            className="flex items-center relative justify-center rounded-md border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-slate-900 shadow-sm hover:bg-slate-50 focus:outline-none"
          >
            {i18n("label.cancel")}
          </button>
          <button
            type="submit"
            onClick={submitForm}
            disabled={!changed || loading}
            className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none  disabled:bg-blue-200 disabled:cursor-not-allowed"
          >
            {i18n("label.save")}
          </button>
        </div>
      </div>
    </div>
  );
}
