import Icon from "app/components/atoms/icons/icon";

export default function HistoryBuilder(props) {
  const { active, length, onNext, onPrev } = props;

  const disabled = length < 2;

  return (
    <div className="flex items-center gap-2 mx-4">
      <button type="button" disabled={disabled || active >= length - 1} onClick={onPrev} className="btn-icon disabled:opacity-30 disabled:pointer-events-none">
        <Icon name="undo" />
      </button>
      <button type="button" disabled={disabled || !active} onClick={onNext} className="btn-icon disabled:opacity-30 disabled:pointer-events-none">
        <Icon name="redo" />
      </button>
    </div>
  );
}
