import { hasPermission } from "app/utils/roles";
import React from "react";
import { useSelector } from "react-redux";
import { i18n } from "app/i18n";

// React Router
import { Link, useLocation, Outlet, Navigate } from "react-router-dom";
import { useGetCustomInputsQuery } from "app/stores/builder";

const tabs = [
  { name: "sections.title", href: "/dashboard/builders/sections" },
  { name: "templates.title", href: "/dashboard/builders/templates" },
  { name: "postTypes.title", href: "/dashboard/builders/post-types" },
  { name: "contentTypes.title", href: "/dashboard/builders/content-types" },
];

export default function Builders() {
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.auth);

  const tabNormal = "border-white text-text hover:text-primary-500";
  const tabActive = "border-primary-500 text-primary-500";

  const isCurrent = (index) => {
    return pathname.includes(tabs[index].href);
  };

  const { isLoading } = useGetCustomInputsQuery();

  if (!user) return null;
  if (!hasPermission(user, "DEVELOPER")) return <Navigate to="/dashboard" />;

  return (
    <div className="h-full flex flex-col pt-36 lg:pt-20">
      <div className="absolute top-36 lg:top-20 left-0 right-0 flex items-center justify-center h-20 px-6 bg-white border-b border-b-primary-50">
        <nav className="flex space-x-4" aria-label="Tabs" disabled={isLoading}>
          {tabs.map((tab, index) => (
            <Link
              to={tab.href}
              key={tab.name}
              aria-current={isCurrent(index) ? "page" : undefined}
              className={`border px-6 py-3 font-medium text-sm rounded-md main-transition ${isCurrent(index) ? tabActive : tabNormal}`}
            >
              {i18n(tab.name)}
            </Link>
          ))}
        </nav>
      </div>
      <Outlet />
    </div>
  );
}
