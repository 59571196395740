import Dropdown from "app/components/molecules/dropdown";
import { HasPermission } from "app/components/molecules/permission";
import { i18n } from "app/i18n";

export default function ItemTranslation(props) {
  const { translation, onEdit, onDelete, languages } = props;

  return (
    <tr className={`${translation.highlight ? "item-highlight" : ""}`}>
      <td className="whitespace-nowrap leading-10">
        {translation.key} {translation.highlight ? "NEW" : ""}
      </td>
      {!!languages?.length &&
        languages.map(({ language }, key) => (
          <td key={String(key)} className="text-left">
            <div className="text-ellipsis whitespace-nowrap overflow-hidden w-80">{translation?.values?.find((e) => e.language.slug === language.slug)?.value}</div>
          </td>
        ))}
      <td>
        <Dropdown
          options={[
            { title: i18n("button.edit"), onClick: onEdit, icon: "edit", disabled: !HasPermission({ min: "EDITOR", project: true, dev: true }) },
            { title: i18n("button.delete"), onClick: onDelete, icon: "delete", delete: true, disabled: !HasPermission({ min: "ADMINISTRATOR", project: true, dev: true }) },
          ]}
        />
      </td>
    </tr>
  );
}
