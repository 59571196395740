import { Link, useMatch, useResolvedPath } from "react-router-dom";

// Atoms
import LogoSvalla from "app/components/atoms/logoSvalla";

// Molecules
import Account from "../../account";
import Permission from "../../permission";

// i18n
import { i18n } from "app/i18n";

export default function DashboardNavbar(props) {
  const tabs = [
    { name: i18n("button.projects"), href: "/dashboard", permission: undefined, end: true },
    { name: "Builders", href: "/dashboard/builders", permission: { min: "DEVELOPER" }, end: false },
    { name: i18n("button.users"), href: "/dashboard/users", permission: { min: "ADMINISTRATOR" }, end: true },
    // { name: "API", href: "/dashboard/api", permission: undefined , end: true },
    { name: i18n("button.settings"), href: "/dashboard/settings", permission: { min: "ADMINISTRATOR" }, end: true },
  ];

  return (
    <header className="fixed top-0 left-0 right-0 flex items-center h-36 lg:h-20 py-6 px-6 bg-white border-b border-b-primary-50 z-20 flex-col lg:flex-row">
      <div className="flex-1 absolute left-6 lg:relative lg:left-auto">
        <LogoSvalla />
      </div>
      <nav className="flex space-x-4 mt-14 lg:mt-0" aria-label="Tabs">
        {tabs.map((tab) => (
          <Permission key={tab.name} {...tab.permission}>
            <CustomLink to={tab.href} end={tab.end}>
              {tab.name}
            </CustomLink>
          </Permission>
        ))}
      </nav>
      <div className="flex justify-end flex-1 absolute right-6 lg:relative lg:right-auto">
        <Account {...props} />
      </div>
    </header>
  );
}

// É preciso passar isto para um Atom e os estilos têm que ser dinâmicos
function CustomLink({ children, to, end, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end });

  return (
    <Link to={to} className={`${match ? "bg-primary-500 text-white" : "text-text hover:text-primary-500"} px-6 py-3 font-medium text-sm rounded-md main-transition`} {...props}>
      {children}
    </Link>
  );
}
