import { Content, Footer, Header, Struture } from "../../../atoms/modal";
import ListRevisions from "app/components/organisms/lists/listRevisions";
import { i18n } from "app/i18n";

export default function ModalRevision(props) {
  const { block = false, isOpen = false, onClose = () => {}, content, current = 0, sections } = props;

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={i18n("label.revisions")} onClose={onClose} />
      <Content>
        <div>
          {content && (
            <ListRevisions block={block} section={sections ? sections[current]?.id : undefined} content={content} sections={sections?.map((item) => ({ value: item.id, label: item.section.title }))} />
          )}
        </div>
      </Content>
      <Footer cancel={{ text: i18n("button.close"), onClick: onClose }} />
    </Struture>
  );
}
