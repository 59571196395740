import { gql } from "@apollo/client";
import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { serverAuth } from "app/utils/server";
import { addTranslationToStore, updateOrDeleteTranslationInStore } from "app/utils/visual";

const initialState = {
  total: 0,
  translations: [],

  take: 10,
  page: 1,
  where: undefined,
  skip: undefined,
  orderBy: { key: "asc" },
};

export const translationsSlice = createSlice({
  name: "translations",
  initialState,
  reducers: {
    setTranslations: (state, { payload }) => {
      state.translations = payload;
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setWhere: (state, { payload }) => {
      state.where = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
      state.skip = (state.page - 1) * state.take;
    },
    setOrderBy: (state, { payload }) => {
      state.orderBy = payload;
    },
  },
});

export const translationsApi = createApi({
  reducerPath: "translationsAPI",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    getTranslations: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query ($take: Int, $skip: Int, $where: TranslationWhere, $orderBy: TranslationOrderBy) {
            translations(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              key
              values {
                value
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.translations,
      async onQueryStarted({ skip }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { translations } = getState();

          addTranslationToStore(translations?.translations, translations?.total, data, !!skip, false, translationsSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    getTranslationsTotal: builder.query({
      query: ({ where }) => ({
        document: gql`
          query ($where: TranslationWhere) {
            translationsTotal(where: $where)
          }
        `,
        variables: {
          where,
        },
      }),
      transformResponse: (response) => response.translationsTotal,
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(translationsSlice.actions.setTotal(data));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    createTranslation: builder.mutation({
      query: ({ project, key, values }) => ({
        document: gql`
          mutation createTranslation($project: GenericConnect!, $key: String!, $values: [InputValueTranslation!]!) {
            createTranslation(project: $project, key: $key, values: $values) {
              id
              key
              values {
                value
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          key,
          values,
          project: project ? { connect: { id: project } } : undefined,
        },
      }),
      transformResponse: (response) => response.createTranslation,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { translations } = getState();
          addTranslationToStore(translations?.translations, translations?.total, undefined, false, data, translationsSlice, dispatch);
        } catch (err) {
          console.log("ERR", err);
          console.error(err);
        }
      },
    }),
    updateTranslation: builder.mutation({
      query: ({ id, key, values }) => ({
        document: gql`
          mutation updateTranslation($id: String!, $key: String!, $values: [InputValueTranslation!]!) {
            updateTranslation(id: $id, key: $key, values: $values) {
              id
              key
              values {
                value
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          id,
          key,
          values,
        },
      }),
      transformResponse: (response) => response.updateTranslation,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { translations } = getState();
          updateOrDeleteTranslationInStore(translations?.translations, translations?.total, data, false, translationsSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    deleteTranslation: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation deleteTranslation($id: String!) {
            deleteTranslation(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteTranslation,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { translations } = getState();
          updateOrDeleteTranslationInStore(translations?.translations, translations?.total, data, true, translationsSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
  }),
});

export const { setTranslations, setWhere, setPage, setOrderBy } = translationsSlice.actions;

export const { useGetTranslationsQuery, useGetTranslationsTotalQuery, useCreateTranslationMutation, useUpdateTranslationMutation, useDeleteTranslationMutation } = translationsApi;

export default translationsSlice.reducer;
