import React, { useEffect, useState } from "react";
import { components } from "./constants";
import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import Form from "app/components/organisms/form";
import { i18n } from "app/i18n";
import { validation } from "app/utils/validators";

export default function ModalInput(props) {
  const { isOpen = false, input, type, emailFieldExists, unavailableSlugs, buttonText = i18n("button.add"), isLoading, onSubmit, onClose = () => {} } = props;

  // STATE
  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(null);

  useEffect(() => {
    if (isOpen && type) {
      const inputComponents = components[type];
      if (inputComponents) setStructure(inputComponents);
      if (input) setValue({ ...input });
    } else {
      setValue(null);
      setStructure(null);
    }
  }, [isOpen, type, input]);

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      let tempValid = validation.isValid;
      let tempStructure = structure;

      // INVALID IF KEY DUPLICATED
      if (unavailableSlugs.includes(value.slug)) {
        tempValid = false;
        const index = tempStructure.findIndex((e) => e.key === "slug");
        if (index != -1) {
          tempStructure[index].error = true;
          tempStructure[index].errorMessage = i18n("input.slug_in_use");
        }
      }
      // INVALID IF EMAIL FIELD DUPLICATED
      if (emailFieldExists && value.emailField) {
        tempValid = false;
        const index = tempStructure.findIndex((e) => e.key === "emailField");
        if (index != -1) {
          tempStructure[index].error = true;
          tempStructure[index].errorMessage = i18n("input.email_already_exists");
        }
      }

      setStructure([...tempStructure]);
      if (tempValid) onSubmit({ type, ...value });
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={input ? i18n("label.edit_input") : i18n("label.create_input")} onClose={onClose} />
      <Content>
        <div>
          <h6 className="mb-4 text-sm text-slate-400">Adding a {type}</h6>
          <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
        </div>
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: buttonText, disabled: isLoading, onClick: onClickSubmit }} />
    </Struture>
  );
}
