import Icon from "app/components/atoms/icons/icon";
import { hasPermissionProject } from "app/utils/roles";
import { memo } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useSelector } from "react-redux";
import InputCheckbox from "./inputs/checkbox";
import InputChecklist from "./inputs/checklist";
import InputFile from "./inputs/file";
import InputSelect from "./inputs/select";
import InputText from "./inputs/text";
import InputTextarea from "./inputs/textarea";

const Item = memo(function (props) {
  const { id, type, error, onMoveItem, findItem, onEdit, onRemove } = props;

  const originalIndex = findItem(id).index;
  const { user } = useSelector((store) => store.auth);
  const { project } = useSelector((store) => store.project);

  // FUNCTION FOR WHEN IT IS PICKED
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "Item",
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) onMoveItem(droppedId, originalIndex);
      },
    }),
    [id, originalIndex, onMoveItem]
  );

  // FUNCTION FOR WHEN IT IS DROPPED
  const [, drop] = useDrop(
    () => ({
      accept: "Item",
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findItem(id);
          onMoveItem(draggedId, overIndex);
        }
      },
    }),
    [findItem, onMoveItem]
  );

  // CHANGE OPACITY OF ELEMENT WHEN DRAGGED
  const opacity = isDragging ? 0 : 1;

  const hasPermission = hasPermissionProject(user, project, "EDITOR", true);

  return (
    <div ref={(node) => (hasPermission ? drag(drop(node)) : undefined)} style={{ opacity }} className="group relative pr-8">
      <div className={`relative border p-4 rounded transition-all duration-300 ease-linear hover:border-slate-700 group-hover:rounded-tr-none bg-gray-50 ${error ? "border-red-700" : ""}`}>
        {type === "text" && <InputText {...props} />}
        {type === "textarea" && <InputTextarea {...props} />}
        {type === "file" && <InputFile {...props} />}
        {type === "select" && <InputSelect {...props} />}
        {type === "checkbox" && <InputCheckbox {...props} />}
        {type === "checklist" && <InputChecklist {...props} />}

        {hasPermission && (
          <div className="absolute top-0 left-full -translate-y-[1px] ml-[1px] transition-all duration-300 ease-in-out opacity-0 group-hover:opacity-100">
            <div className="aspect-square w-8 h-8 border border-l-0 hover:bg-blue-700" onClick={onEdit}>
              <button type="button" className="btn-icon hover:text-white hover:bg-transparent">
                <Icon name="pencil" />
              </button>
            </div>
            <div className="aspect-square w-8 h-8 border border-l-0 hover:bg-blue-700">
              <button type="button" className="btn-icon hover:text-white hover:bg-transparent" onClick={onRemove}>
                <Icon name="delete" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default Item;
