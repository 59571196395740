import { useEmptyProjectQuery } from "app/stores/auth";
import { useGetMeQuery } from "app/stores/users";
import { useCookies } from "react-cookie";
import { Navigate, Outlet } from "react-router-dom";

export default function AuthMiddleware() {
  const [{ logged_in }, setCookie, removeCookie] = useCookies(["logged_in", "access_token", "token"]);

  const { data: me, isFetching, isLoading, isUninitialized } = useGetMeQuery({}, { skip: false, refetchOnMountOrArgChange: true });
  const {
    data: emptyProject,
    isFetching: isFetchingProject,
    isLoading: isLoadingProject,
    isUninitialized: isUninitializedProject,
  } = useEmptyProjectQuery({}, { skip: false, refetchOnMountOrArgChange: true });

  const loading = isLoading || isFetching || isLoadingProject || isFetchingProject || isUninitialized || isUninitializedProject;
  const hasUser = (me && me.user) || (me && !me.error);

  if (loading)
    return (
      <div className="loader-full-page">
        <span className="loader-styled"></span>
      </div>
    );

  // IF IN LOGIN PAGE AND NOT LOGGED IN, CHECK FOR PROJECTS INITIALIZED
  // IF NO PROJECTS FOUNDED, SHOW WELCOME PAGE
  if (emptyProject && !hasUser && !logged_in) {
    return <Navigate to="/welcome" />;
  }

  // CLEAN COOKIES WHEN LOGIN RETURN MISTAKES
  if (!hasUser) {
    // removeCookie("logged_in");
    // removeCookie("access_token");
    // removeCookie("refresh_token");
    return <Navigate to="/login" />;
  }

  return <Outlet />;
}
