import Icon from "app/components/atoms/icons/icon";
import Dropdown from "app/components/molecules/dropdown";
import Permission from "app/components/molecules/permission";
import { i18n } from "app/i18n";
import { getSource } from "app/utils/content";
import { Link } from "react-router-dom";

export default function ItemProject(props) {
  const { id, enabled, thumbnail, label, name, onEdit, onDelete } = props;

  return (
    <div className="col-span-12 overflow-visible bg-white rounded-b-lg md:col-span-6 lg:col-span-4 xl:col-span-3">
      <Link to={`/project/${id}`} className="group">
        <div className="relative overflow-hidden rounded-t-lg aspect-video bg-gray-300">
          <div className="absolute inset-0 z-10 flex items-center justify-center text-white opacity-0 group-hover:opacity-100 bg-primary-500/50 main-transition">
            <Icon name="chevronRight" width="w-20" height="h-20" strokeWidth={1} />
          </div>
          {thumbnail && <img src={getSource(thumbnail.href)} alt={name} className="object-cover w-full h-full" />}
        </div>
      </Link>
      <div className="flex items-center justify-center px-4 py-5">
        <div className="flex-1">
          <span className="text-xs font-normal mr-2">{enabled ? <span className="text-green-700">{i18n("label.active")}</span> : <span className="text-red-700">{i18n("label.inactive")}</span>}</span>
          <span className="text-xs font-normal">{label}</span>
          <div>
            <Link to={`/project/${id}`} className="text_xl main-transition hover:text-primary-500">
              {name}
            </Link>
          </div>
        </div>
        <Permission max="ADMINISTRATOR" project dev>
          <Dropdown
            options={[
              { title: i18n("button.edit"), icon: "edit", onClick: onEdit },
              { title: i18n("button.delete"), icon: "delete", onClick: onDelete, delete: true },
            ]}
          />
        </Permission>
      </div>
    </div>
  );
}
