import HorizontalScroll from "app/components/molecules/horizontalScroll";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import { i18n } from "app/i18n";
import { useState } from "react";
import { useSelector } from "react-redux";
import EditionEmails from "./emails";
import EditionStructure from "./structure";

export default function FormEdition(props) {
  const { loading } = props;

  // STATE
  const [tab, setTab] = useState(0);
  const [modalConfirm, setModalConfirm] = useState(null);

  // STORE
  const { unsavedChanges } = useSelector((state) => state.project);

  // VARS
  const tabs = [{ title: "Form Structure" }, { title: "Emails" }];

  // ON CHANGE TAB
  const changeTab = (index) => {
    if (unsavedChanges) {
      setModalConfirm({
        isOpen: true,
        type: "SAVE",
        mode: "BASIC",
        title: i18n("label.ignore_changes.title"),
        text: i18n("label.ignore_changes.text"),
        buttonText: i18n("label.ignore_changes.button"),
        onConfirm: () => {
          setTab(index);
          setModalConfirm(null);
        },
        onClose: () => {
          setModalConfirm(null);
        },
      });
    } else {
      setTab(index);
    }
  };

  return (
    <>
      <div className="flex flex-col flex-1 h-full overflow-hidden rounded-t-md">
        <div className="bg-white w-full border-b min-h-12">
          <HorizontalScroll>
            {tabs.map((item, key) => {
              let styles = {
                button: "h-12 px-6 py-1 main-transition text-sm whitespace-nowrap border-b-2 bg-white flex items-center",
              };

              if (key === tab) styles.button += " border-blue-900 text-slate-800";
              else styles.button += " border-white text-slate-400 hover:text-slate-700";

              return (
                <button key={key} className={styles.button} onClick={() => changeTab(key)}>
                  {item.title}
                </button>
              );
            })}
          </HorizontalScroll>
        </div>

        <div className="bg-white flex-1 flex flex-col w-full rounded-b-md overflow-hidden">
          {/* EDITOR */}
          <div className="flex flex-col flex-1 overflow-auto">
            {tab === 0 && <EditionStructure loading={loading} />}
            {tab === 1 && <EditionEmails loading={loading} />}
          </div>
        </div>
      </div>

      <ModalConfirm {...modalConfirm} />
    </>
  );
}
