import { useState } from "react";

// ATOMS
import Icon from "app/components/atoms/icons/icon";
import Loading from "app/components/atoms/loading";

// MOLECULES
import ItemProject from "./itemProject";
import Permission from "app/components/molecules/permission";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalProject from "app/components/molecules/modals/modalProject";

// STORE
import { useCreateProjectMutation, useDeleteProjectMutation, useGetMyProjectsQuery, useUpdateProjectMutation } from "app/stores/projects";

// UTILS
import { i18n } from "app/i18n";
import { showServerError, showServerSuccess } from "app/utils/server";

export default function ListProjects() {
  // STATE
  const [modal, setModal] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);

  // REQUESTS
  const { data: projects, isLoading: isGetting, isFetching, refetch: updateList } = useGetMyProjectsQuery();
  const [createProject, { isLoading: isCreating }] = useCreateProjectMutation();
  const [updateProject, { isLoading: isUpdating }] = useUpdateProjectMutation();
  const [deleteProject, { isLoading: isDeleting }] = useDeleteProjectMutation();

  // VARS
  const isLoading = isCreating || isGetting || isFetching || isUpdating || isDeleting;

  // CREATE PROJECT
  const onCreate = () => {
    setModal({
      isOpen: true,
      buttonText: i18n("button.create"),
      onSubmit: (data) => {
        createProject(data).then((res) => {
          if (res.data) {
            showServerSuccess(i18n("toast.success_create"));
            updateList();
          } else {
            showServerError(res);
          }
          setModal(null);
        });
      },
      onClose: () => setModal(null),
    });
  };

  // EDIT PROJECT
  const onEdit = (project) => {
    setModal({
      project,
      isOpen: true,
      buttonText: i18n("button.update"),
      onSubmit: (data) => {
        updateProject({ id: project.id, ...data }).then((res) => {
          if (res && res.data) {
            updateList();
            setModal(null);
            showServerSuccess(i18n("toast.success_update"));
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setModal(null),
    });
  };

  // EDIT PROJECT
  const onDelete = (project) => {
    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      title: "Delete Project",
      buttonText: "Delete",
      mode: "REQUIRED",
      text: "Do you really want to delete this project? This action is undone and could make fatal changes in your website",
      onConfirm: () => {
        deleteProject({ id: project.id }).then((res) => {
          if (res && res.data) {
            updateList();
            setConfirmModal(null);
            showServerSuccess(i18n("toast.success_delete"));
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setConfirmModal(null),
    });
  };

  return (
    <div className="custom-container">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between flex-wrap pt-32 lg:pt-16 pb-10">
        <span className="flex items-center">
          <h1 className="text_3xl">{i18n("button.projects")}</h1>
          <Loading active={isLoading} className="ml-3" />
        </span>
        <Permission min="ADMINISTRATOR">
          <button type="button" className="btn-primary-outline min-w-fit w-fit mt-5 sm:mt-0" onClick={onCreate}>
            <Icon name="plus" />
            {i18n("button.new_project")}
          </button>
        </Permission>
      </div>
      <div className="grid grid-cols-12 gap-5 pb-10">
        {projects && projects.length ? projects.map((item) => <ItemProject {...item} key={item.id} onEdit={() => onEdit(item)} onDelete={() => onDelete(item)} />) : <p>{i18n("label.no_projects")}</p>}
      </div>

      <ModalProject {...modal} isLoading={isLoading} />
      <ModalConfirm {...confirmModal} isLoading={isLoading} />
    </div>
  );
}
