import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { i18n } from "app/i18n";
import React from "react";

export default function LabelPostStatus(props) {
  const { published } = props;

  const styles = {
    kbd: "px-2 py-1 my-1 whitespace-nowrap text-xs font-semibold rounded-lg inline-flex items-center",
    icon: "mr-1.5 h-4 w-4 flex-shrink-0",
  };
  if (published) styles.kbd += " text-white bg-lime-700";
  else styles.kbd += " text-white bg-red-700";

  if (published) styles.icon += " text-white";
  else styles.icon += " text-white";

  return (
    <kbd className={`${styles.kbd}`}>
      {published ? <CheckIcon className={`${styles.icon}`} aria-hidden="true" /> : <XMarkIcon className={`${styles.icon}`} aria-hidden="true" />}
      {published ? i18n("label.published") : i18n("label.draft")}
    </kbd>
  );
}
