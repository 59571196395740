import Loading from "app/components/atoms/loading";
import { i18n } from "app/i18n";
import PasswordStrength from "app/components/molecules/passwordStrength";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate, useOutletContext, useParams, useLocation } from "react-router-dom";
import validator from "validator";
import Form from "../../components/organisms/form";
import { useCreatePasswordMutation } from "../../stores/auth";
import { validation } from "../../utils/validators";
import customValidator from "../../utils/validators/customValidator";
import { secureHost } from "app/utils/server";
import { components } from "./constants";

export default function NewAccount() {
  // CONTEXT
  const { buttonColor } = useOutletContext();

  // PARAMS
  let { token } = useParams();
  const navigate = useNavigate();

  // COOKIES
  const [{ newAccountToken }, setCookie, removeCookie] = useCookies(["newAccountToken"]);

  // STATE
  const [value, setValue] = useState(null);
  const [formMessage, setFormMessage] = useState(null);
  const [structure, setStructure] = useState(components);

  // REQUEST
  const [createPassword, { data, isLoading: isSetting }] = useCreatePasswordMutation();

  // VARS
  const loading = isSetting || !newAccountToken;

  useEffect(() => {
    if (token) {
      const secure = secureHost();
      setCookie("newAccountToken", token, { secure });
      navigate("/new-account");
    }
  }, [token]);

  const onSubmit = () => {
    if (!newAccountToken || newAccountToken === "") {
      setValue(null);
      setFormMessage({ status: false, message: "no.token" });
    } else {
      validation(structure, value, (structure, validation) => {
        setStructure([...structure]);
        if (validation.isValid || !token)
          createPassword({ password: value.password, token: newAccountToken }).then((res) => {
            if (res?.data) {
              if (!res.data.error) {
                setValue(null);
                removeCookie("newAccountToken");
                navigate("/login?newuser=ok", { replace: true });
              } else {
                setFormMessage({ status: false, message: i18n(res.data.message) });
              }
            } else {
              setFormMessage({ status: !data.error, message: i18n("user.request_error") });
            }
          });
      });
    }
  };

  return (
    <div>
      <div className="mb-8">
        <h1 className="text-xl text-[#0c1832]">{i18n("createAccount.title")}</h1>
        <p className="text-md text-[#627385]">{i18n("createAccount.description")}</p>
      </div>

      <Form value={value} onChange={setValue} structure={structure} disabled={loading} mode={"login"} />

      <PasswordStrength withInfo hidden={data} disabled={isSetting} value={value?.password} />

      {formMessage && (
        <div className={`relative w-full p-2 rounded-sm bg-white/40 shadow-md border-b-2 ${formMessage.status ? "text-green-700  border-green-700" : "text-red-700  border-red-700"}`} role="alert">
          <span className="text-xs font-normal">{formMessage.message}</span>
        </div>
      )}

      <div className="flex justify-between items-center flex-wrap mt-6">
        <div className="flex items-center mt-2 mb-2 mr-6">
          <button
            type="button"
            onClick={onSubmit}
            style={{ backgroundColor: buttonColor || undefined }}
            disabled={isSetting || !newAccountToken || (data && !data.error)}
            className="inline-block px-7 py-3 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition-all duration-500 ease-in-out disabled:pointer-events-none disabled:!cursor-not-allowed disabled:!shadow-md bg-[#182e5f] disabled:!opacity-30 hover:!bg-[#0c1832] focus:bg-[#0c1832] active:bg-[#0c1832]"
          >
            {i18n("button.createAccount")}
          </button>
          <Loading active={isSetting} className="ml-2" />
        </div>

        <Link to={"/login"} className="text-gray-800 text-sm transition-all ease-in-out duration-500 hover:!text-[#0c1832] mt-2 mb-2" style={{ color: buttonColor || undefined }}>
          {i18n("button.backToLogin")}
        </Link>
      </div>
    </div>
  );
}
