import { authApi } from "app/stores/auth";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export default function WelcomeMiddleware(props) {
  const { pathname } = useLocation();
  const { data: emptyProject, isFetching, isLoading } = authApi.endpoints.emptyProject.useQuery(null, { skip: false, refetchOnMountOrArgChange: true });

  const loading = isLoading || isFetching;
  if (loading) return <div className="loader-full-page"><span className="loader-styled"></span></div>;

  if (!emptyProject) return <Navigate to="/" />;

  if (pathname != "/welcome") return <Navigate to="/welcome" />;

  return <Outlet />;
}
