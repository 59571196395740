import validator from "validator";
import customValidator from "app/utils/validators/customValidator";
import { i18n } from "app/i18n";

const components = [
  {
    type: "Text",
    key: "title",
    title: i18n("label.title"),
    onBlur: { key: "slug", lower: true, replacement: "-", removeExtra: true },
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    type: "Slug",
    key: "slug",
    title: i18n("label.slug"),
    options: { fullRemove: true },
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: customValidator.isPageSlugAvailable,
        validWhen: true,
        args: { id: undefined, type: undefined, project: undefined, language: undefined },
        message: i18n("input.slug_in_use"),
      },
    ],
  },
  {
    type: "Select",
    key: "template",
    title: i18n("label.template"),
    options: [],
    rules: [
      {
        method: customValidator.isObjectEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
];

const componentsDuplication = [
  {
    type: "Toggle",
    key: "copy",
    title: "Copy Content",
    description: "Active to duplicate the content",
  },
  {
    type: "Select",
    key: "language",
    options: [],
    title: "Language",
    description: "Select the language for duplication",
    conditional: {
      field: "copy",
      when: "isTrue",
    },
  },
  {
    type: "Toggle",
    key: "translate",
    title: "Translate Content",
    description: "Automatic translate content using Google Translate",
    conditional: {
      field: "copy",
      when: "isTrue",
    },
  },
];

const componentsPostDuplication = [
  {
    type: "Group",
    key: "translations",
    title: "Translations",
    description: "You will duplicate the post in different languages. Provided the correct new slugs for the translations.",
    children: [],
  },
];

export { components, componentsDuplication, componentsPostDuplication };
