import { i18n } from "app/i18n";
import validator from "validator";

export const tabs = [{ name: i18n("label.input") }, { name: i18n("label.structure") }];

export const components = [
  {
    type: "Text",
    key: "title",
    title: "Title",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
];
