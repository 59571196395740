import Icon from "app/components/atoms/icons/icon";
import Tooltip from "app/components/atoms/tooltip";
import { i18n } from "app/i18n";
import dayjs from "dayjs";

export default function ItemOccurrence(props) {
  const { id, title, url, type, date, author } = props;

  return (
    <tr key={id}>
      <td className="flex flex-col whitespace-nowrap">
        <span className="text-sm text-slate-700">{title}</span>
        <span className="text-xs text-slate-300">{type}</span>
      </td>
      <td className="whitespace-nowrap">{author}</td>
      <td>{dayjs(date).format("DD.MM.YYYY HH:mm")}</td>
      <td className="text-right">
        <Tooltip message={i18n("button.see_page")} placement="left">
          <a href={url} target="_blank" rel="noopener noreferrer">
            <button type="button" className="btn-icon ml-auto bg-slate-200 rounded-md">
              <Icon name="link" />
            </button>
          </a>
        </Tooltip>
      </td>
    </tr>
  );
}
