import Permission from "app/components/molecules/permission";
import Loading from "../../loading";

export default function Footer(props) {
  const { dynamic, cancel, loading, submit, next, clean, children } = props;

  const confirm = dynamic ? next : submit;

  return (
    <div className="flex items-center justify-between gap-2 px-6 py-6 border-t border-t-primary-50">
      {children ? (
        children
      ) : (
        <>
          <div>
            {clean?.text && clean?.onClick && (
              <>
                {clean.permission ? (
                  <Permission project dev {...clean.permission}>
                    <button className="btn-delete inverse" onClick={clean.onClick} disabled={clean.disabled || loading}>
                      {clean.text}
                    </button>
                  </Permission>
                ) : (
                  <button className="btn-delete inverse" onClick={clean.onClick} disabled={clean.disabled || loading}>
                    {clean.text}
                  </button>
                )}
              </>
            )}
          </div>
          <div className="flex items-center gap-2">
            <Loading active={loading} className="mr-2" />
            {cancel?.text && cancel?.onClick && (
              <button className="btn-cancel" onClick={cancel.onClick} disabled={cancel.disabled || loading}>
                {cancel.text}
              </button>
            )}
            {confirm?.text && confirm?.onClick && (
              <button className="btn-primary" onClick={confirm.onClick} disabled={confirm.disabled || loading}>
                {confirm.text}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
