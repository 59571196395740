import React from "react";
import { LeftArrow, onWheel, RightArrow, useDrag } from "./constants";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

export default function HorizontalScroll(props) {
  const { className, children } = props;

  const { dragStart, dragStop, dragMove, dragging } = useDrag();

  const handleDrag =
    ({ scrollContainer }) =>
    (ev) => {
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) scrollContainer.current.scrollLeft += posDiff;
      });
    };

  return (
    <div className={`${className} relative`} onMouseLeave={dragStop}>
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={onWheel} onMouseDown={() => dragStart} onMouseUp={() => dragStop} onMouseMove={handleDrag}>
        {children}
      </ScrollMenu>
    </div>
  );
}
