import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { getFlag } from "app/utils/languages";
import PropTypes from "prop-types";

export default function Thead(props) {
  const sorts = ["asc", "desc"];
  const { headers, setOrder, orderBy } = props;

  // DEFINE ONCLICK FUNCTION
  const onClick = (slug) => {
    if (!orderBy || !orderBy[slug] || orderBy[slug] === sorts[1]) setOrder({ [slug]: sorts[0] });
    else setOrder({ [slug]: sorts[1] });
  };

  // DEFINE ICON
  const Icon = ({ slug, className }) => {
    if (!orderBy || !orderBy[slug]) return null;
    if (orderBy[slug] === sorts[0]) return <ChevronDownIcon className={className} />;
    else return <ChevronUpIcon className={className} />;
  };

  return (
    <thead className="relative text-xs text-gray-700 before:bg-gray-200 before:absolute before:inset-0 before:bottom-[10px] before:rounded-lg after:leading-[10px] after:-indent-96 after:block after:content-['']">
      <tr className="relative">
        {headers.map((item, index) => (
          <th scope="col" key={String(index)} className={`py-6 px-6 ${item.isLang ? "whitespace-nowrap" : ""}`}>
            {
              // WITH SORT
              !item.noSort ? (
                <button type="button" className="text-sm hover:opacity-80 transition-all duration-300 ease-in-out" onClick={() => onClick(item.slug)}>
                  {item.name} <Icon slug={item.slug} className="inline w-4" />
                </button>
              ) : // LANGUAGE
              item.isLang && item.options?.length ? (
                <span>
                  {item.options.map((lang, key) => (
                    <span key={key} className="uppercase text-xl h-4 w-6 mx-1 inline-flex items-center justify-center">
                      {getFlag(lang)}
                    </span>
                  ))}
                </span>
              ) : (
                // DEFAULT
                <span>{item.name}</span>
              )
            }
          </th>
        ))}
        <th />
      </tr>
    </thead>
  );
}

Thead.propTypes = {
  headers: PropTypes.array.isRequired,
  setOrder: PropTypes.func,
  orderBy: PropTypes.object,
};
