import IconForbidden from "app/components/atoms/icons/IconForbidden";
import Icon from "app/components/atoms/icons/icon";
import { Content } from "app/components/atoms/modal";
import Dropdown from "app/components/molecules/dropdown";
import Permission from "app/components/molecules/permission";
import FormBuilder from "app/components/organisms/builder/Form";
import { i18n } from "app/i18n";
import { useUpdateFormStructureMutation } from "app/stores/forms";
import { setUnsavedChanges } from "app/stores/project";
import { showServerError, showServerSuccess } from "app/utils/server";
import { NOTFOUND } from "app/utils/status";
import { objectsAreEqual } from "app/utils/validators/dataValidator";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

export default function EditionStructure(props) {
  const { loading } = props;

  // PARAMS
  const dispatch = useDispatch();
  const { project, lang: language } = useParams();

  // STORE
  const { form, formStatus } = useSelector((state) => state.form);
  const { unsavedChanges } = useSelector((state) => state.project);

  // STATE
  const [value, setValue] = useState(null);
  const [errors, setErrors] = useState(null);
  const [dataChanged, setDataChanged] = useState(false);

  // MUTATION
  const [updateForm, { isLoading: isUpdating }] = useUpdateFormStructureMutation();

  // VARIABLES
  const isLoading = loading || isUpdating;
  const content = form?.translations?.find((item) => item.language.slug === language);

  useEffect(() => {
    if (content) setValue(content?.structure || []);
  }, [content]);

  useEffect(() => {
    setDataChanged(isDataDifferent());
  }, [value]);

  useEffect(() => {
    if (dataChanged !== unsavedChanges) dispatch(setUnsavedChanges(dataChanged));
    return () => dispatch(setUnsavedChanges(false));
  }, [dataChanged]);

  // CHECK FOR DIFFERENT DATA
  const isDataDifferent = () => {
    return !objectsAreEqual(content?.structure, value);
  };

  // ON SUBMIT
  const onSave = () => {
    setErrors(null);
    let listErrors = [];

    const uniqueEmailField = value.filter((e) => e.emailField).length;
    // ERROR MISSING EMAIL FIELD
    if (uniqueEmailField === 0) {
      listErrors.push({ noInput: true, message: i18n("label.missing_email_input") });
    }
    // ERROR DUPLICATED EMAIL FIELD
    else if (uniqueEmailField !== 1) {
      const inputs = value.filter((e) => e.emailField).map((e) => e.slug);
      for (let i = 0; i < inputs.length; i++) {
        listErrors.push({ input: inputs[i], message: i18n("label.duplicated_email_input") });
      }
    }
    // ERROR DUPLICATED SLUG INPUT
    const inputs = getDuplicateSlug(value);
    if (inputs?.length) {
      for (let i = 0; i < inputs.length; i++) {
        listErrors.push({ input: inputs[i].slug, message: i18n("label.duplicated_slug") });
      }
    }
    if (listErrors.length) setErrors(listErrors);

    // IF NO EXISTS ERRORS
    if (!listErrors?.length) {
      updateForm({ id: content.id, structure: value }).then((res) => {
        if (res.data) showServerSuccess(i18n("toast.form_structure_updated"));
        else showServerError(res);
      });
    }
  };

  // VALIDATE IF EXISTS DUPLICATED KEYS
  const getDuplicateSlug = (records) => {
    const slugsSet = new Set();
    return records.filter((record) => {
      if (slugsSet.has(record.slug)) return true;
      slugsSet.add(record.slug);
      return false;
    });
  };

  // CLEAN CHANGES
  const cleanChanges = () => {
    setValue(content.structure || []);
  };

  return (
    <>
      <div className="flex-1 overflow-auto">
        {formStatus !== NOTFOUND ? (
          <Content>{content ? <FormBuilder value={value} onChange={setValue} errors={errors} /> : <p>{i18n("label.nothing_found")}</p>}</Content>
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <IconForbidden width="w-32" height="h-32" className="mb-10 text-red-700" />
            <h3 className="mb-2 text-4xl font-semibold text-black uppercase">{i18n("label.page_not_found")}</h3>
            <p className="mb-10 text-md font-normal max-w-sm text-center text-slate-600">{i18n("label.page_not_found_description")}</p>
            <Link to={`/project/${project}`}>
              <button
                type="button"
                className="flex mr-1.5 items-center relative justify-center rounded-md border border-blue-800 bg-transparent px-4 py-2 text-sm font-medium text-blue-800 shadow-sm hover:bg-blue-700 hover:border-blue-700 hover:text-white focus:outline-none "
              >
                {i18n("label.return_dashboard")}
              </button>
            </Link>
          </div>
        )}
      </div>
      {formStatus !== NOTFOUND && (
        <Permission project dev min="EDITOR">
          <div className="border-t py-4 px-6 flex justify-between">
            <div>
              <Dropdown position="top-left" options={[{ title: i18n("button.clean_changes"), onClick: cleanChanges, icon: "rollback", delete: true }]} />
            </div>
            <div className="flex items-center gap-4">
              {errors?.length && (
                <>
                  <div className="flex items-center rounded-md border border-transparent bg-red-600 text-white h-full py-0.5 px-2 pr-6">
                    <div className="h-6 w-6 flex-shrink-0 rounded-full items-center justify-center bg-red-400 text-white flex mr-2">
                      <Icon name="close" />
                    </div>
                    <div className="flex flex-col">
                      {errors.map((e) => (
                        <span className="text-xs inline-block leading-tight">{e.message}</span>
                      ))}
                    </div>
                  </div>
                </>
              )}
              <button
                type="button"
                onClick={onSave}
                disabled={!dataChanged || isLoading}
                className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none  disabled:bg-blue-200 disabled:cursor-not-allowed"
              >
                {i18n("button.save")}
              </button>
            </div>
          </div>
        </Permission>
      )}
    </>
  );
}
