import { gql } from "@apollo/client";
import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { serverAuth } from "app/utils/server";
import { updateListOfEnvironments } from "app/utils/visual";

const initialState = {
  environments: null,
  staticEnvironments: null,
  loading: false,
};

export const environmentsSlice = createSlice({
  name: "environments",
  initialState,
  reducers: {
    setEnvironments: (state, { payload }) => {
      state.environments = payload;
    },
    setStaticEnvironments: (state, { payload }) => {
      state.staticEnvironments = payload;
    },
  },
});

export const environmentsApi = createApi({
  reducerPath: "environmentsAPI",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    getEnviroments: builder.query({
      query: ({ take, where, orderBy }) => ({
        document: gql`
          query ($take: Int, $where: EnvironmentWhere, $orderBy: EnvironmentOrderBy) {
            environments(take: $take, where: $where, orderBy: $orderBy) {
              id
              key
              value
            }
            staticEnvironments
          }
        `,
        variables: {
          take,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { environments } = getState();

          // UPDATE LIST OF ENVIROMENTS AVAILABLE
          updateListOfEnvironments(environments?.environments, data?.environments, null, environmentsSlice, dispatch);

          // SAVE DEFAULT TEXTS
          dispatch(environmentsSlice.actions.setStaticEnvironments([...data?.staticEnvironments]));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    getEnviroment: builder.query({
      query: ({ key }) => ({
        document: gql`
          query environment($key: String!) {
            environment(key: $key) {
              value
            }
          }
        `,
        variables: {
          key,
        },
      }),
      transformResponse: (response) => (response.environment ? response.environment.value : null),
    }),
    createEnvironment: builder.mutation({
      query: ({ key, value }) => ({
        document: gql`
          mutation createEnvironment($key: String!, $value: Json!) {
            createEnvironment(key: $key, value: $value) {
              id
            }
          }
        `,
        variables: {
          key,
          value,
        },
      }),
      transformResponse: (response) => response.createEnvironment,
    }),
    updateEnvironment: builder.mutation({
      query: ({ id, value }) => ({
        document: gql`
          mutation updateEnvironment($id: String!, $value: Json!) {
            updateEnvironment(id: $id, value: $value) {
              id
            }
          }
        `,
        variables: {
          id,
          value,
        },
      }),
      transformResponse: (response) => response.updateEnvironment,
    }),
  }),
});

export const { setEnvironments, setStaticEnvironments } = environmentsSlice.actions;

export const { useGetEnviromentsQuery, useGetEnviromentQuery, useCreateEnvironmentMutation, useUpdateEnvironmentMutation } = environmentsApi;

export default environmentsSlice.reducer;
