import { inputsApi } from "app/stores/inputs";

const FULFILLED = "fulfilled";

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, ms);
  });

export const loadOptions = async (search, prevOptions, posttype, language, project, dispatch) => {
  await sleep(1000);

  const take = 10;
  const skip = prevOptions.length;
  const where = {
    post: {
      project: { id: project.id },
      postType: { id: posttype.id },
    },
    language: { id: language.id },
  };
  const orderBy = { title: "asc" };

  try {
    let allOptions = [...prevOptions];
    let options = [];
    let hasMore = false;

    const { data, status } = await dispatch(inputsApi.endpoints.getInputPosts.initiate({ take, skip, where, orderBy }));

    if (status === FULFILLED && data?.length) {
      const newOptions = data.map((e) => ({ value: e.id, label: e.title, type: e.posttype }));
      allOptions = [...allOptions, ...newOptions];
      options = newOptions;
      hasMore = allOptions.length < posttype.total;
    } else {
      hasMore = false;
      options = prevOptions;
    }

    if (search) {
      const filteredOptions = allOptions.filter(({ label }) => {
        return label.toLowerCase().includes(search.toLowerCase());
      });
      return { options: filteredOptions, hasMore };
    } else {
      return { options, hasMore };
    }
  } catch (error) {
    console.error(error);
    return { options: prevOptions, hasMore: false };
  }
};
