import { WrenchIcon } from "@heroicons/react/24/outline";
import Tooltip from "app/components/atoms/tooltip";
import { useGetSectionQuery } from "app/stores/builder";
import { useState } from "react";
import Permission from "../permission";
import BuilderTool from "./tool";
import { i18n } from "app/i18n";

export default function PopupBuilder(props) {
  const { id, block } = props;

  // STATE
  const [isOpen, setIsOpen] = useState(false);

  // REQUESTS
  const { data, refetch } = useGetSectionQuery({ id }, { skip: !isOpen });

  if (!id) return null;
  return (
    <Permission min="SUPER" dev>
      <>
        <Tooltip message={i18n("label.edit_structure")} placement="top">
          <kbd className="px-3 py-1.5 whitespace-nowrap cursor-pointer text-xs font-semibold text-slate-800 bg-slate-200 rounded-xl inline-flex items-center ml-2" onClick={() => setIsOpen(!isOpen)}>
            <WrenchIcon className="h-4" />
          </kbd>
        </Tooltip>
        <BuilderTool section={data} active={isOpen} setActive={setIsOpen} refetchSection={() => refetch()} />
      </>
    </Permission>
  );
}
