import Toggle from "app/components/atoms/inputs/toggle";
import Loading from "app/components/atoms/loading";
import { i18n } from "app/i18n";
import { useUpdateSectionStatusMutation } from "app/stores/post";
import { showServerError, showServerSuccess } from "app/utils/server";

export default function LabelSectionStatus(props) {
  const { id, enabled, content, section } = props;
  const [updateStatus, { isLoading }] = useUpdateSectionStatusMutation();

  const onClick = (enabled) => {
    updateStatus({ id, content, section, enabled }).then((res) => {
      if (res && res.data) {
        showServerSuccess(i18n("toast.success_status_update"))
      } else {
        showServerError(res);
      }
    });
  };

  return (
    <div className="section-status flex">
      <Toggle disabled={isLoading} value={enabled} onChange={(ev) => onClick(ev)} />
      <Loading active={isLoading} className="ml-2" />
    </div>
  );
}
