import { i18n } from "app/i18n";
import validator from "validator";

export const modules = [
  {
    title: "New User",
    label: "newuser",
    description: "When the user receives an email to complete the registration process.",
  },
  {
    title: "New Password",
    label: "newpassword",
    description: "When the user initiates the process to reset their password.",
  },
  {
    title: "Password Changed",
    label: "alertpassword",
    description: "When the user receives a notification when your account password is successfully changed.",
  },
  {
    title: "Success Register",
    label: "registersuccess",
    description: "When the confirmation of the registration process has been successfully completed.",
  },
  {
    title: "Alert Login",
    label: "errorlogin",
    description: "When the user receives the alert if there are multiple failed login attempts to your account.",
  },
  {
    title: "Two-Factor Authentication",
    label: "twofactor",
    description: "Enhance your account security with two-factor authentication.",
  },
  {
    title: "User Restore",
    label: "userrestore",
    description: "When the administration restores an account to its previous state or grants access after an issue.",
  },
  {
    title: "User Validation",
    label: "uservalidate",
    description: "When the user validates their account through user-driven actions for added security.",
  },
];

// { title: "Testing Email", label: "testemail" },

export const defaults = {
  // WHEN RECEIVE EMAIL TO REGIST
  newuser: {
    subject: "Svalla | New User",
    title: "Hello, [name]",
    text: "An administrator of site X has created a new account to access the backoffice with his email. Click on the link below and create your password to access the backoffice",
    buttonTxt: "Create password",
    label: "Svalla CMS",
    copyright: "You received this email because a request was made to regain access to your account. If you didn't ask it, you can ignore or delete this email.",
  },
  // WHEN ASK FOR NEW PASSWORD
  newpassword: {
    subject: "Svalla | Password Recovery",
    title: "Hello, [name]",
    text: "Recover access to your Svalla. Click on the button below and follow the steps to change your password.",
    buttonTxt: "Create new password",
    label: "Svalla CMS",
    copyright: "You received this email because a request was made to regain access to your account. If you didn't ask it, you can ignore or delete this email.",
  },
  // WHEN HIS PASSWORD IS CHANGED
  alertpassword: {
    subject: "Svalla | Password changed successfully",
    title: "Hello, [name]",
    text: "Your access password has been changed. From now on you can access Svalla using the new credentials.",
    buttonTxt: "Access to Svalla",
    label: "Svalla CMS",
    copyright: "You received this email because a request was made to regain access to your account. If you didn't ask it, you can ignore or delete this email.",
  },
  // WHEN REGISTER IS FINISHED
  registersuccess: {
    subject: "Svalla | Account validated",
    title: "Hello, [name]",
    text: "Your account is now fully configured. From now on you will be able to access the backoffice.",
    buttonTxt: "Access to Svalla",
    label: "Svalla CMS",
    copyright: "You received this email because a request was made to regain access to your account. If you didn't ask it, you can ignore or delete this email.",
  },
  // WHEN USER FAIL LOGIN MULTIPLE TIMES
  errorlogin: {
    subject: "Svalla | Forcing Login",
    title: "Hello, [name]",
    text: "Your account has experienced a number of failed login attempts. If this was you, please try to recover your password on the link below. If not alert the site administration and try to improve the security of your password.",
    buttonTxt: "Change my password",
    label: "Svalla CMS",
    copyright: "You received this email because a successive login fails for your account.",
  },
  // TWO-FACTOR AUTHENTICATION
  twofactor: {
    subject: "Svalla | Two-factor Authentication",
    title: "Hello, [name]",
    text: "This is the code for your Svalla CMS authentication. Please copy the code below and paste it into the respective field in your login window.",
    label: "Svalla CMS",
    copyright: "You received this email because you're trying to login",
  },
  // WHEN ACCOUNT IS RESTORED
  userrestore: {
    subject: "Svalla | User Restore",
    title: "Hello, [name]",
    text: "Your account is valid to access Svalla again.",
    buttonTxt: "Access to Svalla",
    label: "Svalla CMS",
    copyright: "You received this email because an admin restore your account",
  },
  // WHEN ACCOUNT IS VALIDATED BY USER
  uservalidate: {
    subject: "Svalla | Validate Account",
    title: "Hello, [name]",
    text: "Complete your account creating, validate your email account. After this you'll do login.",
    buttonTxt: "Verify your email",
    label: "Svalla CMS",
    copyright: "You received this email because a request was made to regain access to your account. If you didn't ask it, you can ignore or delete this email.",
  },
  // TESTING EMAIL SENDER
  testemail: {
    subject: "Svalla | Testing Email",
    title: "Email Server test",
    text: "If you receive this email, your email configuration is ok",
    buttonTxt: "Verify your email",
    label: "Svalla CMS",
  },
};

export const hiddenFields = {
  newuser: {
    table: true,
    endtext: true,
  },
  newpassword: {
    table: true,
    endtext: true,
  },
  alertpassword: {
    table: true,
    endtext: true,
  },
  registersuccess: {
    table: true,
    endtext: true,
  },
  errorlogin: {
    table: true,
    endtext: true,
  },
  twofactor: {
    table: true,
    button: true,
    endtext: true,
  },
  userrestore: {
    table: true,
    endtext: true,
  },
  uservalidate: {
    table: true,
    endtext: true,
  },
};

export const components = [
  { type: "Text", title: i18n("label.name"), key: "name", placeholder: "John Doe Backoffice", rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }] },
  { type: "Text", title: i18n("label.email"), key: "email", placeholder: "johndoe@svalla.com", rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }] },
  { component: "line" },
  { type: "Text", title: i18n("label.host"), key: "host", placeholder: "mail.host.com", rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }] },
  { type: "Text", title: i18n("label.port"), key: "port", placeholder: "465", rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }] },
  { type: "Toggle", title: i18n("label.secure"), key: "secure" },
  { type: "Toggle", title: i18n("label.reject_unauthorized"), key: "rejectUnauthorized" },
  { type: "Text", title: i18n("label.user"), key: "user", placeholder: "johndoe@svalla.com", rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }] },
  { type: "Password", title: i18n("label.password"), key: "password", rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }] },
];
