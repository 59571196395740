import { gql } from "@apollo/client";
import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { serverAuth } from "app/utils/server";
import { IDLE, LOADING, NOTFOUND, SUCCESS } from "app/utils/status";
import { addRevisionToBlock, updateActiveBlock, updateMenuPostTitle } from "app/utils/visual";
import { projectSlice } from "../project";

const initialState = {
  block: null,
  loading: false,
  blockStatus: IDLE,

  revisions: null,
  take: 5,
  page: 1,
  where: undefined,
  skip: undefined,
  orderBy: { createdAt: "desc" },
};

export const blockSlice = createSlice({
  name: "block",
  initialState,
  reducers: {
    setBlock: (state, { payload }) => {
      state.block = payload;
    },
    setBlockStatus: (state, { payload }) => {
      state.blockStatus = payload;
    },
  },
});

export const blockApi = createApi({
  reducerPath: "blockAPI",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    getBlock: builder.query({
      query: ({ id, language, project }) => ({
        document: gql`
          query ($id: String!, $project: String!) {
            block(id: $id, project: $project) {
              id
              contentType {
                structure
              }
              postTypes {
                id
              }
              translations {
                id
                slug
                title
                published
                language {
                  slug
                }
                revisions {
                  id
                  data
                  status
                  createdAt
                  author {
                    name
                  }
                }
              }
              project {
                id
                name
              }
            }
          }
        `,
        variables: {
          id,
          project,
          language,
        },
      }),
      transformResponse: (response) => response.block,
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          let status = LOADING;
          if (data.id && data.translations.find((e) => e.language.slug === params.language)) status = SUCCESS;
          else status = NOTFOUND;

          dispatch(blockSlice.actions.setBlock(data));
          dispatch(blockSlice.actions.setBlockStatus(status));
        } catch (err) {
          dispatch(blockSlice.actions.setBlockStatus(NOTFOUND));
        }
      },
    }),
    updateBlock: builder.mutation({
      query: ({ id, title, slug, published }) => ({
        document: gql`
          mutation updatePostTranslation($id: String!, $title: String, $slug: String, $published: Boolean) {
            updatePostTranslation(id: $id, title: $title, slug: $slug, published: $published) {
              id
              slug
              title
              type
              published
              language {
                slug
              }
              block {
                id
              }
            }
          }
        `,
        variables: {
          id,
          title,
          slug,
          published,
        },
      }),
      transformResponse: (response) => response.updatePostTranslation,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { project, block } = getState();

          // UPDATE NAME OF BLOCK IN MENU
          updateMenuPostTitle(project.info, data, projectSlice, dispatch);

          // UPDATE ACTIVE BLOCK INFORMATION
          updateActiveBlock(block.block, data, blockSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    createRevision: builder.mutation({
      query: ({ data, content, status }) => ({
        document: gql`
          mutation createRevisionBlock($data: Json!, $content: GenericConnect!, $status: RevisionStatus!) {
            createRevisionBlock(data: $data, content: $content, status: $status) {
              id
              revisions {
                id
                data
                status
                createdAt
                author {
                  name
                }
              }
            }
          }
        `,
        variables: {
          data,
          content: { connect: { id: content } },
          status: status ? "PUBLIC" : "DRAFT",
        },
      }),
      transformResponse: (response) => response.createRevisionBlock,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          let { block } = getState();

          // UPDATE ACTIVE BLOCK INFORMATION
          addRevisionToBlock(block.block, data, blockSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    updateBlockPostTypes: builder.mutation({
      query: ({ id, posttypes }) => ({
        document: gql`
          mutation updateBlock($id: ID!, $posttypes: [ID!]) {
            updateBlock(id: $id, posttypes: $posttypes) {
              id
              postTypes {
                id
              }
            }
          }
        `,
        variables: {
          id,
          posttypes,
        },
      }),
      transformResponse: (response) => response.updateBlock,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          let { block } = getState();
          dispatch(blockSlice.actions.setBlock({ ...block.block, ...data }));
        } catch (err) {
          dispatch(blockSlice.actions.setBlockStatus(NOTFOUND));
        }
      },
    }),
  }),
});

export const { setBlock, setBlockStatus } = blockSlice.actions;

export const { useGetBlockQuery, useUpdateBlockMutation, useCreateRevisionMutation, useUpdateBlockPostTypesMutation } = blockApi;

export default blockSlice.reducer;
