import Icon from "app/components/atoms/icons/icon";
import React from "react";
import Text from "../../text";
import { i18n } from "app/i18n";
import { cleanHTML } from "app/utils/content";

export default function BlockHeader(props) {
  const { isEnter, labelName, title, label, name, shortTitle, labelChange, duplicate, remove, error } = props;

  return (
    <>
      <div className="flex items-center flex-1">
        <span type="button" role="button" className={`btn-icon ${isEnter ? "transform -rotate-180" : ""} transition-all ease-in-out duration-300`}>
          <Icon name={labelChange ? "chevronDown" : "menu"} />
        </span>
        {error && (
          <span className="inline-block ml-2">
            <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">{i18n("label.invalid")}</span>
          </span>
        )}
        <span className="inline-block ml-2 w-full">
          {labelChange ? (
            <Text value={cleanHTML(labelName || title || label || name || shortTitle)} placeholder="New Line" mode="hidden" onChange={(v) => labelChange(v)} onClick={(e) => e.stopPropagation()} />
          ) : (
            <div className="py-2 h-5 box-content text-sm">{cleanHTML(labelName || title || label || name || shortTitle)}</div>
          )}
        </span>
      </div>
      <div className="flex">
        {duplicate && (
          <span
            type="button"
            role="button"
            className="btn-icon"
            onClick={(ev) => {
              ev.stopPropagation();
              duplicate();
            }}
          >
            <Icon name="copy" />
          </span>
        )}
        {remove && (
          <span
            type="button"
            role="button"
            className="btn-icon"
            onClick={(ev) => {
              ev.stopPropagation();
              remove();
            }}
          >
            <Icon name="delete" />
          </span>
        )}
      </div>
    </>
  );
}
