import React, { useState } from "react";
import { i18n } from "app/i18n";
import validator from "validator";
import { Link, useOutletContext } from "react-router-dom";
import Form from "../../components/organisms/form";
import { useRequestNewPasswordMutation } from "../../stores/auth";
import { validation } from "../../utils/validators";
import { useDispatch } from "react-redux";
import Loading from "app/components/atoms/loading";

export default function RequestPassword() {
  const { buttonColor } = useOutletContext();

  const components = [
    {
      type: "Text",
      key: "email",
      placeholder: "Email",
      rules: [
        {
          method: validator.isEmpty,
          validWhen: false,
          message: i18n("input.empty_email"),
        },
        {
          method: validator.isEmail,
          validWhen: true,
          message: i18n("input.invalid_email"),
        },
      ],
    },
  ];
  const [value, setValue] = useState(null);
  const [formMessage, setFormMessage] = useState(null);
  const [structure, setStructure] = useState(components);

  const [requestPassword, { data, isLoading: isRequesting }] = useRequestNewPasswordMutation();

  const onClickEnter = (code) => {
    if (code === 13) onSubmit();
  };

  const onSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) {
        requestPassword({ email: value.email }).then((res) => {
          if (res && res.data && !res.data.error) {
            setValue(null);
            setFormMessage({ status: true, message: i18n("user.request_successfully") });
          } else {
            setFormMessage({ status: !data.error, message: i18n("user.request_error") });
          }
        });
      }
    });
  };

  return (
    <div>
      <div className="mb-8">
        <h1 className="text-xl text-[#0c1832]">{i18n("requestPassword.title")}</h1>
        <p className="text-md text-[#627385]">{i18n("requestPassword.description")}</p>
      </div>

      <Form value={value} onChange={setValue} structure={structure} mode={"login"} onKeyDown={onClickEnter} />

      {formMessage && (
        <div className={`relative w-full p-2 rounded-sm bg-white/40 shadow-md border-b-2 ${formMessage.status ? "text-green-700  border-green-700" : "text-red-700  border-red-700"}`} role="alert">
          <span className="text-xs font-normal">{formMessage.message}</span>
        </div>
      )}

      <div className="flex justify-between items-center flex-wrap mt-6">
        <div className="flex items-center mt-2 mb-2 mr-6">
          <button
            type="button"
            onClick={onSubmit}
            disabled={isRequesting || (data && !data.error)}
            className="inline-block px-7 py-3 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition-all duration-500 ease-in-out disabled:pointer-events-none disabled:!cursor-not-allowed disabled:!shadow-md bg-[#182e5f] disabled:!opacity-30 hover:!bg-[#0c1832] focus:bg-[#0c1832] active:bg-[#0c1832]"
            style={{ backgroundColor: buttonColor || undefined }}
          >
            {i18n('button.requestPassword')}
          </button>
          <Loading active={isRequesting} className="ml-2" />
        </div>

        <Link to={"/login"} className="text-gray-800 text-sm transition-all ease-in-out duration-500 hover:!text-[#0c1832] mt-2 mb-2" style={{ color: buttonColor || undefined }}>
          {i18n('button.backToLogin')}
        </Link>
      </div>
    </div>
  );
}
