import Loading from "app/components/atoms/loading";
import { Content, Footer, Tabs } from "app/components/atoms/modal";
import { i18n } from "app/i18n";
import { filesApi, setSkip, setWhere, useGetMediaTotalQuery } from "app/stores/files";
import { IDLE, LOADING } from "app/utils/status";
import { isArray } from "app/utils/validators/dataValidator";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListFiles from "../lists/listFiles";
import MediaFilters from "./filters";
import UploadArea from "./uploadArea";

export default function Media(props) {
  const { value, multi, formats, withAlt, withTitle, onClose, onSubmit } = props;

  // PARAMS
  const dispatch = useDispatch();

  // REF
  const sentinelRef = useRef(null);

  // STORE
  const { user } = useSelector((state) => state.auth);
  const { project } = useSelector((state) => state.project);
  const { files, skip, take, where, orderBy } = useSelector((state) => state.file);

  // STATES
  const [status, setStatus] = useState(IDLE);
  const [selected, setSelected] = useState([]);
  const [tabActive, setTabActive] = useState(0);
  const [forceType, setForceType] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [keyControl, setKeyControl] = useState({ shift: false, ctrl: false });

  // REQUESTS
  const { data: total, isLoading: isLoadingTotal, refetch: refetchTotal } = useGetMediaTotalQuery({ where });

  // CONSTANTS
  const tabs = [{ name: i18n("button.list") }, { name: i18n("button.upload") }];
  const loading = status === LOADING || isLoadingTotal;
  const submitDisabled = loading || !onSubmit || !files || (!multi && selected && selected.length !== 1) || (multi && selected && selected.length < 1);

  // CHECK KEY PRESSED
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  // IF VALUE CHANGE
  useEffect(() => {
    if (value) {
      if (isArray(value, true)) setSelected(value.map((item) => item.id));
      else setSelected([value.id]);
    }
  }, [value]);

  // WHEN FORMAT CHANGES
  useEffect(() => {
    if (formats || project || user) {
      let typeObj = { type: undefined };

      // IF FILTER FOR AVATAR (FILTER BY USER TOO)
      if (formats === "AVATAR" && user) {
        typeObj = { type: "AVATAR", uploadBy: { id: user.id } };
        setForceType("AVATAR");
      } else if (formats) {
        if (forceType) setForceType(false);
        if (typeof formats === "string") typeObj = { type: formats };
        else if (isArray(formats, true) && formats?.length === 1) typeObj = { type: formats[0] };
        else if (isArray(formats, true) && formats?.length >= 2) typeObj = { OR: formats.map((type) => ({ type })) };
        else typeObj = { type: undefined };
      }
      // FILTER BY PROJECT (!IMPORTANT!)
      if (project) typeObj = { ...typeObj, project: { id: project.id } };
      else typeObj = { ...typeObj, project: null };

      dispatch(setWhere({ ...where, ...typeObj }));
    }
  }, [formats, project, user]);

  // SENTINEL ALERT
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          dispatch(setSkip(files?.length || 0));
        }
      },
      { threshold: 0.5 }
    );
    const listSentinel = sentinelRef ? sentinelRef.current : undefined;
    if (listSentinel) observer.observe(listSentinel);
    return () => {
      if (listSentinel) observer.unobserve(listSentinel);
    };
  }, [sentinelRef, total, files]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData();
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, [take, skip, where, orderBy]);

  // ACTION FOR KEY DOWN
  const handleKeyDown = (event) => {
    if (event.shiftKey || event.ctrlKey || event.metaKey) setKeyControl({ shift: event.shiftKey, ctrl: event.ctrlKey || event.metaKey });
  };

  // ACTION FOR KEY UP
  const handleKeyUp = () => {
    setKeyControl({ shift: false, ctrl: false });
  };

  // ACTION FOR SELECTION
  const onSelect = (id) => {
    let temp = selected || [];
    let isArray = typeof id !== "string";
    const currentActive = temp.findIndex((e) => e === id);
    if (keyControl.ctrl) {
      if (currentActive !== -1) temp.splice(currentActive, 1);
      else temp.push(id);
    } else if (keyControl.shift) {
      if (currentActive !== -1) {
        temp.splice(currentActive, 1);
      } else if (temp.length) {
        const lastIndex = files.findIndex((file) => file.id === temp[temp.length - 1]);
        const clickIndex = files.findIndex((file) => file.id === id);
        files.filter((f, k) => k >= Math.min(lastIndex, clickIndex) && k <= Math.max(lastIndex, clickIndex)).forEach((file) => temp.push(file.id));
      } else {
        temp = isArray ? [...id] : [id];
      }
    } else {
      temp = isArray ? [...id] : [id];
    }
    setSelected(temp?.length ? [...temp] : null);
  };

  const onClickSubmit = () => {
    if (typeof onSubmit !== "undefined") {
      let selection = null;
      if (multi) {
        selection = files
          .filter((item) => selected.includes(item.id))
          .map(({ id, path, alt, title }) => {
            let result = { id, path };
            if (withAlt) result.alt = alt;
            if (withTitle) result.title = title;
            return result;
          });
      } else {
        selection = files
          .filter((item) => item.id === selected[0])
          .map(({ id, path, alt, title }) => {
            let result = { id, path };
            if (withAlt) result.alt = alt;
            if (withTitle) result.title = title;
            return result;
          });
        selection = selection && selection.length ? selection[0] : null;
      }
      onSubmit(selection);
      setSelected([]);
    }
  };

  const fetchData = async () => {
    setStatus(LOADING);
    refetchTotal();
    const request = await dispatch(filesApi.endpoints.getMedia.initiate({ take, skip, where, orderBy }, { forceRefetch: true }));
    setStatus(request.status);
  };

  return (
    <>
      <Tabs tabs={tabs} active={tabActive} onChange={setTabActive} toggleFilters={() => setShowFilters(!showFilters)} />
      <Content tabs tab={tabActive} noPad>
        <div className="relative">
          <MediaFilters modal formats={formats} show={showFilters} loading={status === LOADING} />
          <div className="p-6 overflow-auto h-[640px] max-h-[640px] relative">
            <Loading active={status === LOADING} className="absolute inset-0 z-10 bg-white flex items-center justify-center" />
            <ListFiles files={files} selected={selected} onSelect={onSelect} />
            {files?.length && files?.length < total ? <div ref={sentinelRef} className="h-0.5"></div> : null}
          </div>
        </div>
        <div>
          <div className="p-6">
            <UploadArea
              formats={formats}
              loading={loading}
              forceType={forceType}
              select={onSelect}
              reload={() => {
                fetchData();
                setTabActive(0);
              }}
            />
          </div>
        </div>
      </Content>
      {onSubmit && (
        <Footer
          loading={loading}
          cancel={{
            text: i18n("button.close"),
            onClick: onClose,
          }}
          submit={{
            text: i18n("button.save"),
            disabled: submitDisabled,
            onClick: onClickSubmit,
          }}
        />
      )}
    </>
  );
}
