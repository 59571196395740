import LogoSvalla from "app/components/atoms/logoSvalla";
import { authApi } from "app/stores/auth";
import { useGetEnviromentQuery } from "app/stores/environment";
import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const BACKEND = process.env.REACT_APP_MULTIMEDIA_HOST;

export default function Intro() {
  const { pathname } = useLocation();
  const { data: environment, refetch } = useGetEnviromentQuery({ key: "dashboard" });
  const { data: emptyProject } = authApi.endpoints.emptyProject.useQuery(null, { skip: false, refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (pathname === "/login") refetch();
  }, [pathname]);

  if (emptyProject) {
    return <Navigate to="/welcome" />;
  }

  const src = environment?.image?.path ? `${BACKEND}/${environment?.image?.path}` : "/imgs/home-background.jpg";

  return (
    <section className="h-screen overflow-auto">
      <div className="w-full min-h-full bg-white text-gray-800 relative flex flex-col justify-center pt-14 pb-4 px-6 lg:px-20 md:px-16 sm:px-10">
        <img src={src} className="absolute inset-0 w-full h-full object-cover" alt="An intro logo of svalla" />
        <div className="absolute inset-0 w-full h-full object-cover bg-black/10" />
        <div className="relative z-10">
          <LogoSvalla />
        </div>
        <div className="relative z-10 flex-1 flex items-center justify-end pt-10 pb-10">
          <div className="bg-white/40 p-3 rounded-3xl max-w-[575px] min-w-full lg:min-w-[575px]">
            <div className="bg-white/80 px-5 sm:px-10 py-10 rounded-xl backdrop-blur-md shadow-2xl ">
              <Outlet
                context={{
                  buttonColor: environment?.color,
                }}
              />
            </div>
          </div>
        </div>
        <div className="relative z-10 text-center">
          <p className="text-white/60 text-xs">© Copyright 2016-2023</p>
        </div>
      </div>
    </section>
  );
}
