export const updateMediaInfo = (list, item, del, add, addToEnd) => {
  if (!list?.length) return list;
  let temp = [...list];
  if (del) {
    const items = item;
    for (let i = 0; i < items.length; i++) {
      const index = temp.findIndex((e) => e.id === items[i].id);
      if (index !== -1) temp.splice(index, 1);
    }
  } else if (add) {
    const items = item;
    temp = [...items, ...list];
  } else if (addToEnd) {
    const items = item;
    temp = [...list, ...items];
  } else {
    const index = temp.findIndex((e) => e.id === item.id);
    if (index !== -1) temp[index] = { ...temp[index], ...item };
  }
  return temp;
};
