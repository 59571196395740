import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React from "react";

export default function Textarea(props) {
  const { title, description, value = "", onChange, onBlur, placeholder, error, disabled, errorMessage } = props;

  let styles = {
    title: "block text-sm font-medium mb-1",
    description: "block text-xs font-light text-slate-400 -mt-1.5 mb-1",
    input: "bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 trasition-all duration-300 ease-in-out ring-0 outline-none focus-visible:border-none disabled:pointer-events-none",
  };

  if (error) {
    styles.title += " text-red-600";
    styles.input += " border-red-600 hover:border-red-600 focus:border-red-600";
  } else if (disabled) {
    styles.input += " border-gray-300";
  } else {
    styles.title += " text-slate-700";
    styles.input += " border-gray-300 hover:border-gray-400 focus:border-blue-700";
  }

  return (
    <label className="block mb-3">
      <div className="flex items-end justify-between">
        <div>
          {title && <span className={styles.title}>{title}</span>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {error && <ExclamationTriangleIcon className="h-4 w-4 text-red-600 mb-1" />}
      </div>
      <textarea
        rows="4"
        onBlur={onBlur}
        disabled={disabled}
        className={styles.input}
        placeholder={placeholder}
        value={value ? value.replace(/<br\s*[\/]?>/gi, "\n") : ""}
        onChange={(ev) => onChange(ev.target.value.replace(/(?:\r\n|\r|\n)/g, "<br>"))}
      />
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </label>
  );
}
