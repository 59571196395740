import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import Dropdown from "app/components/molecules/dropdown";
import LabelPostStatus from "app/components/molecules/labels/labelPostStatus";
import Permission from "app/components/molecules/permission";
import { i18n } from "app/i18n";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function ItemPost(props) {
  const { id, translations, onCreateTranslation, onDuplicate, onDelete } = props;

  const navigate = useNavigate();

  const { language, languages } = useSelector((state) => state.project);
  const info = language && translations?.length ? translations.find((item) => item.language.slug === language.slug) : undefined;
  const other = !info ? (translations?.length ? translations[0] : undefined) : undefined;

  const onEdit = () => {
    navigate(`${id}/${language.slug}`);
  };

  const openPageUrl = (url) => {
    // GLOBE
    window.open(url, "_blank");
  };

  return (
    <tr key={id}>
      <td className="flex flex-col">
        {info && (
          <>
            <Link to={`${id}/${language.slug}`} className="text-sm text-slate-700 whitespace-nowrap">
              {info.title}
            </Link>
            {info.url ? (
              <a href={info.url} target="_blank" rel="noopener noreferrer">
                <span className="text-[10px] text-slate-300">{info.slug}</span>
              </a>
            ) : (
              <span className="text-[10px] text-slate-300">{info.slug}</span>
            )}
          </>
        )}
        {other && (
          <>
            <span className="text-sm text-slate-300 whitespace-nowrap">
              <span>{other.title}</span>
              <span className="text-[10px] pl-2">[{other.language.slug}]</span>
            </span>
            <span className="text-[10px] text-slate-300">{i18n("label.without_translation")}</span>
          </>
        )}
      </td>
      <td>
        {languages?.length && (
          <>
            {languages.map((lang, key) => {
              const exists = translations?.length ? translations.findIndex((item) => item.language.slug === lang.language.slug) : undefined;
              return (
                <span key={key} className="h-4 w-6 mx-1 inline-flex items-center justify-center transition-all ease-in-out duration-500 text-gray-500 hover:text-gray-900">
                  {exists !== -1 ? (
                    <Link to={`${id}/${lang.language.slug}`}>
                      <PencilIcon className="w-5" />
                    </Link>
                  ) : (
                    <>
                      <Permission project dev min="MANAGER">
                        <button type="button" onClick={() => onCreateTranslation(lang)}>
                          <PlusIcon className="w-5" />
                        </button>
                      </Permission>
                    </>
                  )}
                </span>
              );
            })}
          </>
        )}
      </td>
      <td className="w-10">{info && <LabelPostStatus published={info?.published} />}</td>
      <td className="w-44">
        {info && (
          <>
            <p className="text-slate-500 text-xs">{info?.author?.name || "NO AUTHOR"}</p>
            <p className="text-slate-400 text-xs">{dayjs(info.publishedDate).format("DD.MM.YYYY HH[h]mm")}</p>
          </>
        )}
      </td>
      <td>
        <Permission min="MANAGER" project dev>
          <Dropdown
            icon="ellipsis"
            disabled={!info}
            options={[
              { title: i18n("button.edit"), icon: "edit", onClick: onEdit },
              { title: i18n("button.duplicate"), icon: "copy", onClick: onDuplicate },
              { title: i18n("button.see_page"), icon: "globe", disabled: !info?.url || !info?.published, onClick: () => openPageUrl(info.url) },
              { title: i18n("button.delete"), icon: "delete", onClick: onDelete, delete: true },
            ]}
          />
        </Permission>
      </td>
    </tr>
  );
}
