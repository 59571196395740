// Atoms
import { useDispatch } from "react-redux";
import { Header, Struture } from "../../../atoms/modal";

// Organisms
import Media from "app/components/organisms/media";

// STORE FUNCTIONS
import { setSkip } from "app/stores/files";

export default function ModalMedia(props) {
  const { isOpen = false, onClose, onSubmit } = props;

  const dispatch = useDispatch();

  const onClickClose = () => {
    if (onClose) {
      onClose();
      dispatch(setSkip(0));
    }
  };

  return (
    <Struture isOpen={isOpen} onClose={onClickClose} size="md" media>
      <Header title="Multimedia" onClose={onClickClose} />
      <Media {...props} onClose={onClickClose} onSubmit={onSubmit} />
    </Struture>
  );
}
