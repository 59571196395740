import { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

// store
import { useDispatch, useSelector } from "react-redux";
import { setLogout, useLogoutMutation } from "../../../stores/auth";

// ATOMS
import Avatar from "app/components/atoms/avatar";

// MOLECULES
import Dropdown from "../dropdown";
import ModalAccount from "../modals/modalAccount";

// i18n
import { i18n } from "app/i18n";

export default function Account({ toggleLanguage }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const { user } = useSelector((state) => state.auth);
  const { project } = useSelector((state) => state.project);
  const [cookies, setCookie, removeCookie] = useCookies(["logged_in", "access_token"]);

  const [isOpen, setIsOpen] = useState(false);

  const onEditAccount = () => {
    setIsOpen(true);
  };

  const onLogout = () => {
    logout()
      .then(() => {
        removeCookie("logged_in", "");
        removeCookie("access_token", "");
        dispatch(setLogout());
      })
      .then(() => navigate("/login"));
  };

  if (!user) return null;

  const projectRole = user?.projects?.find((e) => e.project.id === project?.id);

  return (
    <>
      <div className="flex items-center gap-2">
        <Avatar photo={user.photo} name={user.name} />
        <div className="min-w-fit">
          <div className="text_sm max-w-[140px] truncate">{user.name}</div>
          {projectRole ? <div className="text_xs max-w-[140px] truncate">{projectRole.role}</div> : <div className="text_xs max-w-[140px] truncate">{user.role}</div>}
        </div>
        <Dropdown
          icon="chevronDown"
          options={[
            { title: i18n("button.account"), onClick: onEditAccount, icon: "user" },
            { title: i18n("button.logout"), onClick: onLogout, icon: "logout", delete: true },
          ]}
        />
      </div>

      <ModalAccount isOpen={isOpen} toggleLanguage={toggleLanguage} onClose={() => setIsOpen(false)} />
    </>
  );
}
