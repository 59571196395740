import Icon from "app/components/atoms/icons/icon";
import IconDelete from "app/components/atoms/icons/iconDelete";
import IconDuplicate from "app/components/atoms/icons/iconDuplicate";
import IconEdit from "app/components/atoms/icons/iconEdit";
import Tooltip from "app/components/atoms/tooltip";
import { i18n } from "app/i18n";
import { isArray, isObject } from "app/utils/validators/dataValidator";

// BUTTONS
const ButtonEdit = ({ basic, node, path, parentNode, onClick }) => {
  if (basic) return null;
  return (
    <button
      type="button"
      onClick={() => onClick({ node, path, parentNode })}
      className="text-gray-400 bg-gray-200 hover:text-gray-200 hover:bg-gray-600 transition-all duration-300 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center "
    >
      <IconEdit height="h-4" width="h-4" />
      <span className="sr-only">Edit</span>
    </button>
  );
};
const ButtonFavorite = ({ basic, node, path, onClick, isCustom, disabled }) => {
  if (basic || node.type !== "Group") return null;

  let icon = "star";
  let message = i18n("button.add_to_custom");
  let className = "transition-all duration-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center hover:text-gray-200 hover:bg-yellow-600 focus:outline-none";

  if (isCustom) className += " text-white bg-gray-300";
  else className += " text-gray-400 bg-gray-200";

  if (node.blocked) {
    icon = "lock";
    message = i18n("button.unlock_structure");
  }

  return (
    <Tooltip message={message} placement="left">
      <button type="button" disabled={disabled} className={className} onClick={() => onClick(node, node.blocked, path)}>
        <Icon name={icon} height="h-4" width="h-4" />
        <span className="sr-only">Favorite</span>
      </button>
    </Tooltip>
  );
};
const ButonBlock = ({ basic, node, path, onClick, disabled }) => {
  if (basic || disabled) return null;

  let icon = "unlock";
  let message = i18n("button.block");
  let className = "transition-all duration-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center focus:outline-none";

  if (node.blocked) {
    icon = "lock";
    message = i18n("button.unblock");
    className += " text-gray-200 bg-gray-400 hover:bg-gray-600";
  } else {
    className += " text-gray-400 bg-gray-200 hover:text-gray-200 hover:bg-gray-600";
  }

  return (
    <Tooltip message={message} placement="left">
      <button type="button" disabled={disabled} className={className} onClick={() => onClick(node, !!node.blocked, path)}>
        <Icon name={icon} height="h-4" width="h-4" />
        <span className="sr-only">Favorite</span>
      </button>
    </Tooltip>
  );
};
const ButtonDuplicate = ({ basic, node, treeIndex, path, onClick }) => {
  if (basic) return null;
  return (
    <button
      type="button"
      onClick={() => onClick({ node, treeIndex, path })}
      className="text-gray-400 bg-gray-200 hover:text-gray-200 hover:bg-gray-600 transition-all duration-300 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center "
    >
      <IconDuplicate height="h-4" width="h-4" />
      <span className="sr-only">Duplicate</span>
    </button>
  );
};
const ButtonDelete = ({ path, onClick }) => {
  return (
    <button
      type="button"
      onClick={() => onClick(path)}
      className="text-gray-400 bg-gray-200 hover:text-gray-200 hover:bg-red-600 transition-all duration-300 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center "
    >
      <IconDelete height="h-4" width="h-4" />
      <span className="sr-only">Delete</span>
    </button>
  );
};

// LABELS
const Title = ({ node }) => {
  return <span>{node.title}</span>;
};
const Subtitle = ({ basic, node }) => {
  if (basic) return null;
  return <span>{node.key}</span>;
};
const LabelRequired = ({ basic, node }) => {
  if (basic || !node || !node.isRequired) return null;
  return <kbd className="bg-orange-300 text-text text-xs py-1 px-2 mr-1 rounded-lg">Required</kbd>;
};
const LabelType = ({ basic, isCustom, node }) => {
  if (basic) return null;
  if (isCustom) return <kbd className="bg-yellow-100 text-yellow-700 text-xs py-1 px-2 mr-4 rounded-lg">Custom</kbd>;
  return <kbd className="bg-primary-100 text-primary-700 text-xs py-1 px-2 mr-4 rounded-lg">{node.type}</kbd>;
};

// FUNCTIONS
const getFieldsOfLevel = (treeData, path, exclude) => {
  let temp = treeData;
  if (path.length > 1) {
    let currentDepth = 0;
    for (let i = 0; i < path.length - 1; i++) {
      const index = path[i] - currentDepth;
      temp = temp[index].children;
      currentDepth = i;
    }
  }
  return temp.filter((item) => (exclude && item.key && item.key !== exclude.key) || (!exclude && item.key)).map((item) => ({ value: item.key, label: item.title }));
};
const isCustomInput = (input, custom) => {
  return input?.type === "Group" && custom?.length && custom.find((e) => e.model === input.id || e.model === input.model);
};
const prepareDataForTree = (val, custom) => {
  if (!val) return [];
  if (!custom?.length) return val.map((item) => ({ ...item, expanded: true }));

  return val.map((item) => {
    if (isCustomInput(item, custom)) return { ...item, expanded: false, blocked: true };
    else if (item?.children?.length) return { ...item, expanded: true, children: prepareDataForTree(item.children, custom) };
    else return { ...item, expanded: true };
  });
};
const searchKeyInTheArray = (array, key, id) => {
  for (var i = 0; i < array.length; i++) {
    if (array[i].key == key && array[i].id !== id) return false;
  }
  return true;
};
const isKeyAvailableInArray = (parent, array, key, id) => {
  if (parent) return searchKeyInTheArray(parent.children, key, id);
  else if (array && array.length) return searchKeyInTheArray(array, key, id);
  else return true;
};

export { getFieldsOfLevel, prepareDataForTree, isKeyAvailableInArray, isCustomInput, Title, Subtitle, ButtonFavorite, ButonBlock, ButtonDelete, ButtonDuplicate, LabelRequired, ButtonEdit, LabelType };
