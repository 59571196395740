import Icon from "app/components/atoms/icons/icon";
import { fileTypes, formatSize, getSource, isImageExtension } from "app/utils/content";
import React from "react";

export default function MediaItem(props) {
  const { id, title, extension, size, type, path, active, onClick } = props;

  let styles = {
    outer: "border-b group ",
    image: "pointer-events-none h-10 aspect-video bg-gray-300 object-scale-down group-hover:opacity-75 ",
    file: "pointer-events-none h-10 aspect-video object-cover flex items-center justify-center bg-gray-300 text-xs font-bold uppercase group-hover:opacity-75",
  };

  if (active) {
    styles.outer += "bg-gray-50 ";
    styles.image += "";
  } else {
    styles.outer += "";
    styles.image += "group-hover:opacity-75";
  }

  return (
    <tr className={styles.outer}>
      <td className="relative px-7 sm:w-12 sm:px-6">
        {active && <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          value={id}
          checked={active}
          onChange={() => onClick(id)}
        />
      </td>
      <td>
        <div className="flex items-center py-2 cursor-pointer" onClick={() => onClick(id)}>
          <div className="mr-2 rounded-lg overflow-hidden">
            {type === "IMAGE" || isImageExtension(extension) ? (
              <img src={getSource(path)} alt="" className={styles.image} />
            ) : (
              <div className={styles.file}>
                <span>{extension}</span>
              </div>
            )}
          </div>
          <div>
            <p className="block text-base font-medium text-gray-900 truncate pointer-events-none">{title}</p>
            <p className="block text-sm font-medium text-gray-500 pointer-events-none uppercase">{type.includes("EXTERNAL") ? fileTypes.find((e) => e.value === type).label : formatSize(size)}</p>
          </div>
        </div>
      </td>
      {/* <td>{!type.includes("EXTERNAL") ? formatSize(size) : "-"}</td>
      <td>{fileTypes.find((e) => e.value === type).label}</td> */}
      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <button type="button" className="btn-icon m-0 ml-auto" onClick={() => onClick(id)}>
          <Icon name="chevronRight" className="text-gray-400 hover:text-gray-700" />
        </button>
      </td>
    </tr>
  );
}
