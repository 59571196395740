import Dropdown from "app/components/molecules/dropdown";
import LabelSyncStatus from "app/components/molecules/labels/labelSyncStatus";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalPreview from "app/components/molecules/modals/modalPreview";
import ModalTemplate from "app/components/molecules/modals/modalTemplate";
import BuilderConstructor from "app/components/organisms/builder";
import { useDeleteTemplateMutation, useGetSectionsQuery, useGetTemplateQuery, useUpdateTemplateMutation } from "app/stores/builder";
import { showServerError } from "app/utils/server";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "app/components/atoms/loading";
import { i18n } from "app/i18n";

export default function TemplateEdit(props) {
  const { id, reloadList } = props;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [modal, setModal] = useState(null);
  const [previewModal, setPreviewModal] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);
  const { data: template, isFetching, isLoading: isGetting, refetch } = useGetTemplateQuery({ id });
  const { data: sections, isFetching: isFetchingSections, isLoading: isGettingSections } = useGetSectionsQuery({ where: { type: "PAGE" }, orderBy: { title: "asc" } });
  const [updateTemplate, { isLoading: isUpdating, isError }] = useUpdateTemplateMutation();
  const [deleteTemplate, { isLoading: isDeleting }] = useDeleteTemplateMutation();

  const isLoading = isFetching || isGetting || isFetchingSections || isGettingSections || isUpdating || isDeleting;

  useEffect(() => {
    refetch();
  }, [pathname]);

  const onOpenPreview = () => {
    setPreviewModal({
      isOpen: true,
      list: template.sections.map((item) => ({ name: item?.section?.title, image: item?.section?.thumbnail?.href })),
      onClose: () => setPreviewModal(null),
    });
  };

  const onUpdate = (list = []) => {
    let sections = undefined;
    if (list) {
      sections = { create: [], update: [] };
      for (let i = 0; i < list.length; i++) {
        if (list[i].section) sections.update.push({ id: list[i].id, order: i });
        else sections.create.push({ id: list[i].id, order: i });
      }
    }
    updateTemplate({ id: template.id, sections }).then((res) => {
      if (res && res.data) refetch();
      else showServerError(res);
    });
  };

  const onEdit = () => {
    setModal({
      template,
      isOpen: true,
      buttonText: i18n("button.update_template"),
      onSubmit: (data) => {
        updateTemplate({ id: data.id, title: data.title }).then((res) => {
          if (res && res.data) {
            refetch();
            reloadList();
          } else {
            showServerError(res);
          }
          setModal(null);
        });
      },
      onClose: () => setModal(null),
    });
  };

  const onDelete = () => {
    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      buttonText: i18n("button.delete"),
      forceNotice: template.inUse,
      notice: i18n("alert.template_in_use"),
      text: i18n("alert.template_delete"),
      onConfirm: () => {
        deleteTemplate({ id: template.id }).then((res) => {
          if (res && res.data) {
            reloadList();
            navigate("/dashboard/builders/templates");
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setConfirmModal(null),
    });
  };

  if (!id)
    return (
      <div>
        <p>{i18n("templates.noItems")}</p>
      </div>
    );

  if (isFetching) return <Loading active className="h-full flex items-center justify-center" />;

  return (
    <>
      <BuilderConstructor data={{ ...template }} options={sections} isLoading={isLoading} isError={isError} basic onUpdate={onUpdate} placeholder={i18n("label.your_sections_here")}>
        {template && template.sections && !!template.sections.filter((e) => !!!e?.section?.thumbnail?.href).length && (
          <button className="btn-primary-outline mx-2" onClick={onOpenPreview}>
            {i18n("label.preview")}
          </button>
        )}
        <Dropdown
          options={[
            { title: i18n("button.edit"), onClick: onEdit, icon: "edit" },
            { title: i18n("button.delete"), onClick: onDelete, icon: "delete", delete: true },
          ]}
        />
      </BuilderConstructor>

      <ModalTemplate {...modal} />
      <ModalPreview {...previewModal} />
      <ModalConfirm {...confirmModal} />
    </>
  );
}
