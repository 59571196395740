import { i18n } from "app/i18n";
import { projectRolesList, rolesList } from "app/utils/roles";
import customValidator from "app/utils/validators/customValidator";
import validator from "validator";

const components = [
  {
    type: "Text",
    key: "name",
    title: i18n("label.name"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("label.required"),
      },
    ],
  },
  {
    type: "Text",
    key: "email",
    title: i18n("label.email"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("label.required"),
      },
      {
        method: validator.isEmail,
        validWhen: true,
        message: i18n("input.email_invalid_format"),
      },
      {
        method: customValidator.isEmailAvailable,
        validWhen: true,
        args: { id: undefined },
        message: i18n("input.email_already_in_use"),
        code: "already.in.use",
      },
    ],
  },
  {
    type: "Select",
    key: "role",
    title: i18n("label.global_profile_role"),
    options: rolesList,
    rules: [
      {
        method: customValidator.isObjectEmpty,
        validWhen: false,
        message: i18n("label.required"),
      },
    ],
  },
];

const inputProjectRole = {
  type: "Select",
  key: "role",
  title: i18n("input.project_role"),
  options: projectRolesList,
  rules: [
    {
      method: customValidator.isObjectEmpty,
      validWhen: false,
      message: i18n("label.required"),
    },
  ],
};

const inputForceUser = {
  type: "Checkbox",
  key: "force",
  text: i18n("input.force.user.create"),
  description: i18n("input.force.user.create.description"),
};

export { components, inputProjectRole, inputForceUser };
