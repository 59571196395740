import validator from "validator";
import customValidator from "app/utils/validators/customValidator";
import { i18n } from "app/i18n";

const tabs = [{ name: i18n("label.project") }, { name: i18n("label.languages") }, { name: i18n("label.thumbnail") }];

const components = [
  {
    tab: 0,
    type: "Toggle",
    key: "enabled",
    title: i18n("label.enabled"),
  },
  {
    tab: 0,
    type: "Text",
    key: "name",
    title: i18n("label.name"),
    onBlur: { key: "slug" },
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    tab: 0,
    type: "Text",
    key: "slug",
    title: i18n("label.slug"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: customValidator.isProjectSlugAvailable,
        validWhen: true,
        args: { id: undefined },
        message: i18n("input.slug_in_use"),
      },
    ],
  },
  {
    tab: 1,
    type: "List",
    key: "languages",
    title: i18n("label.languages"),
    format: "table",
    components: [
      {
        type: "Select",
        key: "language",
        title: i18n("label.language"),
        disabled: true,
        options: [],
        rules: [
          {
            method: customValidator.isObjectEmpty,
            validWhen: false,
            message: i18n("input.required_field"),
          },
        ],
      },
      {
        type: "Checkbox",
        key: "default",
        title: i18n("label.default"),
        text: "Set default language of project",
      },
      {
        type: "Checkbox",
        key: "enabled",
        title: i18n("label.enabled"),
        text: "Define if it appears in frontend",
      },
    ],
  },
  {
    tab: 2,
    type: "Multimedia",
    key: "thumbnail",
    noAlt: true,
    title: "Thumbnail",
  },
];

export { tabs, components };
