import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";

import { setWhere, useGetTotalQuery, useGetUsersQuery } from "app/stores/users";
import { useDispatch, useSelector } from "react-redux";

// REQUEST
import { useGetSitemapQuery, useRefreshSitemapMutation } from "app/stores/project";
import Loading from "app/components/atoms/loading";
import Accordion from "app/components/molecules/accordion";
import { i18n } from "app/i18n";
import Icon from "app/components/atoms/icons/icon";

export default function TabSitemap() {
  // STORE
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.project);

  // REQUEST
  const { data: list, isLoading, refetch } = useGetSitemapQuery({ id: project?.id });
  const [refreshSitemap] = useRefreshSitemapMutation();

  // VARS
  const backendUrl = process.env.REACT_APP_BACKEND_HOST;

  const onClickButton = () => {
    if (!project?.url) return null;
    refreshSitemap({ id: project?.id }).then((e) => refetch());
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold tracking-tight text-slate-900">Sitemap</h1>
          <p className="mt-1 text-sm text-slate-500">See all pages available for sitemap</p>
        </div>
        <div>
          <div className="flex justify-end items-center gap-2">
            {project?.id && (
              <button
                onClick={onClickButton}
                disabled={!project?.url}
                className="rounded-md border border-transparent text-sm font-medium bg-blue-800 px-4 py-2 text-white shadow-sm disabled:bg-gray-200 disabled:cursor-not-allowed hover:bg-blue-500 focus:outline-none"
              >
                {i18n("button.update")}
              </button>
            )}
            {project?.url && (
              <Menu as="div" className="relative inline-block text-left outer-dropdown rounded-md">
                <div>
                  <Menu.Button className="relative btn-sm-primary text-primary-800 hover:bg-primary-500 bg-white hover:text-white shadow-sm focus:outline-none">
                    <Icon name="globe" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-20 bg-white divide-y rounded-md shadow-xl divide-primary-50 ring-1 ring-text ring-opacity-5 focus:outline-none origin-top-right mt-2 right-0">
                    <div className="py-2 px-4">
                      <a href={`${backendUrl}/${project?.slug}/sitemap.xml`} target="_blank" className="transition text-xs text-primary-800 hover:text-primary-500">
                        {`${backendUrl}/${project?.slug}/sitemap.xml`}
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
          </div>
        </div>
      </div>
      <div className="divide-y-slate-200 mt-6 space-y-8 divide-y">
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
          <div className="sm:col-span-6">
            {/* REFRESH */}
            <div className="mt-6">
              {!project?.url && <p className="my-10 text-sm text-gray-500 text-center">{i18n("label.project.required_url")}</p>}
              <Loading active={isLoading} />
              {!list?.error && list?.data?.length && (
                <Accordion title={`${list?.data?.length || 0} ${i18n("label.routes")}`} className="mt-8 bg-white">
                  <div className="p-5">
                    <ul className="list-disc pl-3">
                      {list.data.map((e, key) => (
                        <li key={key} className="leading-normal pl-3">
                          {e.url}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Accordion>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
