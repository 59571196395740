import React, { useEffect, useState } from "react";
import Form from "../../form";
import { components } from "./constants";
import { i18n } from "app/i18n";
import { useDispatch, useSelector } from "react-redux";
import { validation } from "app/utils/validators";
import { modesList } from "app/utils/roles";
import { cleanStructure } from "app/utils/content";
import { setUnsavedChanges, useUpdateProjectMutation } from "app/stores/project";
import { showServerError, showServerSuccess } from "app/utils/server";

export default function TabProject() {
  // PARAMS
  const dispatch = useDispatch();

  // STORE
  const { project, unsavedChanges } = useSelector((store) => store.project);

  // STATE
  const [value, setValue] = useState(null);
  const [changed, setChanged] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [structure, setStructure] = useState(components);

  // REQUEST
  const [updateProject, { isLoading }] = useUpdateProjectMutation();

  useEffect(() => {
    if (project) fillForm();
  }, [project]);

  useEffect(() => {
    if (changed !== unsavedChanges) dispatch(setUnsavedChanges(changed));
    return () => dispatch(setUnsavedChanges(false));
  }, [changed]);

  const buildStructure = () => {
    const temp = cleanStructure([...components]);
    const index = temp.findIndex((e) => e.key === "slug");
    if (index !== -1) temp[index].rules[1].args.id = project.id;
    setStructure([...temp]);
  };

  const fillForm = () => {
    const content = {
      url: project.url,
      name: project.name,
      slug: project.slug,
      enabled: project.enabled,
      thumbnail: project.thumbnail,
      secretKey: project.secretKey,
      mode: modesList.find((e) => e.value === project.mode),
    };
    // SET VALUE
    setValue(content);
    setDefaultValue(content);
    buildStructure();
  };

  const submitForm = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);

      if (validation.isValid) {
        // PREPARE VALUE
        const data = {
          id: project.id,
          url: value.url || undefined,
          name: value.name,
          slug: value.slug,
          enabled: !!value.enabled,
          mode: value.mode.value,
          thumbnail: undefined,
          settings: { ...project.settings },
        };

        // IF DISCONNECT AND THUMBNAIL ALREADY DECLARATED - DISCONNECT
        if (value.thumbnail && value.thumbnail.disconnect && project.thumbnail) data.thumbnail = value.thumbnail;
        // IF THUMBNAIL SELECTED AND DIFFERENT FROM CURRENT - SET NEW CONNECTION
        else if (value.thumbnail && value.thumbnail.id && (!project.thumbnail || value.thumbnail.id !== project.thumbnail.id)) data.thumbnail = { connect: { id: value.thumbnail.id } };

        updateProject(data).then((res) => {
          if (res?.data) showServerSuccess(i18n("toast.success_update"));
          else showServerError(res);
        });
      }
    });
  };

  const loading = isLoading;

  return (
    <div>
      <h1 className="text-3xl font-bold tracking-tight text-slate-900">Project</h1>
      <div className="divide-y-slate-200 mt-6 space-y-8 divide-y">
        {/* MAIN SETTING OF PROJECT */}
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
          <div className="sm:col-span-6">
            <h2 className="text-xl font-medium text-slate-900">Settings</h2>
            <p className="mt-1 text-sm text-slate-500">Information to identify the project</p>
            <div className="mt-6">
              <Form defaultValue={defaultValue} changed={setChanged} structure={structure.filter((e) => e.tab === "settings")} value={value} onChange={setValue} disabled={loading} />
            </div>
          </div>
        </div>

        {/* ENVIRONMENT */}
        <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
          <div className="sm:col-span-6">
            <h2 className="text-xl font-medium text-slate-900">Environment</h2>
            <p className="mt-1 text-sm text-slate-500">Information to identify the project</p>
            <div className="mt-6">
              <Form defaultValue={defaultValue} changed={setChanged} structure={structure.filter((e) => e.tab === "environment")} value={value} onChange={setValue} disabled={loading} />
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-x-3 pt-8">
          <button
            type="button"
            disabled={loading}
            onClick={fillForm}
            className="flex items-center relative justify-center rounded-md border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-slate-900 shadow-sm hover:bg-slate-50 focus:outline-none"
          >
            {i18n("label.cancel")}
          </button>
          <button
            type="submit"
            onClick={submitForm}
            disabled={!changed || loading}
            className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none  disabled:bg-blue-200 disabled:cursor-not-allowed"
          >
            {i18n("label.save")}
          </button>
        </div>
      </div>
    </div>
  );
}
