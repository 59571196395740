import { i18n } from "app/i18n";
import { modesList } from "app/utils/roles";
import customValidator from "app/utils/validators/customValidator";
import validator from "validator";

const components = [
  {
    tab: "settings",
    type: "Toggle",
    title: "Enabled",
    key: "enabled",
  },
  {
    tab: "settings",
    type: "Text",
    title: "Name",
    key: "name",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    tab: "settings",
    type: "Text",
    title: "Slug",
    key: "slug",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: customValidator.isProjectSlugAvailable,
        validWhen: true,
        args: { id: undefined },
        message: i18n("input.slug_in_use"),
      },
    ],
  },
  {
    tab: "settings",
    type: "Multimedia",
    title: "Thumbnail",
    key: "thumbnail",
    formats: ["IMAGE"],
    clearMode: true,
    noAlt: true,
  },
  {
    tab: "environment",
    type: "Text",
    title: "URL",
    key: "url",
  },
  {
    tab: "environment",
    type: "Select",
    title: "Environment Mode",
    key: "mode",
    options: modesList,
    rules: [
      {
        method: customValidator.isObjectEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    tab: "environment",
    type: "Text",
    title: "Secret Key",
    key: "secretKey",
    disabled: true,
    readOnly: true,
  },
];

export { components };
