import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { i18n } from "app/i18n";
import React from "react";

export default function Radio(props) {
  const { title, value, name, options, description, error, mode, columns = "gap-1 grid-cols-3 md:grid-cols-4", errorMessage, isRequired, onChange } = props;
  const styles = {
    title: "block text-sm font-medium mb-1",
    description: "block text-xs font-light text-slate-400 -mt-1.5 mb-1",
    columns: "gap-1",
  };
  const handleClick = (val) => {
    onChange(val);
  };

  const isChecked = (val) => {
    let temp = value || null;
    return temp === val;
  };
  if (error) styles.title += " text-red-600";
  else styles.title += " text-slate-700";

  if (mode === "sidebar") styles.columns += " flex flex-col";
  else styles.columns += ` grid ${columns}`;

  return (
    <div className="block mb-3">
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <div className={styles.columns}>
        {options?.length ? (
          <>
            {options.map((option, key) => (
              <div className="w-full" key={String(key)}>
                <label className={`flex items-center pl-3 rounded-lg ${isChecked(option.value) ? "bg-gray-100" : "bg-gray-50"} hover:bg-gray-200 cursor-pointer border border-gray-200`}>
                  <input
                    name={name}
                    type="radio"
                    value={option.value}
                    defaultChecked={isChecked(option.value)}
                    onClick={(ev) => handleClick(ev.target.value)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded-full border-gray-300 !outline-none !ring-0 !ring-transparent cursor-pointer"
                  />
                  <span className="py-2 ml-2 w-full text-xs font-light text-gray-900">{option.label}</span>
                </label>
              </div>
            ))}
          </>
        ) : (
          <p className="text-gray-600 text-sm -mt-1.5 mb-1">{i18n("label.nothing_found")}</p>
        )}
      </div>
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </div>
  );
}
