import { i18n } from "app/i18n";
import { setUnsavedChanges, useUpdateProjectMutation } from "app/stores/project";
import { showServerError, showServerSuccess } from "app/utils/server";
import { validation } from "app/utils/validators";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "../../form";
import { components } from "./constants";

export default function TabMultimedia() {
  // PARAMS
  const dispatch = useDispatch();

  // STORE
  const { project, unsavedChanges } = useSelector((store) => store.project);

  // STATE
  const [value, setValue] = useState(null);
  const [changed, setChanged] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [structure, setStructure] = useState(components);

  // REQUEST
  const [updateProject, { isLoading }] = useUpdateProjectMutation();

  useEffect(() => {
    if (project) fillForm();
  }, [project]);

  useEffect(() => {
    if (changed !== unsavedChanges) dispatch(setUnsavedChanges(changed));
    return () => dispatch(setUnsavedChanges(false));
  }, [changed]);

  const fillForm = () => {
    const content = {
      optimize: project?.settings?.media?.optimize,
      quality: project?.settings?.media?.quality || "80",
      maxWidth: project?.settings?.media?.maxWidth || 2400,
      customized: project?.settings?.media?.customized || false,
      customPath: project?.settings?.media?.customPath || undefined,
    };
    setValue(content);
    setDefaultValue(content);
  };

  const submitForm = () => {
    validation(structure, value, (structure, validation) => {
      let isValid = validation.isValid;
      let tempStructure = [...structure];

      if (value.customized && !value.customPath) {
        const index = tempStructure.findIndex((e) => e.key === "customPath");
        if (index !== -1) {
          isValid = false;
          tempStructure[index].error = "true";
          tempStructure[index].errorMessage = i18n("input.required_field");
        }
      }

      setStructure([...tempStructure]);
      if (isValid) {
        updateProject({ id: project.id, settings: { media: value } }).then((res) => {
          if (res?.data) showServerSuccess(i18n("toast.success_update"));
          else showServerError(res);
        });
      }
    });
  };

  const loading = isLoading;

  return (
    <div>
      <h1 className="text-3xl font-bold tracking-tight text-slate-900">Multimedia</h1>
      <div className="divide-y-slate-200 mt-6 space-y-8 divide-y">
        {/* SPECIFY THE MODULES ACTIVED FOR PROJECT */}
        <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
          <div className="sm:col-span-6">
            <h2 className="text-xl font-medium text-slate-900">Upload</h2>
            <p className="mt-1 text-sm text-slate-500">Choose options to upload files</p>
            <div className="mt-6">
              <Form defaultValue={defaultValue} changed={setChanged} structure={structure} value={value} onChange={setValue} disabled={loading} />
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-x-3 pt-8">
          <button
            type="button"
            onClick={fillForm}
            className="flex items-center relative justify-center rounded-md border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-slate-900 shadow-sm hover:bg-slate-50 focus:outline-none"
          >
            {i18n("label.cancel")}
          </button>
          <button
            type="submit"
            onClick={submitForm}
            disabled={!changed || loading}
            className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none  disabled:bg-blue-200 disabled:cursor-not-allowed"
          >
            {i18n("label.save")}
          </button>
        </div>
      </div>
    </div>
  );
}
