import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React from "react";

export default function Checkbox(props) {
  const { value, title, text, onChange, error, errorMessage, isRequired, description } = props;

  const styles = {
    title: "block text-sm font-medium mb-1",
    description: "block text-xs font-light text-slate-400 -mt-1.5 mb-1",
  };

  if (error) {
    styles.title += " text-red-600";
  } else {
    styles.title += " text-slate-700";
  }

  return (
    <div className="block mb-3">
      <div className="flex items-end justify-between">
        <div>{title && <label className={styles.title}>{title}</label>}</div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <label className="inline-flex pr-4">
        <div className="flex items-center h-5">
          <input
            type="checkbox"
            checked={value || false}
            onChange={() => onChange(!value)}
            aria-describedby="helper-checkbox-text"
            className="w-4 h-4 text-blue-600 bg-gray-100 hover:bg-gray-200 rounded border-gray-300 transition-all duration-300 ease-in-out !outline-none !ring-0 !ring-transparent cursor-pointer"
          />
        </div>
        <div className="ml-2 text-sm cursor-pointer">
          {text && <p className="font-medium text-gray-900 mb-1">{text}</p>}
          {description && <span className="block text-xs font-light text-slate-400 -mt-1 mb-1">{description}</span>}
          {error && <p className="text-xs text-red-600">{errorMessage}</p>}
        </div>
      </label>
    </div>
  );
}
