import React from "react";
import { i18n } from "app/i18n";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export default function Toggle(props) {
  const { title, value = false, texts = [i18n("label.active"), i18n("label.inactive")], onChange, disabled = false, error, errorMessage, isRequired, description } = props;

  const styles = {
    title: "block text-sm font-medium mb-1",
    label: "inline-flex relative items-center ",
    description: "block text-xs font-light text-slate-400 -mt-1.5 mb-1",
  };

  if (error) styles.title += " text-red-600";
  else styles.title += " text-slate-700";

  if (disabled) styles.label += " pointer-events-none";
  else styles.label += " cursor-pointer";

  return (
    <div className="block mb-3">
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <label className={styles.label}>
        <input type="checkbox" value="" className="sr-only peer" checked={value || false} disabled={disabled} onChange={() => onChange(!value)} />
        <div className="w-11 h-6 border-gray-300 bg-gray-200 peer-[:not(:checked)]:hover:bg-gray-300 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 peer-disabled:opacity-50 transition-all duration-300 ease-in-out !outline-0"></div>
        <span className="ml-3 text-sm font-normal text-gray-400">{value ? texts[0] : texts[1]}</span>
      </label>
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </div>
  );
}
