import { getRandomString } from "app/utils/content/index.js";
import { arraysAreFullEqual } from "app/utils/validators/dataValidator.js";
import update from "immutability-helper";
import { memo, useCallback, useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { Card } from "./item.js";

const Container = memo(function Container(props) {
  const { accept, value = [], errors, remove, duplicate, onChange } = props;

  const [cards, setCards] = useState(null);

  useEffect(() => {
    if (value) setCards(value.map((item) => ({ id: getRandomString(4), item })));
    return () => setCards(null);
  }, []);

  const findCard = useCallback(
    (id) => {
      const card = cards.filter((c) => `${c.id}` === id)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards]
  );

  const moveCard = useCallback(
    (id, atIndex) => {
      const { card, index } = findCard(id);

      const newElements = update(cards, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
      });
      setCards(newElements);

      const newValue = newElements?.length ? newElements.map((e) => e.item) : [];
      if (!arraysAreFullEqual(value, newValue)) {
        setTimeout(() => {
          onChange(newElements.map((e) => e.item));
        }, 900);
      }
    },
    [findCard, cards, setCards]
  );

  const [, drop] = useDrop(() => ({ accept }));

  return (
    <div ref={drop}>
      {!!cards?.length &&
        cards
          .filter((card) => card?.id)
          .map((card, key) => (
            <Card
              key={card?.id}
              id={`${card?.id}`}
              accept={accept}
              {...card?.item}
              moveCard={moveCard}
              findCard={findCard}
              remove={() => remove(key)}
              duplicate={() => duplicate(key)}
              error={errors && errors[key] && Object.keys(errors[key]).length > 0}
            />
          ))}
    </div>
  );
});

export default Container;
