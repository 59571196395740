export const isObject = (data, withLength) => {
  const r = typeof data === "object" && !Array.isArray(data);
  if (withLength) return r && !!Object.keys(data).length;
  return r;
};

export const isArray = (data, withLength) => {
  const r = typeof data === "object" && Array.isArray(data);
  if (withLength) return r && !!data.length;
  return r;
};

export const arraysAreEqual = (arr1, arr2) => {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }
  // Use every() to compare each element in the arrays
  return arr1.every((elem, index) => elem === arr2[index]);
};

export const arraysAreFullEqual = (arr1, arr2) => {
  // Check if the arrays have the same length
  if (!arr1 || !arr2 || arr1.length !== arr2.length) {
    return false;
  }

  // Iterate over each element in the arrays
  for (let i = 0; i < arr1.length; i++) {
    const elem1 = arr1[i];
    const elem2 = arr2[i];

    // If both elements are arrays, recursively check their equality
    if (Array.isArray(elem1) && Array.isArray(elem2)) {
      if (!arraysAreEqual(elem1, elem2)) {
        return false;
      }
    }
    // If both elements are objects, recursively check their equality
    else if (typeof elem1 === "object" && typeof elem2 === "object") {
      if (!objectsAreEqual(elem1, elem2)) {
        return false;
      }
    }
    // Otherwise, compare the elements
    else if (elem1 !== elem2) {
      return false;
    }
  }

  // Use every() to compare each element in the arrays
  return true;
};

export const objectsAreEqual = (obj1, obj2) => {
  if (!obj2) return true;

  // Check if objects have the same properties
  const keys1 = obj1 ? Object.keys(obj1) : [];
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // Compare property values
  for (let key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }
    if (obj1[key] !== obj2[key]) {
      return false;
    }
    if (isArray(obj1[key])) {
      if (!arraysAreEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
    if (isObject(obj1[key])) {
      if (!objectsAreEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
  }

  return true;
};
