import Icon from "app/components/atoms/icons/icon";
import Select from "app/components/atoms/inputs/select";
import Textarea from "app/components/atoms/inputs/textarea";
import Tooltip from "app/components/atoms/tooltip";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalOccurs from "app/components/molecules/modals/modalOccurs";
import ModalPreview from "app/components/molecules/modals/modalPreview";
import { i18n } from "app/i18n";
import { setFilesSelected, useDeleteMediaMutation, useUpdateMediaMutation } from "app/stores/files";
import { formatSize, getSource, getTranslation, isImageExtension, removeBreaklines, showFileType, showOrientation, sumSizes } from "app/utils/content";
import { showServerError, showServerSuccess } from "app/utils/server";
import axios from "axios";
import dayjs from "dayjs";
import JSZip from "jszip";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";

export default function MediaDetail(props) {
  const { files } = props;

  // REFS
  const downloadLinkRef = useRef(null);

  // STORE
  const dispatch = useDispatch();
  const { project, language, fileCategories } = useSelector((state) => state.project);

  // STATE
  const [value, setValue] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [modalOccurs, setModalOccurs] = useState(null);
  const [modalPreview, setModalPreview] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(null);
  const [editControl, setEditControl] = useState({ title: false, alt: false, categories: false });

  // REQUESTS
  const [updateMedia, { isLoading: isUpdating }] = useUpdateMediaMutation();
  const [deleteMedia, { isLoading: isDeleting }] = useDeleteMediaMutation();

  // VARS
  const isLoading = isUpdating || isDeleting;
  const fileUnique = files && files.length === 1 ? files[0].id : undefined;

  // CUSTOMIZE DATA FOR WHEN EDITION TOGGLE
  useEffect(() => {
    if (fileUnique) {
      setValue({
        alt: files[0].alt,
        title: files[0].title,
        categories: !!files[0]?.categories?.length ? files[0].categories.map((e) => ({ value: e.id, label: getTranslation(e.translations, language.slug) })) : undefined,
      });
      setEditControl({ title: false, alt: false, categories: false });
    } else {
      setValue(null);
      setEditControl({ title: false, alt: false, categories: false });
    }
    setVideoReady(false);
    // eslint-disable-next-line
  }, [fileUnique]);

  // FUNCTIONS

  // OPEN MODAL TO SEE IMAGE IN BIG
  const showImage = (file) => {
    setModalPreview({
      isOpen: true,
      href: getSource(file?.path),
      onClose: () => setModalPreview(null),
    });
  };

  // TOGGLE FOR ZONES (TITLE, ALT, CATEGORIES)
  const changeEdition = (zone) => {
    let temp = { ...editControl };
    temp[zone] = !temp[zone];
    setEditControl({ ...temp });
  };

  // WHEN SUBMIT CHANGES IN ZONES (TITLE, ALT, CATEGORIES)
  const submitChanges = (zone) => {
    const val = { [zone]: value[zone] };
    updateMedia({ id: fileUnique, ...val }).then((res) => {
      if (res && res.data) {
        showServerSuccess(i18n("toast.success_update"));
        setEditControl({ title: false, alt: false, categories: false });
      } else {
        showServerError(res);
      }
    });
  };

  // CLICK TO DELETE (CHECK VALIDATION IF FILE IN USE)
  const handleDelete = () => {
    const forceNotice = files.filter((e) => e.inUse).length;
    const mode = !!forceNotice ? "REQUIRED" : "BASIC";
    const notice = !forceNotice ? undefined : files.length > 1 ? i18n("alert.images_usage") : i18n("alert.image_usage");

    setModalConfirm({
      mode,
      notice,
      isOpen: true,
      type: "DELETE",
      forceNotice: !!forceNotice,
      text: i18n("alert.delete_file"),
      onConfirm: () => {
        const ids = files.map((item) => item.id);
        deleteMedia({ ids, project: project.id }).then((res) => {
          if (res && res.data) {
            showServerSuccess(i18n("toast.success_delete"));
            setModalConfirm(null);
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setModalConfirm(null),
    });
  };
  // CLICK TO DOWNLOAD (SINGLE AND ZIP)
  const handleDownload = async () => {
    if (files) {
      if (files.length === 1) {
        const fileURL = getSource(files[0].path);
        downloadLinkRef.current.href = fileURL;
        downloadLinkRef.current.click();
      } else {
        const filenames = [];
        const zip = new JSZip();
        const urls = files.filter((item) => item.type !== "EXTERNAL_VIDEO").map((item) => ({ href: getSource(item.path), title: item.title, extension: item.extension }));
        const requests = urls.map(async (item) => {
          const response = await axios.get(item.href, { responseType: "blob" });
          let filename = `${item.title}.${item.extension}`;
          let i = 1;
          while (filenames.includes(filename)) {
            filename = `${item.title}-${i}.${item.extension}`;
            i++;
          }
          filenames.push(filename);
          zip.file(filename, response.data);
        });
        await Promise.all(requests);

        const content = await zip.generateAsync({ type: "blob" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = `svalla_uploads_${dayjs().format("YYYYMMDD")}.zip`;
        link.click();

        // Clean up the temporary link
        URL.revokeObjectURL(link.href);
      }
    }
  };
  // CLICK TO COPY URL (JUST SINGLE)
  const handleCopyUrl = () => {
    const fileURL = getSource(files[0].path);
    navigator.clipboard
      .writeText(fileURL)
      .then(() => {
        showServerSuccess(i18n("toast.success_url_copy"));
      })
      .catch((error) => {
        console.error("Failed to copy URL to clipboard:", error);
      });
  };
  // CLICK TO SHOW WHERE THE IMAGE IS BEING USED
  const onOpenWhereAppears = () => {
    const id = files?.length ? files[0].id : null;
    setModalOccurs({
      id,
      isOpen: true,
      onClose: () => setModalOccurs(null),
    });
  };
  // UNSELECT FILES
  const onUnselect = () => {
    dispatch(setFilesSelected(null));
  };

  return (
    <>
      <aside
        className={`absolute right-0 z-30 min-h-full max-h-full transition-all duration-500 ease-in-out overflow-y-auto bg-white border-l border-gray-200 ${
          !!files?.length ? "w-96 p-8" : "w-0 p-0"
        } xl:relative xl:right-auto xl:block `}
      >
        {!!files?.length && (
          <div className="pb-24 space-y-6">
            {files.length === 1 ? (
              <>
                <div>
                  {/* THUMBNAIL */}
                  <div className="block relative w-full overflow-hidden rounded-lg aspect-h-7 aspect-w-10 group">
                    {files[0].type === "IMAGE" || isImageExtension(files[0].extension) ? (
                      <>
                        <img src={getSource(files[0].path)} alt="" className="mx-auto object-cover" />
                        <button
                          type="button"
                          onClick={() => showImage(files[0])}
                          className="cursor-pointer transition-all duration-500 absolute opacity-0 inset-0 z-10 py-3 px-4 flex items-end justify-end group group-hover:bg-gray-100/75 group-hover:opacity-100"
                        >
                          <Icon name="expand" className="drop-shadow-lg" />
                        </button>
                      </>
                    ) : (
                      <div className="w-full aspect-video flex flex-col items-center justify-center bg-gray-300 ">
                        {files[0].type.includes("VIDEO") ? (
                          <>
                            <span
                              type="button"
                              onClick={() => {
                                setIsPlaying(true);
                                setIsPlaying(true);
                              }}
                              className="mb-2 transition-all duration-500 border border-gray-500 aspect-square rounded-full p-2 hover:bg-gray-500 hover:text-white"
                            >
                              <Icon name="play" />
                            </span>
                            <span className="text-lg font-bold uppercase">{files[0].extension}</span>
                            <div className={`transition-all duration-500 absolute inset-0 ${videoReady ? "opacity-100" : "opacity-0"}`}>
                              <ReactPlayer
                                width="100%"
                                height="100%"
                                url={getSource(files[0]?.path)}
                                playing={isPlaying}
                                controls={true}
                                muted={true}
                                onReady={() => {
                                  setVideoReady(true);
                                  setIsPlaying(true);
                                }}
                                onPause={() => setIsPlaying(false)}
                                onPlay={() => setIsPlaying(true)}
                              />
                            </div>
                          </>
                        ) : (
                          <span className="text-3xl font-bold uppercase">{files[0].extension}</span>
                        )}
                      </div>
                    )}
                  </div>

                  {/* TITLE */}
                  {editControl && (
                    <div className={`file-title flex items-start justify-between mt-4 ${editControl.title ? "edition-mode" : ""}`}>
                      <div className="flex-1">
                        <h2 className="text-lg font-medium text-gray-900 break-all">
                          <span className="sr-only">{i18n("label.detail_for")} </span>
                          {editControl.title ? (
                            <Textarea
                              value={value && value.title}
                              disabled={!editControl.title}
                              placeholder={i18n("placeholder.title")}
                              onChange={(title) => setValue({ ...value, title: removeBreaklines(title) })}
                            />
                          ) : (
                            <span>{files[0].title}</span>
                          )}
                        </h2>
                        <p className="text-sm font-medium text-gray-500 uppercase">{showFileType(files[0].type)}</p>
                      </div>

                      <div className="flex flex-col gap-1">
                        {!editControl.title ? (
                          <Tooltip message={i18n("label.edit")}>
                            <button type="button" className="btn-icon ml-2" onClick={() => changeEdition("title")}>
                              <Icon name="pencil" />
                              <span className="sr-only">{i18n("placeholder.title")}</span>
                            </button>
                          </Tooltip>
                        ) : (
                          <>
                            <Tooltip message={i18n("label.save")}>
                              <button type="button" className="btn-icon good ml-2" onClick={() => submitChanges("title")}>
                                <Icon name="check" />
                                <span className="sr-only">{i18n("label.save")}</span>
                              </button>
                            </Tooltip>
                            <Tooltip message={i18n("label.cancel")}>
                              <button type="button" className="btn-icon cancel ml-2" onClick={() => changeEdition("title")}>
                                <Icon name="cross" />
                                <span className="sr-only">{i18n("label.cancel")}</span>
                              </button>
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {/* INFORMATION */}
                <div>
                  <h3 className="font-medium text-gray-900">{i18n("label.information")}</h3>
                  <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                    {!files[0].type.includes("EXTERNAL_") && (
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">{i18n("label.size")}</dt>
                        <dd className="text-gray-900 whitespace-nowrap">{formatSize(files[0].size)}</dd>
                      </div>
                    )}

                    {files[0].type === "IMAGE" && (
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">{i18n("label.dimension")}</dt>
                        <dd className="text-gray-900 whitespace-nowrap">
                          {files[0].width} x {files[0].height}
                        </dd>
                      </div>
                    )}
                    {files[0].type === "IMAGE" && (
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">{i18n("label.orientation")}</dt>
                        <dd className="text-gray-900 whitespace-nowrap">{showOrientation(files[0].orientation)}</dd>
                      </div>
                    )}
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">{i18n("label.extension")}</dt>
                      <dd className="text-gray-900 whitespace-nowrap uppercase">{files[0].extension}</dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">{i18n("label.created_at")}</dt>
                      <dd className="text-gray-900 whitespace-nowrap">{dayjs(files[0].createdAt).format("MMM D, YYYY HH:mm")}</dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">{i18n("label.uploaded_by")}</dt>
                      <dd className="text-gray-900 whitespace-nowrap">{files[0].uploadBy.name}</dd>
                    </div>
                  </dl>
                </div>

                {/* ALTERNATIVE TEXT */}
                {editControl && (
                  <div className={`file-alternative-text ${editControl.alt ? "edition-mode" : ""}`}>
                    <h3 className="font-medium text-gray-900 flex items-center justify-between">
                      <span>{i18n("label.alt")}</span>
                      {!editControl.alt && (
                        <Tooltip message={i18n("label.edit")}>
                          <button type="button" className="text-label ml-2" onClick={() => changeEdition("alt")}>
                            <Icon name="pencil" height="h-4" width="w-4" />
                            <span className="sr-only">{i18n("placeholder.alt")}</span>
                          </button>
                        </Tooltip>
                      )}
                    </h3>
                    <div className="flex flex-col items-start justify-between mt-1">
                      <div className="w-full">
                        <Textarea value={value && value.alt} onChange={(alt) => setValue({ ...value, alt })} disabled={!editControl.alt} placeholder={i18n("placeholder.alt")} />
                      </div>
                      <div className="w-full flex flex-row justify-end gap-1">
                        {editControl.alt && (
                          <>
                            <Tooltip message={i18n("label.save")}>
                              <button type="button" className="btn-icon good ml-2" onClick={() => submitChanges("alt")}>
                                <Icon name="check" />
                                <span className="sr-only">{i18n("label.save")}</span>
                              </button>
                            </Tooltip>
                            <Tooltip message={i18n("label.cancel")}>
                              <button type="button" className="btn-icon cancel ml-2" onClick={() => changeEdition("alt")}>
                                <Icon name="cross" />
                                <span className="sr-only">{i18n("label.cancel")}</span>
                              </button>
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* CATEGORIES */}
                <div>
                  <h3 className="font-medium text-gray-900 flex items-center justify-between">
                    <span>{i18n("label.categories")}</span>
                    {!editControl.categories && (
                      <Tooltip message={i18n("label.edit")}>
                        <button type="button" className="text-label ml-2" onClick={() => changeEdition("categories")}>
                          <Icon name="pencil" height="h-4" width="w-4" />
                          <span className="sr-only">{i18n("placeholder.categories")}</span>
                        </button>
                      </Tooltip>
                    )}
                  </h3>
                  <div className="mt-1 relative w-full">
                    <div className="w-full">
                      {value?.categories?.length ? (
                        <div className="flex flex-wrap gap-0.5">
                          {value.categories.map((cat, key) => (
                            <span key={key} className="inline-flex items-center px-2 py-1 text-sm font-medium text-gray-500 bg-gray-200 rounded">
                              {cat.label}
                            </span>
                          ))}
                        </div>
                      ) : (
                        <p className="text-sm italic text-gray-500">{i18n("placeholder.categories")}</p>
                      )}
                    </div>
                    {editControl.categories && (
                      <div className="w-full mt-2">
                        <Select
                          multiple
                          creatable
                          hideSelection
                          options={fileCategories}
                          value={value?.categories}
                          disabled={!editControl.categories}
                          placeholder={i18n("placeholder.categories")}
                          onChange={(categories) => setValue({ ...value, categories })}
                        />
                        <div className="flex justify-end gap-1">
                          {editControl.categories && (
                            <>
                              <Tooltip message={i18n("label.save")}>
                                <button type="button" className="btn-icon good ml-2" onClick={() => submitChanges("categories")}>
                                  <Icon name="check" />
                                  <span className="sr-only">{i18n("label.save")}</span>
                                </button>
                              </Tooltip>
                              <Tooltip message={i18n("label.cancel")}>
                                <button type="button" className="btn-icon cancel ml-2" onClick={() => changeEdition("categories")}>
                                  <Icon name="cross" />
                                  <span className="sr-only">{i18n("label.cancel")}</span>
                                </button>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : files.length > 1 ? (
              <>
                <div>
                  <div className="block relative w-full rounded-lg aspect-square">
                    {files
                      .filter((e, key) => key < 3)
                      .map((item, key) => {
                        const first = !key;

                        let className = "overflow-hidden rounded-lg origin-top-left scale-95 ";
                        if (first) className += "relative";
                        else className += "absolute top-0 left-0 h-full w-full ";

                        if (key === 1) className += "ml-2 mt-2";
                        else if (key === 2) className += "ml-4 mt-4";

                        return (
                          <div key={key} className={className}>
                            <img src={getSource(item.path)} alt="" className="aspect-square object-cover bg-gray-300" />
                          </div>
                        );
                      })}
                    {files.length > 3 && (
                      <div className="bg-gray-200 flex items-center justify-center overflow-hidden rounded-lg origin-top-left scale-95 absolute top-0 left-0 h-full w-full ml-6 mt-6">
                        <span className="flex items-center justify-center w-16 text-lg bg-slate-800 text-white aspect-square rounded-full">+{files.length - 3}</span>
                      </div>
                    )}
                  </div>
                  <div className="flex items-start justify-between mt-4">
                    <div>
                      <h2 className="text-lg font-medium text-gray-900">
                        <span className="sr-only">{i18n("label.detail_for")} </span>
                        {files.length} {i18n("label.files")}
                      </h2>
                      <p className="text-sm font-medium text-gray-500">{formatSize(sumSizes(files))}</p>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <div className="flex flex-col gap-y-3">
              <div className="flex justify-center gap-x-3">
                {files.length === 1 && files[0].inUse && (
                  <Tooltip message={i18n("label.where_appears")}>
                    <button type="button" className="btn-icon" onClick={onOpenWhereAppears}>
                      <Icon name="comment" />
                    </button>
                  </Tooltip>
                )}
                <Tooltip message={i18n("label.download")}>
                  <button type="button" className="btn-icon" onClick={handleDownload}>
                    <Icon name="download" />
                  </button>
                  <a href="#" ref={downloadLinkRef} className="hidden">
                    &nbsp;
                  </a>
                </Tooltip>
                {files.length === 1 && (
                  <Tooltip message={i18n("label.copy_url")}>
                    <button type="button" className="btn-icon" onClick={handleCopyUrl}>
                      <Icon name="share" />
                    </button>
                  </Tooltip>
                )}
                <Tooltip message={i18n("label.delete")}>
                  <button type="button" className="btn-icon cancel" onClick={handleDelete}>
                    <Icon name="delete" />
                  </button>
                </Tooltip>
              </div>
              <button
                type="button"
                className="flex-1 px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={onUnselect}
              >
                {i18n("button.unselect")}
              </button>
            </div>
          </div>
        )}
        {isLoading && "isLoading"}
      </aside>

      <ModalOccurs {...modalOccurs} />
      <ModalPreview {...modalPreview} />
      <ModalConfirm {...modalConfirm} />
    </>
  );
}
