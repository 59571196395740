import { i18n } from "app/i18n";
import { useCreateEnvironmentMutation, useUpdateEnvironmentMutation } from "app/stores/environment";
import { setUnsavedChanges } from "app/stores/project";
import { showServerError, showServerSuccess } from "app/utils/server";
import { validation } from "app/utils/validators";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { components } from "./constants";
import EmailLayout from "../../builder/EmailLayout";

export default function TabEmailDesign(props) {
  // PARAMS
  const { active } = props;
  const dispatch = useDispatch();

  // STATE
  const key = "emailConfig";
  const [value, setValue] = useState(null);
  const [changed, setChanged] = useState(false);
  const [structure, setStructure] = useState(components);
  const [defaultValue, setDefaultValue] = useState(null);

  // STORE
  const { environments } = useSelector((store) => store.environments);
  const { unsavedChanges } = useSelector((store) => store.project);

  // REQUEST
  const [createEnvironment, { isLoading: isCreating }] = useCreateEnvironmentMutation();
  const [updateEnvironment, { isLoading: isUpdating }] = useUpdateEnvironmentMutation();

  const isLoading = isCreating || isUpdating;

  useEffect(() => {
    if (!active) {
      setValue(null);
      setDefaultValue(null);
    } else if (environments?.length) {
      const item = environments.find((e) => e.key === key);
      if (item) {
        setValue({ ...item.value });
        setDefaultValue({ ...item.value });
      }
    }
  }, [active, environments]);

  useEffect(() => {
    if (changed !== unsavedChanges) dispatch(setUnsavedChanges(changed));
    return () => dispatch(setUnsavedChanges(false));
  }, [changed]);

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);

      if (validation.isValid) {
        const index = environments.findIndex((item) => item.key === key);
        // CREATE
        if (index === -1)
          createEnvironment({ key, value }).then((res) => {
            if (res?.data) {
              showServerSuccess(i18n("toast.success_update"));
              setDefaultValue({ ...value });
            } else {
              showServerError(res);
            }
          });
        // UPDATE
        else
          updateEnvironment({ id: environments[index].id, value }).then((res) => {
            if (res?.data) {
              showServerSuccess(i18n("toast.success_update"));
              setDefaultValue({ ...value });
            } else {
              showServerError(res);
            }
          });
      }
    });
  };

  return (
    <div>
      <div>
        <h3 className="text-slate-800 font-bold text-xl">{i18n("settings.dashboard_title")}</h3>
        <p className="text-slate-500 font-normal text-sm">{i18n("settings.dashboard_description")}</p>
      </div>
      <div className="my-8">
        <EmailLayout defaultValue={defaultValue} changed={setChanged} value={value} structure={structure} onChange={setValue} errors={null} disabled={isLoading} />
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          disabled={!changed || isLoading}
          onClick={onClickSubmit}
          className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none  disabled:bg-blue-200 disabled:cursor-not-allowed"
        >
          {i18n("button.submit")}
        </button>
      </div>
    </div>
  );
}
