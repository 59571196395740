import Tooltip from "app/components/atoms/tooltip";
import React from "react";
import { i18n } from "app/i18n";

export default function LabelRevisionStatus(props) {
  const { message, status } = props;

  const styles = {
    kbd: "px-3 py-1.5 whitespace-nowrap text-xs font-semibold rounded-xl inline-flex items-center ml-2",
    span: "h-1.5 w-1.5 rounded-full inline-block mr-2",
    DRAFT: {
      kbd: "text-slate-600 bg-slate-200",
      span: "bg-slate-600",
      text: i18n("label.draft"),
    },
    PUBLIC: {
      kbd: "text-green-600 bg-green-200",
      span: "bg-green-600",
      text: i18n("label.published"),
    },
  };
  if (!status || !styles[status]) return null;

  return (
    <Tooltip message={message} disabled={!message} placement="top">
      <kbd className={`${styles.kbd} ${styles[status].kbd}`}>
        <span className={`${styles.span} ${styles[status].span}`} />
        {`${styles[status].text || ""}`}
      </kbd>
    </Tooltip>
  );
}
